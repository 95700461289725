/*
 * note reducer
 */

import * as C from './types'

const FETCHING = 'FETCHING'
const SUCCESS = 'SUCCESS'
const ERROR = 'ERROR'

const queuesInitialState = {
  data: {
    list: [],
    listByOrg: {},
    listByUser: {}
  },
  status: undefined
}
const queueInitialState = {
  data: {},
  status: undefined
}

const priorityQueuesReducer = (state = { ...queuesInitialState }, action) => {
  switch (action.type) {
  case C.FETCH_PRIORITY_QUEUES_REQUEST:
    return {
      ...state,
      status: FETCHING
    }
  case C.FETCH_PRIORITY_QUEUES_SUCCESS:
    return {
      data: action.payload,
      status: SUCCESS
    }
  case C.FETCH_PRIORITY_QUEUES_FAILURE:
    return {
      data: {},
      status: ERROR
    }
  default:
    return state
  }
}

const priorityQueueReducer = (state = { ...queueInitialState }, action) => {
  switch (action.type) {
  case C.FETCH_PRIORITY_QUEUE_REQUEST:
    return {
      ...state,
      status: FETCHING
    }
  case C.FETCH_PRIORITY_QUEUE_SUCCESS:
    return {
      data: action.payload,
      status: SUCCESS
    }
  case C.FETCH_PRIORITY_QUEUE_FAILURE:
    return {
      data: {},
      status: ERROR
    }
  case C.CREATE_PRIORITY_QUEUE_REQUEST:
  case C.UPDATE_PRIORITY_QUEUE_REQUEST:
    return {
      ...state,
      status: FETCHING
    }
  case C.CREATE_PRIORITY_QUEUE_SUCCESS:
  case C.UPDATE_PRIORITY_QUEUE_SUCCESS:
    return {
      data: action.payload,
      status: SUCCESS
    }
  case C.CREATE_PRIORITY_QUEUE_FAILURE:
  case C.UPDATE_PRIORITY_QUEUE_FAILURE:
    return {
      data: {},
      status: ERROR
    }
  case C.REMOVE_PRIORITY_QUEUE_REQUEST:
    return {
      ...state,
      status: FETCHING
    }
  case C.REMOVE_PRIORITY_QUEUE_SUCCESS:
    return {
      data: {},
      status: SUCCESS
    }
  case C.REMOVE_PRIORITY_QUEUE_FAILURE:
    return {
      data: {},
      status: ERROR
    }
  case C.SET_PRIORITY_QUEUE:
    return {
      data: action.payload,
      status: SUCCESS
    }
  case C.CLEAR_PRIORITY_QUEUE:
    return {
      data: {},
      status: SUCCESS
    }
  default:
    return state
  }
}

const priorityQueueSettingsReducer = (state = {}, action) => {
  switch (action.type) {
  case C.SYNC_PRIORITY_QUEUE_SETTINGS:
    return action.payload ? action.payload : {}
  default:
    return state
  }
}

export { priorityQueuesReducer, priorityQueueReducer, priorityQueueSettingsReducer }
