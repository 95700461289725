import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { ChevronRight, ExpandMore } from '@material-ui/icons'
import StandardTable from '../standard-table'

/**
 * Component to hold a group of rows for the Nested Table.
 *
 * @param {object[]} childTableData       The data to display in the child table.
 * @param {object[]} childTableHeaders    The headers to display for the child table.
 * @param {function} childTableOnDelete   The function to execute when Deleting a record from the child table.
 * @param {function} childTableOnEdit     The function to execute when Editing a record in the child table.
 * @param {object} pagination             Pagination data to be passed to the `StandardTable` component.
 * @param {object} parentTableActionsCell Actions that can be performed in the parent row, executed against the parent level record.
 * @param {object[]} parentTableHeaders   Headers to display for the parent row.
 */
class NestedTableRowGroup extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: this.props.expanded || false
    }

    this.toggleRowVisibility = this.toggleRowVisibility.bind(this)
  }

  toggleRowVisibility () {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render () {
    const {
      parentTableHeaders,
      parentTableActionsCell,
      childTableHeaders,
      childTableData,
      childTableOnCopy,
      childTableOnEdit,
      childTableOnDelete,
      pagination,
      enableRowSelection,
      onSelectRow,
      onSelectAllRows,
      sortable,
      draggable,
      ...props
    } = this.props

    const reallySortable = !(props.enableChildSort === false) && sortable
    const reallyDraggable = !(props.enableChildDrag === false) && draggable

    return (
      <Fragment>
        <thead>
          <tr>
            <td onClick={this.toggleRowVisibility} className="header-row flex items-center pa2 mb1">
              {this.state.isOpen ? <ExpandMore /> : <ChevronRight />}
              {parentTableHeaders}
            </td>
            {parentTableActionsCell}
          </tr>
        </thead>
        {this.state.isOpen && (
          <tbody>
            <tr>
              <td colSpan={2}>
                <StandardTable
                  tableData={childTableData}
                  tableHeaders={childTableHeaders}
                  pagination={{ ...pagination, minRowCount: 1 }}
                  handleFocus={childTableOnEdit}
                  handleDelete={childTableOnDelete}
                  handleCopy={childTableOnCopy}
                  enableRowSelection={enableRowSelection}
                  onSelectRow={onSelectRow}
                  onSelectAllRows={onSelectAllRows}
                  sortable={reallySortable}
                  draggable={reallyDraggable}
                  {...props}
                />
              </td>
            </tr>
          </tbody>
        )}
      </Fragment>
    )
  }
}

NestedTableRowGroup.propTypes = {
  childTableData: PropTypes.array.isRequired,
  childTableHeaders: PropTypes.array.isRequired,
  childTableOnCopy: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  childTableOnDelete: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  childTableOnEdit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  pagination: PropTypes.object,
  parentTableActionsCell: PropTypes.object,
  parentTableHeaders: PropTypes.array.isRequired,
  enableRowSelection: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onSelectAllRows: PropTypes.func,
  expanded: PropTypes.bool
}

NestedTableRowGroup.defaultProps = {
  parentTableActionsCell: <td />,
  childTableData: []
}

export default NestedTableRowGroup
