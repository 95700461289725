/*
 * Actions for Notify services
 */

import * as C from './types'
import log from 'services/logger'

/**
 * Notification payload:
 */
const notificationBase = {
  message: 'String',
  type: 'error', // could be 'info', 'error', 'warning'
  sticky: false, // does the notification disappear automatically?
  id: Date.now(), // generated automatically for use in removing notifications,
  timeout: null // override the default timeout
}

/**
 * Add a notification, with optional ability to pass in an error to log, and whether to send to Sentry
 * @param {Object} payload           The notification payload
 * @param {String} payload.message   The notification message
 * @param {String} [payload.type]    The type and variant of the error to display, default: `error`
 * @param {Boolean} [payload.sticky] Whether the notification should stick or disappear after timeout, default: `false`
 * @param {Number} [payload.timeout] Specify a custom timeout for the notification to disappear in, default `5000`
 * @param {Error} [errorToLog]       An optional `Error` to log
 * @param {Boolean} [sendToSentry]   Optionally send this error to Sentry
 */
export const addNotification = (payload, errorToLog, sendToSentry) => {
  let newPayload = Object.assign({}, notificationBase, payload, {
    id: Date.now()
  })
  return (dispatch, getState) => {
    dispatch({
      type: C.ADD_NOTIFICATION,
      payload: newPayload
    })

    if (errorToLog && errorToLog instanceof Error) {
      log(null, errorToLog, sendToSentry)
    }
  }
}

export const removeNotification = id => ({
  type: C.REMOVE_NOTIFICATION,
  payload: id
})

export const clearNotifications = () => ({
  type: C.CLEAR_NOTIFICATIONS
})
