/*
 * Tabs component
 * Should be able to handle 2 layers of tab menus
 * based on the UI design given
 * tab: {
     name: 'Name' || function(props) {}, // Can be a string or a function
                                         // with props passed, should return a
                                         // string
     component: Component, // the component object to display in the tab content
     active: true | false, // whether this is active or not, controlled via
                              DashboardEmitter
     enabled: true | false | function(props) {}, // use this to control
                                                    permissions, defaults true
     children: [] // child tabs, these have the same structure
 * }
 *
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ErrorBoundary from './error-boundary'

import './styles/tabs.scss'

const tabClass = tab => {
  const activeClass = tab.active ? 'active' : ''
  const errorClass =
    tab.error && ((typeof tab.error === 'function' && tab.error()) || tab.error === true)
      ? 'error'
      : ''
  return `tab tab-${tab.id} ${activeClass} ${errorClass}`
}

class TabbedBox extends Component {
  changeTab (tab, child) {
    let { box, changeTab, eventEmitter } = this.props
    // changeTab can be passed to manage tabs in parent state
    if (changeTab) {
      return changeTab({ box, tab, child })
    }

    // if its not a tab object, the event param also gets passed
    if (!child.id) child = undefined

    if (tab.location) {
      this.props.history.push(tab.location)
    }

    eventEmitter.emit('changeTab', { box, tab, child })
  }

  render () {
    let { ...props } = this.props
    let Content = null
    let childTabsDOM
    let buttonDOM
    let tabDOM = this.props.tabs.map(tab => {
      if (typeof tab.enabled !== 'undefined' && tab.enabled === false) {
        return null
      }
      if (typeof tab.enabled === 'function' && !tab.enabled(props)) return null

      if (tab.active) Content = tab.component

      let tabName
      if (typeof tab.name === 'function') {
        tabName = tab.name(props)
      } else {
        tabName = tab.name
      }

      if (tab.children && tab.active) {
        childTabsDOM = tab.children.map(c => {
          if (typeof c.enabled !== 'undefined' && c.enabled === false) {
            return null
          }
          if (typeof c.enabled === 'function' && !c.enabled(props)) return null

          if (c.active) Content = c.component

          let childTabName
          if (typeof c.name === 'function') {
            childTabName = c.name(props)
          } else {
            childTabName = c.name
          }

          return (
            <button
              key={`child-tab-${c.id}`}
              onClick={this.changeTab.bind(this, tab, c)}
              className={tabClass(c)}
            >
              <span>{childTabName}</span>
            </button>
          )
        })
      }

      return (
        <button
          key={`tab-${tab.id}`}
          onClick={this.changeTab.bind(this, tab)}
          className={tabClass(tab)}
          type="button"
        >
          <span>{tabName}</span>
        </button>
      )
    })

    if (Array.isArray(this.props.buttons)) {
      buttonDOM = this.props.buttons.map((button, i) => {
        let key = `${this.props.box}${button.name}${i}`
        let Component = button.component
        return <Component key={key} {...props} />
      })
    }

    return (
      <div className="tabbed-box">
        <div className="tab-container">
          {tabDOM}
          <div className="context-buttons">{buttonDOM}</div>
        </div>
        {childTabsDOM ? <div className="tab-container children">{childTabsDOM}</div> : null}
        <div className="tab-contents">
          <ErrorBoundary>{Content ? <Content {...this.props} /> : null}</ErrorBoundary>
        </div>
      </div>
    )
  }
}

TabbedBox.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
  eventEmitter: PropTypes.any
}

export { TabbedBox }
