import dayjs from 'dayjs'
import { get, set } from 'lodash'
import { DateFormat, Messages } from 'constants/date'
import { isValidDateObject, isFuture } from 'validators/date'

const validateDate = (date, errorKeyName) => {
  if (!isValidDateObject(dayjs(date).format(DateFormat.DATE_DISPLAY_SHORT), DateFormat.DATE_DISPLAY_SHORT)) return set({}, errorKeyName, Messages.INVALID_DATE)
  if (isFuture(date)) return set({}, errorKeyName, Messages.FUTURE_DATE)
}

export const allergyValidation = allergy => {
  let errors = {}
  const substance = allergy.substance || {}
  const allergyOnsetDate = get(allergy, 'onsetDate')

  if (!allergy.nkda && (!substance.text || !substance.text.length)) {
    set(errors, 'substance.text', 'Allergy selection is required')
  }
  if (!allergy.nkda && !allergy.reaction) errors.reaction = 'Please add a reaction'

  if (allergyOnsetDate) {
    const onsetErrors = validateDate(allergyOnsetDate, 'onsetDate')
    errors = Object.assign(errors, onsetErrors)
  }

  return errors
}

export const sideEffectValidation = se => {
  var errors = {}
  const substance = se.substance || {}
  const seOnsetDate = get(se, 'onsetDate')

  if (!substance.text || !substance.text.length) {
    set(errors, 'substance.text', 'Side Effect selection is required')
  }
  if (!se.reaction) errors.reaction = 'Please add a reaction'
  
  if (seOnsetDate) {
    const onsetErrors = validateDate(seOnsetDate, 'onsetDate')
    errors = Object.assign(errors, onsetErrors)
  }
  
  return errors
}

export const conditionValidation = condition => {
  var errors = {}
  const code = condition.code || {}
  const conditionOnsetDate = get(condition, 'onsetDate')

  if (!code.text || !code.text.length) {
    set(errors, 'code.text', 'Condition selection is required')
  }
  
  if (conditionOnsetDate) {
    const onsetErrors = validateDate(conditionOnsetDate, 'onsetDate')
    errors = Object.assign(errors, onsetErrors)
  }
  
  return errors
}

export const observationValidation = observation => {
  let errors = {}
  const selectObs = get(observation, 'deleteValues.selectObs') || ''
  const observationIssued = get(observation, 'issued')

  if (!selectObs) {
    set(errors, 'deleteValues.selectObs', 'Observation is required')
  }
  if (selectObs === 'BMI' && !observation.deleteValues.weight) {
    set(errors, 'deleteValues.weight', 'Weight is required')
  }
  if (
    !get(observation, 'valueQuantity.value') &&
    (selectObs === 'Creatinine Serum' || selectObs === 'Custom')
  ) {
    set(errors, 'valueQuantity.value', 'Value is required')
  }
  if (selectObs === 'Custom' && !observation.text) {
    set(errors, 'text', 'Observation name is required')
  }

  if (observationIssued) {
    const issuedErrors = validateDate(observationIssued, 'issued')
    errors = Object.assign(errors, issuedErrors)
  }
  
  return errors
}

export const riskFactorValidation = rf => {
  var errors = {}
  if (!rf.category) {
    set(errors, 'category', 'Category selection is required')
  }
  if (!rf.riskFactor) {
    set(errors, 'riskFactor', 'Please add a Risk Factor')
  }
  return errors
}