import gateway from 'services/gateway'
import {
  capitalize as _capitalize,
  get as _get,
  filter as _filter,
  sortBy as _sortBy
} from 'lodash'
import { RXNORM_RXCUI, FHIR_NDC, FDB_ROUTE, SNOMED, FDB_VERSION } from 'constants/systems'

/**
 * Search for medication. Must supply one of `name`, `ndc`, or `rxcui`
 * @param  {object} options
 * @param  { string } options.name The name, or partial name of the medication
 * @param  { string } options.ndc The NDC of the medication
 * @param  { string } options.rxcui The RXCUI of the medication
 * @return {[type]}         [description]
 */
export const searchMedication = async options => {
  let result
  if (options.name) {
    result = await gateway.get('/api/fdb/medication', {
      _content: options.name,
      searchType: 'StartsWith',
      searchTermTypeCodes: '4',
      includeFormularyMedications: options.includeFormularyMedications,
      orgId: options.orgId
    })
  } else if (options.code) {
    result = gateway.get('/api/fdb/medication', options)
  } else {
    throw new Error('Missing valid search parameters')
  }

  return result
}

export const getRXCUICoding = medication => {
  return _filter(_get(medication, 'code.coding'), coding => coding.system === RXNORM_RXCUI)[0]
}

export const getNDCCoding = medication => {
  return _filter(_get(medication, 'code.coding'), coding => coding.system === FHIR_NDC)[0]
}

export const filterNoRXCUIs = medications => {
  return medications.filter(med => {
    return _get(med, 'medication.code.coding', []).find(code => code.system === RXNORM_RXCUI)
  })
}

export const getRoute = medication => {
  let route
  const extension = _get(medication, 'extension', [])
  for (let i = 0; i < extension.length; i++) {
    const valueCodeableConcept = _get(extension[i], 'valueCodeableConcept.coding', [])
    for (let j = 0; j < valueCodeableConcept.length; j++) {
      const concept = valueCodeableConcept[j]
      if (concept.system === FDB_ROUTE) {
        route = concept
      }
    }
  }

  return route
}

const getCoding = medication => {
  return _get(medication, 'code.coding', []).map(coding => {
    coding.value = coding.code
    return coding
  })
}

/**
 * This should mostly be deprecated in the new backend, since we will actually be storing MedicationStatments
 * with Medication bundled inside. However, we might have some custom stuff we want to keep.
 * @param  {Object}  medication The Medication object
 * @return {Promise}            [description]
 */
export const buildMedication = medication => {
  if (!_get(medication, 'code.coding')) {
    throw new Error('Missing valid medication')
  }

  const route = getRoute(medication)
  const coding = getCoding(medication)

  return {
    type: 'patient.medication',
    dosageInstructions: {
      route: {
        Id: route ? route.code : '0',
        Name: route ? route.display : 'N/A'
      }
    },
    medication: {
      code: {
        coding: coding
      },
      isBrand: false, // Don't think this is used anywhere that's really important anymore.
      name: {
        full: _capitalize(medication.code.text)
      },
      product: {
        drug: {
          code: _get(medication, 'code.coding')
        },
        form: {
          code: _get(medication, 'form.coding') // this is silly, but would rather match it, than do an OR
        },
        strength: {
          code: [
            {
              system: _get(medication, 'ingredient[0].amount.numerator.system'),
              value: _get(medication, 'ingredient[0].amount.numerator.value', 'N/A'),
              code: _get(medication, 'ingredient[0].amount.numerator.code', ''),
              display: _get(medication, 'ingredient[0].amount')
                ? _get(medication, 'ingredient[0].amount.numerator.value', 'N/A') +
                  ' ' +
                  _get(medication, 'ingredient[0].amount.numerator.code')
                : 'N/A'
            }
          ]
        }
      },
      tierLevel: medication.tierLevel || 'N',
      isFormulary: medication.isFormulary
    }
  }
}

/**
 * Search for allergies. Must supply `name`
 * @param  {object} options
 * @param  { string } options.name The name, or patial name of the allergy
 * @return {[type]}         [description]
 */
export const searchAllergy = async options => {
  let result
  if (options.name) {
    result = await gateway.get('/api/fdb/allergy', { _content: options.name })
  } else {
    throw new Error('Missing valid search parameters')
  }
  const contains = result.expansion.contains
  const sortedResults = _sortBy(contains, a => a.display.toLowerCase())
  return sortedResults
}

const determineAllergenType = system => (system === SNOMED ? 'allergen' : 'medication')

export const buildAllergy = (allergy, isSideEffect) => {
  const selection = allergy
  if (!selection) {
    throw new Error('Missing valid allergy')
  }
  return {
    substance: {
      text: selection.display,
      coding: [
        {
          system: selection.system,
          display: selection.display,
          code: selection.code,
          type: determineAllergenType(selection.system)
        }
      ]
    },
    isSideEffect,
    sensitivityType: 'allergy',
    type: 'patient.allergy',
    status: 'active'
  }
}

/**
 * Search for conditions or side effects. Must supply `name`
 * @param  {object} options
 * @param  { string } options.name The name, or patial name of the allergy
 * @return {[type]}         [description]
 */
export const searchCondition = async inputValue => {
  // This regex looks for a 3 character match where the first is a letter followed by 2 numbers (ICD10 code pattern)
  const options = /^[A-Za-z]\d(\d)/i.test(inputValue)
    ? { _filter: 'byCode', code: inputValue, version: FDB_VERSION }
    : { _filter: 'byName', _content: inputValue }
  let result
  if (Object.keys(options).length > 0) {
    result = await gateway.get('/api/fdb/condition', options)
  } else {
    throw new Error('Missing valid search parameters')
  }
  const contains = result.expansion.contains
  const sortedResults = /^[A-Za-z]\d(\d)/i.test(inputValue)
    ? contains
    : _sortBy(contains, c => c.display.toLowerCase())
  return sortedResults
}

export const buildCondition = condition => {
  const selection = condition
  if (!selection) {
    throw new Error('Missing valid condition')
  }

  return {
    substance: {
      text: selection.display,
      coding: [
        {
          system: selection.system,
          display: selection.display,
          code: selection.code
        }
      ]
    },
    type: 'patient.condition',
    status: 'active'
  }
}

export const searchLabelDescription = async options => {
  if (options.ndcCode) {
    return await gateway.get('/api/fdb/label', {
      ndcCode: options.ndcCode,
      dob: options.dob ? options.dob : null
    })
  } else {
    throw new Error('Missing valid search parameters')
  }
}
