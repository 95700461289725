import React from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import { connect } from 'react-redux'
import { change, Field, Form, reduxForm, isDirty } from 'redux-form'
import { get as _get } from 'lodash'
import ReduxFormText from 'components/common/redux-form/text'
import ReduxFormCheckbox from 'components/common/redux-form/checkbox'
import FormAddress from 'components/common/form-address'
import PhoneForm from 'components/common/form-phone'
import validate from './validation'

const OBJECT_NAME = 'caregiver'

/**
 * Form input for receiving caregiver data for a patient.
 *
 * @param {object} props Properties passed to the component.
 * @param {function} props.handleSubmit The function that will be executed upon submission of the form.
 * @constructor
 */
class CaregiverForm extends React.Component {
  componentDidUpdate (prevProps, prevState, snapshot) {
    const {
      badNumber,
      dispatch
    } = this.props
    if (badNumber !== prevProps.badNumber) {
      dispatch(change(OBJECT_NAME, 'badNumber', badNumber))
    }
  }

  render () {
    const {
      badNumber,
      handleSubmit,
      phone1IsPreferred,
      phone2IsPreferred
    } = this.props

    return (
      <Form
        name={`${pluralize(OBJECT_NAME)}Form`}
        className="w-100 flex flex-column"
        onSubmit={handleSubmit}
      >
        <div className="flex justify-between mb2">
          <div className="dib w-33">
            <Field
              required
              name="name.first"
              type="text"
              label="First Name"
              component={ReduxFormText}
              data-cy={"caregiver-first-name"}
            />
          </div>
          <div className="dib w-33">
            <Field name="name.middle" type="text" label="Middle Name" component={ReduxFormText} />
          </div>
          <div className="dib w-33">
            <Field
              required
              name="name.last"
              type="text"
              label="Last Name"
              component={ReduxFormText}
              data-cy={"caregiver-last-name"}
            />
          </div>
        </div>
        <div>
          <Field
            className="w-100"
            required
            name="relationship"
            type="text"
            label="Relationship"
            component={ReduxFormText}
            data-cy={"caregiver-relationship"}
          />
        </div>
        <div className="flex justify-between mb2">
          <div className="dib w-33">
            <Field
              name="primaryCaregiver"
              type="checkbox"
              label="Primary Caregiver"
              component={ReduxFormCheckbox}
            />
          </div>
          <div className="dib w-33">
            <Field
              name="preferredContact"
              type="checkbox"
              label="Preferred Contact"
              component={ReduxFormCheckbox}
            />
          </div>
          <div className="dib w-33">
            <Field
              name="powerOfAttorney"
              type="checkbox"
              label="Power of Attorney"
              component={ReduxFormCheckbox}
            />
          </div>
        </div>
        <FormAddress label="Address 1" formPrefix="address" />
        <div className="flex justify-between">
          <div className="dib w-50 mr1">
            <PhoneForm
              fieldLabel="Phone"
              phoneFieldName="phone"
              preferredFieldName="phonePreferred"
              badNumber={badNumber && phone1IsPreferred}
            />
          </div>
          <div className="dib w-50 ml1">
            <PhoneForm fieldLabel="Fax" phoneFieldName="fax" />
          </div>
        </div>
        <div className="flex justify-between">
          <div className="dib w-50 mr1">
            <PhoneForm
              fieldLabel="Cell Phone"
              phoneFieldName="cellphone.number"
              smartphoneFieldName="cellphone.isSmartPhone"
              receiveSmsFieldName="cellphone.receiveSMS"
            />
          </div>
          <div className="div w-50 ml1">
            <div className="flex flex-column">
              <Field name="email" label="Email Address" component={ReduxFormText} />
              <div className="dib w-100">
                <div className="dib w-60">
                  <Field
                    name="receiveEmail"
                    label="Receive Email"
                    type="checkbox"
                    component={ReduxFormCheckbox}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <FormAddress label="Address 2" formPrefix="address2" isOptional />
        <div className="flex justify-between">
          <div className="dib w-50 mr1">
            <PhoneForm
              fieldLabel="Phone"
              phoneFieldName="address2.phone"
              preferredFieldName="address2.phonePreferred"
              badNumber={badNumber && phone2IsPreferred}
            />
          </div>
          <div className="dib w-50 ml1">
            <PhoneForm fieldLabel="Fax" phoneFieldName="address2.fax" />
          </div>
        </div>
      </Form>
    )
  }
}

CaregiverForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  const badNumber = _get(state, 'patient.data.badNumber')
  const caregiverIsPreferredContact = _get(state, 'form.caregiver.initial.preferredContact')
  const phone2IsPreferred = _get(state, 'form.caregiver.initial.address2.phonePreferred', false)
  const phone1IsPreferred = _get(state, 'form.caregiver.initial.phonePreferred', false) || !phone2IsPreferred

  const caregivierPreferredContactIsDirty = isDirty(OBJECT_NAME)(state, 'preferredContact')
  const phone1IsDirty = isDirty(OBJECT_NAME)(state, 'phone', 'phonePreferred')
  const phone2IsDirty = isDirty(OBJECT_NAME)(state, 'address2.phone', 'address2.phonePreferred')

  const hasBadNumber = !!(badNumber &&
    caregiverIsPreferredContact && !caregivierPreferredContactIsDirty &&
    ((phone1IsPreferred && !phone1IsDirty) || (phone2IsPreferred && !phone2IsDirty)))

  return {
    badNumber: hasBadNumber,
    phone1IsPreferred,
    phone2IsPreferred
  }
}

const ReduxCaregiverForm = reduxForm({
  form: OBJECT_NAME,
  validate (caregiver) {
    return validate(caregiver)
  }
})(CaregiverForm)

export default connect(mapStateToProps)(ReduxCaregiverForm)
