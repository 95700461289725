/**
 *  The medication risk score bar with bells and whistles
 */

import React, { Component } from 'react'

import { isEqual } from 'lodash'

import './styles/medication-risk-score.scss'

export const severities = [
  {
    level: 'very-low',
    description: 'Very Low',
    min: 0,
    max: 5
  },
  {
    level: 'low',
    description: 'Low',
    min: 6,
    max: 12
  },
  {
    level: 'moderate',
    description: 'Moderate',
    min: 13,
    max: 19
  },
  {
    level: 'high',
    description: 'High',
    min: 20,
    max: 34
  },
  {
    level: 'very-high',
    description: 'Very High',
    min: 35,
    max: 50
  }
]

export const getSeverity = score => {
  let s = severities.find(s => score >= s.min && score <= s.max)
  return s || { description: 'Loading...', level: 'very-low', min: 0, max: 0 }
}

export const getMRSPercent = score => {
  let severity = getSeverity(score)
  let index = severities.findIndex(s => isEqual(severity, s))
  if (severity.max === 0) return 0
  return (
    (index + (score - severity.min) / (severity.max - (severity.min))) *
    (100 / severities.length)
  )
}

/**
 *  Display a bar with the provided risk score value
 *
 *  @param {Number} score The risk score value to display
 */
export class MRSBar extends Component {
  render () {
    let severity = getSeverity(this.props.score)
    let legend = severities.map(s => {
      return (
        <div
          key={s.level}
          className={`severity ${severity.level === s.level ? 'active' : ''}`}
          style={{ width: `${100 / severities.length}%` }}
        >
          <div className="description">{s.description}</div>
          <div className="tick" />
          <div className={`name ${s.level}`} />
          <div className="range">
            {s.min} - {s.max}
          </div>
        </div>
      )
    })

    let scoreBar =
      severity.max === 0 ? (
        <span className="score-bar">
          <span className="score" />
          <span className="ph1" />
          <span />
        </span>
      ) : (
        <span className="score-bar">
          <span className="score">{this.props.score}</span>
          <span className="ph1">of</span>
          <span>{severities[severities.length - 1].max}</span>
        </span>
      )

    return (
      <div className={`mrs-bar ${this.props.className}`}>
        <div className="background">
          <div
            className={`bar ${severity.level}`}
            style={{
              width: `${getMRSPercent(this.props.score)}%`
            }}
          >
            {scoreBar}
          </div>
        </div>
        <div className="legend">{legend}</div>
      </div>
    )
  }
}
