import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SimpleSelect from 'components/common/simple-select'
import { get } from 'lodash'

class FilterSet extends Component {
  handleChange = filter => (selection /*, actionType */) => {
    const { comparator, name } = filter
    const { onUpdate } = this.props

    // passing undefined clears the filter
    // we could use actionType === 'clear' to know when to clear the filter,
    // but this implicit method of passing an undefined filter value works fine
    onUpdate(name, get(selection, 'value', undefined), comparator)
  }

  render () {
    const { filters } = this.props
    return (
      <div className="w-100">
        {filters &&
          filters.map(filter => {
            return (
              // {...filter} here handles filter-specific 'onUpdate' prop override
              <SimpleSelect
                key={filter.name}
                className="dib w-50 w-30-m w-20-l"
                onChange={this.handleChange(filter)}
                placeholder={`Filter by ${filter.label}`}
                {...filter}
              />
            )
          })}
      </div>
    )
  }
}

FilterSet.propTypes = {
  onUpdate: PropTypes.func,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      onUpdate: PropTypes.func
    })
  )
}

export default FilterSet
