/*
 * Actions for Patient Searching
 */

import gateway from 'services/gateway'
import logger from 'services/logger'

import { actions as notifyActions } from 'store/modules/notify'
import { actions as reservationsActions } from 'store/modules/reservations'

import * as C from './types'

export const searchPatients = (options, fetchReservations) => {
  return async dispatch => {
    dispatch({
      type: C.SEARCH_PATIENTS_REQUEST
    })
    try {
      let patients = await gateway.post(`/api/medwise/patient/search`, options)
      dispatch(searchPatientSuccess(patients))
      fetchReservations && dispatch(reservationsActions.getReservationsForSearchResults(patients))
    } catch (err) {
      dispatch(searchPatientFailure(err))
      dispatch(
        notifyActions.addNotification({
          message: 'An error occurred getting the patients'
        })
      )
      logger('An error occurred getting the patients', JSON.stringify(err), true)
    }
  }
}

export const searchPatientSuccess = patients => ({
  type: C.SEARCH_PATIENTS_SUCCESS,
  payload: patients
})

export const searchPatientFailure = err => ({
  type: C.SEARCH_PATIENTS_FAILURE,
  error: err
})

export const setPatients = patients => ({
  type: C.SET_PATIENTS,
  payload: patients
})
export const removePatients = () => ({
  type: C.REMOVE_PATIENTS
})
