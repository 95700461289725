/*
 * Login component
 */

import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { get } from 'lodash'
import { connect } from 'react-redux'

import * as AuthService from 'services/auth'

const privacyPolicyLink = (
  <a target="_blank" rel="noopener noreferrer" href="https://www.tabularasahealthcare.com/privacy/">
    Policy Link
  </a>
)

const LoginView = () => (
  <div className="login-wrapper">
    <div id="mwa-login-container" />
    <p className="mwa-disclaimer">
      This computer system and related software applications are the property of Tabula Rasa
      HealthCare and are for authorized use only; access and usage of this system and related assets
      are routinely monitored and reviewed. By the utilization of this system, all users acknowledge
      the notice of, and agree to comply with, all Tabula Rasa HealthCare policies for acceptable
      use available at: {privacyPolicyLink}. Unauthorized or improper use of this system may result
      in disciplinary action, civil charges/criminal penalties, and/or other sanctions as set forth
      in Tabula Rasa HealthCare’s acceptable use policies. By continuing to use this system you
      indicate your awareness of and consent to abide the terms and conditions of use. If you do not
      agree to the conditions stated in this warning, <b>DO NOT LOG IN</b>.
    </p>
  </div>
)

class Login extends Component {
  componentDidMount () {
    const isAuthenticated = get(this.props, 'auth.isAuthenticated')
    if (!isAuthenticated) {
      if (this.props.auth.error) {
        AuthService.show({
          flashMessage: {
            type: 'error',
            text: get(this.props, 'auth.error.message', 'An error occurred')
          }
        })
      } else {
        AuthService.show()
      }
    } else {
      AuthService.hide()
    }
  }

  componentWillUnmount () {
    AuthService.lock.hide()
  }

  render () {
    const isAuthenticated = get(this.props, 'auth.isAuthenticated')
    const redirect = AuthService.getRedirect()
    return (
      <div className="login">{isAuthenticated ? <Redirect to= {redirect || "/dashboard"} /> : <LoginView />}</div>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  auth
})

export default connect(mapStateToProps)(Login)
