/*
 * Dummy Page for placeholder content
 */

import React, { Component } from 'react'

class Privacy extends Component {
  render () {
    return (
      <div className="page dummy">
        <h2>PRIVACY POLICY</h2>
          <p>At Tabula Rasa HealthCare, Inc., a Delaware corporation (referred to as &ldquo;Company,&rdquo; &ldquo;us&rdquo; or &ldquo;we&rdquo;), we recognize that your privacy is important. This Policy discloses the privacy practices for the Company&rsquo;s family of Websites (collectively, the &ldquo;Site&rdquo;), as well as related products and services we may offer to you (collectively referred to as the &ldquo;Services&rdquo;). This Policy also covers how personal and other information that we receive or collect about you is treated. Please read the information below to learn the following regarding your use of this Site.</p>
          <p>You acknowledge that this Privacy Policy is designed to be read in connection with the Site Terms and Conditions of Use, which is available at <em>www.medwiseadvisor.com</em>, along with any other applicable agreement between you and the Company.You further acknowledge that by accessing or using our Site, you agree to be bound by the Site&rsquo;s terms and conditions, any other applicable agreement, and this Policy.</p>
          <p>We reserve the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our site, and by updating any privacy information on this page. Your continued use of the Site and or Services available through this Site after such modifications will constitute your: (a) acknowledgment of the modified Policy; and (b) your agreement to abide and be bound by that Policy.</p>
          <p>If you have any questions about this Policy, please feel free to contact us at:</p>
          <p>Address:110 Marter Avenue, Suite 309, Moorestown, NJ 08057</p>
          <p>Phone:1-888-974-2763</p>
          <p>IMPORTANT: BY USING THE SITE AND/OR OFFERINGS AVAILABLE ON THIS SITE, YOU GIVE YOUR CONSENT THAT ALL PERSONAL DATA THAT YOU SUBMIT MAY BE PROCESSED BY US IN THE MANNER AND FOR THE PURPOSES DESCRIBED BELOW. IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, DO NOT USE THE SITE.</p>
          <p><strong>1. Types of Information We Collect</strong></p>
          <p>In order to better provide you with the medication risk management services provided at this site, we collect two types of information about our users: Personally Identifiable Information (&ldquo;PII&rdquo;) and Aggregate Information.</p>
          <p><strong>Personally Identifiable Information:</strong> This refers to information that lets us know the specifics of who you are. When you engage in certain activities on this Site, such as registering for an account, downloading or purchasing a product or service, submitting content, or sending us feedback, we may ask you to provide certain information about yourself.</p>
          <p>Examples of PII may include your first and last name, email address, mailing address (including zip code), employer, job title and department, telephone and facsimile numbers, and other identifying information. When ordering products or services on the Site, you may be asked to provide a credit card number.</p>
          <p><strong>Aggregate Information:</strong> This refers to information that does not by itself identify a specific individual. We gather certain information about you based upon where you visit on our Site and what other sites may have directed you to us. This information, which is collected in a variety of different ways, is compiled and analyzed on both a personal and an aggregated basis. This information may include the Website&rsquo;s Uniform Resource Locator (&ldquo;URL&rdquo;) that points to the site you just came from, which URL you go to after visiting our Site, what browser you are using, and your Internet Protocol (&ldquo;IP&rdquo;) address.</p>
          <p><strong>2. How We Collect and Use Information</strong></p>
          <p>We do not collect any PII about you unless you voluntarily provide it to us. However, you may be required to provide certain PII to us when you elect to use certain products or services available on the Site. These may include: (a) registering for an account on our Site; (b) sending us an email message; (c) submitting a form or transmitting other information by telephone or letter; or (d) submitting your credit card or other payment information when ordering and purchasing services on our Site.</p>
          <p>We will primarily use your PII to provide product or service offerings to you. We will also use certain forms of PII to enhance the operation of our Site, improve our internal marketing and promotional efforts, statistically analyze Site use, improve our product and service offerings, and customize our Site&rsquo;s content, layout, and services. We may use PII to deliver information to you and to contact you regarding administrative notices. Finally, we may use your PII to resolve disputes, troubleshoot problems and enforce our agreements with you, including, but not limited to, our Site <strong><em>Terms of Use</em></strong>, and this Privacy Policy.</p>
          <p>We may also collect certain Aggregate Information. For example, we may use your IP address to diagnose problems with our servers, software, to administer our Site and to gather demographic information.</p>
          <p><strong>3. Cookies</strong></p>
          <p>Depending on how you use our Site, we may store cookies on your computer in order to collect certain aggregate data about our users and to customize certain aspects of your specific user experience. A cookie is a small data text file which is stored on your computer that uniquely identifies your browser. Cookies may also include more personalized information, such as your IP address, browser type, the server your computer is logged onto, the area code and zip code associated with your server, and your first name to welcome you back to our Site. We may use cookies to perform tasks such as: monitoring aggregate site usage metrics, storing and remembering your passwords (if you allow us to do so), storing account preferences that you have set, and personalizing the Services we make available to you. However, we do not use cookies to track your browsing behaviors.</p>
          <p>Most browsers are initially set up to accept cookies, but you can reset your browser to refuse all cookies or to indicate when a cookie is being sent. However, some aspects of the Site may not function properly if you elect to disable cookies.</p>
          <p><strong>4. Release of Information</strong></p>
          <p>We will not sell, trade, or rent your PII to others. Occasionally we may be required by law enforcement or judicial authorities to provide PII to the appropriate governmental authorities. In such cases, we will disclose PII upon receipt of a court order, subpoena, or to cooperate with a law enforcement investigation. We fully cooperate with law enforcement agencies in identifying those who use our services for illegal activities. We reserve the right to report to law enforcement agencies any activities that we in good faith believe to be unlawful.</p>
          <p><strong>5. Updating and Correcting Information</strong></p>
          <p>We believe you should have the ability to access and edit the PII that you have provided to us. You may change any of your PII in your account online at any time by linking to your account in accordance with instructions posted elsewhere on this Site. You may also access and correct your personal information and privacy preferences by contacting us at:</p>
          <p>Address: 228 Strawbridge Drive Moorestown NJ 08057</p>
          <p>Phone: 1-888-974-2763</p>
          <p>Please include your name, address, and/or email address when you contact us.</p>
          <p>We encourage you to promptly update your PII if it changes. You may ask to have the information on your account deleted or removed; however, some information, such as past transactions, logs of technical support calls, or other information may not be deleted. In addition, it may be impossible to completely delete your information without some residual information because of backups.</p>
          <p><strong>6. User Choices on Collection and Use of Information</strong></p>
          <p>We may, from time to time, send you email regarding new products and services that we feel may interest you. Only the Company and its subsidiaries and affiliates (or agents working on behalf of Company and under confidentiality agreements) will send you these solicitations. If you do not want to receive solicitations from us, you can &ldquo;opt-out&rdquo; by accessing your account online editing your account information to no longer receive such offers and mailings.</p>
          <p>You also have choices with respect to cookies, as described above. By modifying your browser preferences, you have the choice to accept all cookies, to be notified when a cookie is set, or to reject all cookies. If you choose to reject all cookies some parts of our Site may not work properly in your case.</p>
          <p><strong>7. Security of Your PII</strong></p>
          <p>At our Site you can be assured that your PII is secure, consistent with current industry standards. We strive to take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of your PII. For example:</p>
          <p>&bull; We work hard to ensure that the data we collect is reliable, accurate, complete and current. We encrypt all PII, in order to prevent unauthorized parties from viewing such information when it is transmitted to us. We also only keep collected information only for as long as reasonably necessary and use it only for the purposes for which it was collected or to comply with any applicable legal or ethical reporting or document retention requirements.</p>
          <p>&bull; We limit access to PII only to specific employees, contractors and agents who have a reasonable need to come into contact with your information. For example, we may provide members of our technical support team with limited access to your account in order to allow them to troubleshoot problems you may be having with the Site.</p>
          <p>&bull; Additionally, we also employ a number of physical, electronic, and procedural safeguards to protect PII. Our secure servers and our data centers are protected by industry-standard encryption, and our servers reside behind firewalls and employ high-level password protection.</p>
          <p>&bull; Finally, access by you to your PII is available through a password and unique customer ID selected by you. This password is encrypted. We recommend that you do not divulge your password to anyone.</p>
          <p>Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your PII, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) the security, integrity and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.</p>
          <p><strong>8. Miscellaneous</strong></p>
          <p>You must be at least 18 years old to have our permission to use this Site. Our policy is that we do not knowingly collect, use or disclose PII about minor visitors.</p>
          <p>If you have any questions, concerns or inquiries about our Privacy Policy, or our use of your PII, or our privacy practices, please contact us by the means herein provided above.</p>
          <p>12671857v1</p>
      </div>
    )
  }
}

export default Privacy
