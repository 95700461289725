import React, { Component, Fragment } from 'react'
import NumberFormat from 'react-number-format'
import { get } from 'lodash'

import './styles/data-item.scss'
import { FaExclamationTriangle } from 'react-icons/fa'

/**
 *  A standard data item with a label and value
 *
 *  @param {String} label The label for the data
 *  @param {String} value The value to display
 *  @param {Boolean} [hideOnUndefined] Hide this if the value is undefined
 */
export class DataItem extends Component {
  render () {
    if (!this.props.value && this.props.hideOnUndefined) {
      return null
    }
    if (this.props.fragment) {
      return (
        <Fragment>
          <div className={`${this.props.labelClass || 'data-label'}`}>{this.props.label}</div>
          <div className={`${this.props.valueClass || 'data-value'}`}>
            {this.props.value || 'N/A'}
          </div>
        </Fragment>
      )
    }
    return (
      <div className={`${this.props.className || 'fl w-100'}`}>
        <div className={`${this.props.labelClass || 'data-label'}`}>{this.props.label}</div>
        <div className={`${this.props.valueClass || 'data-value'}`}>
          {this.props.value || 'N/A'}
        </div>
      </div>
    )
  }
}

/**
 *  An array standard data item with a label and value
 *
 *  @param {String} label The label for the data
 *  @param {Array} value The value to display
 *  @param {Boolean} [hideOnUndefined] Hide this if the value is undefined
 */
export class DataItemArray extends Component {
  render () {
    if (!this.props.value && this.props.hideOnUndefined) {
      return null
    }
    if (this.props.fragment) {
      return (
        <Fragment>
          <div className={`${this.props.labelClass || 'data-label'}`}>{this.props.label}</div>
          {this.props.value.map((v, key) => (
            <div key={key} className={`${this.props.valueClass || 'data-value'}`}>
              {v || 'N/A'}
            </div>
          ))}
        </Fragment>
      )
    }
    return (
      <div className={`${this.props.className || 'fl w-100'}`}>
        <div className={`${this.props.labelClass || 'data-label'}`}>{this.props.label}</div>
        {this.props.value.map((v, key) => (
          <div key={key} className={`${this.props.valueClass || 'data-value'}`}>
            {v || 'N/A'}
          </div>
        ))}
      </div>
    )
  }
}

/**
 *  An address split up into parts that can be formated anywhich way via CSS
 *
 *  @param {String} label The label for the data
 *  @param {Object} address The address object which will be parsed
 */
export class AddressItem extends Component {
  render () {
    let line1 = get(this.props, 'address.line1')
    let line2 = get(this.props, 'address.line2')
    let line3 = get(this.props, 'address.line3')
    let city = get(this.props, 'address.city')
    let state = get(this.props, 'address.state')
    let zip = get(this.props, 'address.zip')
    let addressParts = (
      <div className={`${this.props.valueClass || 'data-value'} address`}>
        {line1 ? <span className="line1">{line1}</span> : null}
        {line2 ? <span className="line2">{line2}</span> : null}
        {line3 ? <span className="line3">{line3}</span> : null}
        {city ? <span className="city">{city}</span> : null}
        {state ? <span className="state">{state}</span> : null}
        {zip ? <span className="zip">{zip}</span> : null}
      </div>
    )
    if (this.props.fragment) {
      return (
        <Fragment>
          <div className={`${this.props.labelClass || 'data-label'}`}>{this.props.label}</div>
          {addressParts}
        </Fragment>
      )
    }
    return (
      <div className={`${this.props.className || 'fl w-100'}`}>
        <div className={`${this.props.labelClass || 'data-label'}`}>{this.props.label}</div>
        {addressParts}
      </div>
    )
  }
}

/**
 *  A formatted phone number
 *
 *  @param {String} label The label for the data
 *  @param {String} value The value to display
 */
export class PhoneItem extends Component {
  // QA-4238 - warning for bad number flag
  BadNumberWarning = (
    <div className="warn flex items-center pa1">
      <FaExclamationTriangle className="mr1" /> Bad Number
    </div>
  )

  render () {
    if (this.props.fragment) {
      return (
        <Fragment>
          <div className={`${this.props.labelClass || 'data-label'}`}>{this.props.label}</div>
          <div className={`${this.props.valueClass || 'data-value'}`}>
            {(
              <NumberFormat displayType={'text'} value={this.props.value} format="(###) ###-####" />
            ) || 'N/A'}
            {this.props.badNumber && this.BadNumberWarning}
          </div>
        </Fragment>
      )
    }
    return (
      <div className={`${this.props.className || 'fl w-100'}`}>
        <div className={`${this.props.labelClass || 'data-label'}`}>{this.props.label}</div>
        <div className={`${this.props.valueClass || 'data-value'}`}>
          {<NumberFormat displayType={'text'} value={this.props.value} format="(###) ###-####" /> ||
            'N/A'}
          {this.props.badNumber && this.BadNumberWarning}
        </div>
      </div>
    )
  }
}
