import React, { Component, Fragment } from 'react'
import Button from '@material-ui/core/Button'
import { get, kebabCase, map } from 'lodash'

import { INDEXED_PROPERTIES } from 'constants/search'

class SearchPropertyPicker extends Component {
  render () {
    const { label, propertyMap, excludedProperties, toggleProperty } = this.props

    return (
      <Fragment>
        {label}
        <div className="flex flex-row flex-wrap mt2" data-cy={kebabCase(label)}>
          {map(INDEXED_PROPERTIES, (property, propertyKey) => {
            return get(excludedProperties, propertyKey) ? null : (
              <div key={propertyKey} className="mb2 mr2">
                <Button
                  data-cy={`property-${kebabCase(property.label)}`}
                  onClick={() => toggleProperty(propertyKey)}
                  variant={get(propertyMap, propertyKey) ? 'contained' : 'outlined'}
                  size="small"
                >
                  {property.label}
                </Button>
              </div>
            )
          })}
        </div>
      </Fragment>
    )
  }
}

export default SearchPropertyPicker
