/*
 * Actions for priority queues
 */
import gateway from 'services/gateway'
import logger from 'services/logger'
import { get, groupBy } from 'lodash'

import { actions as notifyActions } from 'store/modules/notify'

import * as C from './types'

// group queues by org
export const groupByOrg = list => {
  const groupedData = groupBy(list, queue => get(queue, 'Organization.name'))
  delete groupedData.undefined
  return groupedData
}

// group orgs by user
export const groupByUser = list => {
  const groupedData = groupBy(list, queue => (get(queue, 'userId') ? 'Your Queues' : undefined))
  delete groupedData.undefined
  return groupedData
}

// fetch single queue actions
const fetchPriorityQueueRequest = () => ({ type: C.FETCH_PRIORITY_QUEUE_REQUEST })
const fetchPriorityQueueSuccess = result => ({
  payload: result,
  type: C.FETCH_PRIORITY_QUEUE_SUCCESS
})
const fetchPriorityQueueFailure = () => ({ type: C.FETCH_PRIORITY_QUEUE_FAILURE })

// fetch queue list actions
const fetchPriorityQueuesRequest = () => ({ type: C.FETCH_PRIORITY_QUEUES_REQUEST })
const fetchPriorityQueuesSuccess = result => ({
  payload: {
    list: result,
    listByOrg: groupByOrg(result),
    listByUser: groupByUser(result)
  },
  type: C.FETCH_PRIORITY_QUEUES_SUCCESS
})
const fetchPriorityQueuesFailure = () => ({ type: C.FETCH_PRIORITY_QUEUES_FAILURE })

// This handles fetching all queues
// AS WELL fetching an individual queue by ID
export const getPriorityQueues = id => {
  return async (dispatch, getState) => {
    dispatch(id ? fetchPriorityQueueRequest() : fetchPriorityQueuesRequest())
    try {
      let result = await gateway.get(`/api/medwise/priorityQueues${id ? `/${id}` : ''}`)
      dispatch(id ? fetchPriorityQueueSuccess(result) : fetchPriorityQueuesSuccess(result))
      return result
    } catch (err) {
      logger(`An error occurred fetching priority queue${id ? 's' : ''}`, err, true)
      dispatch(id ? fetchPriorityQueueFailure() : fetchPriorityQueuesFailure())
      dispatch(
        notifyActions.addNotification({
          message: `An error occurred fetching priority queue${id ? 's' : ''}`
        })
      )
      return false
    }
  }
}

// remove single queue actions
const removePriorityQueueRequest = () => ({ type: C.REMOVE_PRIORITY_QUEUE_REQUEST })
const removePriorityQueueSuccess = result => ({
  payload: result,
  type: C.REMOVE_PRIORITY_QUEUE_SUCCESS
})
const removePriorityQueueFailure = () => ({ type: C.REMOVE_PRIORITY_QUEUE_FAILURE })

export const removePriorityQueue = id => {
  return async (dispatch, getState) => {
    dispatch(removePriorityQueueRequest())
    try {
      let result = await gateway.delete(`/api/medwise/priorityQueues${id ? `/${id}` : ''}`)
      dispatch(clearPriorityQueue())
      dispatch(removePriorityQueueSuccess(result))
      dispatch(
        notifyActions.addNotification({
          message: `Successfully removed the priority queue`,
          type: 'info'
        })
      )
      return true
    } catch (err) {
      logger(`An error occurred removing priority queue`, err, true)
      dispatch(removePriorityQueueFailure())
      dispatch(
        notifyActions.addNotification({
          message: `An error occurred removing priority queue`
        })
      )
      return false
    }
  }
}

// create priority queue actions
const createPriorityQueueRequest = () => ({ type: C.CREATE_PRIORITY_QUEUE_REQUEST })
const createPriorityQueueSuccess = priorityQueue => ({
  payload: priorityQueue,
  type: C.CREATE_PRIORITY_QUEUE_SUCCESS
})
const createPriorityQueueFailure = () => ({ type: C.CREATE_PRIORITY_QUEUE_FAILURE })

export const createPriorityQueue = priorityQueue => {
  return async (dispatch, getState) => {
    dispatch(createPriorityQueueRequest())
    try {
      let result = await gateway.post(`/api/medwise/priorityQueues`, priorityQueue)
      // just grab the new ID from the result (result properties are all stringified)
      priorityQueue.id = result.id
      dispatch(createPriorityQueueSuccess(priorityQueue))
      dispatch(setPriorityQueue(priorityQueue))
      dispatch(
        notifyActions.addNotification({
          message: `Successfully created the priority queue: ${priorityQueue.name}`,
          type: 'info'
        })
      )
      return true
    } catch (err) {
      logger('An error occurred creating the priority queue', err, true)
      dispatch(createPriorityQueueFailure())
      dispatch(
        notifyActions.addNotification({
          message: 'An error occurred creating the priority queue'
        })
      )
      return false
    }
  }
}

// update priority queue actions
const updatePriorityQueueRequest = () => ({ type: C.UPDATE_PRIORITY_QUEUE_REQUEST })
const updatePriorityQueueSuccess = priorityQueue => ({
  payload: priorityQueue,
  type: C.UPDATE_PRIORITY_QUEUE_SUCCESS
})
const updatePriorityQueueFailure = () => ({ type: C.UPDATE_PRIORITY_QUEUE_FAILURE })

export const updatePriorityQueue = priorityQueue => {
  return async (dispatch, getState) => {
    dispatch(updatePriorityQueueRequest())
    try {
      await gateway.put(`/api/medwise/priorityQueues`, priorityQueue)
      // use priorityQueue passed in since result is number (of affected row?)
      dispatch(updatePriorityQueueSuccess(priorityQueue))
      await dispatch(
        notifyActions.addNotification({
          message: `Successfully updated the priority queue: ${priorityQueue.name}`,
          type: 'info'
        })
      )
      return true
    } catch (err) {
      logger('An error occurred updating the priority queue', err, true)
      dispatch(updatePriorityQueueFailure())
      await dispatch(
        notifyActions.addNotification({
          message: 'An error occurred updating the priority queue'
        })
      )
      return false
    }
  }
}

// set and clear currently selected priority queue
export const setPriorityQueue = queue => dispatch => {
  if (queue) {
    localStorage.setItem('priorityQueueId', queue.id)
  }
  dispatch({
    type: C.SET_PRIORITY_QUEUE,
    payload: queue
  })
}

export const clearPriorityQueue = () => dispatch => {
  localStorage.setItem('priorityQueueId', null)
  dispatch({
    type: C.CLEAR_PRIORITY_QUEUE
  })
}

export const updatePriorityQueueSettings = priorityQueueSettings => async dispatch => {
  localStorage.setItem('priorityQueueSettings', JSON.stringify(priorityQueueSettings))
  dispatch({
    type: C.SYNC_PRIORITY_QUEUE_SETTINGS,
    payload: priorityQueueSettings
  })
}

export const initializePriorityQueueSettings = () => async dispatch => {
  let priorityQueueSettings = localStorage.getItem('priorityQueueSettings')
  if (!priorityQueueSettings) {
    priorityQueueSettings = '{}'
    localStorage.setItem('priorityQueueSettings', priorityQueueSettings)
  }
  priorityQueueSettings = JSON.parse(priorityQueueSettings)

  dispatch({
    type: C.SYNC_PRIORITY_QUEUE_SETTINGS,
    payload: priorityQueueSettings
  })
}
