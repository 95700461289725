/*
 * Menu component
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { NavLink } from 'react-router-dom'
import { get } from 'lodash'

import { actions as menuActions } from 'store/modules/main-menu'

import IdAM from 'components/common/idam'
import { TRHC_GREEN_DARK } from 'constants/colors'

/**
 * The main menu list component, which renders out each list in your navigation. This will apply your config to the Material UI `<List />` component.
 * @extends Component
 * @prop {String} name The name of the list
 * @prop {Array.<Object>} items The items for `MainMenuListItem`
 */
class MainMenuList extends Component {
  static propTypes = {
    config: PropTypes.shape({
      name: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          icon: PropTypes.element,
          text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
          listItemTextProps: PropTypes.any,
          listItemIconProps: PropTypes.any,
          child: PropTypes.object
        })
      ).isRequired
    })
  }

  render () {
    const { config, ...props } = this.props
    const items = config.items || []
    let listDOM = items.map(item => {
      return <MainMenuListItem config={item} key={item.name} {...props} />
    })
    return (
      <List component="nav" {...config}>
        {listDOM}
      </List>
    )
  }
}

/**
 * [MainMenuListItem description]
 * @extends Component
 * @prop {String} name The name of the item (for key usage)
 * @prop {Element} [icon] The icon element to use
 * @prop {Element|String} text The node to use as the `primary` prop on Material UI `ListItemText`
 * @prop {String} link The route to link to
 * @prop {Object} [child] A child list `MainMenuList` configuration to include
 * @prop {any} listItemTextProps Props to pass to the `ListItemText`
 * @prop {any} listItemIconProps Props to pass to the `ListItemIcon`
 */
class MainMenuListItem extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: props.config.open ? props.config.open : false
    }
  }

  handleClick (e) {
    const { config } = this.props
    if (config.child) {
      this.setState({ open: !this.state.open })
      e.stopPropagation()
    }

    if (config.onClick) {
      config.onClick(config)
    }
  }

  render () {
    const { config, ...props } = this.props
    const child = get(config, 'child')
    let listDOM = child ? (
      <Collapse in={this.state.open} timeout="auto">
        <MainMenuList config={{ ...child, disablePadding: true }} {...props} />
      </Collapse>
    ) : null
    return (
      <IdAM {...config.idam}>
        <ListItem
          button
          component={config.link ? NavLink : 'div'}
          onClick={this.handleClick.bind(this)}
          to={config.link}
          {...config}
        >
          {config.icon ? (
            <ListItemIcon
              {...config.listItemIconProps}
              classes={{ root: props.classes.listItemIcon }}
            >
              {config.icon}
            </ListItemIcon>
          ) : null}
          <ListItemText
            classes={{ primary: props.classes.listItemText }}
            primary={config.text}
            {...config.listItemTextProps}
          />
          {child && (this.state.open ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        {listDOM}
      </IdAM>
    )
  }
}

const menuStyles = {
  listItemText: {
    color: TRHC_GREEN_DARK
  },
  listItemIcon: {
    color: TRHC_GREEN_DARK
  }
}

/**
 * The main menu component which is tied into the main menu redux module items and open states
 * @extends Component
 */
class MainMenu extends Component {
  render () {
    const { menu, menuActions, ...props } = this.props
    let menuDOM = menu.items.map(item => {
      return <MainMenuList config={item} key={item.name} {...props} />
    })

    return (
      <React.Fragment>
        <Drawer classes={props.classes.drawer} open={menu.open} onClose={menuActions.toggleMenu}>
          <div
            tabIndex={0}
            role="button"
            onClick={menuActions.toggleMenu}
            onKeyDown={menuActions.toggleMenu}
          >
            {menuDOM}
          </div>
        </Drawer>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ auth, menu }) => ({
  auth,
  menu
})

const mapDispatchToProps = dispatch => ({
  menuActions: {
    toggleMenu: () => dispatch(menuActions.toggleMenu())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(menuStyles)(MainMenu))
