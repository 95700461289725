/*
 * Dashboard pharmacy  tab
 */

import React, { Component } from 'react'
import pluralize from 'pluralize'
import { MdCheck as CheckIcon } from 'react-icons/md'
import { PhoneItem } from 'components/common/data-item'
import TitleCell from 'components/common/table-cells/title-cell'
import StandardTab from 'components/common/standard-tab'
import logger from 'services/logger'
import { get as _get, find as _find } from 'lodash'
import Form from './form'

const OBJECT_NAME = 'pharmacy'

class Pharmacies extends Component {
  DEFAULT_STRUCTURE = {
    id: undefined,
    name: undefined,
    addressLine1: undefined,
    addressLine2: undefined,
    city: undefined,
    state: undefined,
    zip: undefined,
    telephoneNumber: undefined,
    faxNumber: undefined,
    isPrimary: false,
    isSecondary: false,
    verified: false
  }

  formHandler = async (data, editing, originalItem) => {
    // manually merge the pharmacy entry into the array
    const { patient, patientActions } = this.props
    const pharmacies = _get(patient, 'data.pharmacies', [])

    if (editing) {
      // DISCLAIMER: Change this once the back end supports granular CRUD operations for this resource.
      // We are currently finding the item that is being edited by using _.find with _.matches shorthand (which checks all object attributes for equality).
      // We do this because the item doesn't have a unique identifier, and manually hashing the objects to have a temporary ID
      // for front-end use only comes with it's own tradeoffs, but adds complexity, with the IDs then needing to be manually stripped before persisting.
      //
      // This method (using _.find with _.matches shorthand) is slow but reliable and a simple fill-gap.
      //
      // Once the backend supports granular CRUD operations for this resource, we should be able to make a call to update the item,
      // while optimistically updating it on the front-end, and then refetch the item list to get back in sync with the true backend state.
      //
      // This means this front-end find/update will only serve to optimistically update the item and may still use _.find,
      // but can search more efficiently, by searching for a specific item id

      let foundOriginalItem = _find(pharmacies, originalItem)
      let foundItemIndex = pharmacies.indexOf(foundOriginalItem)
      if (foundItemIndex !== -1) {
        pharmacies[foundItemIndex] = data
      } else {
        // This is bad UX. With unique IDs in the future we could merge their changes over the existing changes
        // or otherwise indicate what was concurrently updated by another user while they were editing
        // so they can confirm their changes and try again or cancel.
        this.props.notifyActions.addNotification({
          message:
            'Unable to save pharmacy. The item was updated by another user before saving. Please try editing the item again'
        })
        logger('Original pharmacy item not found when saving.')
      }
    } else {
      pharmacies.push(data)
    }

    patient.data.pharmacies = pharmacies

    patientActions.updatePatient(patient.data)
  }

  render () {
    const { patient } = this.props
    let headers = [
      {
        name: 'Name',
        maps: [{ propName: 'boldText', value: 'name' }],
        component: TitleCell
      },
      {
        name: 'Relation',
        maps: pharmacy => (pharmacy.isPrimary ? 'Primary' : pharmacy.isSecondary ? 'Secondary' : '')
      },
      {
        name: 'Phone',
        maps: pharmacy => <PhoneItem value={_get(pharmacy, 'telephoneNumber')} />
      },

      { name: 'Fax', maps: pharmacy => <PhoneItem value={_get(pharmacy, 'fax')} /> },
      {
        name: 'Address',
        maps: pharmacy =>
          `${pharmacy.addressLine1}${pharmacy.addressLine2 ? pharmacy.addressLine2 : ''}, ${
            pharmacy.city
          }, ${pharmacy.state} ${pharmacy.zip}`
      },
      {
        name: 'Verified',
        maps: prescriber =>
          prescriber.verified ? <CheckIcon className="center" size="25" /> : null
      },
      {
        name: 'Type',
        maps: 'pharmacyType'
      }
    ]
    let data = _get(patient.data, pluralize(OBJECT_NAME), [])

    return (
      <StandardTab
        name="Pharmacy"
        object={OBJECT_NAME}
        form={Form}
        initialValues={this.DEFAULT_STRUCTURE}
        formHandler={this.formHandler}
        tableHeaders={headers}
        tableData={data}
        pagination={{
          pageSize: 5,
          maxPages: 5
        }}
        {...this.props}
      />
    )
  }
}

export default Pharmacies
