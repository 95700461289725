/*
 * Actions for the Main Menu
 */

import * as C from './types'

export const openMenu = () => ({
  type: C.OPEN_MENU
})

export const closeMenu = () => ({
  type: C.CLOSE_MENU
})

export const toggleMenu = () => ({
  type: C.TOGGLE_MENU
})

/**
 * Set the main menu items for the app
 * @param {Array.<Object>} items The menu items. These correlate with the Material UI List and ListItem components.
 */
export const setMenuItems = items => {
  return dispatch => {
    dispatch({
      type: C.SET_MENU_ITEMS,
      payload: items
    })
  }
}
