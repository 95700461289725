export const languages = [
  'Not Selected',
  'English',
  'French',
  'Spanish',
  'Chinese',
  'German',
  'Vietnamese',
  'Tagalog',
  'Portuguese',
  'Arabic',
  'Bengali',
  'Cantonese',
  'Farsi',
  'Haitian Creole',
  'Italian',
  'Karen',
  'Korean',
  'Mandarin (simplified)',
  'Mandarin (traditional)',
  'Polish',
  'Russian',
  'Somali',
  'Swahili',
  'Yiddish',
  'Other'
]

export const locations = [
  'Assisted Living Facility',
  'Memory Care Unit',
  'Own Home',
  'Senior Living Facility',
  'Foster Care/Group Home',
  'Other'
]

export const planMapping = {
  IAPDP: 'Wellmark Blue Cross and Blue Shield of Iowa',
  MNPDP: 'Blue Cross and Blue Shield of Minnesota',
  MNPDPG: 'Blue Cross and Blue Shield of Minnesota',
  MTPDP: 'Blue Cross and Blue Shield of Montana',
  NDPDP: 'Blue Cross Blue Shield of North Dakota',
  NEPDP: 'Blue Cross and Blue Shield of Nebraska',
  SDPDP: 'Wellmark Blue Cross and Blue Shield of South Dakota',
  WYPDP: 'Blue Cross Blue Shield of Wyoming'
}
