/*
 * Dashboard risk factor tab
 */

import React, { Component } from 'react'
import { get, merge } from 'lodash'
import uuid from 'uuid/v4'
import dayjs from 'dayjs'

import TitleCell from 'components/common/table-cells/title-cell'
import StandardTab from 'components/common/standard-tab'

import RiskFactorsForm from './risk-factors-form'
import { DateFormat } from 'constants/date'

const OBJECT_NAME = 'riskfactor'

class RiskFactors extends Component {
  constructor (props) {
    super(props)

    this.formHandler = this.formHandler.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  componentDidMount () {
    const { schema, schemaActions } = this.props
    if (!get(schema, 'nonMedicationRelatedProblems.length')) {
      schemaActions.getNonMedicationRelatedProblems()
    }
  }

  DEFAULT_STRUCTURE = {
    category: undefined,
    riskFactor: undefined,
    riskFactorNote: undefined,
    verified: undefined
  }

  formHandler (data, editing) {
    const { auth, patientActions, notifyActions } = this.props
    let patient = get(this.props, 'patient.data')
    let riskFactor
    if (data._id) {
      const riskFactorIndex = get(patient, 'riskFactors', []).findIndex(rf => rf._id === data._id)
      if (riskFactorIndex === -1) {
        return notifyActions.addNotification({
          message: 'Could not find existing risk factor'
        })
      }
      riskFactor = patient.riskFactors[riskFactorIndex]
      riskFactor = merge(riskFactor, data)
      riskFactor.updatedOn = new Date()
      if (!riskFactor.changes) {
        riskFactor.changes = []
      }
      riskFactor.changes.push({
        date: new Date(),
        reportedBy: {
          auth0Id: auth.profile.id,
          firstName: auth.profile.firstName,
          lastName: auth.profile.lastName,
          email: auth.profile.email
        }
      })

      patient.riskFactors[riskFactorIndex] = riskFactor
    } else {
      riskFactor = {
        ...data,
        _id: uuid(),
        reportedBy: {
          auth0Id: auth.profile.id,
          firstName: auth.profile.firstName,
          lastName: auth.profile.lastName,
          email: auth.profile.email
        },
        createdOn: new Date(),
        updatedOn: new Date(),
        changes: []
      }

      if (!patient.riskFactors) {
        patient.riskFactors = []
      }

      patient.riskFactors.push(riskFactor)
    }

    patientActions.updatePatient(patient)
  }

  handleDelete (data) {
    const { patientActions } = this.props
    let patient = get(this.props, 'patient.data')
    patient.riskFactors = patient.riskFactors.filter(rf => rf._id !== data._id)
    patientActions.updatePatient(patient)
  }

  render () {
    const { patient, schema } = this.props
    let headers = [
      {
        name: 'Risk Factor',
        maps: [{ propName: 'boldText', value: 'riskFactor.display' }],
        component: TitleCell
      },
      { name: 'Category', maps: 'category' },
      {
        name: 'Status',
        maps: value => (get(value, 'verified', false) ? 'Verified' : 'Unverified')
      },
      { name: 'Reported By', maps: 'reportedBy.email' },
      {
        name: 'Updated',
        maps: value => dayjs(value.updatedOn).format(DateFormat.DATE_DISPLAY)
      }
    ]

    let data = get(patient, 'data.riskFactors', [])

    return (
      <StandardTab
        name="Risk Factor"
        tableHeaders={headers}
        tableData={data}
        object={OBJECT_NAME}
        form={RiskFactorsForm}
        initialValues={this.DEFAULT_STRUCTURE}
        formHandler={this.formHandler}
        handleDelete={this.handleDelete}
        modalFormProps={{ patient, schema }}
        deleteAlert
        pagination={{
          pageSize: 5,
          maxPages: 5
        }}
        {...this.props}
      />
    )
  }
}

export default RiskFactors
