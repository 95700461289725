/* eslint-disable indent */
import React, { useState, useEffect } from 'react'
import { SimpleList, SimpleListItem } from 'components/common/simple-list'
import PropTypes from 'prop-types'

import classnames from 'classnames'

import './styles/actionable-list.scss'

/**
 * The ActionableList component allows you to create a list of ActionableListItems.
 * You can pass a list of ActionableListItems as children to this component.
 */
export const ActionableList = ({children}) => {
  return (
    <SimpleList>
      {children}
    </SimpleList>
  )
}

/**
 * The ActionableListItem component displays a summary when not expanded.
 * When expanded, the summary will be hidden and the children will be displayed.
 * 
 * Optional actions can be set to perform actions on the list item. 
 *
 *  @param {object} icon              The icon to be displayed on the left hand side of the list item.
 *  @param {string} className         Additional string to pass in order to append classnames.
 *  @param {object} summary           The information to be displayed when the actionable list item is not expanded. (condensed)
 *  @param {object} itemActions       Optional actions to be tied to the actionable list item.
 *  @param {boolean} expanded         True if the actionable list item is expanded.
 *  @param {function} onClickHandler  Optional function to call after onClick event.
 */
export const ActionableListItem = ({ icon, className, summary, itemActions, expanded, onClickHandler, children }) => {

  const [isExpanded, setIsExpanded] = useState(summary === undefined)

  useEffect(
    () => {
      setIsExpanded(expanded)
    }, 
    [expanded]
  )

  const toggleExpanded = () => {
    setIsExpanded(isExpanded => !isExpanded)
  }

  const itemIcon = () => {
    return (
      <div className="list-item-icon">
        { icon }
      </div> 
    )
  }

  const renderSummaryContent = () => {
    if (!summary) return

    return (      
      <div className={classnames("actionable-list-item", className)} onClick={ onClickHandler }>
        {itemIcon()}
        {summary}
        {itemActions}
      </div>
    )
  }

  return ( 
    <SimpleListItem 
      className={className} 
      summary={renderSummaryContent()}
      expanded={isExpanded}
      onClickHandler={onClickHandler || toggleExpanded}
    >
      {!summary && itemIcon()}
      {children}
      {!summary && itemActions}
    </SimpleListItem>
  )
}

ActionableListItem.propTypes = {
  icon: PropTypes.object,
  className: PropTypes.string,
  summary: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  itemActions: PropTypes.object,
  expanded: PropTypes.bool,
  onClickHandler: PropTypes.func
}

ActionableListItem.defaultProps = {
  expanded: false,
}
