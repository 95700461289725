export const validCallTypes = [
  'Initiating Interaction',
  'Medication Review',
  'Medication Safety Review'
]

export const oldTypes = {
  'Initiating Call': 'Initiating Interaction',
  'Medication Review Interaction': 'Medication Review',
  'Medication Review Call': 'Medication Review',
  'MedWise Advisor Review Call': 'Medication Safety Review'
}

/**
 * Validates and transforms a callType if it is found in oldTypes
 * @param  {String} callType The call type
 * @return {String}          The validated and transformed callType
 */
export const validateCallType = callType => {
  if (oldTypes[callType]) return oldTypes[callType]
  return callType
}
