/*
 * Actions for Patient services
 */

import { get as _get, isEqual as _isEqual, pick as _pick } from 'lodash'
import dayjs from 'dayjs'
import { eligibility } from '@jrs/map-common-logic'
import gateway from 'services/gateway'
import { getMaxGroupAndCohort } from 'services/patient'
import ProjectService from 'services/project'


import { handleError, handleRequest } from 'store/actions'

import { actions as notifyActions } from 'store/modules/notify'

import { getLatestCall } from './middleware'

import * as C from './types'

export const updateWorkflowState = (patientId, workflowState, newOrgId = null) => dispatch => {
  return dispatch({
    type: C.WORKFLOW_STATE_UPDATE,
    patientId,
    workflowState,
    newOrgId
  })
}

/*
 * The patient-portal-service is incorrectly adding a primary flag to
 * addresses when the address object exists, but is otherwise empty.
 * This deletes the flag when conditions are met avoiding UI and data issues.
 * @param {Object} patient - the entire patient doc
 * @return {Object} patient - patient doc as it was, or with adjusted address
 * data for patient and/or caregiver addresses.
 */
export const confirmAddressData = patient => {
  const onlyPrimaryFlag = { primary: true }
  if (_isEqual(_get(patient, 'address'), onlyPrimaryFlag)) {
    delete patient.address.primary
  }
  if (_isEqual(_get(patient, 'caregiver.address'), onlyPrimaryFlag)) {
    delete patient.caregiver.address.primary
  }
  return patient
}

export const getPatient = id => {
  return async dispatch => {
    handleRequest(dispatch, C.REQUEST, C.GET)
    try {
      let patient = await gateway.get(`/api/medwise/patient?patientId=${id}`)
      // Fix removes primary address flag when there are no other address values
      patient = confirmAddressData(patient)
      dispatch(setPatient(patient))
    } catch (err) {
      dispatch(removePatient())
      handleError(dispatch, err, C.FAILURE, C.GET)
    }
  }
}

/*
 * @param {Array} - eligibilityDocs - An Array of eligibility doc(s) found by the eligibleSearch()
 * @returns {Array} - arrayOfFilteredPatients
 */
const prepareForPreview = async eligibilityDocs => {
  const filterPatient = (eligDoc, patientDoc = {}) => {
    let pickedFromEligibilityDoc = _pick(eligDoc, [
      'address',
      'name',
      'dob',
      'sex',
      'telecom',
      'currentWorkflowState',
      'managingOrganization',
      'export_subject',
      'externalId',
      'exported'
    ])
    let pickedFromPatientDoc = _pick(patientDoc, [
      'address',
      'name',
      'dob',
      'sex',
      'telecom',
      'currentWorkflowState',
      'managingOrganization',
      'workflowIteration'
    ])
    return Object.assign({}, pickedFromEligibilityDoc, pickedFromPatientDoc)
  }

  let arrayOfFilteredPatients = []
  // When multiple members with the same lastNamefirstName exist
  for (let i = 0; i < eligibilityDocs.length; i++) {
    let mapPatientDoc
    if (eligibilityDocs[i].export_subject) {
      try {
        mapPatientDoc = await gateway.get(
          `/api/medwise/patient?patientId=${eligibilityDocs[i].export_subject}`
        )
      } catch (err) {
        // do nothing
      }
    }
    let filteredPatient = filterPatient(eligibilityDocs[i], mapPatientDoc)
    filteredPatient.isEligible =
      eligibility.isPatientEligible(eligibilityDocs[i]) &&
      eligibility.isPatientNotHospice(eligibilityDocs[i])
    arrayOfFilteredPatients.push(filteredPatient)
  }
  return arrayOfFilteredPatients
}

// This is just how this was named in 1.x. May not be a fitting name for 2.0
export const getRiskStratPatient = externalId => {
  return async dispatch => {
    handleRequest(dispatch, C.REQUEST, C.PREVIEW)
    try {
      let eligDoc = await gateway.get(`/api/stratification/patient?externalId=${externalId}`)
      if (eligDoc.name) {
        let refinedPatient = await prepareForPreview([eligDoc])
        dispatch({
          type: C.PREVIEW,
          payload: refinedPatient
        })
      } else {
        dispatch({
          type: C.PREVIEW,
          payload: [] // seems to set an emtpy {}
        })
      }
    } catch (err) {
      handleError(dispatch, err, C.FAILURE, C.PREVIEW)
    }
  }
}

export const eligibleSearch = params => {
  return async dispatch => {
    try {
      handleRequest(dispatch, C.REQUEST, C.PREVIEW)
      let patients = await gateway.get(`/api/stratification/search`, params)
      if (!patients.rows || patients.rows.length === 0) {
        return dispatch({
          type: C.PREVIEW,
          payload: []
        })
      }

      patients = await prepareForPreview(patients.rows.map(p => p.doc))
      dispatch({
        type: C.PREVIEW,
        payload: patients
      })
    } catch (err) {
      handleError(dispatch, err, C.FAILURE, C.PREVIEW)
    }
  }
}

export const doesPatientAlreadyExist = async externalId => {
  let patient = await gateway.get(`/api/stratification/patient?externalId=${externalId}`)
  /**
   * [setPatientData helper fn]
   * @param {Boolean} [existence=undefined] - true if patient exists
   * @param {Array}  [patientInfo=[]]       - a filtered version of the patient for UI exposure
   */
  const setPatientData = (existence = undefined, patientInfo = []) => {
    if (existence) {
      return {
        patientExists: existence,
        patientInfo: patientInfo
      }
    } else {
      return {}
    }
  }
  if (Object.keys(patient).length > 0) {
    let filteredPatient = await prepareForPreview([patient])
    return setPatientData(true, filteredPatient[0])
  } else {
    return setPatientData()
  }
}

export const importPatient = (externalId, exportPharmacy) => {
  return async dispatch => {
    handleRequest(dispatch, C.REQUEST, C.IMPORT)
    try {
      const maxes = await getMaxGroupAndCohort()
      const postData = {
        client: 'ClearStone Solutions',
        batchId: 'EMTM',
        groupId: maxes.groupId,
        cohortId: maxes.cohortId,
        useCohorts: true,
        patients: [externalId],
        isAutoAdd: true,
        exportPharmacy: exportPharmacy
      }
      const result = await gateway.post('/api/stratification/patients', postData)
      dispatch({
        type: C.IMPORT,
        payload: result
      })
    } catch (err) {
      handleError(dispatch, err, C.FAILURE, C.IMPORT)
    }
  }
}

// export const getReferralTypes = () => {
//   return async dispatch => {
//     handleRequest(dispatch, C.REQUEST, C.SET)
//     try {
//       // works
//       // const schema = await gateway.get('/api/medwise/schema', { id: 'patientReferralOptions' })
//
//       const schema = SchemaService.getReferralTypes()
//       console.log('patient.actions = schema result: ', schema)
//     } catch (err) {
//       handleError(dispatch, err, C.FAILURE, C.SET)
//       dispatch(
//         notifyActions.addNotification({
//           message: err.message || 'An error occurred gathering form information from the database.'
//         })
//       )
//       logger('An error occurred saving the patient', err, true)
//     }
//   }
// }

export const saveNew = (patient, selectedOrgDetails, user) => {
  return async dispatch => {
    handleRequest(dispatch, C.REQUEST, C.SET)
    try {
      let formatPatientForStorage = (p, org) => {
        let patient = Object.assign({}, p) // Is this still needed?
        let orgId = _get(org, '_id')
        let orgParent = _get(org, 'parent', false)
        let orgNPI = _get(org, 'NPI')

        let orgStartingWorkflow = _get(
          org,
          'workflows.startingWorkflowState',
          'Ready for Initiating Call'
        )
        if (!orgParent) {
          patient.managingOrganization = { id: orgId }
        }
        // extra nodes are needed for child orgs
        if (orgParent) {
          patient.managingOrganization = {
            id: orgId,
            primary: orgId,
            secondary: orgParent
          }
        }
        if (orgNPI) {
          // QUESTION: Does this need to have a default of some kind?
          patient.managingOrganization.NPI = orgNPI
        }
        if (!patient.type) {
          patient.type = 'patient'
        }
        if (!patient.status) {
          patient.status = 'active'
        }
        if (patient.referralType) {
          // TODO: This is not done - needs multi select, and a date to be added.
        }
        if (!patient.currentWorkflowState) {
          patient.currentWorkflowState = { name: orgStartingWorkflow }
        }
        if (patient.dob) {
          patient.dob = dayjs(patient.dob).format('YYYY-MM-DD')
        }
   
        if (patient.programId) {
          const isEnrolling = true
          const reason = "Patient added manually"
          const performedBy = {
            userFirstName: _get(user, ['profile', 'firstName']),
            userLastName: _get(user, ['profile', 'lastName']),
            userEmail: _get(user, ['profile', 'email'])
          }
          ProjectService.updatePatientProjectInfo(patient, patient.programId, isEnrolling, reason, performedBy)
          delete patient.programId
        }
        return patient
      }
      const formattedPatient = formatPatientForStorage(patient, selectedOrgDetails)

      const result = await gateway.post('/api/medwise/patient', { patient: formattedPatient })
      if (result.ok && result.id.length) {
        const patientId = result.id
        // reusing this event to trigger a trip to the workflow state machine, but we could add a unique event.
        await dispatch({ type: C.MOVE_FROM_STAGED })
        dispatch({
          type: C.IMPORT,
          payload: { mapPatientId: patientId }
        })    
      } else {
        dispatch(
          notifyActions.addNotification({
            message: 'There was an error saving the patient.',
            type: 'error',
            sticky: true
          })
        )
        throw new Error('There was an error saving the patient.')
      }
    } catch (err) {
      handleError(dispatch, err, C.FAILURE, C.SET)
    }
  }
}

/**
 * TODO updatePatient is an async function to send to the api
 */
export const updatePatient = data => {
  return async (dispatch, getState) => {
    const result = handleRequest(dispatch, C.REQUEST, C.UPDATE, data)
    if (result === false) {
      return
    }
    try {
      let formatPatientForStorage = (p) => {
        let patient = Object.assign({}, p) // Is this still needed?

        if (patient.dob) {
          patient.dob = dayjs(patient.dob).format('YYYY-MM-DD')
        }
        return patient
      }
      
      let formattedPatient = formatPatientForStorage(data)

      // update redux store
      dispatch(setPatient(formattedPatient))

      // TODO: handle a failure here?
      await gateway.put(`/api/medwise/patient`, { patient: formattedPatient })
      // Consider updating the .rev received from "patientResult" on payload "data"
      dispatch({ type: C.UPDATE, payload: formattedPatient })
      // we save the new patient object but reload patient to get new revision etc.
      // we could also just merge in the new revision.. this is a paranoid/sanity-check approach
      dispatch(getPatient(formattedPatient._id))
    } catch (err) {
      // set error, patient stays in previous known state
      // (though altered form state stays the same)
      handleError(dispatch, err, C.FAILURE, C.UPDATE)
    }
  }
}

export const setPatient = patient => {
  return {
    type: C.SET,
    payload: patient
  }
}

export const removePatient = () => {
  return {
    type: C.REMOVE
  }
}

/**
 * Use this to reset a patient workflow state. It should probably have some restriction around it on what roles/permissions
 * can do this.
 * @param  {String} workflowState The state to change to
 * @param  {String} reason        The reason for change
 * @param  {String} reasonOther   The "other" reason. Not sure what this is for, it was just in 1.x
 * @param  {Boolean} newIteration Whether to start a new iteration or not, defaults to `false`
 * @return {Function}             Dispatch
 */
export const manualWorkflowChange = (workflowState, reason, reasonOther, newIteration) => ({
  type: C.MANUAL_WORKFLOW_CHANGE,
  payload: {
    workflowState,
    reason,
    reasonOther,
    newIteration
  }
})

/**
 * Send an invitation to MyMedWise (silly that this is hardcoded like this)
 * @param  {Object} params The parameters to pass to the API call
 * @param  {String} params.patientId The patient ID
 * @param  {String} params.email The email of the patient to invite
 * @param  {String} params.os The value in telecom.cellphone.os
 * @return {[type]}        [description]
 */
export const sendInvitationEmail = params => {
  return async (dispatch, getState) => {
    const result = handleRequest(dispatch, C.REQUEST, C.SEND_INVITATION, params)
    if (result === false) {
      return
    }
    try {
      // TODO: handle a failure here?
      await gateway.post(`/api/medwise/email/invite`, { params })
      // Consider updating the .rev received from "patientResult" on payload "data"
      dispatch({ type: C.SUCCESS })
      dispatch(
        notifyActions.addNotification({
          message: `Email sent successfully. The patient should receive an email at ${params.email} soon.`,
          type: 'info'
        })
      )
    } catch (err) {
      // set error, patient stays in previous known state
      // (though altered form state stays the same)
      handleError(dispatch, err, C.FAILURE, C.SEND_INVITATION)
      dispatch(
        notifyActions.addNotification({
          message: 'There was an error sending the email. Please try again.',
          type: 'error',
          sticky: false
        })
      )
    }
  }
}

const makeReduxType = string => {
  const type = string
    .toUpperCase()
    .split(' ')
    .join('_')
  return type
}

export const movePatientFromStaged = () => {
  return { type: C.MOVE_FROM_STAGED }
}

export const dispatchInteractionEvent = (interactions, user) => {
  const latestCall = getLatestCall(interactions)
  // Opt in
  if (latestCall.record.successful && latestCall.record.decision === true) {
    const typeName = makeReduxType(`${latestCall.app} success`)
    return { type: C[typeName], payload: { call_log: interactions } }
  }
  // Opt out
  if (
    latestCall.record.successful &&
    latestCall.record.decision === false &&
    !latestCall.record.forPrimaryProject
  ) {
    const typeName = makeReduxType(`${latestCall.app} optout`)
    return {
      type: C[typeName],
      payload: {
        call_log: interactions,
        workflowData: {
          decisionReason: latestCall.record.decisionReason,
          call: latestCall,
          user: user.email,
          userId: user.id,
          date: dayjs().format()
        }
      }
    }
  }

  // Decline service
  if (
    latestCall.record.successful &&
    latestCall.record.decision === false &&
    latestCall.record.forPrimaryProject
  ) {
    const typeName = makeReduxType(`${latestCall.app} decline`)
    return {
      type: C[typeName],
      payload: {
        call_log: interactions,
        workflowData: {
          decisionReason: latestCall.record.decisionReason,
          call: latestCall,
          user: user.email,
          userId: user.id,
          date: dayjs().format()
        }
      }
    }
  }
  // Failure to Engage Calls
  if (!latestCall.record.successful) {
    const typeName =
      latestCall.app === 'Initiating Call'
        ? 'INITIATING_INTERACTION_FAIL'
        : makeReduxType(`${latestCall.app} fail`)
    return {
      type: C[typeName],
      payload: {
        // when backend workflow service is up and running can remove attempt below.
        attempt: latestCall.record.attempt,
        call_log: interactions,
        overrideWorkflow: latestCall.record.overrideWorkflow,
        workflowData: {
          call: latestCall,
          user: user.email,
          userId: user.id,
          date: dayjs().format()
        }
      }
    }
  }

  // logic that looks at the call object that is passed in and dispatches the correct type
  // Initiating and successful
  // Initiating and not successful - pass attempt # as data
  // Initiating and not successful user Override fte
  // Initiating and OptOut
  // Initiating and Decline of service
}
