/*
 * Actions for getting aggregate filter values for Patient Search
 */

import gateway from 'services/gateway'
import logger from 'services/logger'
import { get, forEach } from 'lodash'

import { actions as notifyActions } from 'store/modules/notify'

import * as C from './types'

const noneFirstSorting = (a, b) => {
  const aString = a.toString().toLowerCase()
  const bString = b.toString().toLowerCase()
  const aNum = Number(a)
  const bNum = Number(b)
  let aComparator, bComparator

  // 'none' to the top
  if (aString === 'none') {
    return -1
  } else if (bString === 'none') {
    return 1
  }

  // move numbers before non-numbers
  // and set proper comparator for homogenous items
  if (!isNaN(aNum) && isNaN(bNum)) {
    return -1
  } else if (isNaN(aNum) && !isNaN(bNum)) {
    return 1
  } else if (!isNaN(aNum) && !isNaN(bNum)) {
    aComparator = aNum
    bComparator = bNum
  } else {
    aComparator = aString
    bComparator = bString
  }

  // compare nums or strings as usual
  if (aComparator < bComparator) {
    return -1
  } else if (aComparator > bComparator) {
    return 1
  } else {
    return 0
  }
}

export const fetchAggregates = options => {
  // add in fields for aggregations
  options.aggregations = [
    'cohort',
    'group',
    'prn',
    'routine',
    'workflow_iteration',
    'current_year',
    'group',
    'attempts_made',
    'total_risk_score'
  ]
  return async dispatch => {
    dispatch({
      type: C.FETCH_SEARCH_AGGREGATES_REQUEST
    })
    try {
      let results = await gateway.post(`/api/medwise/patient/search`, options)
      let filterAggregates = {}
      var aggregations = get(results, ['aggregations'], {})
      forEach(aggregations, function (val, key) {
        const term = key.replace('agg_terms_', '')

        const aggregateArray = val.buckets.map(bucket => {
          return bucket.key
        })
        filterAggregates[term] = aggregateArray.sort(noneFirstSorting)
      })

      dispatch(fetchAggregatesSuccess(filterAggregates))
    } catch (err) {
      dispatch(fetchAggregatesFailure())
      dispatch(
        notifyActions.addNotification({
          message: 'An error occurred getting the patient search aggregates'
        })
      )
      logger('An error occurred getting the patient search aggregates', err, true)
    }
  }
}

export const fetchAggregatesSuccess = results => ({
  type: C.FETCH_SEARCH_AGGREGATES_SUCCESS,
  payload: results
})

export const fetchAggregatesFailure = () => ({
  type: C.FETCH_SEARCH_AGGREGATES_FAILURE
})

export const clearResults = () => ({
  type: C.CLEAR_AGGREGATES
})
