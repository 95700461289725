import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Form, Field, reduxForm, formValueSelector } from 'redux-form'
import MenuItem from '@material-ui/core/MenuItem'
import ReduxFormText from 'components/common/redux-form/text'
import ReduxFormSelect from 'components/common/redux-form/select'
import ReduxFormCheckbox from 'components/common/redux-form/checkbox'
import { riskFactorValidation } from './validation'

const FORM_NAME = 'riskfactor'

export class RiskFactorsForm extends Component {
  render () {
    const { handleSubmit, schema, riskFactor } = this.props
    const categories = schema.nonMedicationRelatedProblems.map(nmrp => nmrp.display)
    const subfactors = {}
    schema.nonMedicationRelatedProblems.forEach(nmrp => {
      nmrp.subfactors.forEach(sub => {
        if (!subfactors[nmrp.display]) {
          subfactors[nmrp.display] = []
        }
        subfactors[nmrp.display].push(sub)
      })
    })
    return (
      <Form name="riskFactor" className="w-100 flex flex-column" onSubmit={handleSubmit}>
        <div className="flex justify-between mb2">
          <div className="dib w-50">
            <Field required name="category" label="Category" component={ReduxFormSelect}>
              {categories.map(c => (
                <MenuItem key={c} value={c}>
                  {c}
                </MenuItem>
              ))}
            </Field>
          </div>
          <div className="dib w-40">
            {riskFactor && (
              <Field
                required
                name="riskFactor"
                label="Risk Factor"
                disabled={!riskFactor}
                renderValue={selection => selection.display}
                component={ReduxFormSelect}
              >
                {riskFactor &&
                  subfactors[riskFactor].map(sf => (
                    <MenuItem key={sf.snowMedCode} value={sf}>
                      {sf.display}
                    </MenuItem>
                  ))}
              </Field>
            )}
          </div>
        </div>
        <div className="flex justify-between mb2">
          <div className="dib w-100">
            <Field
              name="riskFactorNote"
              type="text"
              label="Note"
              helperText=""
              component={ReduxFormText}
            />
          </div>
        </div>
        <div className="flex justify-between mb2">
          <div className="dib w-100">
            <Field name="verified" type="checkbox" label="Verified" component={ReduxFormCheckbox} />
          </div>
        </div>
      </Form>
    )
  }
}

const selector = formValueSelector(FORM_NAME)
const ConnectedReduxForm = connect(state => ({
  riskFactor: selector(state, 'category')
}))(RiskFactorsForm)

const ReduxRiskFactorsForm = reduxForm({
  form: FORM_NAME,
  validate: riskFactorValidation
})(ConnectedReduxForm)

export default ReduxRiskFactorsForm
