/*
 * Dashboard profile component
 */

import React, { Component } from 'react'
import dayjs from 'dayjs'
import { capitalize, cloneDeep, get, isEqual, last, map, reduce, set, unset } from 'lodash'
import Chip from '@material-ui/core/Chip'
import { eligibility } from '@jrs/map-common-logic'
import { RiskScoreDial } from '@trhc/react-risk-visualizations'

import { getProfileForSave, canAccessFeature } from 'services/auth'
import { getOrganizationById } from 'services/organizations'
import { getPatientTimeZone, getPatientOrgDoc } from 'services/patient'
import { formatWorkflow } from 'services/workflow'
import { removeOrgPrefix } from 'services/utils'
import { canAccess } from 'services/idam'

import { TRHC_ERROR, TRHC_GREEN, TRHC_WARNING } from 'constants/colors'
import { planMapping } from 'constants/patient'
import { WORKFLOW_NAMES } from 'constants/workflow'

import ContextButton from 'components/common/context-button'
import ReservationButton from 'components/common/reservation-button'
import { AddressItem, DataItem, DataItemArray, PhoneItem } from 'components/common/data-item'
import { getSeverity, MRSBar } from 'components/common/medication-risk-score'
import {
  calculateBMI,
  calculateCreatinineClearance,
  convertInchesToFeetAndInches
} from 'services/calculations'
import StandardTab from 'components/common/standard-tab'
import AlertDialog from 'components/common/alert-dialog'
import ProfileForm from './form'
import PrintButton from './print-button'
import { DateFormat } from 'constants/date'

export const patientFormFields = [
  'name.first',
  'name.middle',
  'name.last',
  'telecom.email',
  'telecom.phone',
  'telecom.cellphone.number',
  'telecom.cellphone.preferred',
  'sex',
  'dob',
  'languagePreference',
  'managingOrganization.id',
  'programId',
  'externalId',
  'referralType',
  'history',

  'address.primary',
  'address.verified',
  'address.line1',
  'address.line2',
  'address.city',
  'address.state',
  'address.zip',
  'address.phone',
  'telecom.phonePreferred',
  'telecom.phoneMobile',
  'telecom.cellphone.receiveSMS',

  'address2.primary',
  'address2.verified',
  'address2.line1',
  'address2.line2',
  'address2.city',
  'address2.state',
  'address2.zip',
  'address2.phone',
  'address2.phonePreferred',
  'address2.phoneMobile',

  'serviceOptions.myMedWiseServices'
]

const formattedName = nameObject => {
  const nameArray = []
  nameObject.first && nameArray.push(nameObject.first)
  nameObject.middle && nameArray.push(nameObject.middle)
  nameObject.last && nameArray.push(nameObject.last)

  return nameArray.join(' ')
}

const determinePreferredPhone = caregiver => {
  const phone1 = get(caregiver, 'phone')
  const phone1MarkedPreferred = get(caregiver, 'phonePreferred')
  const phone2 = get(caregiver, 'address2.phone')
  const phone2MarkedPreferred = get(caregiver, 'address2.phonePreferred')

  const prefersPhone1 = caregiver && phone1 && phone1MarkedPreferred
  const prefersPhone2 = caregiver && caregiver.address2 && phone2 && phone2MarkedPreferred
  const noPreference = caregiver && (!phone1MarkedPreferred && !phone2MarkedPreferred)

  if (!caregiver || (!phone1 && !phone2)) {
    // no data - nothing to return
  } else {
    if (prefersPhone1) {
      // Use phone #1 when it is present and preferred
      return phone1
    } else if (prefersPhone2) {
      // Use phone #2 when it is present and preferred
      return phone2
    } else if (noPreference) {
      // Returns what is available with priority going to
      // phone #1 over #2 when both are available
      if (phone1) {
        return phone1
      } else if (phone2) {
        return phone2
      }
    }
  }
}

export const initializeFormValuesFromModel = (model, fieldArray, existingInitialValues) => {
  const initialValues = reduce(
    fieldArray,
    (_initialValues, field) => {
      // set the initialValues object path
      // to the value of that path on the model
      return set(_initialValues, field, get(model, field))
    },
    existingInitialValues || {}
  )

  return initialValues
}

const useApigee = process.env.REACT_APP_USE_APIGEE && process.env.REACT_APP_USE_APIGEE.toLowerCase() === 'true'

let reservationPoller

class Profile extends Component {
  constructor (props) {
    super(props)

    this.standardTab = React.createRef()

    this.savePatient = this.savePatient.bind(this)
    this.getMostRecentWeightText = this.getMostRecentWeightText.bind(this)
    this.getMostRecentHeightText = this.getMostRecentHeightText.bind(this)
    this.mostRecentWeight = this.mostRecentWeight.bind(this)
    this.getMostRecent = this.getMostRecent.bind(this)
    this.bmiValue = this.bmiValue.bind(this)
    this.creatinineClearance = this.creatinineClearance.bind(this)
    this.openInactivate = this.openInactivate.bind(this)
    this.cancelInactivate = this.cancelInactivate.bind(this)
    this.confirmInactivate = this.confirmInactivate.bind(this)
    this.activatePatient = this.activatePatient.bind(this)
    this.getPatientPrimaryPhone = this.getPatientPrimaryPhone.bind(this)

    this.state = {
      inactivateOpen: false,
      latestReview: null,
      reservationConflict: false,
      hasSeenReservationWarning: false,
      hasSeenReleaseWarning: false
    }
  }

  componentDidMount () {
    // not a huge fan of this setInterval it just feels dirty but gets the job done
    reservationPoller = setInterval(
      this.fetchReservation,
      process.env.REACT_APP_RESERVATION_POLL_TIME || 10000
    )
  }

  componentWillUnmount () {
    // make sure to turn off the timer
    clearInterval(reservationPoller)
  }

  componentDidUpdate (prevProps) {
    const { patientDataActions, patient, notifyActions, reservations } = this.props
    const patientId = get(patient, 'data._id')
    const reservation = get(this.props, `reservations.data.${patientId}`)
    const reservationsStatus = get(this.props, 'reservations.status')
    if (
      !isEqual(get(prevProps, 'reservations'), get(this.props, 'reservations')) ||
      !isEqual(get(prevProps, 'patient'), get(this.props, 'patient')) ||
      !isEqual(
        get(prevProps, 'patientData.latestReview'),
        get(this.props, 'patientData.latestReview')
      )
    ) {
      this.standardTab.current.updateButtons()

      // show reservation alert
      if (
        reservation &&
        reservationsStatus &&
        !this.state.hasSeenReservationWarning &&
        !isEqual(get(prevProps, 'reservations'), get(this.props, 'reservations'))
      ) {
        const id = get(this.props, 'patient._id')
        const user = map(reservations.data, id)
        const email = user[0].user.identity_data.email

        notifyActions.addNotification({
          message: `Patient has been reserved by ${email}.`,
          sticky: true
        })

        this.setState({
          hasSeenReservationWarning: true,
          hasSeenReleaseWarning: false
        })
      } else if (
        !this.state.hasSeenReleaseWarning &&
        this.state.hasSeenReservationWarning &&
        !isEqual(get(prevProps, 'reservations'), get(this.props, 'reservations')) &&
        !reservation
      ) {
        // Show release message and reset states
        notifyActions.addNotification({
          message: `Patient has been released.`,
          type: 'info',
          sticky: true
        })
        this.setState({
          hasSeenReservationWarning: false,
          hasSeenReleaseWarning: true
        })
      }
    }

    if (
      !isEqual(
        get(prevProps, 'patient.data.currentWorkflowState.name'),
        get(this.props, 'patient.data.currentWorkflowState.name')
      )
    ) {
      patientDataActions.getLatestReviewType(patient.data._id)
    }
  }

  buildDropdownOptions = () => {
    const {
      auth,
      patient,
      patientActions,
      patientData,
      workflow
    } = this.props

    const dropdown = []

    if (canAccess(auth, true, { permissions: { $eq: ['medwise-advisor.edit-patient'] } })) {
      dropdown.push({
        name: get(patient, 'data.status') === 'active' ? 'Inactivate' : 'Activate',
        onClick: () => {
          get(patient, 'data.status') === 'active'
            ? // inactivate
            this.openInactivate()
            : this.activatePatient()
        }
      })
    }

    // This is a stop gap for red october release to allow a user to activate an imported patient.
    // Don't want to use the term "Activate" because we already use that but maybe "Begin work on imported patient"
    if (get(workflow, 'workflowState.type') === 'STAGED') {
      dropdown.push({
        name: 'Begin work on imported patient',
        onClick: () => {
          console.log('Imported')
          patientActions.movePatientFromStaged()
        }
      })
    }

    const latestReviewType = get(patientData, 'latestReview.0.value.reviewType', '')
    if (
      [
        WORKFLOW_NAMES.READY_PRINTING,
        WORKFLOW_NAMES.READY_PRINTING_MSA,
        WORKFLOW_NAMES.READY_MAILING,
        WORKFLOW_NAMES.COMPLETE
      ].indexOf(get(patient, 'data.currentWorkflowState.name')) > -1 &&
      (latestReviewType.includes('MSR Lite') || latestReviewType.includes('MSA'))
    ) {
      // we can reset the workflow
      const newWorkflow = WORKFLOW_NAMES.READY_MEDICATION_SAFETY_REVIEW
      dropdown.push({
        name: `Reset workflow to '${formatWorkflow(newWorkflow)}'`,
        onClick: () => {
          patientActions.manualWorkflowChange(newWorkflow, 'Reset worfklow for MSR')
        }
      })
    }

    const newEngagementObj = {
      name: `Start New Workflow Engagement`,
      onClick: () => {
        patientActions.manualWorkflowChange(
          WORKFLOW_NAMES.READY_INITIATING_CALL,
          'Start New Engagement',
          null,
          true
        )
      }
    }

    if (
      canAccess(auth, true, { permissions: { $eq: ['medwise-advisor.reset-workflow'] } }) &&
      get(patient, 'data.status') === 'active'
    ) {
      dropdown.push(newEngagementObj)
    } else if (
      [WORKFLOW_NAMES.COMPLETE].indexOf(get(patient, 'data.currentWorkflowState.name')) > -1
    ) {
      dropdown.push(newEngagementObj)
    }

    return dropdown
  }

  showChartViewerButton (orgDoc) {
    return get(orgDoc, 'workflows.showChartViewer')
  }

  openChartViewer (obj) {
    const patientExternalId = get(obj, 'props.patient.data.identifier[0].value')
    const chartViewerUrl = process.env.REACT_APP_CHART_VIEWER_URL
    const fhirPlatform = useApigee ? process.env.REACT_APP_FHIR_PLATFORM_URL_APIGEE : process.env.REACT_APP_FHIR_PLATFORM_URL
    return window.open(`${chartViewerUrl}?iss=${fhirPlatform}&launch=${patientExternalId}`)
  }

  getButtons = () => {
    const {
      auth,
      patient,
      organizations,
      reservationsActions: { createReservation, deleteReservation }
    } = this.props
    const { reservationConflict } = this.state
    const patientId = get(patient, 'data._id')
    const reservation = get(this.props, `reservations.data.${patientId}`)
    const reservationsStatus = get(this.props, 'reservations.status')
    const patientOrg = get(patient, 'data.managingOrganization')
    let buttons = []
    if (this.showChartViewerButton(getPatientOrgDoc(patientOrg, organizations.allOrgs))) {
      buttons.push({
        name: 'View Chart',
        component: () => (
          <ContextButton
            text={<span>View Chart</span>}
            onClick={this.openChartViewer}
            className="secondary"
            hint={'Click to launch Chart Viewer in a new window.'}
            {...this.props}
          />
        )
      })
    }

    if (canAccess(auth, true, { permissions: { $eq: ['medwise-advisor.edit-patient'] } })) {
      buttons.push({
        name: 'Edit Profile',
        component: () => (
          <ContextButton
            text={<span>Edit Profile</span>}
            onClick={() => this.standardTab.current.handleFocus(this.props.patient.data)}
            className="primary"
            {...this.props}
          />
        )
      })
    }

    buttons = buttons.concat([
      { name: 'Print', component: PrintButton },
      {
        name: 'Reserve',
        component: () => (
          <div className="dib">
            <ReservationButton
              auth={this.props.auth}
              patientId={patientId}
              reservation={reservation}
              reservationsStatus={reservationsStatus}
              createReservation={createReservation}
              deleteReservation={deleteReservation}
              reservationConflict={reservationConflict}
              onUpdate={this.fetchReservation}
              tooltipPlacement={'bottom'}
            />
          </div>
        )
      }
    ])

    const dropDownOptions = this.buildDropdownOptions()
    if (dropDownOptions.length > 0) {
      buttons.push({
        name: 'Secondary',
        component: () => {
          return (
            <ContextButton
              text={<i className="material-icons">more_vert</i>}
              dropdown={dropDownOptions}
              className="secondary"
              {...this.props}
            />
          )
        }
      })
    }

    return buttons
  }

  fetchReservation = (reservationConflict = false) => {
    const {
      patient,
      reservationsActions: { getReservations }
    } = this.props

    const patientId = get(patient, 'data._id')

    this.setState(
      {
        reservationConflict
      },
      () => patientId && getReservations([patientId])
    )
  }

  // helper functions for BMI and Creatinine
  getMostRecent (obsArray) {
    // if (obsArray.length) {
    return obsArray.reduce((pre, cur) => {
      return dayjs(pre.issued).isBefore(dayjs(cur.issued)) ? cur : pre
    })
    // }
  }

  mostRecentWeight (obsArray) {
    const weightObservations = obsArray.filter(o => o.text === 'Weight')
    if (weightObservations.length) {
      return this.getMostRecent(weightObservations)
    }
  }

  mostRecentHeight (obsArray) {
    const heightObservations = obsArray.filter(o => o.text === 'Height')
    if (heightObservations.length) {
      return this.getMostRecent(heightObservations)
    }
  }

  // Calculate Creatinine Clearance
  creatinineClearance () {
    const obs = get(this.props, ['patientData', 'observations'], [])

    if (obs.length) {
      const mostRecentHeight = this.mostRecentHeight(obs) || {}
      const mostRecentWeight = this.mostRecentWeight(obs) || {}
      const creatinineSerumData = obs.filter(o => o.text === 'Creatinine Serum') || []

      const mostRecentCS = creatinineSerumData.length ? this.getMostRecent(creatinineSerumData) : []

      const sex = get(this.props, 'patient.data.sex')
      const dob = get(this.props, 'patient.data.dob')

      if (
        mostRecentHeight.valueQuantity &&
        mostRecentWeight.valueQuantity &&
        mostRecentCS.valueQuantity
      ) {
        const cal = calculateCreatinineClearance(
          mostRecentHeight.valueQuantity,
          mostRecentWeight.valueQuantity,
          mostRecentCS.valueQuantity,
          sex,
          dob
        )
        return `${cal} mL/min`
      }
    }
  }

  // calculate BMI
  bmiValue () {
    const obs = get(this.props, ['patientData', 'observations'], [])
    if (obs.length) {
      const mostRecentHeight = this.mostRecentHeight(obs) || {}
      const mostRecentWeight = this.mostRecentWeight(obs) || {}
      if (Object.keys(mostRecentHeight).length && Object.keys(mostRecentWeight).length) {
        const bmiNum = calculateBMI(mostRecentHeight.valueQuantity, mostRecentWeight.valueQuantity)
        return bmiNum
      }
    }
  }

  // calculate most recent weight
  getMostRecentWeightText () {
    const obs = get(this.props, ['patientData', 'observations'], [])
    if (obs.length) {
      const mostRecent = this.mostRecentWeight(obs) || {}
      if (mostRecent.valueQuantity) {
        return `${mostRecent.valueQuantity.value} ${mostRecent.valueQuantity.units}`
      }
    }
  }

  getMostRecentHeightText () {
    const obs = get(this.props, ['patientData', 'observations'], [])
    if (obs.length) {
      const mostRecent = this.mostRecentHeight(obs) || {}
      if (mostRecent.text === 'Height') {
        if (get(mostRecent, 'valueQuantity.units') === 'in') {
          const feetAndInches = convertInchesToFeetAndInches(mostRecent.valueQuantity.value)
          return `${feetAndInches.feet} ft ${feetAndInches.inches} in`
        } else {
          return `${mostRecent.valueQuantity.value} (${mostRecent.valueQuantity.units})`
        }
      }
    }
  }

  getPrimaryAddress (caregiver) {
    return get(caregiver, 'address2.primary')
      ? get(caregiver, 'address2')
      : get(caregiver, 'address')
  }

  getPatientPrimaryPhone (patient) {
    const primaryIsPreferred = get(patient, 'data.telecom.phonePreferred', false)
    const secondaryIsPreferred = get(patient, 'data.address2.phonePreferred', false)
    const cellphoneIsPreferred = get(patient, 'data.telecom.cellphone.preferred', false)

    // by default, primary is the preferred number
    let pathToPrimaryPhone = 'data.telecom.phone'

    // if primary is not marked and secondary is, then secondary is preferred
    if (!primaryIsPreferred && secondaryIsPreferred) {
      pathToPrimaryPhone = 'data.address2.phone'
    }

    // if primary and secondary are not marked but cellphone is, then cellphone is preferred
    if (!primaryIsPreferred && !secondaryIsPreferred && cellphoneIsPreferred) {
      pathToPrimaryPhone = 'data.telecom.cellphone.number'
    }

    return get(patient, pathToPrimaryPhone)
  }

  async savePatient (values, editing, initial) {
    const { auth, organizations, patient } = this.props
    const data = {
      ...patient.data,
      ...values
    }

    const currentOrg = getOrganizationById(
      organizations.allOrgs,
      get(patient, 'data.managingOrganization.id')
    )

    // Remove a patient from ECPN workflow. If they also want to do the transfer at the same time, they can.
    if (values.removeFromECPNWorkflow) {
      delete data.removeFromECPNWorkflow
      delete data.managingOrganization.second
      delete data.managingOrganization.primary
      if (values.transferTo) {
        const transferTo = getOrganizationById(organizations.allOrgs, values.transferTo)
        if (!data.orgChanges) {
          data.orgChanges = []
        }

        data.orgChanges.push({
          from: get(patient, 'data.managingOrganization.id'),
          to: transferTo._id,
          date: new Date().toISOString(),
          changedBy: {
            email: auth.profile.email,
            auth0Id: auth.profile.id
          },
          reason: values.transferToReason
        })

        data._changingOrg = {
          id: transferTo._id,
          NPI: transferTo.NPI
        }

        delete data.transferTo
        delete data.transferToReason
      }
    }

    if (values.transferTo && !values.removeFromECPNWorkflow) {
      const transferTo = getOrganizationById(organizations.allOrgs, values.transferTo)
      if (!data.orgChanges) {
        data.orgChanges = []
      }

      data.orgChanges.push({
        from: get(patient, 'data.managingOrganization.id'),
        to: transferTo._id,
        date: new Date().toISOString(),
        changedBy: {
          email: auth.profile.email,
          auth0Id: auth.profile.id
        },
        reason: values.transferToReason
      })

      if (currentOrg.parent) {
        // the managingOrganization becoming the parent
        data._changingOrg = {
          id: transferTo._id,
          primary: currentOrg._id,
          secondary: transferTo._id,
          NPI: transferTo.NPI
        }
      } else {
        // the managingOrganization becoming the child
        data._changingOrg = {
          id: transferTo._id,
          primary: transferTo._id,
          secondary: transferTo.parent,
          NPI: transferTo.NPI
        }
      }

      delete data.transferTo
      delete data.transferToReason
    }

    // check for bad number flag and for caregiver being preferred contact
    const badNumber = get(initial, 'badNumber', false)
    const caregiverIsPreferredContact = get(initial, 'caregiver.preferredContact', false)

    if (badNumber && !caregiverIsPreferredContact) {
      const oldPrimaryPhone = this.getPatientPrimaryPhone({ data: initial }) // kind of hacky but..
      const newPrimaryPhone = this.getPatientPrimaryPhone({ data: values })

      // if it was updated, remove the bad number flag from the patient doc
      if (oldPrimaryPhone !== newPrimaryPhone) {
        set(data, 'badNumber', false)
      }
    }

    // **** support-only stuff **** \\
    const initialHistory = get(initial, 'history', [])
    const latestHistory = get(data, 'history', [])

    // check to see if the history array was updated
    if (!isEqual(latestHistory, initialHistory)) {
      const history = cloneDeep(initialHistory)
      const newEntry = last(latestHistory)

      // if home care flag is non-existent, set it to an empty string
      if (!newEntry.homeCareFlag) {
        set(newEntry, 'homeCareFlag', '')
      }

      // if the date is valid, add the history entry to the existing array
      if (dayjs(get(newEntry, 'dischargeDate')).isValid()) {
        set(newEntry, 'manualChange.updatedAt', dayjs().toISOString())
        set(newEntry, 'manualChange.user', getProfileForSave(['email', 'auth0Id']))
        history.push(last(get(data, 'history', [])))
      }

      // if there is at least one entry, set the data to be saved to the new history array. otherwise, remove it.
      if (history.length) {
        set(data, 'history', history)
      } else {
        unset(data, 'history')
      }
    }

    await this.props.patientActions.updatePatient(data)
  }

  openInactivate () {
    this.setState({
      inactivateOpen: true
    })
  }

  cancelInactivate () {
    this.setState({
      inactivateOpen: false
    })
  }

  confirmInactivate () {
    const data = Object.assign({}, this.props.patient.data)
    data.status = 'inactive'
    this.props.patientActions.updatePatient(data)
    this.setState({
      inactivateOpen: false
    })
  }

  activatePatient () {
    const data = Object.assign({}, this.props.patient.data)
    data.status = 'active'
    this.props.patientActions.updatePatient(data)
  }

  getPatientPlan () {
    const { patient } = this.props
    if (get(patient, 'data.metadata.type3.account')) {
      return planMapping[patient.data.metadata.type3.account]
    }
  }

  render () {
    const {
      patient,
      reduxForm,
      organizations,
      schema,
      referrals
    } = this.props

    const formProps = {
      patient,
      organizations,
      referrals,
      reduxForm,
      schema
    }

    const initialValues = patient.data
      ? initializeFormValuesFromModel(patient.data, patientFormFields)
      : {}

    // hack for data quality issue 'Female -> female' for sex selection
    initialValues.sex = initialValues.sex ? initialValues.sex.toLowerCase() : undefined

    const latestEligibility = eligibility.parseLatestEligibleDates(
      get(patient, 'data.eligibility', [])
    )
    const isPatientEligible = eligibility.isPatientEligible(patient.data)

    const eligibilityComponent = latestEligibility ? (
      isPatientEligible ? (
        <DataItem
          label="Eligibility:"
          valueClass="gray"
          value={`Patient eligible until ${dayjs(latestEligibility.terminationDate).format(
            DateFormat.DATE_DISPLAY
          )}`}
          className="pt3"
        />
      ) : (
        <DataItem
          label="Eligibility:"
          value={
            <Chip
              style={{ height: '18px', backgroundColor: TRHC_ERROR }}
              label="Patient is not eligible"
            />
          }
          className="pt3"
        />
      )
    ) : null

    const contextButtons = this.getButtons()

    const patientPlan = this.getPatientPlan()

    const oldRiskVis = (
      <div>
        <div className="risk-score-top pb1">
          MedWise Risk Score™:{' '}
          <b>{getSeverity(get(this.props.mds, 'scores.risks.TotalScore')).description}</b>
        </div>
        <div className="risk-score-chart pb3">
          <MRSBar score={get(this.props.mds, 'scores.risks.TotalScore')} />
        </div>
      </div>
    )
    const newRiskVis = (
      <div>
        <RiskScoreDial
          riskDialData={get(this.props, 'mds.riskScoreDial', undefined)}
        />
      </div>
    )

    return (
      <div className="profile">
        <AlertDialog
          open={this.state.inactivateOpen}
          title="Inactivate Patient?"
          text="Inactivating the patient will remove them from normal MedWise workflows. Are you sure you want to do this?"
          actions={[
            {
              buttonText: `No, don't inactivate`,
              color: 'default',
              onClick: this.cancelInactivate
            },
            {
              buttonText: `Yes, Inactivate`,
              autoFocus: true,
              onClick: this.confirmInactivate
            }
          ]}
        />
        <StandardTab
          ref={this.standardTab}
          name="Profile"
          object="profile"
          form={ProfileForm}
          initialValues={initialValues}
          formHandler={this.savePatient}
          modalFormProps={formProps}
          contextButtons={contextButtons}
          buttonContext={this}
          {...this.props}
        >
          <div className="center">
            <div className="cf">
              <div className="fl w-100 w-25-ns pa2">
                <div className="risk-score pb2">
                  { canAccessFeature('NEW_RISK_VIS') ? newRiskVis : oldRiskVis }
                </div>
                {get(patient, 'data.caregiver.preferredContact') && (
                  <div className="pb3">
                    <Chip
                      style={{ height: '18px' }}
                      label="Caregiver is Preferred Patient Contact"
                    />
                  </div>
                )}
                {get(patient, 'data.caregiver', false) && (
                  <div className="dt pb3">
                    <div className="dt-row">
                      <DataItem
                        label="Caregiver Name:"
                        labelClass="dtc fw6 w-50 pb3"
                        valueClass="dtc pb2 gray pb3"
                        data-cy="caregiver-name"
                        value={formattedName(get(patient, 'data.caregiver.name', {}))}
                        fragment
                      />
                    </div>
                    <div className="dt-row" data-cy="caregiver-address">
                      <AddressItem
                        label="Caregiver Address:"
                        labelClass="dtc fw6 w-50 pb3"
                        valueClass="dtc v-top gray pb3"
                        address={this.getPrimaryAddress(get(patient, 'data.caregiver'))}
                        fragment
                      />
                    </div>
                    <div className="dt-row" data-cy="caregiver-phone">
                      <PhoneItem
                        label="Caregiver Phone:"
                        labelClass="dtc fw6 w-50 pb3"
                        valueClass="dtc pb2 gray pb3"
                        value={determinePreferredPhone(get(patient, 'data.caregiver'))}
                        badNumber={
                          get(patient, 'data.caregiver.preferredContact', false) &&
                          get(patient, 'data.badNumber', false)
                        }
                        fragment
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="fl w-100 w-25-ns pa2">
                <div className="f3 b pb3">
                  {get(patient, 'data.name.first')} {get(patient, 'data.name.middle')}{' '}
                  {get(patient, 'data.name.last')}
                  {get(patient, 'data.status', 'active') === 'inactive' && (
                    <Chip
                      className="ml2"
                      style={{ height: '18px', backgroundColor: TRHC_WARNING }}
                      label="Inactive"
                    />
                  )}
                  {get(last(get(patient, 'data.history', [])), 'homeCareFlag') && (
                    <Chip
                      className="ml2"
                      style={{ height: '18px', backgroundColor: TRHC_GREEN }}
                      label={
                        get(last(get(patient, 'data.history', [])), 'homeCareFlag') === '0'
                          ? 'CALL'
                          : 'HOME'
                      }
                    />
                  )}
                </div>
                <div className="dt">
                  <div className="dt-row">
                    <DataItem
                      label="Date of Birth:"
                      labelClass="dtc fw6 pr3 pb3"
                      valueClass="dtc pb2 gray pb3"
                      value={dayjs(get(patient, 'data.dob')).format('MMMM DD, YYYY')}
                      fragment
                    />
                  </div>
                  {get(last(get(patient, 'data.history', [])), 'dischargeDate') && (
                    <DataItem
                      label="Date of Discharge:"
                      labelClass="dtc fw6 pr3 pb3"
                      valueClass="dtc pb2 gray pb3"
                      value={dayjs(
                        get(last(get(patient, 'data.history', [])), 'dischargeDate')
                      ).format('MMMM DD, YYYY')}
                      fragment
                    />
                  )}
                  <div className="dt-row">
                    <AddressItem
                      label="Address:"
                      labelClass="dtc fw6 pr3 pb3"
                      valueClass="dtc v-top gray pb3"
                      address={this.getPrimaryAddress(patient.data)}
                      fragment
                    />
                  </div>
                  <div className="dt-row">
                    <PhoneItem
                      label="Phone:"
                      labelClass="dtc fw6 pr3 pb3"
                      valueClass="dtc pb2 gray pb3"
                      value={this.getPatientPrimaryPhone(patient)}
                      badNumber={
                        !get(patient, 'data.caregiver.preferredContact', false) &&
                        get(patient, 'data.badNumber', false)
                      }
                      fragment
                    />
                  </div>
                </div>
                <div className="dt--fixed">
                  <DataItem
                    label="Year:"
                    valueClass="gray"
                    value={get(patient, 'data.currentWorkflowState.year', 1)}
                    className="dtc w-50"
                  />
                  <DataItem
                    label="Engagement:"
                    valueClass="gray"
                    value={get(patient, 'data.workflowIteration', 1)}
                    className="dtc w-50"
                  />
                </div>
                {eligibilityComponent}
                <DataItem
                  label="Status:"
                  valueClass="gray"
                  value={formatWorkflow(get(patient, 'data.currentWorkflowState'))}
                  className="pt3"
                />
                <DataItem
                  label="Patient ID:"
                  valueClass="gray"
                  value={get(patient, 'data.externalId')}
                  className="pt3"
                />
              </div>
              <div className="fl w-100 w-25-ns pa2">
                <div className="dt">
                  <div className="dt-row">
                    <DataItem
                      label="Time Zone:"
                      valueClass="gray"
                      value={getPatientTimeZone(get(patient, 'data'))}
                      className="dtc w-100 pb3"
                    />
                  </div>
                </div>
                <div className="dt--fixed">
                  <div className="dt-row">
                    <DataItem
                      label="Sex:"
                      valueClass="gray"
                      value={capitalize(get(patient, 'data.sex'))}
                      className="dtc w-20 pb3"
                    />
                    <DataItem
                      label="Height:"
                      valueClass="gray"
                      value={this.getMostRecentHeightText()}
                      className="dtc w-20 pb3"
                    />
                    <DataItem
                      label="Weight:"
                      valueClass="gray"
                      value={this.getMostRecentWeightText()}
                      className="dtc w-20 pb3"
                    />
                    <DataItem
                      label="BMI:"
                      valueClass="gray"
                      value={this.bmiValue()}
                      className="dtc w-20 pb3"
                    />
                    <DataItem
                      label="Creatinine Clearance:"
                      valueClass="gray"
                      value={this.creatinineClearance()}
                      className="dtc w-20 pb3"
                    />
                  </div>
                </div>
                <div>
                  <DataItem
                    label="Language:"
                    valueClass="gray"
                    value={get(patient, 'data.languagePreference')}
                    className="w-50 pb3"
                  />
                </div>
                {patientPlan && (
                  <div>
                    <DataItem
                      label="Plan:"
                      valueClass="gray"
                      value={patientPlan}
                      className="w-100 pb3"
                    />
                  </div>
                )}
                <div>
                  <DataItem
                    label="Spouse:"
                    valueClass="gray"
                    value={get(patient, 'data.spouse')}
                    className="w-100 pb3"
                  />
                </div>
              </div>
              <div className="fl w-100 w-25-ns pl5">
                <div>
                  <DataItem
                    label="Patient Location:"
                    valueClass="gray"
                    value={get(patient, 'data.locationType')}
                    className="w-100 pt2"
                  />
                </div>
                {get(patient, 'data.facility') && (
                  <div>
                    <DataItem
                      label="Facility:"
                      valueClass="gray"
                      value={get(patient, 'data.facility')}
                      className="w-100 pt2"
                    />
                  </div>
                )}
                <div className="pt2">
                  <DataItemArray
                    label="Referrals:"
                    valueClass="gray"
                    value={get(patient, 'data.referralType', [{ text: 'N/A' }]).map(r => r.text)}
                    className="w-100 pt2"
                  />
                </div>
                <div className="pt2">
                  {/* this needs to connect to the org name from the org doc, not the patient managingOrganization.id */}
                  <DataItem
                    label="Organization:"
                    valueClass="gray"
                    value={removeOrgPrefix(get(patient, 'data.managingOrganization.id', ''))}
                    className="w-100 h3 pt2"
                  />
                </div>
              </div>
            </div>
          </div>
        </StandardTab>
      </div>
    )
  }
}

export default Profile
