import React from 'react'
import PropTypes from 'prop-types'
import { get as _get, isEqual } from 'lodash'
import { Edit, Delete } from '@material-ui/icons'
import NestedRowGroup from './nested-row-group'

import '../styles/nested-table.scss'

/**
 * Creates a nested table for use within the standard tab.  Table displays a summary row for the
 * parent and an expandable details row to display child data.
 *
 * @param {object}          props                           Properties passed to the component.
 * @param {object}          props.childTableActionsCell     The actions cell for the child table.
 * @param {string}          props.childTableDataPath        The path to the property within the parent table data that contains the data needed to display child table data.
 * @param {object[]}        props.childTableHeaders         The headers and mappings for the child data.
 * @param {function}        [props.childTableOnDelete]      The function to perform when child data is deleted.  If no function is provided, deletion will be disabled for the child data.
 * @param {function}        [props.childTableOnEdit]        The function to perform when child data is being edited.  If no function is provided, editing will be disabled for the child data.
 * @param {object[]}        props.data                      The data for the table.
 * @param {object|object[]} [props.parentTableActions=null] The actions that can be performed on the parent table data independently of the child data  By default, this is nothing.
 * @param {object[]}        props.parentTableHeaders        The headers for the parent table data.  These will be displayed in a single cell as summary data rather than true headers.
 */
class NestedTable extends React.Component {
  constructor (props) {
    super(props)

    this.handleDeleteWithParentData = this.handleDeleteWithParentData.bind(this)
    this.handleEditWithParentData = this.handleEditWithParentData.bind(this)
    this.handleCopyWithParentData = this.handleCopyWithParentData.bind(this)
  }

  /**
   * Fires the child's "delete" handler and also provides parent data to the caller.
   *
   * @param {object} parent   The item's parent's data.
   */
  handleDeleteWithParentData = parent => child => {
    this.props.childTableOnDelete(child, parent)
  }

  /**
   * Fires the child's "edit" handler and also provides parent data to the caller.
   *
   * @param {object} parent   The item's parent's data.
   */
  handleEditWithParentData = parent => child => {
    this.props.childTableOnEdit(child, parent)
  }

  /**
   * Fires the child's "copy" handler and also provides the parent data to the caller.
   *
   * @param {object} parent   The item's parent data.
   */
  handleCopyWithParentData = parent => child => {
    this.props.childTableOnCopy(child, parent)
  }

  render () {
    const {
      childTableActionsCell,
      childTableDataPath,
      childTableHeaders,
      data,
      expanded,
      parentTableActions,
      parentTableHeaders,
      rowEdit,
      rowDelete,
      showRowEdit,
      showRowDelete,
      ...leftovers
    } = this.props

    delete leftovers.childTableOnDelete
    delete leftovers.childTableOnCopy
    delete leftovers.enableRowSelection

    return (
      <table className="w-100 table nested">
        {data.map((parentData, k1) => {
          const childData = parentData[childTableDataPath]

          let Component
          if (parentTableActions.component) {
            const ParentTableActionsComponent = parentTableActions.component
            const propValues = {}

            parentTableActions.maps.forEach(prop => {
              if (prop.func) {
                // if passing a func for the component to call asynchronously
                propValues[prop.propName] = prop.func
              } else if (prop.rowItem) {
                // if passing the item itself (for passing back to a supplied func)
                propValues[prop.propName] = parentData
              } else {
                // for getting value from a path on the item
                // if prop.value is a function, derive the value by passing the item to the function,
                // else it should be a string path for deriving the value with lodash get,
                propValues[prop.propName] =
                  typeof prop.value === 'function'
                    ? prop.value(parentData)
                    : _get(parentData, prop.value, prop.default)
              }
            })

            Component = <ParentTableActionsComponent {...propValues} />
          }

          const header = parentTableHeaders.map((header, k2) => (
            <span key={k2} className="mr4">
              {header.name ? <strong>{header.name}: </strong> : undefined}
              {isEqual(typeof header.maps, 'function')
                ? header.maps(parentData)
                : parentData[header.maps]}
            </span>
          ))

          header.push(
            <span key="row-edit" className="ml-auto actions">
              {rowEdit && (showRowEdit === undefined || showRowEdit(parentData)) && <button
                type="button"
                title="Edit"
                onClick={evt => {
                  evt.stopPropagation()
                  rowEdit(parentData)
                }}
              >
                <Edit />
              </button>}
              {rowDelete && (showRowDelete !== undefined && showRowDelete(parentData)) && <button
                type="button"
                title="Delete"
                onClick={evt => {
                  evt.stopPropagation()
                  rowDelete(parentData)
                }}
              >
                <Delete />
              </button>}
            </span>)

          const enableRowSelection =
            typeof this.props.enableRowSelection === 'function'
              ? this.props.enableRowSelection(parentData)
              : this.props.enableRowSelection

          const enableChildDelete =
            typeof this.props.enableChildDelete === 'function'
              ? this.props.enableChildDelete(parentData)
              : this.props.enableChildDelete

          const enableChildEdit =
            typeof this.props.enableChildEdit === 'function'
              ? this.props.enableChildEdit(parentData)
              : this.props.enableChildEdit

          const enableChildCopy =
            typeof this.props.enableChildCopy === 'function'
              ? this.props.enableChildCopy(parentData)
              : this.props.enableChildCopy

          const enableChildSort =
            typeof this.props.enableChildSort === 'function'
              ? this.props.enableChildSort(parentData)
              : this.props.enableChildSort

          const enableChildDrag =
            typeof this.props.enableChildDrag === 'function'
              ? this.props.enableChildDrag(parentData)
              : this.props.enableChildDrag

          const expand = typeof expanded === 'function' ? expanded(parentData, k1) : expanded

          return (
            <NestedRowGroup
              key={k1}
              {...leftovers}
              parentTableHeaders={header}
              parentTableActionsCell={Component}
              childTableHeaders={childTableHeaders}
              parentData={parentData}
              childTableData={childData}
              childTableOnDelete={enableChildDelete && this.handleDeleteWithParentData(parentData)}
              childTableOnEdit={enableChildEdit && this.handleEditWithParentData(parentData)}
              childTableOnCopy={enableChildCopy && this.handleCopyWithParentData(parentData)}
              enableChildSort={enableChildSort}
              enableChildDrag={enableChildDrag}
              childTableActionsCell={childTableActionsCell}
              enableRowSelection={enableRowSelection}
              onSelectRow={this.props.onSelectRow}
              onSelectAllRows={this.props.onSelectAllRows}
              expanded={expand}
            />
          )
        })}
      </table>
    )
  }
}

NestedTable.propTypes = {
  childTableActionsCell: PropTypes.object,
  childTableDataPath: PropTypes.string.isRequired,
  childTableHeaders: PropTypes.arrayOf(PropTypes.object).isRequired,
  childTableOnCopy: PropTypes.func,
  childTableOnEdit: PropTypes.func,
  childTableOnDelete: PropTypes.func,
  data: PropTypes.array.isRequired,
  parentTableActions: PropTypes.object,
  parentTableHeaders: PropTypes.array.isRequired,
  enableRowSelection: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onSelectRow: PropTypes.func,
  onSelectAllRows: PropTypes.func,
  expanded: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  rowEdit: PropTypes.func,
  rowDelete: PropTypes.func,
  showRowEdit: PropTypes.func,
  showRowDelete: PropTypes.func
}

NestedTable.defaultProps = {
  data: [],
  parentTableActions: {},
  enableChildDelete: true,
  enableChildEdit: true,
  enableChildCopy: true
}

export default NestedTable
