/**
 * The dashboard is basically a shell for the whole inner app.
 * Below is a proposed structure for dashboard content "boxes":
 *
 * {
     id: 'name', // the name of the box
     enabled: true | false | function(props) {}, // whether a box is enabled,
                                                    the function allows you to
                                                    return true/false to control
     tabs: [], // the tabs
     buttons: [] // the buttons
   }
 *
 * tab: {
     name: 'Name' || function(props) {}, // Can be a string or a function
                                         // with props passed, should return a
                                         // string
     component: Component, // the component object to display in the tab content
     active: true | false, // whether this is active or not, controlled via
                              DashboardEmitter
     enabled: true | false | function(props) {}, // use this to control
                                                    permissions, defaults true
     children: [] // child tabs, these have the same structure
 * }
 *
 * button: {
     name: 'Name',
     component: Component, // its easier to just pass a component as the button.
     enabled: true | false | function(props) {}, // same as above
 * }
 */

import PriorityQueue from './modules/search/priority-queue'
import MemberSearch from './modules/search/member-search'

const requiresEula = props => {
  return props.eula && props.eula.acknowledgedAndAgreed
}

export default [
  {
    id: 'profile',
    enabled: true,
    tabs: [
      {
        id: 'priority-queues',
        name: 'Priority Queues',
        component: PriorityQueue,
        active: true,
        location: '/dashboard',
        enabled: requiresEula
      },
      {
        id: 'member-search',
        name: 'Member Search',
        component: MemberSearch,
        enabled: requiresEula
      }
    ],
    buttons: []
  }
]
