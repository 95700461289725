/*
 * Medication Reconciliation Container
 *
 * Container to mock out the use of actionable lists
 */

import React, { useState, Fragment } from 'react'
import { ActionableList, ActionableListItem} from 'components/common/actionable-list'
import {ListItemAction, ListItemActions} from 'components/common/list-item-actions'

import {getIconForMedStatus} from '../../../../components/dashboard/modules/medication-list-v2/medrec-status-icons'

// mock data items to use in list generation
const mockItems = [
  {
    "status": "VERIFIED",
    "summary": "Summary medication details goes here in this example.",
    "content": "Expanded medication content/form goes here in this example."
  },
  {
    "status": "ADDED",
    "summary": "Summary medication details goes here in this example.",
    "content": "Expanded medication content/form goes here in this example."
  },
  {
    "status": "MODIFIED",
    "summary": "Summary medication details goes here in this example.",
    "content": "Expanded medication content/form goes here in this example."
  },
  {
    "status": "WARNING",
    "summary": "Summary medication details goes here in this example.",
    "content": "Expanded medication content/form goes here in this example."
  },
  {
    "status": "DISCONTINUED",
    "summary": "Summary medication details goes here in this example.",
    "content": "Expanded medication content/form goes here in this example."
  }
]

const ActionableListContainer = (props) => {

  const [mockItemProps, setMockItemProps] = useState(mockItems)

  // doing handlers this way for demo purposes on /components.  ActionableListItems will become children of MedicationListItems in MedicationLists
  const handleEdit = () => {
    alert('You are in editing mode.  Item will expand eventually')
  }

  const handleDelete = (item) => {
    setMockItemProps(mockItemProps.filter(listItem =>  listItem.status !== item.status))
  }

  const buildActionsForItem = (item) => {
    return (
      <ListItemActions>
        <ListItemAction title="onEdit" onClickHandler={handleEdit}>
          <i className="material-icons">edit</i>
        </ListItemAction>
        <ListItemAction title="onDelete" onClickHandler={ () => handleDelete(item) }>
          <i className="material-icons">delete</i>
        </ListItemAction>
      </ListItemActions>
    )
  }

  // actionable list
  const actionableListItems = (
    <ActionableList>
      { 
        mockItemProps.map((item, i) => {

          return (
            <ActionableListItem 
              key={`item${i}`} 
              icon={getIconForMedStatus(item.status)}
              className={item.status}
              itemActions={buildActionsForItem(item)}
              {...item}
            >
              {item.content}
            </ActionableListItem>
          )
        })
      }
    </ActionableList>
  )
  
  return (
    <Fragment>
      {actionableListItems}
    </Fragment>
  )
}

export {ActionableListContainer}