/**
 * Print service to handle getting printed materials
 */

import jstz from 'jstz'

import gateway from './gateway'

/**
 * Get a PDF From the gateway API
 * @param  {String}  patientId             The patient's ID
 * @param  {String}  route                 Route / PDF you want to genereate
 * @param  {String}  [orgId]               Optional organization ID
 * @param  {Object}  [options]             Optional options to pass to the call
 * @param  {Boolean} [options.attachments] Whether to include attachments
 * @return {Promise}                       Returns API result
 */
export const getPDF = async (patientId, route, orgId, options) => {
  if (!patientId || !route) {
    throw new Error('Missing patientId or route')
  }

  const location = jstz.determine()
  const params = { patientId, location: location.name(), orgId, ...options }
  return gateway.get(route, params)
}
