import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { PortalWithState } from 'react-portal'

import ErrorBoundary from './error-boundary'

import './styles/modal.scss'

const portalModalClasses = ['portal-modal', 'flex', 'flex-column', 'flex-auto', 'justify-between']
const modalBackgroundClasses = ['portal-background', 'flex', 'justify-center', 'items-center']
/**
 * Modal component.
 *
 * @param {boolean} [closeOnOutsideClick=false] Boolean value indicating whether to close the modal when the user clicks an area outside the modal.
 * @param {boolean} [closeOnEsc=false]          Boolean value indicating whether to close the modal when the user presses the `esc` key.
 * @param {boolean} [isOpen=false]              Boolean value indicating whether the modal is currently open.
 * @param {string} title                        The title of the modal which will be displayed in the top title bar.
 * @param {function} toggle                     The function to perform the toggle between the modal being either open or closed.
 * @param {(object|object[])} [actions=null]    The actions to display at the bottom of the modal. By default, nothing is shown.
 * @param {(string|string[])} [modalClasses]    Classes to apply to the modal.
 */
class Modal extends Component {
  render () {
    const { isOpen } = this.props

    const component = isOpen && (
      <PortalWithState
        node={document && document.querySelector('#root>.mwa')}
        closeOnOutsideClick={this.props.closeOnOutsideClick}
        closeOnEsc={this.props.closeOnEsc}
        defaultOpen={isOpen}
      >
        {({ openPortal, closePortal, isOpen, portal }) => {

          /* eslint-disable jsx-a11y/anchor-is-valid */
          return (
            <React.Fragment>
              {portal(
                <div className={cx(modalBackgroundClasses, this.props.modalBackgroundClasses)}>
                  <div className={cx(portalModalClasses, this.props.modalClasses)}>
                    <div className="portal-header flex justify-between items-center">
                      <h4 className="align-start">{this.props.title}</h4>
                      <a className="close-portal" onClick={this.props.toggle}>
                        <i className="material-icons">close</i>
                      </a>
                    </div>
                    <div className="portal-content">
                      <ErrorBoundary>{this.props.children}</ErrorBoundary>
                    </div>
                    <div className="portal-footer flex justify-center align-center pv3 bt b--moon-gray">
                      {this.props.actions}
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          )
          /* eslint-enable jsx-a11y/anchor-is-valid */
        }}
      </PortalWithState>
    )
    return component
  }
}

Modal.propTypes = {
  closeOnOutsideClick: PropTypes.bool,
  closeOnEsc: PropTypes.bool,
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  actions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  modalClasses: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
}

Modal.defaultProps = {
  closeOnOutsideClick: false
}

export default Modal
