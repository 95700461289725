import * as C from './types'

export const updatePatientWorkflowState = (patientId, workflowState) => {
  return dispatch => {
    dispatch({ type: C.REQUEST, patientId })
    try {
      dispatch({ type: C.UPDATE_WORKFLOW, payload: workflowState })
    } catch (err) {
      dispatch({ type: C.ERROR, error: err })
    }
  }
}

export const clear = () => dispatch => {
  dispatch({ type: C.REQUEST, patientId: undefined })
  dispatch({ type: C.UPDATE_WORKFLOW, payload: {} })
}
