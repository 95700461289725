import React, { Component } from 'react'

import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'

/*
// Example Usage:
<div>
  <Field
    required
    name="someSelection"
    type="checkbox"
    label="Selection"
    component={ReduxFormRadiogroup}
  />               ↖️ THIS COMPONENT
</div
 */

class ReduxFormRadioGroup extends Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  render () {
    const {
      helperText,
      input,
      meta: { touched, error, warning },
      required,
      radioLabelsAndValues,
      onChange
    } = this.props
    const showError = !!(touched && error)
    const showWarning = !!(touched && warning)
    let radios = radioLabelsAndValues || [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
    const radioButtons = radios.map(l => (
      <FormControlLabel
        key={l.value}
        value={l.value}
        control={l.control || <Radio />}
        label={l.label}
      />
    ))
    return (
      <FormControl
        error={showError}
        warning={warning}
        required={required}
        component="fieldset"
        fullWidth
      >
        <RadioGroup
          value={input.value}
          onChange={event => {
            input.onChange(event.target.value)
            onChange && onChange(event.target.value)
          }}
          row
        >
          {radioButtons}
        </RadioGroup>

        <div className="flex justify-between">
          <div className="flex flex-column">
            {showError && <FormHelperText error>{error}</FormHelperText>}
            {!showError && showWarning && (
              <FormHelperText warning={warning}>{warning}</FormHelperText>
            )}
            {!showError && !showWarning && helperText && (
              <FormHelperText>{helperText}</FormHelperText>
            )}
          </div>
        </div>
      </FormControl>
    )
  }
}

export default ReduxFormRadioGroup
