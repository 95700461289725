import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { startSubmit, stopSubmit, submit, touch, destroy } from 'redux-form'
import { get, kebabCase } from 'lodash'
import pluralize from 'pluralize'

import logger from 'services/logger'
import Modal from 'components/common/modal'
import ContextButton from 'components/common/context-button'
import StandardTable from 'components/common/standard-table'
import NestedTable from 'components/common/nested-table'
import AlertDialog from 'components/common/alert-dialog'
import { getDeepObjectKeys } from 'services/utils'

/**
 *  The StandardTab component will allow you to load typical tab structures
 *  into view.
 *
 *  You can pass children to this component and replace the standard DOM and use the
 *  methods available in this component.
 *
 *  @param {string} name                    The proper singular name for this tab, eg: 'Allergy'
 *  @param {object[]} contextButtons        Use these to control the context buttons
 *                                          listed when the tab is active. These
 *                                          can use some default values by specifying:
 *                                          ```
 *                                          {
 *                                            name: 'Add Something',
 *                                            default: 'ADD'
 *                                          }
 *                                          ```
 *  @param {component} form                 The form component that will be presented when adding or
 *                                          editing an item.
 *  @param {component} [appendComponent]    A component to append to the bottom of the standard tab.
 *  @param {component} [prependComponent]   A component to add to the top of the standard tab.
 *  @param {boolen} [deleteAlert]           True if the tab should display the default confirmation model before performing the delete function.  Must be used with a handleDelete prop.
 *  @param {component} [deleteAlertComponent] A custom AlertDialog box.
 *  @param {boolen} [hideEdit] If true, the handleFocus function will not be sent to the common table, causing the edit button to not display.
 *  @param {function} [handleDelete]        Function to handle the deleting of an item in the table.
 *  @param {object} [formValues]            The form values
 *  @param {object} [initialValues]         The form defaults.  Populates default values to desired
 *                                          fields when adding a new record.
 *  @param {function} formHandler           The method to handle form submission.
 *  @param {object} tableHeaders            The header values for the Table component.
 *  @param {object[]} tableData             The data for the table.
 *  @param {object} [tableActionsCell=null] Default null, set this to override the table
 *                                          actions cell.
 *  @param {object} [filters]               Object for configuring the FilterSet (currently just
 *                                          client-side). Disabled by default.
 *  @param {object} [pagination]            Object to configure pagination. Disabled by default.
 *  @param {boolean} [useNestedTable=false] True if the tab is using a nested table.
 *  @param {string} [childTableDataPath]    Required for nested table functionality. This represents
 *                                          the property on the parent data object that should be
 *                                          used to display the child data.
 *  @param {object[]} [childTableHeaders]   Required for nested table functionality. The headers to
 *                                          be used for the child table.
 *  @param {function} [onCancel]            Callback for when the cancel button is clicked (when using
 *                                          default modal actions)
 *  @param {boolean} [disableEdit]          Disable all edit buttons in the table
 *  @param {boolean} [disableCopy]          Disable all copy buttons in the table
 *  @param {boolean} [disableDelete]        Disable all delete buttons in the table
 *  @param {boolean} [disableDefaultPrimaryAction] Disables the default action button when condition is met.
 */

class StandardTab extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      focusedItem: null,
      editing: false,
      submitting: false,
      modalIsOpen: false,
      alertOpen: false
    }

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.remoteSubmission = this.remoteSubmission.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleConfirmAlert = this.handleConfirmAlert.bind(this)
    this.cancelChanges = this.cancelChanges.bind(this)
  }

  openModal() {
    this.setState({
      submitting: false,
      modalIsOpen: true
    })
  }

  closeModal() {
    this.setState({
      submitting: false,
      modalIsOpen: false
    })
  }

  toggleModal() {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    })
  }

  cancelSubmitting = () => {
    this.setState({
      submitting: false
    })
  }

  remoteSubmission = () => {
    const { reduxForm, object, dispatch } = this.props
    this.setState({
      submitting: true
    }, () => {
      dispatch(startSubmit(object))

      if (reduxForm[object].syncErrors && Object.keys(reduxForm[object].syncErrors).length) {
        const setKeys = getDeepObjectKeys(reduxForm[object].syncErrors)
        setKeys.forEach(key => {
          console.log(`Setting error ${key} in ${object} form`)
          dispatch(touch(object, key))
        })
        this.setState({
          submitting: false
        })
        return dispatch(stopSubmit(object, reduxForm[object].syncErrors))
      }
      dispatch(submit(object))
    })
  }

  async handleFormSubmit(...args) {
    const { dispatch, object, preventDestroy } = this.props
    if (this.props.formHandler) {
      try {
        await this.props.formHandler(
          Object.assign({}, this.props.initialValues, this.props.reduxForm[this.props.object.toLowerCase()].values),
          this.state.editing,
          this.state.focusedItem,
          ...args
        )
      } catch (err) {
        this.setState({
          submitting: false
        })
        return logger('An error occurred', err)
      }
    }
    this.closeModal()
    if (!preventDestroy && object) {
      dispatch(destroy(object))
    }
  }

  /**
   *
   * @public
   */
  handleAdd() {
    this.setState({
      editing: false,
      submitting: false,
      focusedItem: this.props.initialValues || {},
      modalIsOpen: true
    })
  }

  handleFocus(item) {
    this.setState({
      editing: true,
      submitting: false,
      focusedItem: Object.assign({}, item),
      modalIsOpen: true
    })
  }

  handleConfirmAlert(item) {
    this.setState({ alertOpen: true, itemToDelete: item })
  }

  confirmDelete = () => {
    this.props.handleDelete(this.state.itemToDelete)
    this.setState({ alertOpen: false, itemToDelete: null })
  }

  cancelDelete = () => {
    this.setState({ alertOpen: false })
  }

  cancelChanges() {
    const { dispatch, object, preventDestroy } = this.props
    if (!preventDestroy && object) {
      dispatch(destroy(object))
    }
    this.setState({
      focusedItem: this.props.initialValues || {},
      modalIsOpen: false
    })
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  updateButtons() {
    let { name, box, contextButtons, tabManager } = this.props
    if (!contextButtons) {
      contextButtons = [
        {
          name: `Add ${name}`,
          component: () => (
            <ContextButton
              text={<span>{`Add ${name}`}</span>}
              data-cy={`standard-tab-add-${kebabCase(name)}`}
              className="primary"
              onClick={this.handleAdd}
              {...this.props}
            />
          )
        }
      ]
    }
    tabManager.emitter.emit('changeButtons', {
      box,
      buttons: contextButtons,
      context: this.props.buttonContext || this
    })
  }

  onRowSort(direction, row, parentData) {
    const { handleRowSort } = this.props

    handleRowSort(direction, row, parentData)
  }

  onRowDrag(dragIndex, hoverIndex, parentData) {
    const { handleRowDrag } = this.props

    handleRowDrag(dragIndex, hoverIndex, parentData)
  }

  onRowDragEnd(parentData) {
    const { handleRowDragEnd } = this.props

    handleRowDragEnd(parentData)
  }

  componentDidMount() {
    this.updateButtons()
  }

  componentDidUpdate(prevProps) {
    let prevButtons = get(prevProps, 'contextButtons', [])
    let currentButtons = get(this.props, 'contextButtons', [])

    let changed = false
    if (prevButtons.length !== currentButtons.length) changed = true
    if (!prevButtons.every(b => currentButtons.find(cb => cb.name === b.name))) changed = true
    if (prevProps && changed) {
      this.updateButtons()
    }
  }

  componentWillUnmount() {
    const { box, tabManager, dispatch, object } = this.props
    if (object) {
      dispatch(destroy(object))
    }

    tabManager.emitter.emit('changeButtons', {
      box,
      buttons: [],
      context: this.props.buttonContext || this
    })
  }

  renderChildren = children => {
    if (typeof children === 'function') {
      return children({
        ...this.state,
        openModal: this.openModal,
        closeModal: this.closeModal,
        toggleModal: this.toggleModal,
        handleAdd: this.handleAdd,
        handleFocus: this.handleFocus,
        handleDelete: this.handleDelete,
        handleConfirmAlert: this.handleConfirmAlert,
        handleFormSubmit: this.handleFormSubmit,
        remoteSubmission: this.remoteSubmission,
        cancelSubmitting: this.cancelSubmitting
      })
    }

    return children
  }

  render() {
    const { alertOpen } = this.state
    const {
      auth,
      dispatch,
      filters,
      tableData,
      tableHeaders,
      pagination,
      tableActionsCell,
      actionsColumnClasses,
      childOnDelete,
      childOnEdit,
      childOnCopy,
      notifyActions,
      modalFormProps,
      hideEdit,
      handleDelete,
      deleteAlert,
      deleteAlertComponent,
      deleteAlertText,
      children,
      prependComponent,
      appendComponent,
      form: FormComponent,
      ...props
    } = this.props

    const modalTitle = `${this.state.editing ? 'Edit' : 'Add'} ${get(props, 'name', '')}`
    const modalName = get(props, 'name', '')
    const formProps = {
      auth,
      notifyActions,
      dispatch,
      ...modalFormProps
    }

    const defaultActions = (
      <div className="context-buttons">
        <ContextButton
          text={<span>Cancel</span>}
          className="secondary"
          onClick={this.cancelChanges}
          data-cy={`modal-cancel-${kebabCase(modalName)}`}
        />
        <ContextButton
          type="submit"
          text={<span>{`Save ${modalName}`}</span>}
          className="primary"
          data-cy={`modal-save-${kebabCase(modalName)}`}
          onClick={this.remoteSubmission}
          disabled={this.state.submitting || this.state.disabled || props.disableDefaultPrimaryAction}
        />
      </div>
    )
    /**
     * If the deleteAlert prop is present from the parent component, use the built in confirmation models.  Else, only use the handleDelete function passed in from the parent.
     */
    const handleDeleteProcess = deleteAlert ? this.handleConfirmAlert : handleDelete

    const standardModal = FormComponent && (
      <Modal
        title={modalTitle}
        isOpen={this.state.modalIsOpen}
        toggle={this.toggleModal}
        actions={props.modalActions || defaultActions}
        modalClasses={props.modalClasses}
      >
        <FormComponent
          initialValues={this.state.focusedItem}
          onSubmit={this.handleFormSubmit}
          cancelSubmitting={this.cancelSubmitting}
          {...formProps}
        />
      </Modal>
    )

    return (
      <div className={`standard-tab ${kebabCase(pluralize(get(props, 'name', '')))}`}>
        {children ? (
          <Fragment>
            {standardModal}
            {this.renderChildren(children)}
          </Fragment>
        ) : (
          <Fragment>
            {standardModal}
            {children}
            {prependComponent}
            {deleteAlertComponent}
            <AlertDialog
              open={alertOpen}
              title={`Remove this ${get(props, 'name', '').toLowerCase()}`}
              text={
                deleteAlertText ||
                `Are you sure you want to remove this ${get(
                  props,
                  'name',
                  ''
                ).toLowerCase()} from the member's profile?`
              }
              actions={[
                {
                  buttonText: `No, don't remove`,
                  color: 'default',
                  onClick: this.cancelDelete
                },
                {
                  buttonText: `Yes, remove`,
                  autoFocus: true,
                  onClick: this.confirmDelete
                }
              ]}
            />

            {props.useNestedTable ? (
              <NestedTable
                data={tableData}
                parentTableHeaders={tableHeaders}
                childTableOnEdit={childOnEdit}
                childTableOnDelete={childOnDelete}
                childTableOnCopy={childOnCopy}
                pagination={pagination}
                onRowSort={this.onRowSort.bind(this)}
                onRowDrag={this.onRowDrag.bind(this)}
                onRowDragEnd={this.onRowDragEnd.bind(this)}
                {...props}
              />
            ) : (
              <StandardTable
                tableActionsCell={tableActionsCell}
                tableHeaders={tableHeaders}
                tableData={tableData}
                actionsColumnClasses={actionsColumnClasses}
                filters={filters}
                handleDelete={handleDeleteProcess}
                handleFocus={hideEdit ? null : this.handleFocus}
                pagination={pagination}
                onRowSort={this.onRowSort.bind(this)}
                onRowDrag={this.onRowDrag.bind(this)}
                onRowDragEnd={this.onRowDragEnd.bind(this)}
                {...props}
              />
            )}
            {appendComponent}
          </Fragment>
        )}
      </div>
    )
  }
}

StandardTab.propTypes = {
  name: PropTypes.string,
  contextButtons: PropTypes.arrayOf(PropTypes.object),
  form: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  formValues: PropTypes.object,
  initialValues: PropTypes.object,
  formHandler: PropTypes.func,
  tableHeaders: PropTypes.arrayOf(PropTypes.object),
  tableData: PropTypes.arrayOf(PropTypes.object),
  tableActionsCell: PropTypes.object,
  filters: PropTypes.arrayOf(PropTypes.object),
  pagination: PropTypes.object,
  prependComponent: PropTypes.node,
  appendComponent: PropTypes.element,
  expanded: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  modalClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  handleDelete: PropTypes.func,
  deleteAlert: PropTypes.bool,
  deleteAlertComponent: PropTypes.element,
  hideEdit: PropTypes.bool,
  disableEdit: PropTypes.bool,
  disableCopy: PropTypes.bool,
  disableDelete: PropTypes.bool,
  preventDestroy: PropTypes.bool,
  sortable: PropTypes.bool,
  handleRowSort: PropTypes.func,
  handleRowDrag: PropTypes.func,
  handleRowDragEnd: PropTypes.func,
  deleteAlertText: PropTypes.string,

  // the following props are for using nested table. not needed when using standard table.
  useNestedTable: PropTypes.bool,
  isSideBySide: PropTypes.bool,
  parentRowActions: PropTypes.object,
  childTableHeaders: PropTypes.arrayOf(PropTypes.object),
  childTableDataPath: PropTypes.string,
  childOnEdit: PropTypes.func,
  childOnDelete: PropTypes.func,
  childOnCopy: PropTypes.func,
  rowEdit: PropTypes.func,
  showRowEdit: PropTypes.func,
  showRowDelete: PropTypes.func
}

StandardTab.defaultProps = {
  useNestedTable: false
}

const mapStateToProps = ({ auth, form }) => ({ auth, reduxForm: form })

export default connect(mapStateToProps, null, null, { forwardRef: true })(StandardTab)
