/*
 * Auth actions for Auth0
 */

import * as C from './types'
import { types as P } from 'store/modules/patient'

export const loginRequest = () => ({
  type: C.AUTH_LOGIN_REQUEST
})

export const loginSuccess = profile => ({
  type: C.AUTH_LOGIN_SUCCESS,
  payload: { profile }
})

export const loginError = error => ({
  type: C.AUTH_LOGIN_ERROR,
  error
})

export const logoutSuccess = () => {
  return dispatch => {
    dispatch({
      type: C.AUTH_LOGOUT_SUCCESS
    })
    // lets clear out the patient too. easier to do this here
    // than to connect the patient actions in the header.
    dispatch({
      type: P.REMOVE
    })
  }
}

export const forcedLogoutSuccess = message => {
  return dispatch => {
    dispatch({
      type: C.AUTH_FORCED_LOGOUT_SUCCESS,
      message
    })

    // from logoutSuccess
    dispatch({
      type: P.REMOVE
    })
  }
}
