/*
 * Dashboard Caregivers Tab
 */
import React, { Component } from 'react'
import dayjs from 'dayjs'
import StandardTab from 'components/common/standard-tab'
import { get as _get, set as _set } from 'lodash'
import { AddressItem, PhoneItem } from 'components/common/data-item'
import { DateFormat }from 'constants/date'
import CaregiversForm from './form'

const OBJECT_NAME = 'caregiver'
const PAGINATION_OPTIONS = { pageSize: 5, maxPages: 5 }

/**
 * Renders the caregivers tab.
 */
class Caregivers extends Component {
  constructor (props) {
    super(props)

    this.formHandler = this.formHandler.bind(this)
  }

  /**
   * Formats the POA field to display "yes" if one is present or "no" if not
   *
   * @param {object} caregiver - The caregiver data
   * @returns {string} - "Yes" if a POA is present; "No" if no POA is present
   */
  formatPowerOfAttorney = caregiver => (_get(caregiver, 'powerOfAttorney') ? 'Yes' : '')

  /**
   * Formats the Type field to display "Primary" if a caregiver has a
   * preferredContact, otherwise displays as an an empty string
   * @param {object} caregiver - The caregiver data
   * @returns {string} - "Primary" if explicity set to true; otherwise ""
   */
  formatType = caregiver => (_get(caregiver, 'primaryCaregiver') === true ? 'Primary' : '')

  /**
   * Formats the phone number to use the `PhoneItem` field.
   *
   * @param {object} caregiver - The caregiver data
   * @returns {object} The PhoneItem component to display the phone number with formatting
   */
  formatPhoneNumber = caregiver => {
    // get bad number info - QA-4238
    const badNumber = _get(this.props, 'patient.data.badNumber')
    const caregiverIsPreferred = _get(caregiver, 'preferredContact')

    return (
      <PhoneItem
        value={_get(caregiver, this.getPrimaryCaregiverPhone(caregiver))}
        badNumber={badNumber && caregiverIsPreferred}
      />
    )
  }

  /**
   * Evaluates which address to show based on member preference, or if unset,
   * uses the first address.
   * @param {object} caregiver - The caregiver data
   * @returns {object} The preferred address passed to the AddressItem component
   * to display the address with proper formatting
   */
  getPrimaryCaregiverAddress = caregiver => {
    return _get(caregiver, 'address2.primary') ? (
      <AddressItem address={_get(caregiver, 'address2')} />
    ) : (
      <AddressItem address={_get(caregiver, 'address')} />
    )
  }

  /**
   * Evaluates which phone number to show based on member preference.
   *
   * @param {object} caregiver - The caregiver data
   * @returns {object} The preferred phone number
   */
  getPrimaryCaregiverPhone = caregiver => {
    const phone2Preferred = _get(caregiver, 'address2.phonePreferred')

    if (phone2Preferred || (phone2Preferred === undefined && _get(caregiver, 'address2.primary'))) {
      return 'address2.phone'
    }

    return 'phone'
  }

  /**
   * Formats the date created on save from the `lastUpdate` key's value.
   *
   * @param {object} caregiver - The caregiver data
   * @returns {object} The AddressItem component to display the address with formatting
   */
  formatDateOfLastUpdate = caregiver =>
    _get(caregiver, 'lastUpdate') !== undefined && _get(caregiver, 'lastUpdate') !== ''
      ? dayjs(caregiver.lastUpdate).format(DateFormat.DATE_DISPLAY)
      : ''

  /**
   * Formats the name of the caregiver to display `first [middle] last` where middle name is optional.
   * @param {object} caregiver - The caregiver data
   * @returns {string} The formatted name
   */
  formatName = caregiver => {
    if (!caregiver) {
      return undefined
    }

    const first = _get(caregiver, 'name.first')
    const middle = _get(caregiver, 'name.middle')
    const last = _get(caregiver, 'name.last')
    return `${first} ${middle ? `${middle} ` : ''}${last}`
  }

  /**
   * Handles the data submission.
   *
   * @param {object} data - The form data
   * @returns {Promise} The update to the Patient data
   */
  async formHandler (data, editing, initial) {
    // Adds updated on info for that column's display
    const updatedDateTime = new Date()
    _set(data, 'lastUpdate', updatedDateTime)

    // check for bad number flag on patient doc
    const badNumber = _get(this.props, 'patient.data.badNumber', false)

    // see if the caregiver was initially marked as the primary contact
    const caregiverWasPrimary = _get(initial, 'preferredContact', false)

    if (badNumber && caregiverWasPrimary) {
      // check to see if it is still a bad number
      const stillBadNumber = _get(data, 'badNumber', false)

      // if it is not still a bad number, remove flag from patient doc
      if (!stillBadNumber) {
        _set(this.props, 'patient.data.badNumber', false)
      }
    }

    // remove the bad number flag from the caregiver before save
    if (data.badNumber) delete data.badNumber

    // prepare for save
    _set(this.props.patient.data, OBJECT_NAME, data)
    await this.props.patientActions.updatePatient(this.props.patient.data)
  }

  render () {
    const defaultStructure = {
      subject: this.props.patient.data._id
    }

    // Retrieve the caregiver data from the patient
    const data = _get(this.props.patient.data, OBJECT_NAME)

    // QA-4250 - check for the "phonePreferred" flag on the Address1/Address2 phone numbers
    // if it is not there, add it with whatever the current value is for the address in question
    if (_get(data, 'phonePreferred') === undefined) {
      _set(data, 'phonePreferred', _get(data, 'address.primary'))
    }

    if (_get(data, 'address2.phonePreferred') === undefined) {
      _set(data, 'address2.phonePreferred', _get(data, 'address2.primary'))
    }

    // if there is a caregiver, wrap it in an array to pass as the table data; otherwise pass
    // `undefined` so the table doesn't render an empty row
    const tableData = data ? [data] : undefined

    // table headers - show # of caregivers retrieved (for now it will only ever be 0 or 1)
    const headers = [
      { name: `Caregivers (${tableData ? tableData.length : 0})`, maps: this.formatName },
      { name: 'Type', maps: this.formatType },
      { name: 'Power of Attorney', maps: this.formatPowerOfAttorney },
      { name: 'Relationship', maps: 'relationship' },
      { name: 'Phone', maps: this.formatPhoneNumber },
      { name: 'Address', maps: this.getPrimaryCaregiverAddress },
      { name: 'Updated', maps: this.formatDateOfLastUpdate }
    ]

    let contextButtons
    if (data) {
      contextButtons = []
    }

    return (
      <StandardTab
        name="Caregiver"
        object={OBJECT_NAME}
        form={CaregiversForm}
        initialValues={defaultStructure}
        formHandler={this.formHandler}
        tableHeaders={headers}
        tableData={tableData}
        pagination={PAGINATION_OPTIONS}
        contextButtons={contextButtons}
        {...this.props}
      />
    )
  }
}

export default Caregivers
