/*
 * Eula reducer
 */

import { getEulaStorage, setEulaStorage } from 'services/eula'

import * as C from './types'

const eulaReducer = (
  state = {
    isFetchingAcceptance: false,
    isFetchingContent: false,
    isPostingAgreement: false,
    error: null,
    isOpen: false,
    acknowledgedAndAgreed: getEulaStorage()
  },
  action
) => {
  switch (action.type) {
  case C.CHECK_IF_EULA_IS_NEEDED:
    return {
      ...state
    }
  case C.TOGGLE_MODAL:
    return {
      ...state,
      isOpen: !state.isOpen
    }
  case C.GET_EULA_ERROR:
    return {
      ...state,
      isFetchingContent: false,
      isFetchingAcceptance: false,
      error: action.payload.error
    }
  case C.GET_EULA_CONTENT_REQUEST:
    return {
      ...state,
      isFetchingContent: true,
      error: null
    }
  case C.GET_EULA_CONTENT_SUCCESS:
    return {
      ...state,
      isFetchingContent: false,
      error: null,
      details: action.payload.details
    }
  case C.GET_EULA_ACCEPTANCE_REQUEST:
    return {
      ...state,
      isFetchingAcceptance: true,
      error: null
    }
  case C.GET_EULA_ACCEPTANCE_SUCCESS:
    return {
      ...state,
      isFetchingAcceptance: false,
      error: null,
      acceptances: action.payload.acceptances
    }
  case C.POST_EULA_ACCEPTANCE_REQUEST:
    return {
      ...state,
      isPostingAcceptance: true,
      error: null
    }
  case C.POST_EULA_ACCEPTANCE_SUCCESS:
    return {
      ...state,
      isPostingAcceptance: false,
      error: null,
      acceptances: action.payload.acceptances
    }
  case C.POST_EULA_ERROR:
    return {
      ...state,
      isPostingAcceptance: false,
      postError: 'An error occurred posting the End User License Agreement (EULA)'
    }
  case C.READ_AND_UNDERSTOOD:
    return {
      ...state,
      readAndUnderstood: action.payload.acknowledgement
    }
  case C.ACKNOWLEDGED_AND_AGREED:
    setEulaStorage(action.payload.agreement)
    return {
      ...state,
      readAndUnderstood: action.payload.agreement,
      acknowledgedAndAgreed: action.payload.agreement
    }
  default:
    return state
  }
}

export default eulaReducer
