import React from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import { tabManager } from 'components/common/tab-manager'

import { actions as patientActions } from 'store/modules/patient'
import { actions as orgActions } from 'store/modules/organizations'
import { actions as projectActions } from 'store/modules/project'
import { actions as schemaActions } from 'store/modules/schema'

import Display from 'components/patient-import/display'

const tabs = [
  {
    id: 'main',
    enabled: true,
    tabs: [
      {
        active: true,
        id: 'patient-import',
        name: 'Patient Import',
        component: Display,
        location: '/dashboard/patient-import'
      }
    ],
    buttons: []
  }
]

class PatientImport extends React.Component {

  componentDidMount () {
    const { auth, organizations, orgActions, project, projectActions} = this.props
    if (!(organizations.userOrgs && organizations.userOrgs.length)) {
      orgActions.getUserOrgs(auth.profile.organizations)
    }
    // Had to put this in here because if you go to the import page without loading the dashboard the projects are not set in state.
    if (!get(project, 'allProjects.length')) {
      projectActions.getProjects()
    }
  }

  render () {
    const { children } = this.props
    return <div className="patient-import">{children}</div>
  }
}

const mapStateToProps = ({ auth, eula, patient, organizations, project, form, schema }) => ({
  auth,
  eula,
  patient,
  organizations,
  project,
  reduxForm: form,
  schema
})

const mapDispatchToProps = dispatch => ({
  patientActions: {
    getRiskStratPatient: externalId => dispatch(patientActions.getRiskStratPatient(externalId)),
    eligibleSearch: params => dispatch(patientActions.eligibleSearch(params)),
    importPatient: (externalId, exportPharmacy) => dispatch(patientActions.importPatient(externalId, exportPharmacy)),
    removePatient: () => dispatch(patientActions.removePatient()),
    saveNew: (patient, orgWithDetails, user) => dispatch(patientActions.saveNew(patient, orgWithDetails, user)),
    doesPatientAlreadyExist: externalId =>
      dispatch(patientActions.doesPatientAlreadyExist(externalId))
  },
  orgActions: {
    getUserOrgs: organizations => dispatch(orgActions.getUserOrgs(organizations))
  },
  projectActions: {
    getProjects: () => dispatch(projectActions.getProjects())
  },
  schemaActions: {
    getReferralOptions: () => dispatch(schemaActions.getReferralOptions())
  },
  dispatch
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(tabManager(tabs, PatientImport))
