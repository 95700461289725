import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { connectRouter } from 'connected-react-router'

import auth from 'store/modules/auth'
import eula from 'store/modules/eula'
import menu from 'store/modules/main-menu'
import mds from 'store/modules/mds'
import notify from 'store/modules/notify'
import organizations from 'store/modules/organizations'
import schema from 'store/modules/schema'
import patient from 'store/modules/patient'
import patientData from 'store/modules/patient-data'
import patientSearch from 'store/modules/patient-search'
import patientSearchAggregates from 'store/modules/patient-search-aggregates'
import { priorityQueues, priorityQueue, priorityQueueSettings } from 'store/modules/priority-queues'
import project from 'store/modules/project'
import { reservations } from 'store/modules/reservations'
import review from 'store/modules/review'
import urlHash from 'store/modules/url-hash'
import medRecData from 'store/modules/medication-reconciliation'
import workflow from 'store/modules/workflow'
import medrec from 'store/modules/medrecv2'

export default history =>
  combineReducers({
    form: formReducer,
    router: connectRouter(history),
    auth,
    eula,
    menu,
    mds,
    notify,
    organizations,
    patient,
    patientData,
    patientSearch,
    medRecData,
    patientSearchAggregates,
    priorityQueues,
    priorityQueue,
    priorityQueueSettings,
    project,
    reservations,
    review,
    urlHash,
    schema,
    workflow,
    medrec
    // Add other reducers here
  })
