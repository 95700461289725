/*
 * Actions for the URL Hash management
 */

import { replace } from 'connected-react-router'
import { get } from 'lodash'

import { unhash, storeHash } from 'services/url-hash'

import * as C from './types'

export const getHash = () => (dispatch, getState) => {
  let { router } = getState()
  let unhashed = unhash(router.location.pathname)
  dispatch({
    type: C.GET_URL_HASH,
    payload: unhashed
  })
}

export const setHash = payload => (dispatch, getState) => {
  // set the hash param here
  let { router } = getState()
  let boxes = null
  let hash = null
  let oldHash = unhash(router.location.pathname)
  let currentPath = get(router, 'location.pathname', '').split('/')
  if (payload && payload.boxes && currentPath.length > 3) {
    boxes = {}
    payload.boxes.forEach(b => {
      if (!b.enabled) return
      if (!boxes[b.id]) boxes[b.id] = {}
      b.tabs.forEach(t => {
        if (t.active) {
          boxes[b.id].tab = { id: t.id }
          if (t.children && Array.isArray(t.children)) {
            t.children.forEach(c => {
              if (c.active) boxes[b.id].tab.child = { id: c.id }
            })
          }
        }
      })
    })

    hash = encodeURIComponent(btoa(JSON.stringify(boxes)))
    if (oldHash) {
      // we have an existing hashed path.
      currentPath.pop()
    }

    currentPath.push(hash)
    let newPath = currentPath.join('/')

    if (newPath !== router.location.pathname) {
      dispatch(replace(currentPath.join('/')))
    }
  }

  storeHash(hash)
  dispatch({
    type: C.SET_URL_HASH,
    payload: boxes
  })
}
