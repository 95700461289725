import gateway from './gateway'
import dayjs from 'dayjs'
import { cloneDeep, filter, get, set } from 'lodash'

import { WORKFLOW_NAMES } from 'constants/workflow'

export const filterDraftReviews = (draftReviews, patient) => {
  draftReviews = filter(draftReviews, { version: 2 })
  return draftReviews.map(review => {
    review.reviewType = getPatientReviewType(patient)
    return review
  })
}

export const getDraftReviews = async patient => {
  const draftReviews = await gateway.get('/api/medwise/reviews/drafts', { patientId: patient._id })
  return filterDraftReviews(draftReviews, patient)
}

export const addUnpublishedRec = publishedReviews => {
  if (publishedReviews.length) {
    for (var i = 0; i < publishedReviews.length; i++) {
      const unpublished = get(publishedReviews[i], 'unpublishedRecommendations', [])
      if (unpublished.length > 0) {
        const recommendations = get(publishedReviews[i], 'recommendations', [])
        set(publishedReviews[i], 'recommendations', recommendations.concat(unpublished))
      }
    }
  }

  return publishedReviews
}

export const getPublishedReviews = async patient => {
  const published = await gateway.get('/api/medwise/reviews/published', { patientId: patient._id })
  return addUnpublishedRec(published)
}

export const saveDraftReview = async review => {
  set(review, 'version', 2)
  return gateway.post('/api/medwise/review/draft', review)
}

export const savePublishedReview = async review => {
  return gateway.post('/api/medwise/review/publish', review)
}

export const deleteDraftReview = async review => {
  return gateway.put('/api/medwise/review/deleteDraft', review)
}

// MCS-1527 altered getNewDraftReview and autoRecomemendations
// to avoid a deprecated endpoint
export const getNewDraftReview = (patient, patientData) => dummyDraft(patient, patientData)

export const autoRecommendations = payload => []

export const saveReview = async review => {
  return gateway.put('/api/medwise/review', review)
}

export const generateAutoReview = async patientId => {
  return gateway.post('/api/medwise/review/auto', { patientId })
}

/**
 * Kind of a weird call that gets a ton of data about a patient. We can do this better, considering most of this is
 * at our disposal already.
 * @param  {String}  patientId The patient ID
 * @return {Promise}           Returns a call with [patientDoc, observations, genetics, allergies,
 *                             current medications, (bad)snomed stuff, last review, last outreach]
 */
export const getPatientData = async patientId => {
  return gateway.get('/api/review/data', { patientId: patientId })
}

export const canPerformReview = patient => {
  if (get(patient, 'status') !== 'active') return false

  const state = get(patient, 'currentWorkflowState.name', '')
  if (!state || !state.length) return false

  if (
    [
      WORKFLOW_NAMES.FAILURE_ENGAGE_INITIATING_CALL,
      WORKFLOW_NAMES.FAILURE_ENGAGE_MEDICATION_REVIEW,
      'Disenrolled',
      WORKFLOW_NAMES.NOT_ELIGIBLE,
      WORKFLOW_NAMES.HOSPICE,
      WORKFLOW_NAMES.WAITING_MAILER
    ].some(v => state.includes(v))
  ) {
    return false
  }
  return true
}

export const getPatientReviewType = patient => {
  // this is crap, we need to move to org config
  if (get(patient, 'managingOrganization.id') !== 'org:EMTM') {
    return 'Medication Safety Review (MSR)'
  }

  switch (get(patient, 'currentWorkflowState.name')) {
  // not sure if we need this since MSA's get generated automatically now
  case WORKFLOW_NAMES.FAILURE_ENGAGE_INITIATING_CALL:
  case WORKFLOW_NAMES.FAILURE_ENGAGE_MEDICATION_REVIEW:
    return 'Medication Safety Advisory (MSA)'
  case WORKFLOW_NAMES.FAILURE_ENGAGE_MEDICATION_SAFETY_REVIEW:
    return 'Medication Safety Review Lite (MSR Lite)'
  default:
    return 'Medication Safety Review (MSR)'
  }
}
// change to reviewIncludesResponses
export const reviewIncludesResponses = review => {
  return get(review, 'recommendations', []).every(rec => {
    if (rec.includeInPrescriber && rec.includeInMap) {
      return (
        rec.includeInPrescriber &&
        get(rec, 'prescriberResponse.status.snowMedCode') &&
        rec.includeInMap &&
        get(rec, 'patientResponse.status.snowMedCode')
      )
    } else if (rec.includeInPrescriber) {
      return rec.includeInPrescriber && get(rec, 'prescriberResponse.status.snowMedCode')
    } else if (rec.includeInMap) {
      return rec.includeInMap && get(rec, 'patientResponse.status.snowMedCode')
    }
    return true
  })
}

/**
 * Does some basic things to a 1.0 review like move recommendation *SubCategory into the parent element.
 * @param  {Object} review Review to convert
 * @return {Object}        Converted review
 */
export const convertVersion1To2 = review => {
  const newReview = cloneDeep(review)
  newReview.version = 2
  newReview.recommendations = newReview.recommendations.map(rec => {
    if (rec.medicationRecommendationSubCategory) {
      rec.medicationRecommendation = rec.medicationRecommendationSubCategory
      delete rec.medicationRecommendationSubCategory
    }
    if (rec.medicationRelatedProblemSubCategory) {
      rec.medicationRelatedProblem = rec.medicationRelatedProblemSubCategory
      delete rec.medicationRelatedProblemSubCategory
    }

    return rec
  })

  return newReview
}

const dummyDraft = (patient, patientData) => ({
  recommendations: [],
  riskScoresNormalized: {},
  reviewDate: dayjs().format(),
  reviewType: getPatientReviewType(patient),
  patient: patient,
  patientOrg: {}, // this should be pulled from organizations. probably easier to move all this into a redux module.
  currentMedications: patientData.medications,
  observations: patientData.observations,
  genetics: get(patientData, 'pgxData.entry', []),
  allergies: patientData.allergies,
  reportedSideEffects: [],
  subject: patient._id,
  draft: true,
  workflowIteration: patient.workflowIteration || 1,
  currentYear: get(patient, 'currentWorkflowState.year', 1),
  riskScoreAffectedRecommendations: [],
  createdOn: dayjs().format(),
  updatedOn: dayjs().format()
})
