import { Field } from 'redux-form'
import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import ReduxFormText from 'components/common/redux-form/text'
import { isEmpty as _isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { FaExclamationTriangle } from 'react-icons/fa'

/**
 * Formats the phone number to (###) ###-#### format.
 *
 * @param {object} props - Properties provided to the component.
 * @param {object} props.input - The input field being formatted.
 * @param {function} props.input.onChange - the onChange method of the input field.
 * @param {string} props.input.value - the value of the input field
 */
class ReduxFormPhoneNumber extends Component {
  /**
   * The constructor of the component.
   * @param {object} props - Properties provided to the component.
   */
  constructor (props) {
    super(props)

    this.handleValueChange = this.handleValueChange.bind(this)
  }

  /**
   * Handles the value change.
   *
   * @param {object} valueObject - the value object provided to the formatter.
   * @param {function} onChange - the onChange function of the input field.
   */
  handleValueChange (valueObject, onChange) {
    onChange(valueObject.value)
  }

  BadNumberWarning = (
    <div className="warn flex items-center pa1">
      <FaExclamationTriangle className="mr1" /> Bad number reported. Please update.
    </div>
  )

  render () {
    const {
      badNumber,
      input: { onChange, value, name },
      ...other
    } = this.props

    return (
      <React.Fragment>
        <NumberFormat
          value={value}
          onValueChange={v => this.handleValueChange(v, onChange)}
          format="(###) ###-####"
          name={name}
          {...other}
        />

        {badNumber && this.BadNumberWarning}
      </React.Fragment>
    )
  }
}

export const validatePhone = number => {
  // accept phone numbers in ########## or ###-###-#### format
  const acceptablePatterns = /^(\d{10}|(\d{3}-){2}\d{4})$/
  return _isEmpty(number) || acceptablePatterns.test(number.toString()) ? undefined : 'Number must be 10 digits long'
}

const ReduxPhoneField = props => {
  const {
    ...other
  } = props

  return <Field
    type="text"
    component={ReduxFormPhoneNumber}
    customInput={ReduxFormText}
    validate={[validatePhone]}
    {...other}
  />
}

ReduxPhoneField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

export default ReduxPhoneField
