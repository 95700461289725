import gateway from './gateway'
import { filter, get, uniqBy } from 'lodash'
import { loggedIn } from './auth'
import dayjs from 'dayjs'

const PROJECT_BASE_URL = '/api/medwise/projects'
const CACHE_EXPIRES = 60000 * 30 // 30 minutes

async function getProjects () {
  // make a check if the projects are in local storage.  If not, call setAllProjects.
  const list = JSON.parse(window.sessionStorage.getItem('all_projects'))
  if (!list || list.timestamp < Date.now() - CACHE_EXPIRES) {
    clearProjects()
    let projects = storeProjects()
    return projects
  }
  return list.allProjects
}

async function storeProjects () {
  if (loggedIn()) {
    try {
      const projects = await gateway.get(PROJECT_BASE_URL)
      const output = {
        timestamp: Date.now(),
        allProjects: projects
      }
      window.sessionStorage.setItem('all_projects', JSON.stringify(output))
      return projects
    } catch (error) {
      throw Error('Unrecoverable failure when fetching projects! Please refresh page.', error)
    }
  }
}

export const clearProjects = () => {
  window.sessionStorage.removeItem('all_projects')
}

async function getPrimaryProject (orgId) {
  const primary = await gateway.get(`/api/medwise/project/primary?orgId=${orgId}`)

  return primary
}

/**
 * Retrieves list of projects that are associated with a patient
 *
 * @param {object} patient The patient whose projects need to be retrieved
 * @returns {Promise}
 */
async function getProjectsByPatientAssociation (patient) {
  let projects = await getProjects()
  const patientProjectIds = Object.keys(get(patient, ['data', 'projects'], {}))
  const orgId = get(patient, 'data.managingOrganization.secondary')
    ? get(patient, 'data.managingOrganization.secondary')
    : get(patient, 'data.managingOrganization.id')

  projects = filter(projects, p => {
    if (!p.Organization) return true
    if (p.Organization.externalId === orgId) return true
    if (patientProjectIds.indexOf(p.couchId) > -1) return false
  })

  const primaryProject = await getPrimaryProject(orgId)
  if (primaryProject) {
    projects.push(primaryProject)
    // removing potential duplicates
    projects = uniqBy(projects, proj => proj.couchId)
  }

  return projects
}

/**
 * Updates the project information for a provided Patient
 *
 * @param {object} patient The patient whose information should be updated
 * @param {string} couchId The CouchID of the project to be modified or added
 * @param {Boolean} isEnrolling Whether the patient is enrolling (true) or disenrolling (false)
 * @param {string} reason The reason for the change to the patient's project data
 * @param {object} performedBy The email, first and last name of the user making the change
 */
function updatePatientProjectInfo (patient, couchId, isEnrolling, reason, performedBy) {
  // get today's date
  const date = dayjs().format('YYYY-MM-DD')

  // create change record to append this change to the record
  const change = {
    date,
    performedBy,
    enrolled: isEnrolling
  }

  // if there was a reason, add it to the change record
  if (reason) {
    change.reason = reason
  }

  if (!isEnrolling) {
    // Hard-coding this as a) it will be using the SnomedService shortly and b) it won't be saved here anyway,
    // so wiring in another service and using a path or constant is a waste of time.
    change.snowmedCode = '412725004'
  }

  // get the current patient record for this project or create a new one
  const defaultRecord = {
    enrolled: isEnrolling,
    changes: [],
    enrollDate: date
  }
  const patientProjectRecord = get(patient, ['projects', couchId], defaultRecord)

  // push the current change to the record's changes list
  patientProjectRecord.changes.push(change)

  // set the enrolled status appropriately
  patientProjectRecord.enrolled = isEnrolling

  // if there are no existing projects field, add one
  if (patient.projects === undefined) {
    patient.projects = {}
  }

  // assign the project record back to the patient
  patient.projects[couchId] = patientProjectRecord
}

/**
 * Check if patient is in a project
 * @param  Object patient   The patient data
 * @param  String projectId The ID of the project
 * @return Truthy           True, false, or undefined
 */
export const patientIsInProject = (patient, projectId) => {
  return get(patient, `projects['${projectId}'].enrolled`)
}

/**
 * Gets info for a single project based on its ID
 * @param {string} projectId The ID of the project
 * @return {Promise<*>}
 */
const getProject = async projectId => {
  return await gateway.get('/api/medwise/project', { projectId })
}

export const getProjectIdAssumingATonOfThings = patientProjects => {
  const keys = Object.keys(patientProjects)
  return keys.length ? keys[0] : undefined
}

export default {
  getProject,
  getProjects,
  getProjectsForPatient: patient => getProjectsByPatientAssociation(patient),
  updatePatientProjectInfo: (patient, project, enrolling, reason, performedBy) =>
    updatePatientProjectInfo(patient, project, enrolling, reason, performedBy),
  patientIsInProject,
  getProjectIdAssumingATonOfThings
}
