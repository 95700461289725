/*
 * Organization reducer
 */

import { getOrganizations } from 'services/organizations'

import * as C from './types'

const reducer = (
  state = {
    loading: false,
    error: null,
    allOrgs: getOrganizations().allOrgs,
    userOrgs: getOrganizations().userOrgs
  },
  action
) => {
  switch (action.type) {
  case C.ORG_REQUEST:
    return {
      ...state,
      loading: true,
      error: null
    }
  case C.ORG_ERROR:
    return {
      ...state,
      loading: false,
      error: action.error
    }
  case C.ORG_SET_USER_ORGS:
    return {
      ...state,
      loading: false,
      userOrgs: action.payload
    }
  case C.ORG_SET_ALL_ORGS:
    return {
      ...state,
      loading: false,
      allOrgs: action.payload
    }
  default:
    return state
  }
}

export default reducer
