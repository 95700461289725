
import React from 'react'
import PropTypes from 'prop-types'

import './styles/stepper.scss'

const classNames = require('classnames')

const Stepper = ({children}) => {

  // search for last active step
  const lastActiveStepIndex = children.reduce((currentActiveIndex, currentStep, currentStepIndex) => {
    if(currentStep.props.isActive) return currentStepIndex;
    return currentActiveIndex;
  }, 0);

  // mark all steps complete before active step
  let steps = React.Children.map(children, (child, index) => React.cloneElement(child, {
    isComplete: index < lastActiveStepIndex,
    isActive: index === lastActiveStepIndex
  }, null))

  return (
    <div className="stepper-wrapper">
      <ul className="stepper">
        { steps }
      </ul>
    </div>
  )
}

const Step = ({ title, description, isActive, isComplete }) => {
  const stepClass = classNames('step', 'w-25', {
    'active': isActive,
    'complete': isComplete
  });

  return (
    <li className={stepClass}>
      <div className="title">{title}</div>
      <div className="description">{description}</div>
    </li>
  )
}

Step.propTypes = {
  isActive: PropTypes.bool,
  isComplete: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  number: PropTypes.number
}

Step.defaultProps = {
  isActive: false,
  isComplete: false
}

export {Step, Stepper}