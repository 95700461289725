import { get as _get, set as _set } from 'lodash'

const validate = data => {
  const errors = {}
  const couchId = _get(data, 'project.couchId')
  const patientProject = _get(data, ['patient', 'data', 'projects', couchId])
  const reason = _get(data, 'reason')

  if (patientProject && !reason) {
    _set(errors, 'reason', 'Reason is required')
  }

  return errors
}

export default validate
