/**
 * Service to save and clear patient form options
 */

export const CACHE_EXPIRES = 60000 * 30 // 30 minutes
export const REFERRAL_DEFAULTS = { allReferrals: [] }
export const NMRP_DEFAULTS = { nmrp: [] }

export const getReferralOptions = () => {
  const defaults = REFERRAL_DEFAULTS
  const list = JSON.parse(window.sessionStorage.getItem('referral_list'))
  if (!list || list.timestamp < Date.now() - CACHE_EXPIRES) {
    clearReferrals()
    return defaults
  }
  return list
}

export const setReferralOptions = allReferrals => {
  const output = {
    timestamp: Date.now(),
    allReferrals
  }
  window.sessionStorage.setItem('referral_list', JSON.stringify(output))
}

export const clearReferrals = () => {
  window.sessionStorage.removeItem('referral_list')
}

/**
 * Get Non Medication Related Problems
 * @return {Array} List of the NMRP
 */
export const getNMRP = () => {
  const defaults = NMRP_DEFAULTS
  const list = JSON.parse(window.sessionStorage.getItem('non_medication_related_problems'))
  if (!list || list.timestamp < Date.now() - CACHE_EXPIRES) {
    clearNMRP()
    return defaults
  }

  return list
}

export const setNMRP = nmrp => {
  window.sessionStorage.setItem(
    'non_medication_related_problems',
    JSON.stringify({ timestamp: Date.now(), nmrp })
  )
}

export const clearNMRP = () => {
  window.sessionStorage.removeItem('non_medication_related_problems')
}
