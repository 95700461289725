/*
* Table cell component with colored background and white text
*
*/
import React, { Component } from 'react'

const ERROR = '#cd5542'
const WARN = '#e79f3c'
const SUCCESS = '#49a361'

const colorForType = type => {
  switch (type) {
  case 'error':
    return ERROR
  case 'warn':
    return WARN
  case 'success':
  default:
    return SUCCESS
  }
}

class ColoredCell extends Component {
  render () {
    const { children, type, text } = this.props
    return (
      <td className="pv2 ph2 br2 flex justify-center">
        <div className="white tc br2 pa2 w-100" style={{ backgroundColor: colorForType(type) }}>
          {text}
          {children}
        </div>
      </td>
    )
  }
}

export default ColoredCell
