export const MULTUM_GENERIC_DRUG = 'http://multum.com/GN'
export const LEXI_CORE_SYNONYM = 'http://lexi.com/CORE_SYNONYM'
export const RXNORM_RXCUI = 'http://www.nlm.nih.gov/research/umls/rxnorm'
export const LEXI_CORE_DOSEFORM = 'http://lexi.com/CORE_DOSEFORM'
export const LEXI_CORE_PRODUCTSTRENGTH = 'http://lexi.com/CORE_PRODUCTSTRENGTH'
export const EXTERNAL_PATIENT_ID = 'http://medwise.com/EXTERNAL_PATIENT_ID'
export const NDC = 'http://www.accessdata.fda.gov/scripts/cder/ndc/default.cfm'
export const FHIR_NDC = 'http://hl7.org/fhir/sid/ndc'
export const GSDD_DETAIL_PRODUCT =
  'https://beta-api.gsdd.net/v1/Help/ResourceModel?modelName=DetailProduct'
export const FHIR_ICD10 = 'http://hl7.org/fhir/sid/icd-10'
export const SNOWMED_BROWSING = 'http://browser.ihtsdotools.org/'
export const FDB_ROUTE = 'http://docs.fdbhealth.com/display/CCDOCUS/Routes+by+Identifier'
export const FDB_VERSION = 'EireneRx'
export const SNOMED = 'http://snomed.info/sct'
export const FDB_DISPENSABLE = 'http://docs.fdbhealth.com/display/CCDOCUS/DispensableDrugs'
