/*
 * Search component
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import { actions as orgActions } from 'store/modules/organizations'
import { actions as projectActions } from 'store/modules/project'

import { tabManager } from 'components/common/tab-manager'

// modules
import tabDefaults from './tabs'

class Dashboard extends Component {
  componentDidMount () {
    const { auth, eula, organizations, orgActions, project, projectActions } = this.props
    if (eula && eula.acknowledgedAndAgreed) {
      if (!(organizations.userOrgs && organizations.userOrgs.length)) {
        orgActions.getUserOrgs(auth.profile.organizations)
      }

      if (!get(project, 'allProjects.length')) {
        projectActions.getProjects()
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { auth, organizations, orgActions, project, projectActions } = this.props
    let prevEulaAccept = get(prevProps, 'eula.acknowledgedAndAgreed')
    let newEulaAccept = get(this.props, 'eula.acknowledgedAndAgreed')
    if (prevEulaAccept !== newEulaAccept && newEulaAccept === true) {
      if (!(organizations.userOrgs && organizations.userOrgs.length)) {
        orgActions.getUserOrgs(auth.profile.organizations)
      }
      if (!get(project, 'allProjects.length')) {
        projectActions.getProjects()
      }
    }
  }

  render () {
    const { children } = this.props
    return <div className="dashboard search">{children}</div>
  }
}

const mapStateToProps = ({ auth, eula, organizations, project }) => ({
  auth,
  eula,
  organizations,
  project
})

const mapDispatchToProps = dispatch => ({
  orgActions: {
    getUserOrgs: organizations => dispatch(orgActions.getUserOrgs(organizations))
  },
  projectActions: {
    getProjects: () => dispatch(projectActions.getProjects())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(tabManager(tabDefaults, Dashboard))
