/*
 * Actions for reservations
 */
import gateway from 'services/gateway'
import logger from 'services/logger'
import { get, map } from 'lodash'
import { CONFLICT } from 'constants/reservations'

import { actions as notifyActions } from 'store/modules/notify'

import * as C from './types'

// fetch queue list actions
const fetchReservationsRequest = () => ({ type: C.FETCH_RESERVATIONS_REQUEST })
const fetchReservationsSuccess = result => ({
  payload: result,
  type: C.FETCH_RESERVATIONS_SUCCESS
})
const fetchReservationsFailure = () => ({ type: C.FETCH_RESERVATIONS_FAILURE })

export const getReservations = ids => {
  return async (dispatch, getState) => {
    if (!ids.length) return
    dispatch(fetchReservationsRequest())
    try {
      let result = await gateway.get('/api/medwise/patient/reservations', { ids })
      dispatch(fetchReservationsSuccess(result))
      return result
    } catch (err) {
      logger('An error occurred fetching reservations', err, true)
      dispatch(fetchReservationsFailure())
      dispatch(
        notifyActions.addNotification({
          message: 'An error occurred fetching reservations'
        })
      )
      return false
    }
  }
}

// create reservations actions
const createReservationRequest = () => ({ type: C.UPDATE_RESERVATIONS_REQUEST })
const createReservationSuccess = reservations => ({
  payload: reservations,
  type: C.UPDATE_RESERVATIONS_SUCCESS
})
const createReservationFailure = () => ({ type: C.UPDATE_RESERVATIONS_FAILURE })

// reservationParams { email, patientId }
export const createReservation = reservationParams => {
  return async (dispatch, getState) => {
    dispatch(createReservationRequest())
    try {
      await gateway.post(`/api/medwise/patient/reservations`, reservationParams)
      dispatch(createReservationSuccess(reservationParams))
      return true
    } catch (err) {
      if (err.message === CONFLICT) {
        return CONFLICT
      }
      logger('An error occurred reserving patient', err, true)
      dispatch(createReservationFailure())
      await dispatch(
        notifyActions.addNotification({
          message: 'An error occurred reserving patient'
        })
      )
      return false
    }
  }
}

// delete reservations actions
const deleteReservationRequest = () => ({ type: C.DELETE_RESERVATIONS_REQUEST })
const deleteReservationSuccess = reservation => ({
  payload: reservation,
  type: C.DELETE_RESERVATIONS_SUCCESS
})
const deleteReservationFailure = () => ({ type: C.DELETE_RESERVATIONS_FAILURE })

// reservationParams { _id, _rev, patientId }
export const deleteReservation = reservationParams => {
  return async (dispatch, getState) => {
    dispatch(deleteReservationRequest())
    try {
      await gateway.delete(`/api/medwise/patient/reservations`, reservationParams)
      dispatch(deleteReservationSuccess(reservationParams))
      return true
    } catch (err) {
      if (err.message === 'not_found') {
        logger('The reservation has been deleted')
        return false
      }
      logger('An error occurred releasing reservation', err, true)
      dispatch(deleteReservationFailure())
      await dispatch(
        notifyActions.addNotification({
          message: 'An error occurred releasing reservation'
        })
      )
      return false
    }
  }
}

export const getReservationsForSearchResults = searchResults => async dispatch => {
  const hits = get(searchResults, 'hits', [])
  const patientIds = map(hits, hit => get(hit, '_source.id'))
  return dispatch(getReservations(patientIds))
}
