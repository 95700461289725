/*
 * Auth Service
 * Utilizes auth0-lock to generate the login screen
 * and perform authentication watching
 */
import Auth0Lock from 'auth0-lock'
import jwtDecode from 'jwt-decode'
import { pick, get } from 'lodash'

import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

import { clearOrganizations } from './organizations'
import { clearEulaStorage } from './eula'
import { clearHash } from './url-hash'

const PROFILE_ENCRYPT_KEY = process.env.REACT_APP_PROFILE_ENCRYPT_KEY || 'chicken wing, chicken wing, hot dog, macaroni'

const AUTH0_STATE = 'medwise-advisor'
const AUTH0_SCOPE = 'openid name email'

// Configure Auth0 lock
export const lock = new Auth0Lock(
  process.env.REACT_APP_AUTH0_CLIENT_ID,
  process.env.REACT_APP_AUTH0_DOMAIN,
  {
    auth: {
      redirectUrl:
        process.env.REACT_APP_AUTH0_REDIRECT_URL ||
        window.location.protocol + '//' + window.location.host,
      responseType: 'token',
      // we need to update this when we switch over the backend
      // audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/userinfo`,
      params: {
        state: AUTH0_STATE,
        scope: AUTH0_SCOPE
      },
      sso: true
    },
    autoclose: false,
    rememberLastLogin: false,
    allowSignUp: false,
    container: 'mwa-login-container',
    theme: {
      logo: process.env.REACT_APP_AUTH0_LOGO || 'images/medwise_logo.svg',
      primaryColor: '#8EBA2E'
    },
    languageDictionary: {
      title: ''
    },
    leeway: 30
  }
)


export const show = opts => {
  lock.show(opts)
}

export const hide = () => {
  lock.hide()
}

export const loggedIn = () => {
  // Checks if there is a saved token and it's still valid
  const token = getToken()
  return !!token && !isTokenExpired(token)
}

export const reAuthenticate = () => {
  return new Promise((resolve, reject) => {
    lock.checkSession(
      {
        state: AUTH0_STATE,
        scope: AUTH0_SCOPE
      },
      (err, authResult) => {
        if (err) {
          return reject(err)
        }
        setToken(authResult.idToken)
        setAccessToken(authResult.accessToken)
        return resolve()
      }
    )
  })
}

export const logout = preserveRedirect => {
  // Clear user token and profile data from window.localStorage
  clearBrowserSessionInformation()

  if (!preserveRedirect) {
    window.sessionStorage.removeItem('auth_redirect')
  }
  lock.logout({ returnTo: `${window.location.protocol}//${window.location.host}`, federated: true })
}

const clearBrowserSessionInformation = () => {
  window.localStorage.removeItem('id_token')
  window.localStorage.removeItem('access_token')
  window.localStorage.removeItem('profile')
  clearOrganizations()
  clearEulaStorage()
  clearHash()
}

export const getRedirect = () => {
  return window.sessionStorage.getItem('auth_redirect')
}

export const setRedirect = route => {
  window.sessionStorage.setItem('auth_redirect', route)
}

export const removeRedirect = route => {
  window.sessionStorage.removeItem('auth_redirect')
}

export const getProfile = () => {
  // Retrieves the profile data from window.localStorage
  let profile;

  try {
    const encryptedProfile = window.localStorage.getItem('profile')
    const bytes = AES.decrypt(encryptedProfile, PROFILE_ENCRYPT_KEY)
    const profileString = bytes.toString(Utf8);

    profile = JSON.parse(profileString)

  } catch (err) {
    clearBrowserSessionInformation()
    return {}
  }

  return profile
}

/**
 * Helper function to format user data for saving on documents
 * @param  {Array}  [opts=['auth0Id', 'firstName',  'lastName', 'email', 'title']] [description]
 * @return {Object} The resulting object for user profile
 */
export const getProfileForSave = (
  opts = ['_id', 'auth0Id', 'firstName', 'lastName', 'email', 'title']
) => {
  return pick(getProfile(), opts)
}

export const setProfile = profile => {
  // Saves profile data to window.localStorage
  const encryptedProfile = AES.encrypt(JSON.stringify(profile), PROFILE_ENCRYPT_KEY).toString()

  window.localStorage.setItem('profile', encryptedProfile)
  // Triggers profile_updated event to update the UI
}

export const setToken = idToken => {
  // Saves user token to window.localStorage
  if (!idToken) {
    return window.localStorage.removeItem('id_token')
  }
  window.localStorage.setItem('id_token', idToken)
}

export const getToken = () => {
  // Retrieves the user token from window.localStorage
  return window.localStorage.getItem('id_token')
}

export const setAccessToken = idToken => {
  // Saves user token to window.localStorage
  if (!idToken) {
    return window.localStorage.removeItem('access_token')
  }
  window.localStorage.setItem('access_token', idToken)
}

export const getAccessToken = () => {
  // Retrieves the user token from window.localStorage
  return window.localStorage.getItem('access_token')
}

export const tokenExpiration = decoded => {
  if (!decoded.exp) {
    return null
  }
  return Number(decoded.exp) * 1000
}

export const isTokenExpired = () => {
  const token = getToken()
  if (!token) return true
  let decoded
  try {
    decoded = jwtDecode(token)
  } catch (err) {
    return true
  }
  const date = tokenExpiration(decoded)
  if (date === null) {
    return true
  }
  return !(date > new Date().valueOf())
}

// This lets us know if the user token is more than halfway through expiration period
export const expiringSoon = () => {
  const token = getToken()
  if (!token) return true
  let decoded
  try {
    decoded = jwtDecode(token)
  } catch (err) {
    return true
  }
  const date = tokenExpiration(decoded)
  const offsetSeconds = (decoded.exp - decoded.iat) / 2 // half-way through token expiration
  if (date === null) {
    return true
  }
  return !(date >= new Date().valueOf() + offsetSeconds * 1000)
}

export const canAccessFeature = (feature) => {
  // moved inside of function to make testing easier and it is not used elsewhere
  const featureState = {
    ADVANCED_LABEL_DIRECTIONS: process.env.REACT_APP_ADVANCED_LABEL_DIRECTIONS,
    MED_REC_V2: process.env.REACT_APP_MED_REC_V2,
    NEW_RISK_VIS: process.env.REACT_APP_NEW_RISK_VIS
  }

  if (!featureState[feature] || (featureState[feature] !== 'all' && featureState[feature] !== 'early-access')) {
    return false
  } else if (featureState[feature] === 'all') {
    return true
  }

  const earlyAccessPermission = get(getProfile(), 'permissions.early-access-features')
  return !!earlyAccessPermission
}

export const isUserGraduated = profile => get(profile, 'graduationStatus.graduated', false)
