// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get, isEqual, set, some } from 'lodash'
import { updatePatientWorkflowState } from 'store/modules/workflow/actions'
import { setPatient as setPatientAction } from 'store/modules/patient/actions'
import { getProjectIdAssumingATonOfThings } from 'services/project'

class WebSocketComponent extends Component {
  /**
   * Maximum number of reconnect attempts allowed.
   * @type {number}
   */
  MAX_RECONNECT_ATTEMPTS = 10

  /**
   * Increment in seconds of how long to wait between each reconnect.
   * Ex:  Reconnect attempt 1 fails, 5 second pass. Reconnect 2 attempt fails, increment of 5
   *      applied to the initial 5 seconds, so 10 seconds pass. Etc.
   * @type {number}
   */
  RECONNECT_SECONDS_INCREMENT = 5

  /**
   * Initial number of seconds to wait between reconnects.  Will apply to first attempt and then the
   * increment will start being added on each attempt.
   * @type {number}
   */
  RECONNECT_SECONDS_INITIAL = 5

  /**
   * Creates the component. Sets the initial state values:
   *    - 0 attempts
   *    - default for reconnect attempts
   * @param {object}  props Properties of the component.
   */
  constructor (props) {
    super(props)

    this.state = {
      reconnectAttempt: 0,
      reconnectSeconds: this.RECONNECT_SECONDS_INITIAL
    }
  }

  /**
   * Initializes the web socket connection when the component is mounted.
   */
  componentDidMount () {
    this.initializeWebSocket()
  }

  /**
   * Destroys the web socket connection when the component is unmounted.
   */
  componentWillUnmount () {
    this.setPatientId('', '')
    const { webSocket } = this.state
    webSocket.close(1000)
  }

  /**
   * Sends the updated patient ID via web socket when the component props are changed.
   * @param {object}  prevProps The previous properties of the component.
   */
  componentDidUpdate (prevProps) {
    const pathsOfConcern = ['patient._id', 'primaryProject']

    const equalityCheck = path => !isEqual(get(this.props, path), get(prevProps, path))
    if (some(pathsOfConcern, equalityCheck)) {
      const patientId = get(this.props, 'patient._id')
      const projectId = getProjectIdAssumingATonOfThings(get(this.props, 'patient.projects', {}))
      this.setPatientId(patientId, projectId)
    }
  }

  /**
   * Initializes the web socket connection.  Sets up listeners for web socket events.
   */
  initializeWebSocket () {
    const { url } = this.props
    const webSocket = new WebSocket(url)

    webSocket.onopen = this.onOpen.bind(this)
    webSocket.onmessage = this.onMessage.bind(this)
    webSocket.onclose = this.onClose.bind(this)

    this.setState({
      webSocket
    })
  }

  /**
   * Listener that fires when web socket is opened.  Resets the attempts and reconnect seconds to
   * their defaults.  Sends the active patient ID to the server via newly established connection.
   */
  onOpen () {
    const { patient } = this.props
    const patientId = get(patient, '_id', '')
    const projectId = getProjectIdAssumingATonOfThings(get(this.props, 'patient.projects', {}))
    console.debug('Web socket connected successfully.')

    this.setState(
      {
        reconnectAttempt: 0,
        reconnectSeconds: this.RECONNECT_SECONDS_INITIAL
      },
      () => this.setPatientId(patientId, projectId)
    )
  }

  /**
   * Listener that fires when web socket receives a message.
   * @param {object}  message       The message received from the server
   * @param {string}  message.data  The body of the message. Must be parsed to JSON.
   */
  onMessage (message) {
    console.debug('received message', message)
    try {
      const { patient, updateWorkflowState, setPatient } = this.props
      const patientId = get(patient, '_id')
      const messageDetails = JSON.parse(message.data)
      const newOrgId = get(messageDetails, 'currentOrgId')
      const newWorkflowState = get(messageDetails, 'workflowState')
      const iteration = get(messageDetails, 'iteration')
      const reviewType = get(messageDetails, 'reviewType')
      updateWorkflowState(patientId, newWorkflowState, newOrgId)
      set(patient, 'currentWorkflowState.name', get(newWorkflowState, 'displayName'))
      set(patient, 'workflowIteration', iteration)
      if (newOrgId) {
        // Because of the dependency on couch we have to save the whole org object not just the id.
        set(patient, 'managingOrganization', newOrgId)
      }

      if (reviewType) { // only set this if provided, we don't want to remove this in the UI
        set(patient, 'currentWorkflowState.reviewType', reviewType)
      }

      // TODO: Any additional workflow state stuff that needs to go in here should be added, i.e. opt out info, FTE info.
      setPatient(patient)
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Listener that fires when web socket is closed.  Attempts to automatically reconnect if the
   * close was not intentional.
   * @param {object}  event The close event.
   * @param {number}  event.code  The close event code.  More info here: https://tools.ietf.org/html/rfc6455#section-7.4.1
   */
  onClose (event) {
    // if we got a normal exit code, just log that the close happened
    if (get(event, 'code') === 1000) {
      return console.debug('Web socket connection closed.')
    }

    // if we did not get a normal exit code, attempt to reconnect.
    const { reconnectAttempt, reconnectSeconds } = this.state
    console.warn('web socket connection closed.')

    if (reconnectAttempt < this.MAX_RECONNECT_ATTEMPTS) {
      console.warn(`trying to connect again in ${reconnectSeconds} seconds.`)
      setTimeout(() => {
        this.initializeWebSocket()
        this.setState({
          reconnectSeconds: reconnectSeconds + this.RECONNECT_SECONDS_INCREMENT,
          reconnectAttempt: reconnectAttempt + 1
        })
      }, reconnectSeconds * 1000)
    } else {
      console.error('Web Socket maximum reconnect attempts exceeded.')
    }
  }

  /**
   * Sends a patient ID to the server via web socket.  This will indicate to the server that we are
   * listening for messages concerning this patient, so when an update occurs it will be routed to
   * this client appropriately.
   * @param {string}  patientId The ID of the patient.
   */
  setPatientId (patientId, projectId) {
    const { webSocket } = this.state
    if (get(webSocket, 'readyState') === WebSocket.OPEN && !!patientId === !!projectId) {
      console.debug('Sending patient ID and project ID', patientId, projectId)
      webSocket.send(JSON.stringify({ patientId, projectId }))
    }
  }

  /**
   * Normally this renders the component, but this particular one does not have any display.  It is
   * still required for a react component to contain a render method.
   * @return {null}
   */
  render () {
    return null
  }
}

WebSocketComponent.propTypes = {
  url: PropTypes.string.isRequired
}

const mapStateToProps = state => {
  const patientProjectKey = getProjectIdAssumingATonOfThings(
    get(state, 'patient.data.projects', {})
  )

  return {
    patient: get(state, 'patient.data'),
    primaryProject: patientProjectKey
  }
}

const mapDispatchToProps = dispatch => ({
  updateWorkflowState: (patientId, workflowState, orgId) =>
    dispatch(updatePatientWorkflowState(patientId, workflowState, orgId)),
  setPatient: patient => {
    dispatch(setPatientAction(patient))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(WebSocketComponent)
