/**
 * Project Reducer
 */

import * as type from './types'
import projectService from 'services/project'

const initialState = {
  patientProjects: [],
  allProjects: projectService.getProjects()
}

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
  case type.REQUEST:
    return {
      ...state,
      loading: true,
      status: action.status,
      error: null
    }
  case type.ERROR:
    return {
      ...state,
      loading: false,
      status: action.status,
      error: action.error
    }
  case type.GET_PROJECTS_FOR_PATIENT_SUCCESS:
    return {
      ...state,
      patientProjects: action.projects
    }
  case type.GET_PROJECTS_FOR_PATIENT_ERROR:
    return {
      ...state,
      error: action.error
    }
  case type.ALL_PROJECTS:
    return {
      ...state,
      loading: false,
      error: null,
      status: null,
      allProjects: action.payload
    }
  default:
    return state
  }
}

export default projectReducer
