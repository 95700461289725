/*
 * Eula actions for End User License Agreement
 */

import * as C from './types'
import * as EulaService from 'services/eula'
import { get } from 'lodash'

import logger from 'services/logger'
import { actions as notifyActions } from 'store/modules/notify'

export const toggleModal = () => ({
  type: C.TOGGLE_MODAL
})

export const getEulaError = error => ({
  type: C.GET_EULA_ERROR,
  payload: { error }
})

export const getEulaContentRequest = () => ({
  type: C.GET_EULA_CONTENT_REQUEST
})

export const getEulaContentSuccess = details => ({
  type: C.GET_EULA_CONTENT_SUCCESS,
  payload: { details }
})

export const getEulaAcceptanceRequest = () => ({
  type: C.GET_EULA_ACCEPTANCE_REQUEST
})

export const getEulaAcceptanceSuccess = acceptances => ({
  type: C.GET_EULA_ACCEPTANCE_SUCCESS,
  payload: { acceptances }
})

export const postEulaAcceptanceRequest = () => ({
  type: C.POST_EULA_ACCEPTANCE_REQUEST
})

export const postEulaAcceptance = versionId => {
  if (versionId) {
    return async dispatch => {
      dispatch(postEulaAcceptanceRequest())
      try {
        const eulaAcceptance = await EulaService.postEulaAcceptance(versionId)
        dispatch(postEulaAcceptanceSuccess(eulaAcceptance))
        dispatch(toggleModal())
      } catch (err) {
        // QUESTION: how do I get an actual error (currently empty {})
        // to come from the failed await for eulaAcceptance?
        const postErrObj = {
          message: 'There was an error posting the End User License Agreement (EULA)',
          type: 'error',
          sticky: true
        }
        dispatch(postEulaError(postErrObj.message))
        dispatch(notifyActions.addNotification(postErrObj))
        logger(postErrObj.message, err, true)
      }
    }
  }
}

export const postEulaAcceptanceSuccess = acceptances => ({
  type: C.POST_EULA_ACCEPTANCE_SUCCESS,
  payload: { acceptances }
})

export const postEulaError = error => ({
  type: C.POST_EULA_ERROR,
  payload: { error }
})

export const readAndUnderstood = acknowledgement => ({
  type: C.READ_AND_UNDERSTOOD,
  payload: { acknowledgement }
})

export const acknowledgedAndAgreed = agreement => ({
  type: C.ACKNOWLEDGED_AND_AGREED,
  payload: { agreement }
})

export const checkIfEulaIsNeeded = () => {
  return async dispatch => {
    const postErrObj = {
      message: 'There was an error getting the End User License Agreement (EULA)',
      type: 'error',
      sticky: true
    }
    dispatch(getEulaContentRequest())
    dispatch(getEulaAcceptanceRequest())
    try {
      let userNeedsToAcceptNewEulaVersion, userNeedsToAcceptEula
      /* Promise.all() would be more performant here, but those promises were
       * registering as empty by jest-fetch-mock in tests. Async/await allows
       * all the functions api calls and related store actions to be recognized.
       */
      const eulaContent = await EulaService.getEulaContent()
      const eulaAcceptances = await EulaService.getEulaAcceptance()
      dispatch(getEulaContentSuccess(eulaContent))
      dispatch(getEulaAcceptanceSuccess(eulaAcceptances))
      userNeedsToAcceptNewEulaVersion = () => {
        let currentEulaVersion = get(eulaContent, 'id')
        let eulaAcceptanceMatchingCurrentVersion = eulaAcceptances.some(
          eulaAcceptance => get(eulaAcceptance, 'EulaVersionId') === currentEulaVersion
        )
        // returning the opposite to align with the fn() name:
        // returning true means the user needs to accept a new eula
        // return false means a matching version was found, no eula needed
        return !eulaAcceptanceMatchingCurrentVersion
      }
      userNeedsToAcceptEula = eulaAcceptances === undefined || eulaAcceptances.length === 0
      if (userNeedsToAcceptEula || userNeedsToAcceptNewEulaVersion()) {
        dispatch(acknowledgedAndAgreed(false))
        dispatch(toggleModal())
      } else {
        dispatch(acknowledgedAndAgreed(true))
      }
    } catch (err) {
      dispatch(getEulaError(postErrObj.message))
      dispatch(notifyActions.addNotification(postErrObj))
      logger(postErrObj.message, err, true)
    }
  }
}
