/*
 * Header component
 */

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { get, includes } from 'lodash'
import { RiskLevel } from '@trhc/react-risk-visualizations'

import { getSeverity, MRSBar } from 'components/common/medication-risk-score'

import * as AuthService from 'services/auth'

import { actions as menuActions } from 'store/modules/main-menu'

import './styles/profile.scss'
import { DateFormat } from 'constants/date'

class Header extends Component {
  handleLogoutClick = () => {
    this.props.authActions.logoutSuccess()
    AuthService.logout()
    this.props.history.push({ pathname: '/' })
  }

  handleMenuToggle = () => {
    this.props.menuActions.toggleMenu()
  }

  render () {
    const { title, ...props } = this.props
    const isAuthenticated = get(props, 'auth.isAuthenticated')
    let profile = null
    const mwhcLink = (
      <div className='mwhc-link-box brand'>
        <span>
          Looking for the MedWise HealthCare Division website?
        </span>
        <a href='https://medwisehealthcare.com?utm_source=medwise-app&utm_medium=header&utm_campaign=redirect'
          target='_blank'>
          <span className='mwhc-link-button'>
            Visit MedWise HealthCare
          </span>
        </a>
      </div>
    )
    const data = get(props, 'patient.data')
    if (!includes(get(props, 'location.pathname'), '/support') && data && data._id) {
      const dob = get(data, 'dob')

      // this should come down from the patient reducer.
      if (data) {
        const age = dob && dayjs().diff(dob, 'years')
        const oldRiskVis = (
          <span>
            MRS: {getSeverity(get(props.mds, 'scores.risks.TotalScore')).description}{ ' ' }
            <MRSBar className="small" score={get(props.mds, 'scores.risks.TotalScore')} />
          </span>
        )
        const newRiskVis = (
          <RiskLevel
            riskLevelData={get(this.props, 'mds.riskLevel', undefined)}
            inline={true}
            legendPosition={'bottomCenter'}
          />
        )
        profile = (
          <div className="flex profile">
            <span className="patient-name">
              {get(data, 'name.first')} {get(data, 'name.last')}
            </span>
            <span className="age-and-gender">
              {age} year old {get(data, 'sex')}
            </span>
            <span className="dob">
              Born {dayjs(get(data, 'dob', undefined)).format(DateFormat.DATE_DISPLAY)}
            </span>
            <span className="mrs">
              { AuthService.canAccessFeature('NEW_RISK_VIS') ? newRiskVis : oldRiskVis }
            </span>
          </div>
        )
      }
    }

    let user = (
      <div className="user">
        <span className="pa3">{props.auth.profile.email}</span>
        <span>
          <Link to="/" className="logout" onClick={this.handleLogoutClick}>
            Log Out
          </Link>
        </span>
      </div>
    )

    let menu = (
      <div className="menu-holder">
        <span
          className={`menu-hamburger ${props.menu.open ? 'open' : ''}`}
          onClick={this.handleMenuToggle}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </span>
      </div>
    )

    return (
      <header className="main-header">
        <div className="header-wrapper flex justify-between items-center">
          <div className="flex">
            {isAuthenticated ? menu : null}
            <div className="brand">
              <Link className="fw5" to="/dashboard">
                {title}
              </Link>
            </div>
          </div>
          {isAuthenticated ? profile : mwhcLink}
          {isAuthenticated ? user : null}
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  title: PropTypes.oneOfType([ PropTypes.element, PropTypes.string ])
}

const mapStateToProps = ({ auth, menu, mds, patient }) => ({
  auth,
  menu,
  mds,
  patient
})

const mapDispatchToProps = dispatch => ({
  menuActions: {
    toggleMenu: id => dispatch(menuActions.toggleMenu())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header)
