/*
 * Patient Actions
 */

const PREFIX = '@@patient'

// global
export const REQUEST = `${PREFIX}/REQUEST`
export const FAILURE = `${PREFIX}/FAILURE`
export const SUCCESS = `${PREFIX}/SUCCESS`

// internal
export const SET = `${PREFIX}/SET`
export const REMOVE = `${PREFIX}/REMOVE`
export const CLEAR_PREVIEW = `${PREFIX}/CLEAR_PREVIEW`
export const WORKFLOW_STATE_UPDATE = `${PREFIX}/WORKFLOW_STATE_UPDATE`

// external
export const GET = `${PREFIX}/GET` // just used as status reference
export const UPDATE = `${PREFIX}/UPDATE`
export const INACTIVATE = `${PREFIX}/INACTIVATE`
export const PREVIEW = `${PREFIX}/PREVIEW`
export const IMPORT = `${PREFIX}/IMPORT`
export const SAVE_NEW = `${PREFIX}/SAVE_NEW`
export const MOVE_FROM_STAGED = 'MOVE_FROM_STAGED'
export const MANUAL_WORKFLOW_CHANGE = `${PREFIX}/MANUAL_WORKFLOW_CHANGE`
export const SEND_INVITATION = `${PREFIX}/SEND_INVITATION`
export const INITIATING_CALL_SUCCESS = `${PREFIX}/INITIATING_CALL_SUCCESS`
export const MEDICATION_REVIEW_SUCCESS = `${PREFIX}/MEDICATION_REVIEW_SUCCESS`
export const MEDICATION_SAFETY_REVIEW_SUCCESS = `${PREFIX}/MEDICATION_SAFETY_REVIEW_SUCCESS`
export const INITIATING_CALL_OPTOUT = `${PREFIX}/INITIATING_CALL_OPTOUT`
export const MEDICATION_REVIEW_OPTOUT = `${PREFIX}/MEDICATION_REVIEW_OPTOUT`
export const MEDICATION_SAFETY_REVIEW_OPTOUT = `${PREFIX}/MEDICATION_SAFETY_REVIEW_OPTOUT`
export const INITIATING_CALL_DECLINE = `${PREFIX}/INITIATING_CALL_DECLINE`
export const MEDICATION_REVIEW_DECLINE = `${PREFIX}/MEDICATION_REVIEW_DECLINE`
export const MEDICATION_SAFETY_REVIEW_DECLINE = `${PREFIX}/MEDICATION_SAFETY_REVIEW_DECLINE`
export const INITIATING_INTERACTION_FAIL = `${PREFIX}/INITIATING_INTERACTION_FAIL`
export const MEDICATION_REVIEW_FAIL = `${PREFIX}/MEDICATION_REVIEW_FAIL`
export const MEDICATION_SAFETY_REVIEW_FAIL = `${PREFIX}/MEDICATION_SAFETY_REVIEW_FAIL`
