/**
 * Project Actions
 */

import { filter, find, get } from 'lodash'
import projectService from 'services/project'
import { handleRequest, handleError } from 'store/actions'
import * as C from './types'

const getProjectsForPatientRequest = patient => ({
  type: C.GET_PROJECTS_FOR_PATIENT,
  patient
})

const getProjectsForPatientSuccess = projects => ({
  type: C.GET_PROJECTS_FOR_PATIENT_SUCCESS,
  projects
})

const getProjectsForPatientError = error => ({
  type: C.GET_PROJECTS_FOR_PATIENT_ERROR,
  error
})

/**
 * Retrieves list of projects for a given patient
 *
 * @param {object} patient The patient whose projects need to be retrieved
 * @returns {Function} Dispatches the resulting data to be staged to the redux store
 */
export const getProjectsForPatient = patient => {
  return async dispatch => {
    dispatch(getProjectsForPatientRequest(patient))
    try {
      const projects = await projectService.getProjectsForPatient(patient)

      const patientProjectIds = Object.keys(get(patient, 'data.projects', {}))
      const otherEnrollments = filter(patientProjectIds, couchId => {
        return !find(projects, p => p.couchId === couchId)
      })

      for (let i = 0; i < otherEnrollments.length; i++)  {
        const projectId = otherEnrollments[i]
        const project = await projectService.getProject(projectId)
        projects.push(project)
      }
      dispatch(getProjectsForPatientSuccess(projects))
    } catch (err) {
      dispatch(getProjectsForPatientError(err))
    }
  }
}

/**
 * Get all projects, this is needed because there is no way to get projects by a USER's org list, which is needed.
 * Only using this on the search dashboard because that's the only place it is needed.
 * TODO: filter by user orgs
 * @return {Function} Dispatch
 */
export const getProjects = () => {
  return async dispatch => {
    handleRequest(dispatch, C.REQUEST, C.ALL_PROJECTS)
    try {
      const projects = await projectService.getProjects()
      dispatch({
        type: C.ALL_PROJECTS,
        payload: projects
      })
    } catch (err) {
      handleError(dispatch, err, C.ERROR, C.ALL_PROJECTS)
    }
  }
}
