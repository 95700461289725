/*
 * Organizations Actions
 */

import gateway from 'services/gateway'
import { setOrganizations } from 'services/organizations'
import { removeOrgPrefix } from 'services/utils'
import { get } from 'lodash'

import * as C from './types'

/**
 * Eventually with https://jira.trhc.com/browse/CLINDEV-2901 we can send the keys and get their orgs
 * without having to get all of them and parse them manually
 * @type {[type]}
 * @param  {Array} organizations Array of organization IDs typically from the user profile
 * @return {Function} Redux thunk dispatch
 */
export const getUserOrgs = organizations => {
  return async dispatch => {
    try {
      dispatch({
        type: C.ORG_REQUEST
      })

      let response = await gateway.get('/api/medwise/organizations', { status: 'active' })

      response.docs.sort((a, b) => {
        if (get(a, 'name', '').toLowerCase() < get(b, 'name', '').toLowerCase()) return -1
        if (get(b, 'name', '').toLowerCase() < get(a, 'name', '').toLowerCase()) return 1
        return 0
      })

      dispatch({
        type: C.ORG_SET_ALL_ORGS,
        payload: response.docs
      })

      if (organizations && organizations.length) {
        const userOrgs = response.docs.filter(
          org => organizations.indexOf(removeOrgPrefix(org._id)) > -1
        )
        dispatch({
          type: C.ORG_SET_USER_ORGS,
          payload: userOrgs
        })
        setOrganizations(response.docs, userOrgs)
      } else {
        dispatch({
          type: C.ORG_SET_USER_ORGS,
          payload: response.docs
        })
        setOrganizations(response.docs, response.docs)
      }
    } catch (err) {
      dispatch({
        type: C.ORG_ERROR,
        payload: err
      })
    }
  }
}

/**
 * Post a new organization, generally only reserved for admins
 * @param  {Object} org The organization definition
 * @return {Function} Redux thunk dispatch
 */
export const postOrg = org => {
  return async dispatch => {
    try {
      dispatch({
        type: C.ORG_REQUEST
      })

      await gateway.post('/api/medwise/organizations', org)
      dispatch(getUserOrgs())
    } catch (err) {
      dispatch({
        type: C.ORG_ERROR,
        payload: err
      })
    }
  }
}

/**
 * Update an organization, generally only reserved for admins
 * @param  {Object} org The organization definition
 * @return {Function} Redux thunk dispatch
 */
export const putOrg = org => {
  return async dispatch => {
    try {
      dispatch({
        type: C.ORG_REQUEST
      })

      await gateway.put('/api/medwise/organizations', org)
      dispatch(getUserOrgs())
    } catch (err) {
      dispatch({
        type: C.ORG_ERROR,
        payload: err
      })
    }
  }
}
