/*
 * Priority Queue Actions
 */

export const SET_PRIORITY_QUEUE = 'SET_PRIORITY_QUEUE'
export const CLEAR_PRIORITY_QUEUE = 'CLEAR_PRIORITY_QUEUE'

export const FETCH_PRIORITY_QUEUES_REQUEST = 'FETCH_PRIORITY_QUEUES_REQUEST'
export const FETCH_PRIORITY_QUEUES_SUCCESS = 'FETCH_PRIORITY_QUEUES_SUCCESS'
export const FETCH_PRIORITY_QUEUES_FAILURE = 'FETCH_PRIORITY_QUEUES_FAILURE'

export const FETCH_PRIORITY_QUEUE_REQUEST = 'FETCH_PRIORITY_QUEUE_REQUEST'
export const FETCH_PRIORITY_QUEUE_SUCCESS = 'FETCH_PRIORITY_QUEUE_SUCCESS'
export const FETCH_PRIORITY_QUEUE_FAILURE = 'FETCH_PRIORITY_QUEUE_FAILURE'

export const CREATE_PRIORITY_QUEUE_REQUEST = 'CREATE_PRIORITY_QUEUE_REQUEST'
export const CREATE_PRIORITY_QUEUE_SUCCESS = 'CREATE_PRIORITY_QUEUE_SUCCESS'
export const CREATE_PRIORITY_QUEUE_FAILURE = 'CREATE_PRIORITY_QUEUE_FAILURE'

export const UPDATE_PRIORITY_QUEUE_REQUEST = 'UPDATE_PRIORITY_QUEUE_REQUEST'
export const UPDATE_PRIORITY_QUEUE_SUCCESS = 'UPDATE_PRIORITY_QUEUE_SUCCESS'
export const UPDATE_PRIORITY_QUEUE_FAILURE = 'UPDATE_PRIORITY_QUEUE_FAILURE'

export const REMOVE_PRIORITY_QUEUE_REQUEST = 'REMOVE_PRIORITY_QUEUE_REQUEST'
export const REMOVE_PRIORITY_QUEUE_SUCCESS = 'REMOVE_PRIORITY_QUEUE_SUCCESS'
export const REMOVE_PRIORITY_QUEUE_FAILURE = 'REMOVE_PRIORITY_QUEUE_FAILURE'

export const SYNC_PRIORITY_QUEUE_SETTINGS = 'SYNC_PRIORITY_QUEUE_SETTINGS'
