import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { Lock as LockIcon, LockOpen as UnlockIcon } from '@material-ui/icons'
import { CircularProgress } from '@material-ui/core'
import { get } from 'lodash'

import { TRHC_ERROR, TRHC_WARNING } from 'constants/colors'
import { CONFLICT } from 'constants/reservations'

import ContextButton from 'components/common/context-button'

import AlertDialog from './alert-dialog'

const styles = {
  minHeight: {
    minHeight: '27px'
  },
  btnWarning: {
    backgroundColor: TRHC_WARNING,
    borderColor: TRHC_WARNING,
    color: 'white'
  },
  btnDanger: {
    backgroundColor: TRHC_ERROR,
    borderColor: TRHC_ERROR,
    color: 'white'
  },
  icon: {
    fontSize: '18px',
    verticalAlign: 'middle',
    margin: '-3px 0 0 0'
  }
}

class ReservationButton extends Component {
  state = {
    alertOpen: false
  }

  componentDidMount () {
    const { reservationConflict } = this.props

    if (reservationConflict) {
      this.handleOverride()
    }
  }

  handleReleaseReservation = async () => {
    const { reservation, patientId, deleteReservation, onUpdate } = this.props

    await deleteReservation({
      _id: reservation._id,
      _rev: reservation._rev,
      patientId
    })
    !!onUpdate && onUpdate()
  }

  handleCreateReservation = async override => {
    const { auth, patientId, createReservation, onUpdate } = this.props
    const payload = {
      email: get(auth, 'profile.email'),
      patientId
    }
    if (override) {
      payload.override = true
    }
    const result = await createReservation(payload)
    !!onUpdate && onUpdate(result === CONFLICT, patientId)
  }

  handleOverride = () => {
    this.setState({
      alertOpen: true
    })
  }

  confirmOverride = () => {
    this.handleCreateReservation(true)
    this.setState({ alertOpen: false })
  }

  cancelOverride = () => this.setState({ alertOpen: false })

  render () {
    const { auth, reservation, reservationsStatus, classes, tooltipPlacement } = this.props
    const { alertOpen } = this.state
    const userId = get(auth, 'profile._id')
    const reservationUserId = get(reservation, 'user.identity_data._id')
    const reservationsLoaded = reservationsStatus === 'SUCCESS'
    const reservationUserEmail = get(reservation, 'user.identity_data.email')
    return (
      <Fragment>
        <AlertDialog
          open={alertOpen}
          title="Override Reservation?"
          text={
            `Another user (${reservationUserEmail}) has reserved this patient. Do you want ` +
            `to override the reservation and replace it with your own?`
          }
          actions={[
            {
              buttonText: `No, don't Override`,
              color: 'default',
              onClick: this.cancelOverride
            },
            {
              buttonText: `Yes, Override`,
              autoFocus: true,
              onClick: this.confirmOverride
            }
          ]}
        />

        {reservationUserId && userId === reservationUserId && (
          <Tooltip disableFocusListener title="Reserved by You" placement={tooltipPlacement}>
            <ContextButton
              cypressSuffix="release"
              className="secondary w4"
              onClick={this.handleReleaseReservation}
              text={
                <Fragment>
                  <LockIcon classes={{ root: classes.icon }} className="pr2" />
                  <span>Release</span>
                </Fragment>
              }
            />
          </Tooltip>
        )}
        {reservationUserId && userId !== reservationUserId && (
          <Tooltip
            disableFocusListener
            title={`Reserved by ${get(reservation, 'user.email')}`}
            placement={tooltipPlacement}
          >
            <ContextButton
              cypressSuffix="override"
              className="secondary w4"
              onClick={this.handleOverride}
              text={
                <Fragment>
                  <LockIcon classes={{ root: classes.icon }} className="pr2" />
                  <span>Override</span>
                </Fragment>
              }
            />
          </Tooltip>
        )}
        {!reservationUserId && (
          <ContextButton
            cypressSuffix={reservationsLoaded ? 'reserve' : 'reserve-loading'}
            disabled={!reservationsLoaded}
            className="secondary w4"
            onClick={this.handleCreateReservation.bind(this, false)}
            text={
              <Fragment>
                {
                  reservationsLoaded 
                    ? <UnlockIcon 
                        classes={{ root: classes.icon }} 
                      />
                    : <CircularProgress 
                        style={{margin: '-3px 5px 0 0'}}
                        size={13}
                      />
                }
                <span className="pl2">
                  {
                    reservationsLoaded
                      ? 'Reserve'
                      : 'Loading'
                  }
                </span>
              </Fragment>
            }
          />
        )}
      </Fragment>
    )
  }
}

ReservationButton.propTypes = {
  auth: PropTypes.object,
  reservation: PropTypes.object,
  createReservation: PropTypes.func.isRequired,
  deleteReservation: PropTypes.func.isRequired,
  patientId: PropTypes.string,
  onUpdate: PropTypes.func,
  tooltipPlacement: PropTypes.string,
  reservationConflict: PropTypes.bool
}

ReservationButton.defaultProps = {
  tooltipPlacement: 'top',
  reservationConflict: false
}

export default withStyles(styles)(ReservationButton)
