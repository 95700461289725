import React, { Component } from 'react'
import { KeyboardTimePicker } from '@material-ui/pickers'
import { withStyles } from '@material-ui/core/styles'
// DWA 2020-06-08: The conversion from Ramda's isEmpty to Lodash's isEmpty drifted in two instances: 
//   (1) Lodash considers null/undefined to be empty which was resolved by adding an isNil check and 
//   (2) Lodash considers numeric literals to be empty as well.  This condition should not be an issue in the existing
//       usage.
import { kebabCase, equals, isEmpty, isNil } from 'lodash'
import { Field } from 'redux-form'
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText'
import { AccessTime } from '@material-ui/icons'

const styles = theme => ({
  nonEmptyInput: {
    background: 'white'
  }
})

class TimePickerField extends Component {
  render () {
    const {
      classes,
      customWrapperClass,
      externalOnChange,
      helperText,
      input: { onChange, value },
      label,
      meta,
      required
    } = this.props

    const realValue = value !== undefined ? new Date(value) : value
    const showError = !!(meta.touched && meta.error)

    const inputStyle = value ? classes.nonEmptyInput : ''

    return (
      <div data-cy={`time-picker-${kebabCase(label)}`}>
        <KeyboardTimePicker
          required={required}
          ampm
          keyboardIcon={<AccessTime />}
          className={`w-100 ${customWrapperClass || ''}`}
          format="hh:mm a"
          placeholder="HH:MM AM/PM"
          label={label}
          // handle clearing outside => pass plain array if you are not controlling value outside
          mask={value =>
            value || realValue ? [/\d/, /\d/, ':', /\d/, /\d/, ' ', /A|P/i, /M/i] : []
          }
          value={realValue}
          onChange={newDate => {
            equals(typeof externalOnChange, 'function') && externalOnChange(newDate)
            onChange(newDate)
          }}
          onBlur={e => !isNil(e.target.value) && isEmpty(e.target.value) && onChange('')}
          onFocus={e => !isNil(e.target.value) && isEmpty(e.target.value) && onChange(new Date())}
          invalidDateMessage=""
          InputProps={{
            className: inputStyle
          }}
        />
        <div className="flex justify-between">
          <div className="flex flex-column">
            {showError && <FormHelperText error>{meta.error}</FormHelperText>}
            {!showError && helperText && <FormHelperText>{helperText}</FormHelperText>}
          </div>
        </div>
      </div>
    )
  }
}

const wrappedTimePickerField = withStyles(styles)(TimePickerField)
const ReduxFormTimePicker = props => (
  <Field
    name={props.name}
    label={props.label}
    component={wrappedTimePickerField}
    externalOnChange={props.onChange}
    customWrapperClass={props.customWrapperClass}
    required={props.required}
  />
)

export default ReduxFormTimePicker
