/**
 *  Helper function to unhash URL. This is used in a few places like the Dashboard
 *  and the url-hash module to get the unhashed URL
 */
export const unhash = pathname => {
  let currentPath = pathname.split('/')
  try {
    return JSON.parse(atob(decodeURIComponent(currentPath[currentPath.length - 1])))
  } catch (err) {
    // no url hash
    return null
  }
}

export const storeHash = hash => {
  localStorage.setItem('urlHash', hash)
}

export const clearHash = () => {
  localStorage.removeItem('urlHash')
}
