const ZIP_5 = new RegExp(/^(\d{5})$/)
const ZIP_9 = new RegExp(/^(\d{9})$/)
const ZIP_9_DASHED = new RegExp(/^(\d{5}-\d{4})$/)
const ZIP_5_OR_9 = new RegExp(/^((\d{5})|(\d{9}))$/)
const ZIP_5_OR_9_DASHED = new RegExp(/^((\d{5})|(\d{5}-\d{4}))$/)

const ZIP_MSG = '##### or #####-####'

// Use to check the data against a passed regex
const VALID_FORMAT = (regex, data) => {
  if (regex && data) {
    return regex.test(data)
  }
  return undefined
}

// Use to check the data agains a passed regex and return an err msg for
// redux-form if the input does not pass the regex
const VALID_FORMAT_MSG = (regex, msg = 'Invalid Format', data) => {
  if (regex && msg && data) {
    return VALID_FORMAT(regex, data)
      ? undefined
      : msg
  }
}

export const Format = {
  ZIP_5,
  ZIP_9,
  ZIP_9_DASHED,
  ZIP_5_OR_9,
  ZIP_5_OR_9_DASHED,
  ZIP_MSG,
  VALID_FORMAT,
  VALID_FORMAT_MSG
}
