import { Rifm } from 'rifm';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
import { kebabCase } from 'lodash'
import React, { Component } from 'react'
import { Field } from 'redux-form'
import FormHelperText from '@material-ui/core/FormHelperText'
import { DateFormat } from 'constants/date'
import dayjs from 'dayjs'

const styles = theme => ({
  nonEmptyInput: {
    background: 'white'
  }
})

// match for digits to properly append separator in formatDate
const parseDigits = string => (string.match(/\d+/g) || []).join('');

// add '/' separator between month, date and year for rifm masking
const formatDate = string => {
  const digits = parseDigits(string);
  const chars = digits.split('');
  return chars
    .reduce(
      (r, v, index) => (index === 2 || index === 4 ? `${r}/${v}` : `${r}${v}`),
      ''
    )
    .substr(0, 10);
};

// CouchDB date is stored as ISO 8601 format for patient DOB
// Need to convert to MM/DD/YYYY for UI when loading from CouchDB
// Only convert the first time when dashes are found in date string
// Otherwise, the Rifm formatter will pick up the date and format appropriately when user is inputting new DOB.
const convertStoredDateValue = (value) => {
  const containsDashses = value.match(/-/g)
  return containsDashses ? dayjs(value).format(DateFormat.DATE_DISPLAY_SHORT) : value
}

class DateInputField extends Component {
  render () {
    const {
      classes,
      customWrapperClass,
      input: { onChange, value },
      helperText,
      meta,
      label,
      required
    } = this.props

    const showError = !!(meta.touched && meta.error)

    const inputStyle = value ? classes.nonEmptyInput : ''
    return (
      <div>
        <div data-cy={`date-input-${kebabCase(label)}`}>
          <Rifm
            accept={/[\d]/g}
            value={value}
            onChange={onChange}
            format={formatDate}
          >
            {({ value, onChange }) => (
              <TextField
                className={`w-100 ${customWrapperClass || ''} ${inputStyle || ''}`}
                type="tel"
                placeholder={DateFormat.DATE_DISPLAY_SHORT}
                value={convertStoredDateValue(value)}
                onChange={onChange}
                label={label}
                required={required}
              />
            )}
          </Rifm>
        </div>
        <div className="flex justify-between">
          <div className="flex flex-column">
            {showError && <FormHelperText error>{meta.error}</FormHelperText>}
            {!showError && helperText && <FormHelperText>{helperText}</FormHelperText>}
          </div>
        </div>
      </div>
    )
  }
}

const wrappedDateInputField = withStyles(styles)(DateInputField)

const ReduxFormDateInput = props => {
  return (<Field
    name={props.name}
    label={props.label}
    component={wrappedDateInputField}
    customWrapperClass={props.customWrapperClass}
    required={props.required}
  />
  )
}

export default ReduxFormDateInput

