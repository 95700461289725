// This is the new/expanded version of the styledSnackBar...
// Here's the invision reference doc:
// https://trhc.invisionapp.com/d/main#/console/19914455/427203096/inspect

import React from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles/styled-notification.scss'
import IconButton from '@material-ui/core/IconButton'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'
import CloseIcon from '@material-ui/icons/Close'

const additionalStyles = theme => ({})

const applyIconStyle = (variant: string = 'info', padding?: string) => ({
  color: styles[`${variant}Icon`],
  padding: padding || '12px',
  fontSize: '24px'
})

// These are on the left side of the notification
const whichIcon = (variant: string = 'info') => {
  variant = variant.toLowerCase()

  switch (variant) {
  case 'success':
    return <CheckCircleIcon style={applyIconStyle(variant)} />
  case 'warning':
    return <WarningIcon style={applyIconStyle(variant)} />
  case 'error':
    return <ErrorIcon style={applyIconStyle(variant)} />
  case 'help':
  case 'info':
  default:
    return <InfoOutlinedIcon style={applyIconStyle(variant)} />
  }
}

const InlineNotification = props => {
  const {
    message,
    variant,
    closeNotification // To be developed in future steps
  } = props

  return (
    <div className={classNames('styled-notification', 'container', variant)}>
      {!!variant && <span>{whichIcon(variant)}</span>}
      <span className={classNames('message')}>{message}</span>
      <span className={classNames('content-right')}>
        <IconButton color="inherit" onClick={() => closeNotification()}>
          <CloseIcon style={applyIconStyle(variant, '0px')} />
        </IconButton>
      </span>
    </div>
  )
}

// Currently, withStyles() is not adding additional styling, but left it
// b/c it's used elsewhere and will be a more obvious option if left here.
const StyledNotification = withStyles(additionalStyles)(InlineNotification)

export default StyledNotification
