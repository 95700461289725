/*
 * Project Action Types
 */

const PREFIX = '@@project'

export const REQUEST = `${PREFIX}/REQUEST`
export const ERROR = `${PREFIX}/ERROR`

export const ALL_PROJECTS = `${PREFIX}/ALL_PROJECTS`

// get project details for patient associated projects
export const GET_PROJECTS_FOR_PATIENT = 'GET_PROJECTS_FOR_PATIENT'
export const GET_PROJECTS_FOR_PATIENT_SUCCESS = 'GET_PROJECTS_FOR_PATIENT_SUCCESS'
export const GET_PROJECTS_FOR_PATIENT_ERROR = 'GET_PROJECTS_FOR_PATIENT_ERROR'
