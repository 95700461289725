
import { removeOrgPrefix } from 'services/utils'
/**
 * Helper function to get user orgs and set it to the ALL_USER_ORG_FILTERS variable
 */
export const buildOrgList = orgs => {
  return orgs.map(org => {
    return {
      term: removeOrgPrefix(org._id),
      filterTerm: org.name,
      statType: 'org',
      queryType: 'term'
    }
  })
}
