import React from 'react'
import { Link } from 'react-router-dom'
import { find, isNil, map, times, get, max, isString } from 'lodash'
import dayjs from 'dayjs'
import utcPlugin from 'dayjs-plugin-utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'
import { formatWorkflowName } from 'services/workflow'
import { removeOrgPrefix } from 'services/utils'
import { WORKFLOW_NAMES } from 'constants/workflow'
import { DateFormat } from 'constants/date'

dayjs.extend(utcPlugin, { parseToLocal: true })
dayjs.extend(relativeTime)
dayjs.extend(duration)

export const ageInYears = birthday => dayjs().diff(birthday, 'year')
export const femaleOrMale = initial => (/m/i.test(initial) ? 'Male' : 'Female')
const formatSourceDate = (date, format) => date && dayjs(date).format(format)
export const formatSourceDateSimple = dateProperty => source =>
  formatSourceDate(source[dateProperty], DateFormat.DATE_DISPLAY)
export const formatSourceDateSpecific = dateProperty => source =>
  formatSourceDate(source[dateProperty], DateFormat.DATETIME_DISPLAY)

export const REACT_SELECT_CLEAR_ACTION = 'clear'
export const CREATE_NEW = 'CREATE_NEW'
export const EDITING_MODE = 'EDITING_MODE'
export const VIEWING_MODE = 'VIEWING_MODE'

export const TERM_FILTER_TYPE = 'term'
export const RANGE_FILTER_TYPE = 'range'
export const OK_STATUS = 'OK'
export const MATRIX_ERR_STATUS = 'MATRIX_ERR'

export const AGE_RANGE_OPTIONS = [
  { label: '18-35 yrs', to: 'now-18y', from: 'now-35y' },
  { label: '36-50 yrs', to: 'now-36y', from: 'now-50y' },
  { label: '51-60 yrs', to: 'now-51y', from: 'now-60y' },
  { label: '61-70 yrs', to: 'now-61y', from: 'now-70y' },
  { label: '71-80 yrs', to: 'now-71y', from: 'now-80y' },
  { label: '81-90 yrs', to: 'now-81y', from: 'now-90y' },
  { label: '91+ yrs', to: 'now-91y' }
]

const CALL_TYPES = {
  initiating: 'Initiating Call',
  medicationreview: 'Medication Review',
  medicationsafetyreview: 'Medication Safety Review'
}

export const PRIORITY_QUEUE_KEYS = [
  'id',
  'name',
  'userId',
  'organizationId',
  'role',
  'filters',
  'sorting',
  'displayColumns',
  'secondaryFilters',
  'createdAt',
  'updatedAt',
  'Organization',
  'organization',
  'public'
]

export const DUE_DATE_OPTIONS = map(['Past', 'Today', 1, 2, 3], n => {
  const filter = {
    statType: 'due_date',
    queryType: 'range'
  }
  if (n === 'Past') filter.lte = 'now-1d'
  if (n === 'Today') {
    filter.gte = 'now-1d'
    filter.lte = 'now+0d'
  }
  if (n > 0) filter.gte = `now+${n - 1}d`
  if (n < 4) filter.lte = `now+${n}d`
  return {
    filter: filter,
    label: isString(n) ? n : `${dayjs.duration(n, 'days').humanize(false)} from now`
  }
})

const buildYearOptions = () => {
  let thisYear = dayjs()
  thisYear =
    thisYear
      .startOf('year')
      .add(thisYear.utcOffset(), 'minutes')
      .utcOffset(0)
      .unix() * 1000

  const oldestYear = dayjs('2016-01-01T00:00:00Z')
  const result = []
  for (let year = dayjs(thisYear); year >= oldestYear; year = dayjs(year).subtract(1, 'year')) {
    let label
    let yearsDiff = dayjs(thisYear).diff(year, 'years')
    if (yearsDiff === 0) {
      label = 'Current Year'
    } else if (yearsDiff === 1) {
      label = 'Previous Year'
    } else {
      label = yearsDiff + ' Years Prior'
    }
    result.push({
      label: label,
      filter: {
        statType: 'workflow_year',
        // Use a range with same-valued bounds since data math does
        // not work for ES term queries
        queryType: 'range',
        lte: 'now-' + yearsDiff + 'y/y',
        gte: 'now-' + yearsDiff + 'y/y'
      }
    })
  }
  return result
}

const YEAR_OPTIONS = buildYearOptions()

const LAST_CALL_OPTIONS = map([1, 2, 3, 4, 5], n => {
  let filter = {
    statType: 'last_call',
    queryType: 'range',
    lte: 'now-' + (n - 1) + 'w'
  }
  if (n < 5) filter.gte = 'now-' + n + 'w'
  let label = n === 5 ? '4+' : n - 1 + ' - ' + n
  label += ' week' + (n > 1 ? 's' : '') + ' ago'
  return {
    filter: filter,
    label: label
  }
})

const NEXT_CALL_OPTIONS = map(['past', 'future', 1, 2, 3, 4, 5], function (n) {
  let filter = {
    statType: 'next_call',
    queryType: 'range'
  }
  let label
  if (n === 'past') {
    filter.lte = 'now'
    label = 'past'
  } else if (n === 'future') {
    filter.gte = 'now'
    label = 'future'
  } else {
    filter.gte = 'now+' + (n - 1) + 'w'
    if (n < 5) filter.lte = 'now+' + n + 'w'
    label = n === 5 ? '4+' : n - 1 + ' - ' + n
    label += ' week' + (n > 1 ? 's' : '') + ' from now'
  }
  return {
    filter: filter,
    label: label
  }
})

const NEXT_REVIEW_DATE_OPTIONS = map([10, 20, 30, 40], function (n) {
  var filter = {
    statType: 'next_review_date',
    queryType: 'range'
  }
  if (n > 10) filter.gte = 'now+' + (n - 10) + 'd'
  if (n < 40) filter.lte = 'now+' + n + 'd'
  var label = n === 40 ? '30+' : n - 10 + ' - ' + n
  label += ' days from now'
  return {
    filter: filter,
    label: label
  }
})

const LAST_REVIEW_DATE_OPTIONS = [
  {
    filter: {
      statType: 'last_review_date',
      queryType: 'missing'
    },
    label: 'None'
  },
  {
    filter: {
      statType: 'last_review_date',
      queryType: 'range',
      lte: 'now-60d'
    },
    label: '60+ days ago'
  },
  {
    filter: {
      statType: 'last_review_date',
      queryType: 'range',
      lte: 'now-90d'
    },
    label: '90+ days ago'
  }
]

const HOME_CARE_FLAG_OPTIONS = [
  {
    label: 'Home',
    filter: {
      statType: 'home_care_flag',
      queryType: 'term',
      term: '1'
    }
  },
  {
    label: 'Call',
    filter: {
      statType: 'home_care_flag',
      queryType: 'term',
      term: '0'
    }
  },
  {
    label: 'None',
    filter: {
      statType: 'home_care_flag',
      queryType: 'missing'
    }
  }
]

export const mapSourceToTableData = (
  displayColumns,
  INDEXED_PROPERTIES,
  source,
  orgList,
  projects
) => {
  const tableRowData = {}
  map(displayColumns, (thisIsJustTrue, propertyKey) => {
    const display = get(INDEXED_PROPERTIES, `${propertyKey}.display`)
    tableRowData[propertyKey] = display
      ? display === true
        ? get(source, propertyKey)
        : display(source, orgList, projects)
      : undefined
  })
  // always provide patient id
  tableRowData.id = source.id
  return tableRowData
}

export const MEMBER_SEARCH_FILTER_PROPERTIES = {
  org: true,
  workflow: true,
  dob: true,
  sex: true,
  prn: true,
  routine: true,
  total_risk_score: true,
  group: true,
  cohort: true,
  home_care_flag: true,
  active_projects: true
}

const dobFilterAgeExtractor = filterVal => {
  // grabs the year value form the elasticsearch date query string (i.e now-18y)
  return parseInt(filterVal.split('-')[1], 10)
}

const buildTermFilterText = (typeString, valueString, suffix) => {
  return `${typeString} equal to ${valueString} ${suffix || ''}`
}

const buildRangeFilterText = (typeString, statType, gte, lte, suffix) => {
  let valueString = ''

  if (gte === lte) {
    // if they used range, but entered same value, they want a term search for equality
    var newTermFilter = {
      statType: statType,
      queryType: 'term',
      term: gte
    }
    return buildTermFilterText(newTermFilter)
  }

  // only lte provided
  if (isNil(gte)) {
    valueString = `less than ${lte} ${suffix || ''}`

    // only gte provided
  } else if (isNil(lte)) {
    valueString = `greater than ${gte} ${suffix || ''}`

    // both provided... actual range
  } else {
    valueString = `between ${gte} and ${lte} ${suffix || ''}`
  }

  var fullText = `${typeString} ${valueString}`

  return fullText
}

export const INDEXED_PROPERTIES = {
  name: {
    label: 'Patient',
    display: source => (
      <Link key={source.id} to={`/dashboard/patient/${source.id}`}>
        {source.name}
      </Link>
    )
  },
  // ECPN org was dummy org which we created to
  // make ECPN PQ's available for all eCPN orgs
  // and we should hide this in secondary filter
  org: {
    label: 'Org',
    display: (source, orgList) => {
      let org = orgList.find(org => {
        return ['org', source.org].join(':') === org._id
      })
      return (org && org.name) || source.org
    },
    filter: {
      options: (userProfile, aggregate, orgList, projects) => {
        if (!orgList) return []
        return orgList
          .filter(org => {
            return org.name !== 'ECPN'
          })
          .map(organization => {
            return {
              label: organization.name,
              filter: {
                statType: 'org',
                queryType: 'term',
                term: removeOrgPrefix(organization._id),
                filterTerm: organization.name
              }
            }
          })
      }
    },
    summary: filter => buildTermFilterText('Org', filter.term)
  },
  ecpn_org: {
    label: 'eCPN Org',
    display: (source, orgList) => {
      let org = orgList.find(org => {
        return ['org', source.ecpn_org].join(':') === org._id
      })
      return (org && org.name) || source.ecpn_org
    },
    filter: {
      options: (userProfile, aggregate, orgList, projects) => {
        if (!orgList) return []
        return orgList
          .filter(org => {
            return !!org.parent
          })
          .map(organization => {
            return {
              label: organization.name,
              filter: {
                statType: 'ecpn_org',
                queryType: 'term',
                term: removeOrgPrefix(organization._id),
                filterTerm: organization.name
              }
            }
          })
      }
    },
    summary: filter => buildTermFilterText('eCPN Org', filter.term)
  },
  dob: {
    label: 'Age',
    display: source => ageInYears(source.dob),
    filter: {
      options: () =>
        map([18, 36, 51, 61, 71, 81, 91], function (age, i, ages) {
          let label
          const filter = {
            statType: 'dob',
            queryType: 'range',
            lte: `now-${age}y`
          }
          if (ages[i + 1]) {
            label = `${age} - ${ages[i + 1] - 1}yrs`
            filter.gte = `now-${ages[i + 1] - 1}y`
          } else {
            label = `${age}+ yrs`
          }
          return {
            label: label,
            filter: filter
          }
        })
    },
    summary: filter =>
      buildRangeFilterText(
        'Age',
        filter.statType,
        filter.lte && dobFilterAgeExtractor(filter.lte),
        filter.gte && dobFilterAgeExtractor(filter.gte),
        'yrs'
      ) // gte and lte are swapped for DOB agesince it's `NOW-${age}`
  },
  sex: {
    label: 'Sex',
    display: source => femaleOrMale(source.sex),
    filter: {
      options: () =>
        map(['f', 'm'], function (sex) {
          return {
            label: femaleOrMale(sex),
            filter: {
              statType: 'sex',
              queryType: 'term',
              term: sex
            }
          }
        })
    },
    summary: filter => buildTermFilterText('Sex', filter.term === 'm' ? 'male' : 'female')
  },
  prn: {
    label: 'PRN Meds',
    display: true,
    filter: {
      populateFromAggregationKeys: true,
      options: (userProfile, aggregationKeys) => {
        return times(max(aggregationKeys) + 1, function (n) {
          return {
            label: n,
            filter: {
              statType: 'prn',
              queryType: 'term',
              term: n
            }
          }
        })
      }
    },
    summary: filter => buildTermFilterText('PRN Meds', filter.term)
  },
  routine: {
    label: 'Routine Meds',
    display: true,
    filter: {
      populateFromAggregationKeys: true,
      options: (userProfile, aggregationKeys) => {
        return times(max(aggregationKeys) + 1, function (n) {
          return {
            label: n,
            filter: {
              statType: 'routine',
              queryType: 'term',
              term: n
            }
          }
        })
      }
    },
    summary: filter => buildTermFilterText('Routine Meds', filter.term)
  },
  date: {
    label: 'Last Updated',
    display: formatSourceDateSpecific('date'),
    filter: {
      options: () =>
        map([1, 2, 3, 4, 5], function (n) {
          const label = `${n === 5 ? '4+' : `${n - 1} - ${n}`} week${n > 1 ? 's' : ''} ago`
          const filter = {
            statType: 'date',
            queryType: 'range',
            lte: `now-${n - 1}w`,
            display: label
          }
          if (n < 5) filter.gte = `now-${n}w`
          return {
            filter: filter,
            label: label
          }
        })
    },
    summary: filter => `Last Updated ${filter.display}`
  },
  workflow: {
    label: 'Workflow',
    display: source => formatWorkflowName(source.workflow),
    filter: {
      options: () =>
        map(WORKFLOW_NAMES, function (workflowState) {
          return {
            label: formatWorkflowName(workflowState),
            filter: {
              statType: 'workflow',
              queryType: 'term',
              term: workflowState
            }
          }
        })
    },
    summary: filter => buildTermFilterText('Workflow', formatWorkflowName(filter.term))
  },
  workflow_iteration: {
    label: 'Engagement',
    display: true,
    filter: {
      populateFromAggregationKeys: true,
      options: (userProfile, aggregationKeys) => {
        aggregationKeys.push(10) // We'll go up to at least 10
        return times(max(aggregationKeys), function (n) {
          return {
            label: n + 1,
            filter: {
              statType: 'workflow_iteration',
              queryType: 'term',
              term: n + 1
            }
          }
        })
      }
    },
    summary: filter => buildTermFilterText('Engagement', filter.term)
  },
  workflow_year: {
    label: 'Year',
    display: source => source.workflow_year_number,
    filter: {
      options: userProfile => YEAR_OPTIONS
    },
    summary: filter => {
      let foundFilter = find(YEAR_OPTIONS, ['filter.lte', filter.lte])
      return `Year of ${get(foundFilter, 'label')}`
    }
  },
  current_year: {
    label: 'Current Year',
    display: true,
    filter: {
      populateFromAggregationKeys: true,
      options: (userProfile, aggregationKeys) => {
        return times(max(aggregationKeys) + 1, function (n) {
          return {
            label: n + 1,
            filter: {
              statType: 'current_year',
              queryType: 'term',
              term: n + 1
            }
          }
        })
      }
    },
    summary: filter => buildTermFilterText('Current Year', filter.term)
  },
  group: {
    label: 'Group',
    display: true,
    filter: {
      populateFromAggregationKeys: true,
      options: (userProfile, aggregationKeys) => {
        return map(aggregationKeys, function (key) {
          return {
            label: key,
            filter: {
              statType: 'group',
              queryType: 'term',
              term: key
            }
          }
        })
      }
    },
    summary: filter => buildTermFilterText('Group', filter.term)
  },
  cohort: {
    label: 'Cohort',
    display: true,
    filter: {
      populateFromAggregationKeys: true,
      options: (userProfile, aggregationKeys) => {
        return map(aggregationKeys, function (key) {
          return {
            label: key,
            filter: {
              statType: 'cohort',
              queryType: 'term',
              term: key
            }
          }
        })
      }
    },
    summary: filter => buildTermFilterText('Cohort', filter.term)
  },
  include_in_pq: {
    label: 'Include OP2',
    display: true,
    filter: {
      options: () => [
        {
          label: 'Yes',
          filter: {
            statType: 'include_in_pq',
            queryType: 'term',
            term: true
          }
        },
        {
          label: 'No',
          filter: {
            statType: 'include_in_pq',
            queryType: 'term',
            term: false
          }
        }
      ]
    },
    summary: filter => filter.term === true ? 'Include OP2' : `Don't Include OP2`
  },
  call_type: {
    label: 'Call Type',
    display: source => {
      if (source.call_type === 'initiating') {
        return 'Initiating Call'
      } else if (source.call_type === 'medicationreview') {
        return 'Medication Review'
      } else if (source.call_type === 'medicationsafetyreview') {
        return 'Medication Safety Review'
      } else {
        return 'N/A'
      }
    },
    filter: {
      options: () =>
        map(CALL_TYPES, (label, term) => ({
          label: label,
          filter: {
            statType: 'call_type',
            queryType: 'term',
            term: term
          }
        }))
    },
    summary: filter => {
      let displayTerm
      if (filter.term === 'initiating') {
        displayTerm = 'Initiating Call'
      } else if (filter.term === 'medicationreview') {
        displayTerm = 'Medication Review'
      } else if (filter.term === 'medicationsafetyreview') {
        displayTerm = 'Medication Safety Review'
      } else {
        displayTerm = 'N/A'
      }
      return buildTermFilterText('Call Type', displayTerm)
    }
  },
  attempts_made: {
    label: 'Attempts Made',
    display: source => {
      if (source.attempts_made === undefined) {
        return 'N/A'
      } else {
        return source.attempts_made
      }
    },
    filter: {
      populateFromAggregationKeys: true,
      options: (userProfile, aggregationKeys) =>
        times(max(aggregationKeys) + 1, function (n) {
          return {
            label: n,
            filter: {
              statType: 'attempts_made',
              queryType: 'term',
              term: n
            }
          }
        })
    },
    summary: filter => buildTermFilterText('Attempts Made', filter.term)
  },
  next_attempt_date: {
    label: 'Attempt Filter',
    display: formatSourceDateSimple('due_date'),
    filter: {
      options: () => [
        {
          label: 'On (filter by date)',
          filter: {
            statType: 'next_attempt_date',
            queryType: 'range',
            lte: 'now+1d/d'
          }
        }
      ]
    },
    summary: filter => 'Attempt on or before Today'
  },
  due_date: {
    label: 'Due Date',
    display: formatSourceDateSimple('due_date'),
    filter: {
      options: () => DUE_DATE_OPTIONS
    },
    summary: filter => {
      let foundFilter = find(DUE_DATE_OPTIONS, ['filter.lte', filter.lte])
      return foundFilter.label === 'Past' ? 'Past Due Date' : `Due Date ${foundFilter.label}`
    }
  },
  total_risk_score: {
    label: 'Total Risk Score',
    display: true,
    filter: {
      populateFromAggregationKeys: true,
      options: (userProfile, aggregationKeys) => {
        return times(max(aggregationKeys) + 1, function (n) {
          return {
            label: n,
            filter: {
              statType: 'total_risk_score',
              queryType: 'term',
              term: n
            }
          }
        })
      }
    },
    summary: filter => buildTermFilterText('Total Risk Score', filter.term)
  },
  last_call: {
    label: 'Last Call',
    display: formatSourceDateSimple('last_call'),
    filter: {
      options: () => LAST_CALL_OPTIONS
    },
    summary: filter => {
      let foundFilter = find(LAST_CALL_OPTIONS, ['filter.lte', filter.lte])
      return `Last Call of ${foundFilter.label}`
    }
  },
  last_call_successful: {
    label: 'Last Call Success',
    display: true,
    filter: {
      options: () => [
        {
          label: 'Yes',
          filter: {
            statType: 'last_call_successful',
            queryType: 'term',
            term: true
          }
        },
        {
          label: 'No',
          filter: {
            statType: 'last_call_successful',
            queryType: 'term',
            term: false
          }
        }
      ]
    },
    summary: filter => `Last Call ${filter.term === true ? 'Successful' : 'Unsuccessful'}`
  },
  next_call: {
    label: 'Next Call',
    display: formatSourceDateSpecific('next_call'),
    filter: {
      options: () => NEXT_CALL_OPTIONS
    },
    summary: filter => {
      let foundFilter = filter.gte
        ? find(NEXT_CALL_OPTIONS, ['filter.gte', filter.gte])
        : find(NEXT_CALL_OPTIONS, ['filter.lte', filter.lte])
      return `Next Call of ${foundFilter.label}`
    }
  },
  show_next_call: {
    label: 'Show Next Call',
    display: true,
    filter: {
      options: () => [
        {
          label: 'Yes',
          filter: {
            statType: 'show_next_call',
            queryType: 'term',
            term: true
          }
        },
        {
          label: 'No',
          filter: {
            statType: 'show_next_call',
            queryType: 'term',
            term: false
          }
        }
      ]
    },
    summary: filter => `${filter.term === true ? 'Show Next Call' : "Don't Show Next Call"}`
  },
  next_call_type: {
    label: 'Next Call Type',
    display: function (source) {
      if (source.next_call_type === 'initiatinginteraction') {
        return 'Initiating Interaction'
      } else if (source.next_call_type === 'medicationreviewinteraction') {
        return 'Medication Review Interaction'
      } else if (source.next_call_type === 'medicationsafetyreviewinteraction') {
        return 'Medication Safety Review Interaction'
      } else {
        return 'N/A'
      }
    },
    filter: {
      options: () =>
        map(
          {
            initiatinginteraction: 'Initiating Interaction',
            medicationreviewinteraction: 'Medication Review Interaction',
            medicationsafetyreviewinteraction: 'Medication Safety Review Interaction'
          },
          function (label, term) {
            return {
              label: label,
              filter: {
                statType: 'next_call_type',
                queryType: 'term',
                term: term
              }
            }
          }
        )
    },
    summary: filter => {
      let callType = 'N/A'
      switch (filter.term) {
      case 'initiatinginteraction':
        callType = 'Initiating Interaction'
        break
      case 'medicationreviewinteraction':
        callType = 'Medication Review Interaction'
        break
      case 'medicationsafetyreviewinteraction':
        callType = 'Medication Safety Review Interaction'
        break
      default:
      }
      return `Next Call Type ${callType}`
    }
  },
  project_next_call_project: {
    label: 'Next Call Project (for Project)',
    display: true,
    filter: {
      options: (userProfile, agg, organizations, projectList) => {
        return projectList.map(project => {
          return {
            label: project.name,
            filter: {
              statType: 'project_next_call_project',
              queryType: 'term',
              term: project.couchId.split(':')[1]
            }
          }
        })
      }
    },
    summary: filter => `Next Call Project ${filter.term}`
  },
  // this is unlikely to be used ever, so hiding it from options
  next_call_project: {
    label: 'Next Call Project',
    display: false,
    filter: {
      options: (userProfile, agg, organizations, projectList) => {
        return projectList.map(project => {
          return {
            label: project.name,
            filter: {
              statType: 'next_call_project',
              queryType: 'term',
              term: project.couchId.split(':')[1]
            }
          }
        })
      }
    }
  },
  bad_number: {
    label: 'Bad Number',
    display: true,
    filter: {
      options: () => [
        {
          label: 'Yes',
          filter: {
            statType: 'bad_number',
            queryType: 'term',
            term: true
          }
        },
        {
          label: 'No',
          filter: {
            statType: 'bad_number',
            queryType: 'term',
            term: false
          }
        }
      ]
    },
    summary: filter => buildTermFilterText('Bad Number', filter.term)
  },
  next_review_date: {
    label: 'Next Review Date',
    display: formatSourceDateSimple('next_review_date'),
    filter: {
      options: () => NEXT_REVIEW_DATE_OPTIONS
    },
    summary: filter => {
      let foundFilter = filter.gte
        ? find(NEXT_REVIEW_DATE_OPTIONS, ['filter.gte', filter.gte])
        : find(NEXT_REVIEW_DATE_OPTIONS, ['filter.lte', filter.lte])
      return `Next Review Date of ${get(foundFilter, 'label')}`
    }
  },
  last_review_date: {
    label: 'Last Review Date',
    display: formatSourceDateSimple('last_review_date'),
    filter: {
      options: () => LAST_REVIEW_DATE_OPTIONS
    },
    summary: filter => {
      let foundFilter = filter.lte
        ? find(LAST_REVIEW_DATE_OPTIONS, ['filter.lte', filter.lte])
        : find(LAST_REVIEW_DATE_OPTIONS, ['filter.queryType', filter.queryType])
      return `Last Review Date of ${get(foundFilter, 'label')}`
    }
  },
  published_cmrs: {
    label: 'Published MSRs',
    display: true,
    filter: {
      options: () => [
        {
          label: '0',
          filter: {
            statType: 'published_cmrs',
            queryType: 'term',
            term: 0
          }
        },
        {
          label: '1 or more',
          filter: {
            statType: 'published_cmrs',
            queryType: 'range',
            gte: 1
          }
        }
      ]
    },
    summary: filter =>
      filter.term === 0 ? buildTermFilterText('Published MSRs', 0) : 'Published MSRs of 1 or more'
  },
  draft_cmrs: {
    label: 'Draft MSRs',
    display: true,
    filter: {
      options: () => [
        {
          label: '0',
          filter: {
            statType: 'draft_cmrs',
            queryType: 'term',
            term: 0
          }
        },
        {
          label: '1 or more',
          filter: {
            statType: 'draft_cmrs',
            queryType: 'range',
            gte: 1
          }
        }
      ]
    },
    summary: filter =>
      filter.term === 0 ? buildTermFilterText('Draft MSRs', 0) : 'Draft MSRs of 1 or more'
  },
  medrecs: {
    label: 'Med Recs',
    display: true,
    filter: {
      options: () => [
        {
          label: '0',
          filter: {
            statType: 'medrecs',
            queryType: 'term',
            term: 0
          }
        },
        {
          label: '1 or more',
          filter: {
            statType: 'medrecs',
            queryType: 'range',
            lte: 'now+1d/d'
          }
        }
      ]
    },
    summary: filter =>
      filter.term === 0 ? buildTermFilterText('Med Recs', 0) : 'Med Recs of 1 or more'
  },
  home_care_flag: {
    label: 'Call or Home',
    display: source => {
      if (source.home_care_flag === '1') {
        return 'Home'
      } else if (source.home_care_flag === '0') {
        return 'Call'
      } else {
        return ''
      }
    },
    filter: {
      options: () => HOME_CARE_FLAG_OPTIONS
    },
    summary: filter => {
      const foundFilter = filter.term
        ? find(HOME_CARE_FLAG_OPTIONS, ['filter.term', filter.term])
        : find(HOME_CARE_FLAG_OPTIONS, ['filter.queryType', filter.queryType])
      const label = get(foundFilter, 'label')
      return label === 'None' ? `No Call or Home` : label
    }
  },
  active_projects: {
    label: 'Project',
    display: (source, orgList, projects) => {
      const project = projects.find(p => source.active_projects.find(ap => ap === p.couchId))
      if (project) return project.name
      return ''
    },
    filter: {
      options: (userProfile, agg, organizations, projectList) => {
        return map(projectList, project => {
          return {
            label: project.name,
            filter: {
              statType: 'active_projects',
              queryType: 'term',
              term: project.couchId,
              filterTerm: project.name
            }
          }
        })
      }
    },
    summary: filter => {
      return `Project of ${get(filter, 'filterTerm')}`
    }
  }
}
