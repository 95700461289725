/*
 * Medication Reconciliation Actions
 */

// pattern is still semi fluid here
export const RECONCILIATION_FETCHING = `RECONCILIATION_FETCHING`
export const RECONCILIATION_SUCCESS = `RECONCILIATION_SUCCESS`
export const RECONCILIATION_FAILURE = `RECONCILIATION_FAILURE`

// med rec lists
export const RECONCILIATION_LIST_DATA = `RECONCILIATION_LIST_DATA`

// update med rec
export const UPDATE_RECONCILIATION = `UPDATE_RECONCILIATION`

// reconciled data
export const RECONCILED_DATA = `RECONCILED_DATA`

// save new saveNewMedcationReconciliation
export const NEW_RECONCILIATION = `NEW_RECONCILIATION`

// save new list
export const NEW_RECONCILIATION_LIST = `NEW_RECONCILIATION_LIST`

// complete reconciliation
export const COMPLETE_RECONCILIATION = `COMPLETE_RECONCILIATION`

// clear reconciliation data
export const CLEAR_RECONCILIATION = 'CLEAR_RECONCILIATION'
