/*
 * Footer component
 */

import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

class Footer extends Component {
  render () {
    return (
      <footer className="main-footer">
        <div className="footer-wrapper cf">
          <div className="copyright fl w-100 w-50-ns">
            Copyright © {new Date().getFullYear()} CareKinesis, Inc. and TabulaRasa HealthCare, Inc.
            All Rights Reserved. Version {process.env.REACT_APP_VERSION}
          </div>
          <ul className="footer-menu fl w-100 w-50-ns list pl0 ma0 tr">
            {/*
            <li className="dib">
              <NavLink to="">Contact Us</NavLink>
            </li>
            <li className="dib">
              <NavLink to="/terms">Terms of Use</NavLink>
            </li>
            <li className="dib">
              <NavLink to="/legal">Legal</NavLink>
            </li>
            */}
            <li className="dib">
              <NavLink to="/privacy">Privacy</NavLink>
            </li>
          </ul>
        </div>
      </footer>
    )
  }
}

export default Footer
