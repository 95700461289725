import React from 'react'
import MarkdownView from 'react-showdown'

export const ActionableListDocumentation = () => {
  const markdown = `
# **Actionable Lists**

### Description
Actionable Lists contain Actionable List Items that are list items containing actions.   


## **Actionable List Items**

### Description
Actionable List Items are passed to Actionable Lists and rendered as children.  

### Props
* summary (optional) - summary will be displayed if given a value.
    * If the summary has a value, it is displayed by default.  When clicked, the summary is hidden and the children are displayed.
* icon (optional) - the icon to be displayed for the list item.
* expanded (optional) - whether or not the list item is currently expanded.  Defaults to \`false\`.
* itemActions (optional) - array of actions that can be performed on the item.
* classNames (optional) - additional classes to use for styling the simple list items.

### Example

For example code used to generate the actionable list below, please refer to [Actionable List](https://git.carekinesis.net/clinical/map-front-end/-/blob/master/medwise-advisor/src/components/gallery/actionable-list/medRecContainer/index.js).
`
  return (
    <MarkdownView
      markdown={markdown}
      options={{ tables: true, emoji: true, ghCodeBlocks: true }}
    />
  )
}