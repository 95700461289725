/*
 * Reservation Actions
 */

export const FETCH_RESERVATIONS_REQUEST = 'FETCH_RESERVATIONS_REQUEST'
export const FETCH_RESERVATIONS_SUCCESS = 'FETCH_RESERVATIONS_SUCCESS'
export const FETCH_RESERVATIONS_FAILURE = 'FETCH_RESERVATIONS_FAILURE'

export const UPDATE_RESERVATIONS_REQUEST = 'UPDATE_RESERVATIONS_REQUEST'
export const UPDATE_RESERVATIONS_SUCCESS = 'UPDATE_RESERVATIONS_SUCCESS'
export const UPDATE_RESERVATIONS_FAILURE = 'UPDATE_RESERVATIONS_FAILURE'

export const DELETE_RESERVATIONS_REQUEST = 'DELETE_RESERVATIONS_REQUEST'
export const DELETE_RESERVATIONS_SUCCESS = 'DELETE_RESERVATIONS_SUCCESS'
export const DELETE_RESERVATIONS_FAILURE = 'DELETE_RESERVATIONS_FAILURE'
