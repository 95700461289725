/*
 * Dashboard conditions tab
 */

import React, { Component } from 'react'
import dayjs from 'dayjs'
import { get as _get, find as _find } from 'lodash'
import StandardTab from 'components/common/standard-tab'
import { validateCallType } from 'services/interactions'
import logger from 'services/logger'
import { DateFormat } from 'constants/date'
import { path as pathOptions, dateByYear as dateByYearOptions } from 'services/options-builders'
import InteractionForm from './form'

const OBJECT_NAME = 'schedule_log'

// const UnassignedAlert = (
//   <div className="warn flex items-center">
//     <FaExclamationTriangle className="mr1" /> Unassigned
//   </div>
// )

class ScheduledInteractions extends Component {
  DEFAULT_STRUCTURE = {
    workflowIteration: _get(this.props, 'patient.data.workflowIteration', 1),
    currentYear: _get(this.props, 'patient.data.currentWorkflowState.year', 1)
  }

  formHandler = async (data, editing, originalItem) => {
    // manually merge the schedule_log entry into the array
    const { patient, patientActions } = this.props
    const scheduleLog = _get(patient, 'data.schedule_log', [])

    // merge `date` and `time` into `scheduledForDate`
    let { date: formDate, time: formTime, ...formData } = data
    // clone from formTime then set y/m/d
    let scheduledForDate = dayjs(formTime)
    formDate = dayjs(formDate)
    scheduledForDate = scheduledForDate.set('year', formDate.year())
    scheduledForDate = scheduledForDate.set('month', formDate.month())
    scheduledForDate = scheduledForDate.set('date', formDate.date())

    formData.scheduledForDate = scheduledForDate
    formData.workflowIteration = _get(patient, 'data.workflowIteration', 1)
    formData.currentYear = _get(patient, 'data.currentWorkflowState.year', 1)

    if (editing) {
      // DISCLAIMER: Change this once the back end supports granular CRUD operations for this resource.
      // We are currently finding the item that is being edited by using _.find with _.matches shorthand (which checks all object attributes for equality).
      // We do this because the item doesn't have a unique identifier, and manually hashing the objects to have a temporary ID
      // for front-end use only comes with it's own tradeoffs, but adds complexity, with the IDs then needing to be manually stripped before persisting.
      //
      // This method (using _.find with _.matches shorthand) is slow but reliable and a simple fill-gap.
      //
      // Once the backend supports granular CRUD operations for this resource, we should be able to make a call to update the item,
      // while optimistically updating it on the front-end, and then refetch the item list to get back in sync with the true backend state.
      //
      // This means this front-end find/update will only serve to optimistically update the item and may still use _.find,
      // but can search more efficiently, by searching for a specific item id

      let foundOriginalItem = _find(scheduleLog, originalItem)
      let foundItemIndex = scheduleLog.indexOf(foundOriginalItem)
      if (foundItemIndex !== -1) {
        // TODO: A changes array may make sense in the future
        scheduleLog[foundItemIndex] = formData
      } else {
        // This is bad UX. With unique IDs in the future we could merge their changes over the existing changes
        // or otherwise indicate what was concurrently updated by another user while they were editing
        // so they can confirm their changes and try again or cancel.
        this.props.notifyActions.addNotification({
          message:
            'Unable to save scheduled interaction. The item was updated by another user before saving. Please try editing the item again'
        })
        logger('Original scheduleLog item not found when saving.')
      }
    } else {
      // FOR NOTE SAVES:
      if (_get(formData, 'record.description', false)) {
        let record = formData.record
        record.authorLastName = _get(this.props, 'auth.profile.lastName')
        record.authorFirstName = _get(this.props, 'auth.profile.firstName')
        record.authorEmail = _get(this.props, 'auth.profile.email')
      }
      scheduleLog.push(formData)
    }
    patient.data.schedule_log = scheduleLog
    patientActions.updatePatient(patient.data)
  }

  render () {
    const { patient } = this.props
    let headers = [
      {
        name: 'Type of Call',
        maps: val => validateCallType(val.callType)
      },
      {
        name: 'Engagement',
        maps: 'workflowIteration'
      },
      {
        name: 'Current Year',
        maps: 'currentYear'
      },
      // commenting out per QA-4164
      // {
      //   name: 'Assigned To',
      //   maps: interaction => _get(interaction, 'assignedTo') || UnassignedAlert
      // },
      {
        name: 'Date',
        maps: interaction => dayjs(_get(interaction, 'scheduledForDate')).format(DateFormat.DATE_DISPLAY)
      },
      {
        name: 'Time',
        maps: interaction => dayjs(_get(interaction, 'scheduledForDate')).format(DateFormat.TIME_DISPLAY)
      }
    ]
    let data = _get(patient, `data.${OBJECT_NAME}`, [])
    data.sort((a, b) => {
      if (new Date(_get(a, 'scheduledForDate')) > new Date(_get(b, 'scheduledForDate'))) {
        return -1
      }
      if (new Date(_get(a, 'scheduledForDate')) < new Date(_get(b, 'scheduledForDate'))) {
        return 1
      }
      return 0
    })
    let filters = [
      {
        label: 'Type of Interaction',
        name: 'callType',
        options: pathOptions(data, 'callType'),
        comparator: (filterValue, rowItem) => rowItem.callType === filterValue
      },
      {
        label: 'Engagement',
        name: 'workflowIteration',
        options: pathOptions(data, 'workflowIteration'),
        comparator: (filterValue, rowItem) => rowItem.workflowIteration === filterValue
      },
      {
        label: 'Year',
        name: 'year',
        comparator: (filterValue, rowItem) => {
          // sometimes this is a UTC string and other times its a unix timestamp
          let date = _get(rowItem, 'scheduledForDate', '')
          if (typeof date !== 'string') {
            date = dayjs(date).format() // defaults to ISO6801 format
          }
          return date.startsWith(filterValue)
        },
        options: dateByYearOptions(data, 'updatedOn')
      }
    ]

    return (
      <StandardTab
        name="Scheduled Interaction"
        object={OBJECT_NAME}
        form={InteractionForm}
        initialValues={this.DEFAULT_STRUCTURE}
        formHandler={this.formHandler}
        tableHeaders={headers}
        tableData={data}
        filters={filters}
        pagination={{
          pageSize: 5,
          maxPages: 5
        }}
        {...this.props}
      />
    )
  }
}

export default ScheduledInteractions
