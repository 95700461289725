import * as Sentry from '@sentry/browser'
import * as AuthService from './auth'
Sentry.configureScope(scope => {
  scope.setUser(AuthService.getProfileForSave())
})

/**
 * Add a console log, optionally send it to Sentry
 *
 *
 */
export default (message, payload, sendToSentry) => {
  if (console && console.log) {
    if (message) {
      console.log(message)
    }
    if (payload) {
      console.log(payload)
    }
  }
  if (sendToSentry) {
    if (payload instanceof Error) {
      Sentry.configureScope(scope => {
        scope.setUser(AuthService.getProfileForSave())
      })
      Sentry.captureException(payload)
    } else {
      Sentry.configureScope(scope => {
        scope.setExtra('extra', payload)
        scope.setUser(AuthService.getProfileForSave())
      })
      Sentry.captureMessage(message)
    }
  }
}
