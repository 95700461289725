import { includes, isObject, replace } from 'lodash'
import { WORKFLOW_NAMES } from '../constants/workflow'

const reviewMap = {
  'Medication Safety Advisory (MSA)': 'MSA'
  // 'Medication Safety Advisory Lite (MSR Lite)':'MSR Lite',
}
/**
 * Formats a patient's workflow state name.
 * @param {string} workflowState
 * @returns {string}
 */

export const formatWorkflowName = workflowName => {
  let updatedName =
    workflowName === 'Hospice Do Not Call'
      ? replace(workflowName, 'Call', 'Interact')
      : replace(workflowName, 'Call', 'Interaction')
  let workflowsToChange = [
    'Ready for Initiating Interaction',
    'Ready for Medication Review',
    'Ready for Medication Safety Review'
  ]
  if (includes(workflowsToChange, updatedName)) {
    updatedName = updatedName.replace('Ready', 'Scheduled')
  }
  return updatedName
}

/**
 * Formats a patient's workflow state to display to the user. This function
 * will the review type and if documents have been mailed to the
 * member for MSA patients.
 * @param workflowState
 * @returns {string}
 */
export const formatWorkflow = workflowState => {
  if (!isObject(workflowState)) {
    return workflowState
  }

  const msaWorkflows = [
    WORKFLOW_NAMES.READY_PRINTING,
    WORKFLOW_NAMES.READY_MAILING,
    WORKFLOW_NAMES.COMPLETE
  ]

  let reviewDetails = reviewMap[workflowState.reviewType]

  // TODO Maybe drop MSA prefix, to support all review types?
  if (workflowState.MSASentToMember && !workflowState.MSASentToPrescriber) {
    reviewDetails += ' Sent to Member'
  }
  let output =
    reviewDetails && msaWorkflows.includes(workflowState.name)
      ? `${formatWorkflowName(workflowState.name)} (${reviewDetails})`
      : formatWorkflowName(workflowState.name)

  return output
}

const wfsErrMsg = 'wfs arg undefined'
/* 
 * @param {string} wfs - current workflow state from currentWorkflowState.name or workflowState.type
 * @returns {boolean}
 */
 export const isWfsReadyForConsult = (wfs: string) => {
   if (!wfs) console.error(wfsErrMsg)
   return [
     WORKFLOW_NAMES.READY_CONSULT,
     'READY_FOR_CONSULT'
   ].indexOf(wfs) > -1
 }

/* 
 * @param {string} wfs - current workflow state from currentWorkflowState.name or workflowState.type
 * @returns {boolean}
 */
 export const isWfsComplete = (wfs: string) => {
   if (!wfs) console.error(wfsErrMsg)
   return [
     WORKFLOW_NAMES.COMPLETE,
     'COMPLETE' 
   ].indexOf(wfs) > -1
 }

/* 
 * @param {string} wfs - current workflow state from currentWorkflowState.name or workflowState.type
 * @returns {boolean}
 */
 export const isWfsReadyForPreceptorReview = (wfs: string) => {
   if (!wfs) console.error(wfsErrMsg)
   return [
     WORKFLOW_NAMES.READY_FOR_MSR_PRECEPTOR_REVIEW,
     'READY_FOR_MSR_PRECEPTOR_REVIEW' 
   ].indexOf(wfs) > -1
 }