/*
 * MDS reducer
 */

import * as C from './types'

const mdsReducer = (
  state = {
    scores: null,
    summary: null,
    visualizations: {
      bullseye: null,
      matrix: null,
      windrose: null
    },
    loadingScores: true, // Loading defaults to true.
    loadingSummary: true,
    summaryError: null,
    launchUrl: null,
    loadingLaunchId: false,
    payload: null,
    riskScoreDial: undefined,
    riskLevel: undefined
  },
  action
) => {
  let newViz
  let newState
  switch (action.type) {
  case C.MDS_GET_SCORES:
    return {
      ...state,
      loadingScores: true,
      scores: null
    }
  case C.MDS_SET_SCORES:
    return {
      ...state,
      scores: action.payload,
      loadingScores: false
    }
  case C.MDS_GET_SUMMARY:
    return {
      ...state,
      loadingSummary: true,
      summary: null
    }
  case C.MDS_SET_SUMMARY:
    return {
      ...state,
      summary: action.payload,
      summaryError: action.error,
      loadingSummary: false
    }
  case C.MDS_CLEAR:
    return {
      ...state,
      scores: null,
      summary: null
    }
  case C.MDS_CLEAR_SCORES_LOADING:
    return {
      ...state,
      loadingScores: false
    }
  case C.MDS_CLEAR_SUMMARY_LOADING:
    return {
      ...state,
      loadingSummary: false
    }
  case C.MDS_GET_VISUALIZATION:
    newViz = state.visualizations
    newViz[action.vizType] = null
    return {
      ...state,
      visualizations: newViz
    }
  case C.MDS_SET_VISUALIZATION:
    newViz = state.visualizations
    newViz[action.vizType] = action.payload
    return {
      ...state,
      visualizations: newViz
    }
  case C.MDS_GET_BATCH:
    newState = {
      visualizations: {
        bullseye: null,
        matrix: null,
        windrose: null
      }
    }
    if (action.types.includes('normalizedRiskScores')) {
      newState.loadingScores = true
      newState.scores = null
    }
    return {
      ...state,
      ...newState
    }
  case C.MDS_SET_BATCH:
    newState = {}
    newViz = state.visualizations || {}
    if (action.payload.normalizedRiskScores) {
      newState.scores = action.payload.normalizedRiskScores
      newState.loadingScores = false
    }
    if (action.payload.riskScoreDial) {
      newState.riskScoreDial = action.payload.riskScoreDial
    }
    if (action.payload.riskLevel) {
      newState.riskLevel = action.payload.riskLevel
    }
    if (action.payload.bullseye) {
      newViz.bullseye = action.payload.bullseye
    }
    if (action.payload.windrose) {
      newViz.windrose = action.payload.windrose
    }
    if (action.payload.matrix) {
      newViz.matrix = action.payload.matrix
    }
    return {
      ...state,
      ...newState,
      visualizations: newViz
    }
  case C.MDS_GET_LAUNCH_ID:
    return {
      ...state,
      loadingLaunchId: true,
      payload: action.payload,
      launchUrl: null
    }
  case C.MDS_SET_LAUNCH_ID:
    return {
      ...state,
      launchUrl: action.launchUrl,
      loadingLaunchId: false
    }
  default:
    return state
  }
}

export default mdsReducer
