import React from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import { SnackbarProvider } from 'notistack'
import { TRHC_ERROR, TRHC_INFO, TRHC_WARNING, TRHC_SUCCESS } from 'constants/colors'

const styles = theme => ({
  base: {
    fontSize: 16,
    fontFamily: 'Roboto'
  },
  error: { backgroundColor: TRHC_ERROR },
  info: { backgroundColor: TRHC_INFO },
  warning: { backgroundColor: TRHC_WARNING, color: 'black' },
  success: { backgroundColor: TRHC_SUCCESS, color: 'black' },
  icon: {
    fontSize: 24
  },
  iconVariant: {
    marginRight: theme.spacing(1)
  }
})

const StyleSnackbarProvider = props => {
  const { classes } = props
  return (
    <SnackbarProvider
      classes={{
        base: classes.base,
        variantError: classes.error,
        variantInfo: classes.info,
        variantSuccess: classes.success,
        variantWarning: classes.warning
      }}
      iconVariant={{
        success: <Icon className={classNames(classes.icon, classes.iconVariant)}>warning</Icon>,
        error: <Icon className={classNames(classes.icon, classes.iconVariant)}>warning</Icon>,
        warning: <Icon className={classNames(classes.icon, classes.iconVariant)}>warning</Icon>,
        info: <Icon className={classNames(classes.icon, classes.iconVariant)}>warning</Icon>
      }}
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      {props.children}
    </SnackbarProvider>
  )
}

export default withStyles(styles)(StyleSnackbarProvider)
