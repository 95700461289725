import { get as _get, isEqual as _isEqual, last as _last, set as _set } from 'lodash'
import dayjs from 'dayjs'
import { Format } from 'constants/formatting'
import { DateFormat, Messages } from 'constants/date'
import { isValidDateObject, isFuture } from 'validators/date'

const validate = (profile, { initialValues, project } = {}) => {
  const errors = {}
  const programOptions = _get(project, 'allProjects', [])
  validateProfile(profile, errors, initialValues, programOptions)
  validateContactInformation(profile, errors, initialValues)

  return errors
}

const validateProfile = (profile, errors, initialValues, projects) => {
  // Patient Form
  const firstName = _get(profile, 'name.first')
  const lastName = _get(profile, 'name.last')
  const dob = _get(profile, 'dob')
  const preferredLanguage = _get(profile, 'languagePreference')
  const sex = _get(profile, 'sex')
  const medManager = _get(profile, 'medManager')
  const medManagerOther = _get(profile, 'medManagerOther')
  const organization = _get(profile, 'managingOrganization.id')
  const programId = _get(profile, 'programId')
  const referrals = _get(profile, 'referralType')

  !_get(firstName, 'length') && _set(errors, 'name.first', 'Required')
  !_get(lastName, 'length') && _set(errors, 'name.last', 'Required')
  
  const validateDob = dob => {
    if (!dob) return _set(errors, 'dob', 'Required')
    if (!isValidDateObject(dob, DateFormat.DATE_DISPLAY_SHORT)) return _set(errors, 'dob', Messages.INVALID_DATE)
    if (isFuture(dob)) return _set(errors, 'dob', Messages.FUTURE_DATE)
  }

  validateDob(dob)

  // if there are program options and the user has not chosen a programId
  const validateProgram = (orgId, programId, projectOptions) => {
    const programOptions = projectOptions.filter( proj => {
      const programOrg = _get(proj, 'Organization.externalId')
      return programOrg && programOrg === orgId
    }) || []
    if (programOptions.length > 0 && !programId) return _set(errors, 'programId', 'Please Choose a Program')
  }

  validateProgram(organization, programId, projects)

  !_get(preferredLanguage, 'length') && _set(errors, 'languagePreference', 'Required')
  !_get(sex, 'length') && _set(errors, 'sex', 'Required')

  // TODO: This does not display properly, but enforces the requirement
  if (medManager === 'false' && !_get(medManagerOther, 'length')) {
    _set(errors, 'medManagerOther', 'Required')
  }
  !_get(organization, 'length') && _set(errors, 'managingOrganization.id', 'Required')
  !_get(referrals, 'length') && _set(errors, 'dummyReferral', 'Required')

  if (_get(profile, 'transferTo') && !_get(profile, 'transferToReason.length')) {
    _set(errors, 'transferToReason', 'Required')
  }

  // if there is at least one entry in the original history array, make sure the date given is valid
  if (_get(initialValues, 'history.length')) {
    const latestDischargeDate = _get(_last(_get(profile, 'history')), 'dischargeDate')
    if (!dayjs(latestDischargeDate).isValid()) {
      _set(errors, `history[${_get(profile, 'history.length', 1) - 1}].dischargeDate`, 'Invalid Date')
    }
  }

  // if the history array is changed, require the change reason
  if (
    !_isEqual(_get(initialValues, 'history'), _get(profile, 'history')) &&
    !_get(_last(_get(profile, 'history')), 'manualChange.reason')
  ) {
    _set(errors, `history[${_get(profile, 'history.length', 1) - 1}].manualChange.reason`, 'Required')
  }
}

const validateContactInformation = (profile, errors) => {
  // Contact Information
  // Address 1
  const address1Line1 = _get(profile, 'address.line1')
  const address1City = _get(profile, 'address.city')
  const address1Zip = _get(profile, 'address.zip')
  const address1Primary = _get(profile, 'address.primary')
  // const address1Verified = _get(profile, 'address.verified')

  if (address1Primary) {
    !_get(address1Line1, 'length') && _set(errors, 'address.line1', 'Required')
    !_get(address1City, 'length') && _set(errors, 'address.city', 'Required')
    !_get(address1Zip, 'length') && _set(errors, 'address.zip', 'Required')
  }

  // Address 2
  const address2Line1 = _get(profile, 'address2.line1')
  const address2City = _get(profile, 'address2.city')
  const address2Zip = _get(profile, 'address2.zip')
  const address2Primary = _get(profile, 'address2.primary')
  // const address2Verified = __get(profile, 'address2.verified')
  if (address2Primary) {
    !_get(address2Line1, 'length') && _set(errors, 'address2.line1', 'Required')
    !_get(address2City, 'length') && _set(errors, 'address2.city', 'Required')
    !_get(address2Zip, 'length') && _set(errors, 'address2.zip', 'Required')
  }

  const validZipFormat = zip => Format.VALID_FORMAT(Format.ZIP_5_OR_9_DASHED, zip)

  // While zip code validation is coded inline, this check will prevent users
  // bypassing the error even if they switch to a tab without a zip code error.
  if (address1Zip && !validZipFormat(address1Zip)) {
    _set(errors, 'address.zip', Format.ZIP_MSG)
  }
  if (address2Zip && !validZipFormat(address2Zip)) {
    _set(errors, 'address2.zip', Format.ZIP_MSG)
  }

  const multipleAddressesMarkedPrimary = address1Primary && address2Primary
  if (multipleAddressesMarkedPrimary) {
    const errMsg = 'Only one primary address allowed'
    _set(errors, 'address.primary', errMsg)
    _set(errors, 'address2.primary', errMsg)
  }

  const cellphoneNumber = _get(profile, 'telecom.cellphone.number')
  const email = _get(profile, 'telecom.email')

  const textMessageServicesEnabled = _get(profile, 'telecom.cellphone.receiveSMS', false)
  const myMedWiseServicesEnabled = _get(profile, 'serviceOptions.myMedWiseServices', false)

  if (textMessageServicesEnabled === true && !_get(cellphoneNumber, 'length')) {
    _set(errors, 'telecom.cellphone.number', 'Required for Text Message Services')
  }

  if (myMedWiseServicesEnabled === 'true' && !_get(email, 'length')) {
    _set(errors, 'telecom.email', 'Required for My MedWise Services')
  }
  return errors
}

export default validate
