import gateway from './gateway'

export const setEulaStorage = value => {
  window.sessionStorage.setItem('eula_acceptance', JSON.stringify(value))
}

export const getEulaStorage = () => {
  return JSON.parse(window.sessionStorage.getItem('eula_acceptance')) || false
}

export const clearEulaStorage = () => {
  window.sessionStorage.removeItem('eula_acceptance')
}

export const getEulaAcceptance = async () => {
  let eulaAcceptance = await gateway.get('/api/medwise/eulaAcceptance')
  return eulaAcceptance
}

export const getEulaContent = async () => {
  let eulaContent = await gateway.get('/api/medwise/eula')
  return eulaContent
}

export const postEulaAcceptance = async versionId => {
  const body = {
    eulaVersionId: versionId
  }
  let eulaResult = await gateway.post(`/api/medwise/eulaAcceptance`, body)
  return eulaResult
}
