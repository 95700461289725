import { KeyboardDatePicker as MuiDatePicker } from '@material-ui/pickers'
import { withStyles } from '@material-ui/core/styles'
// DWA 2020-06-08: The conversion from Ramda's isEmpty to Lodash's isEmpty drifted in two instances: 
//   (1) Lodash considers null/undefined to be empty which was resolved by adding an isNil check and 
//   (2) Lodash considers numeric literals to be empty as well.  This condition should not be an issue in the existing
//       usage.
import { defaultTo, isEmpty, isNil, kebabCase } from 'lodash'
import React, { Component } from 'react'
import { Field } from 'redux-form'
import FormHelperText from '@material-ui/core/FormHelperText'
import dayjs from 'dayjs'
import { Date, DateFormat } from 'constants/date'

const styles = theme => ({
  nonEmptyInput: {
    background: 'white'
  }
})

class DatePickerField extends Component {
  render () {
    const {
      classes,
      customWrapperClass,
      input: { onChange, value },
      helperText,
      externalOnChange,
      meta,
      label,
      autoOk,
      ...props
    } = this.props

    const showError = !!(meta.touched && meta.error)

    const inputStyle = value ? classes.nonEmptyInput : ''
    return (
      <div>
        <div data-cy={`date-picker-${kebabCase(label)}`}>
          <MuiDatePicker
            className={`w-100 ${customWrapperClass || ''}`}
            clearable
            format={DateFormat.DATE_DISPLAY_SHORT}
            InputProps={{
              className: inputStyle
            }}
            invalidDateMessage={null}
            maxDateMessage={null}
            minDateMessage={null}
            minDate={Date.MIN_DATE}
            placeholder={DateFormat.DATE_DISPLAY_SHORT}
            autoOk={defaultTo(autoOk, true)}
            onBlur={e => !isNil(e.target.value) && isEmpty(e.target.value) && onChange('')}
            onFocus={e => !isNil(e.target.value) && isEmpty(e.target.value) && onChange(dayjs())}
            onChange={onChange}
            value={value}
            label={label}
            {...props}
          />
        </div>
        <div className="flex justify-between">
          <div className="flex flex-column">
            {showError && <FormHelperText error>{meta.error}</FormHelperText>}
            {!showError && helperText && <FormHelperText>{helperText}</FormHelperText>}
          </div>
        </div>
      </div>
    )
  }
}
const wrappedDatePickerField = withStyles(styles)(DatePickerField)
const ReduxFormDatePicker = props => {
  return (<Field
    name={props.name}
    label={props.label}
    disablePast={defaultTo(props.disablePast, false)}
    disableFuture={defaultTo(props.disableFuture, false)}
    component={wrappedDatePickerField}
    externalOnChange={props.onChange}
    customWrapperClass={props.customWrapperClass}
    required={props.required}
    maxDate={props.maxDate}
    keyboard={props.keyboard}
    parse={props.parse}
  />
  )
}

export default ReduxFormDatePicker
