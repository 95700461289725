/*
 * patient reducer
 */

import { get, set } from 'lodash'
import * as C from './types'

const initialState = {
  data: null,
  patientPreview: null,
  importedPatient: null,
  status: null,
  loading: false,
  error: null
}

const patientReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
  case C.REQUEST:
    return {
      ...state,
      loading: true,
      status: action.status,
      error: null
    }
  case C.FAILURE:
    return {
      ...state,
      loading: false,
      status: action.status,
      error: action.error
    }
  case C.SET:
    return {
      ...state,
      data: action.payload,
      loading: false,
      status: null,
      error: null
    }
  case C.SUCCESS:
    return {
      ...state,
      loading: false,
      status: null,
      error: null
    }
  case C.PREVIEW:
    return {
      ...state,
      patientPreview: action.payload,
      status: null,
      loading: false,
      error: null
    }
  case C.REMOVE:
    return {
      ...state,
      data: null,
      patientPreview: null,
      importedPatient: null,
      status: null,
      loading: false,
      error: null
    }
  case C.UPDATE:
    return {
      ...state,
      data: action.payload,
      loading: false,
      status: null,
      error: null
    }
  case C.IMPORT:
    return {
      ...state,
      loading: false,
      importedPatient: action.payload,
      status: null,
      error: null
    }
  case C.INITIATING_INTERACTION_FAIL:
    return {
      ...state,
      callInfo: action.payload
    }
  case C.MEDICATION_REVIEW_FAIL:
    return {
      ...state,
      callInfo: action.payload
    }
  case C.MEDICATION_SAFETY_REVIEW_FAIL:
    return {
      ...state,
      callInfo: action.payload
    }
  case C.WORKFLOW_STATE_UPDATE:
    const statePatientId = get(state, 'data._id')
    const { patientId, workflowState, newOrgId } = action
    // if the patient we are looking at matches the patient we got the update for, update the
    // workflow state on the doc for proper display
    if (statePatientId === patientId) {
      const currentWorkflowState = Object.assign({}, get(state, 'data.currentWorkflowState'), {
        name: workflowState.displayName
        // and other data, tbd
      })
      if (newOrgId) {
        // Workflow changes can result in a change in managing org.
        const managingOrganization = Object.assign({}, get(state, 'data.managingOrganization'), {
          id: newOrgId
        })
        set(state, 'data.managingOrganization', managingOrganization)
      }
      set(state, 'data.currentWorkflowState', currentWorkflowState)
    }
    return state
  default:
    return state
  }
}

export default patientReducer
