/**
 * Some helper functions for patients
 *
 * TODO: move some of the logic here or to map-common-logic
 */
import zipToTz from 'zip-to-tz'
import dayjs from 'dayjs'
import { find, get, isObject, orderBy } from 'lodash'

import gateway from './gateway'
import { canAccess } from './idam'

import { WORKFLOW_NAMES } from 'constants/workflow'
import { Format } from 'constants/formatting'
/**
 * This is useful for importing patients. It will get the latest Cohort and Group IDs
 * for usage during import
 * @return {Promise} The result from the API
 */
export const getMaxGroupAndCohort = async () => {
  return gateway.get('/api/medwise/patientGroups')
}

/**
 * A call to the gateway to get patient(s) latest review by type. This is useful in printing scenarios.
 * @param  {String}  startkey Patient ID startkey for couch
 * @param  {String}  endkey   Patient ID endkey for couch
 * @return {Promise}          [description]
 */
export const getLatestReviewByType = async (startkey, endkey) => {
  return orderBy(
    await gateway.get('/api/medwise/reviews/subject-with-reviewType', {
      startkey: startkey,
      endkey: endkey
    }),
    review =>
      get(review, 'value.publishedDate') ?
        Date.parse(get(review, 'value.publishedDate')) :
        Date.parse(get(review, 'value.reviewDate')),
    'desc'
  )
}

// used to check when a patient can be preceptor or ecpn
export const isPatientInPreceptorModel = patientOrgDoc => {
  return !!patientOrgDoc.usesNewWorkflow && !!patientOrgDoc.reviewingOrganization
}

// used when we need to tell from the patient doc if the patient is in ecpn but don't care about the preceptor model.
export const isPatientInECPNOrg = patientDoc => {
  const isInECPN =
    get(patientDoc, 'managingOrganization.primary') &&
    get(patientDoc, 'managingOrganization.secondary') &&
    get(patientDoc, 'managingOrganization.secondary') === 'org:EMTM'
  return isInECPN || false
}
/**
 * [getPatientOrgId description]
 * @param  {object}  patientManagingOrg The managingOrganization object
 * @return {integer} patient orgId
 * */
export const getPatientOrgId = (patientManagingOrg) => {
  // If an ecpn, don't look at id but primary which is the ecpn org.
  return get(patientManagingOrg, 'primary') || get(patientManagingOrg, 'id')
}

/**
 * [getPatientOrgDoc description]
 * @param  {object} patientManagingOrg The managingOrganization object
 * @param  {array} allOrgs             An array of all the org docs
 * @return {object}                    The org doc from map patient for the patients managing org.
 */
export const getPatientOrgDoc = (patientManagingOrg, allOrgs) => {
  const patientOrgId = getPatientOrgId(patientManagingOrg)
  return find(allOrgs, ['_id', patientOrgId]) || {}
}

// renamed the following since other orgs beside ecpns will have a send for review state.
export const inSendForReviewState = (patient, user) => {
  return (
    !get(user, 'graduationStatus.graduated') &&
    // don't think we need this check but leaving it here anyways.
    isPatientInECPNOrg(patient) &&
    get(patient, 'currentWorkflowState.name') === WORKFLOW_NAMES.READY_MEDICATION_SAFETY_REVIEW
  )
}

export const inSendForReviewPreceptor = (patientOrgDoc, wfs, user) => {
  return !get(user, 'graduationStatus.graduated') && patientOrgDoc.usesNewWorkflow && !!patientOrgDoc.reviewingOrganization && wfs === 'READY_FOR_MSR'
}

export const ecpnPublishAndReturn = patientDoc => {
  return (
    isPatientInECPNOrg(patientDoc) &&
    get(patientDoc, 'currentWorkflowState.name') ===
      WORKFLOW_NAMES.READY_ECPN_MEDICATION_SAFETY_REVIEW
  )
}

export const preceptorPublishAndReturn = (patientOrgDoc, wfs) => {
  return isPatientInPreceptorModel(patientOrgDoc) && wfs === 'READY_FOR_MSR_PRECEPTOR_REVIEW'
}

export const getOrgAccessName = orgDoc => {
  if (!orgDoc || !orgDoc._id) return ''
  if (orgDoc.reviewingOrganization) {
    return orgDoc.reviewingOrganization.split(':').pop()
  } else if (orgDoc.parent) {
    return orgDoc.parent.split(':').pop()
  } else {
    return orgDoc._id.split(':').pop()
  }
}

export const userCanPublishEcpnReview = (patient, auth) => {
  return (
    isPatientInECPNOrg(patient) &&
    get(patient, 'currentWorkflowState.name') ===
      WORKFLOW_NAMES.READY_ECPN_MEDICATION_SAFETY_REVIEW &&
    canAccess(auth, false, {
      organizations: {
        $eq: 'EMTM'
      }
    })
  )
}

export const userCanPublishPreceptorReview = (patientOrgDoc, wfs, auth) => {
  const orgName = getOrgAccessName(patientOrgDoc)
  return (
    isPatientInPreceptorModel(patientOrgDoc) &&
    wfs === 'READY_FOR_MSR_PRECEPTOR_REVIEW' &&
    canAccess(auth, false, {
      organizations: {
        $eq: orgName
      }
    })
  )
}

export const getPatientTimeZone = patient => {
  if (!isObject(patient)) {
    return patient
  }
  var tzMap = {
    'America/New_York': 'Eastern',
    'America/Chicago': 'Central',
    'America/Denver': 'Mountain',
    'America/Los_Angeles': 'Pacific',
    'America/Kentucky/Louisville': 'Eastern',
    'America/Kentucky/Monticello': 'Eastern',
    'America/Indiana/Indianapolis': 'Eastern',
    'America/Indiana/Knox': 'Central',
    'America/Indiana/Winamac': 'Eastern',
    'America/Indiana/Vevay': 'Eastern',
    'America/Indiana/Marengo': 'Eastern',
    'America/Indiana/Vincennes': 'Eastern',
    'America/Indiana/Tell_City': 'Central',
    'America/Indiana/Petersburg': 'Eastern',
    'America/Detroit': 'Eastern',
    'America/Menominee': 'Central',
    'America/North_Dakota/Center': 'Central',
    'America/Boise': 'Mountain',
    'America/Phoenix': 'Mountain Standard Time - Arizona',
    'America/Shiprock': 'Mountain',
    'Pacific/Honolulu': 'Hawaii',
    'America/Anchorage': 'Alaska',
    'America/Nome': 'Alaska',
    'America/Yakutat': 'Alaska',
    'America/Juneau': 'Alaska'
  }

  var zip =
    get(patient, 'address.primary') && get(patient, 'address.zip')
      ? get(patient, 'address.zip')
      : get(patient, 'address2.primary') && get(patient, 'address2.zip')
        ? get(patient, 'address2.zip')
        : null

  if (!zip || !Format.VALID_FORMAT(Format.ZIP_5_OR_9_DASHED, zip)) {
    // prevents missing or incorrectly formatted zip codes from crashing the timezone lookup
    return 'Unknown'
  }

  // Leftover code from 1.0 to check zip is correct format
  if (zip.length !== 5) {
    zip = zip.split('-')[0]
  }
  var tz = zipToTz(zip)
  return tz
    ? `${dayjs(new Date().toLocaleString('en-US', { timeZone: tz })).format('h:mmA')}, ${tzMap[tz]}`
    : null
}

export const getPGxData = async (patientId) => {
  return gateway.get('/api/medwise/patient/pgx', {patientId: patientId})
}
