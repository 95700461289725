/*
 * Private Route handles checking authentication
 * and then display the component or go to Login
 */

import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { get } from 'lodash'

import * as AuthService from 'services/auth'
import Login from 'components/common/login'

class PrivateRoute extends Component {
  render () {
    const { component: PropComponent, requiredRoles, ...rest } = this.props
    /**
     * Maybe we should change this for a better UX to try and get the user to login again without losing their work.
     * OR save the state so they don't lose anything on logout.
     */
    if (!rest.auth.isAuthenticated) {
      AuthService.setRedirect(window.location.pathname)
    } else if (requiredRoles && requiredRoles.length > 0 && rest.auth.isAuthenticated) {
      const userRoles = get(rest.auth, 'profile.roles', [])
      
      if (requiredRoles.filter(role => userRoles.includes(role)).length === 0) {
        AuthService.logout(true)
        this.props.authActions.forcedLogoutSuccess("You do not have permission to access this application.")

        return <Login />
      }
    }
    
    return (
      <Route
        {...rest}
        render={props => {
          return rest.auth.isAuthenticated ? (
            <PropComponent {...props} {...rest} />
          ) : (
            <Redirect to="/" />
          )
        }}
      />
    )
  }
}

export default PrivateRoute
