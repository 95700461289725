/*
 * Welcome to the Wild, Wonderful World of Buttons
 */

import React from 'react'

import ProperButton from 'components/common/proper-button'
import {ProperButtonDocumentation} from './button-documentation'

const phonyCallback = () => {
  const millis = 1500
  const start = Date.now()
  let now = null
  do {
    now = Date.now()
  } while (now - start < millis)
}

const ButtonGallery = () => {
  return (
    <div className="medwise w-80 center">
      <ProperButtonDocumentation />

      <div>
        <h4>Primary</h4>
        <ProperButton
          className="btn primary-btn"
          onClick={phonyCallback}
          disabled={true}>
          {'Disabled'}
        </ProperButton>
        <ProperButton
          className="btn primary-btn"
          onClick={phonyCallback}>
          {'Normal'}
        </ProperButton>
        <ProperButton
          className="btn primary-btn promise-pending"
          onClick={phonyCallback}>
          {'Saving...'}
        </ProperButton>
      </div>
      <div>
        <h4>Secondary with Icon</h4>
        <ProperButton
          className="btn secondary-btn"
          onClick={phonyCallback}
          icon="add_circle"
          disabled={true}>
          {'Disabled'}
        </ProperButton>
        <ProperButton
          className="btn secondary-btn"
          onClick={phonyCallback}
          icon="add_circle">
          {'Normal'}
        </ProperButton>
        <ProperButton
          className="btn secondary-btn promise-pending"
          onClick={phonyCallback}
          icon="add_circle">
          {'Saving...'}
        </ProperButton>
      </div>
      <div>
        <h4>Secondary</h4>
        <ProperButton
          className="btn secondary-btn"
          onClick={phonyCallback}
          disabled={true}>
          {'Disabled'}
        </ProperButton>
        <ProperButton
          className="btn secondary-btn"
          onClick={phonyCallback}>
          {'Normal'}
        </ProperButton>
        <ProperButton
          className="btn secondary-btn promise-pending"
          onClick={phonyCallback}>
          {'Saving...'}
        </ProperButton>
      </div>
      <div>
        <h4>Tertiary</h4>
        <ProperButton
          className="btn tertiary-btn"
          onClick={phonyCallback}
          disabled={true}>
          {'Disabled'}
        </ProperButton>
        <ProperButton
          className="btn tertiary-btn"
          onClick={phonyCallback}>
          {'Normal'}
        </ProperButton>
        <ProperButton
          className="btn tertiary-btn promise-pending"
          onClick={phonyCallback}>
          {'Saving...'}
        </ProperButton>
      </div>
    </div>
  )
}

export default ButtonGallery