import React from 'react'
import MarkdownView from 'react-showdown'

export const ProperButtonDocumentation = () => {
  const markdown = `
# **Proper Buttons**

### Description
Buttons are great.  Especially when they are proper!  

### Props
* type         (optional) - the button type.
* text         (optional) - text to display on the button if no children are passed to component.
* icon         (optional) - icon to display on the button.
* className    (optional) - class for styling button.
* onClick      (optional) - onClick event for button.

### Example

For example code used to generate the buttons below, please refer to [Proper Buttons](https://git.carekinesis.net/clinical/map-front-end/-/blob/master/medwise-advisor/src/components/gallery/button/index.js).
`
  return (
    <MarkdownView
      markdown={markdown}
      options={{ tables: true, emoji: true, ghCodeBlocks: true }}
    />
  )
}