import queryString from 'qs'
import gatewayFetch from './gateway-fetch'

export const GATEWAY_API_URL = process.env.REACT_APP_MAP_GATEWAY_API
export const ETL_API_URL = process.env.REACT_APP_ETL_API
/**
 * Gateway Service methods, these all take a relative path, and the `REACT_APP_GATEWAY_API_URL`
 * is prepended to it
 *
 * @method get - perform an HTTP GET
 * @param {string} path - The path for the request
 * @param {object} [queryString] - The optional query string to pass
 *
 * @method post - perform an HTTP POST
 * @param {string} path - The path for the request
 * @param {object} payload - The JSON object to send
 *
 * @method put - perform an HTTP PUT
 * @param {string} path - The path for the request
 * @param {object} payload - The JSON object to send
 *
 * @method upload - perform a file upload
 * @param {string} path - The path for the request
 * @param {File} file - The file to upload, generally from an input.files[0] form field.
 * @param {object} payload - The JSON object to send
 *
 * @method delete - perform an HTTP DELETE
 * @param {string} path - The path for the request
 * @param {object} [queryString] - The optional query string to pass
 */
export const configure = uri => {
  return {
    get: (path, qs, options = {}) => {
      return gatewayFetch({
        endpoint: `${uri}${path}${qs ? `?${queryString.stringify(qs)}` : ''}`,
        options: { ...options, method: 'GET' }
      })
    },
    post: (path, payload, options = {}) =>
      gatewayFetch({
        endpoint: `${uri}${path}`,
        options: { ...options, method: 'POST', body: JSON.stringify(payload) }
      }),
    put: (path, payload, options = {}) =>
      gatewayFetch({
        endpoint: `${uri}${path}`,
        options: {
          ...options,
          method: 'PUT',
          body: JSON.stringify(payload)
        }
      }),
    patch: (path, payload, options = {}) =>
      gatewayFetch({
        endpoint: `${uri}${path}`,
        options: {
          ...options,
          method: 'PATCH',
          body: JSON.stringify(payload)
        }
      }),
    upload: (path, file, payload, options = {}) => {
      let formData = new FormData()
      formData.append('file', file)
      formData.append('payload', JSON.stringify(payload))

      return gatewayFetch({
        endpoint: `${uri}${path}`,
        options: {
          ...options,
          method: 'POST',
          body: formData,
          headers: new Headers({})
        }
      })
    },
    uploadFormData: (path, formData, options = {}) => gatewayFetch({
      endpoint: `${uri}${path}`,
      options: {
        ...options,
        method: 'POST',
        body: formData,
        headers: new Headers({})
      }
    }),
    delete: (path, qs, options = {}) =>
      gatewayFetch({
        endpoint: `${uri}${path}${qs ? `?${queryString.stringify(qs)}` : ''}`,
        options: { ...options, method: 'DELETE' }
      }),
    getViaProxy: (path, qs = {}, options = {}) => gatewayFetch({
      endpoint: `${uri}/api/medwise/proxy?${queryString.stringify({ ...qs, path })}`,
      options: { ...options, method: 'GET' }
    }),
    postViaProxy: (path, payload, options = {}) => gatewayFetch({
      endpoint: `${uri}/api/medwise/proxy?${queryString.stringify({ path })}`,
      options: { ...options, method: 'POST', body: JSON.stringify(payload) }
    }),
    putViaProxy: (path, payload, options = {}) => gatewayFetch({
      endpoint: `${uri}/api/medwise/proxy?${queryString.stringify({ path })}`,
      options: { ...options, method: 'PUT', body: JSON.stringify(payload) }
    }),
    deleteViaProxy: (path, payload, options = {}) => gatewayFetch({
      endpoint: `${uri}/api/medwise/proxy?${queryString.stringify(({ path }))}`,
      options: { ...options, method: 'DELETE', body: JSON.stringify(payload) }
    })
  }
}

export const etlApi = configure(ETL_API_URL)
export default configure(GATEWAY_API_URL)
