import React, { Component } from 'react'
import { difference, find, findIndex, kebabCase, map, reject } from 'lodash'
import Button from '@material-ui/core/Button'
import SimpleSelect from 'components/common/simple-select'
import { FaArrowsAltH as ArrowsIcon, FaTrash as TrashIcon } from 'react-icons/fa'

const SORTING_STAT_TYPES = [
  'acb',
  'dob',
  'cohort',
  'ci',
  'due_date',
  'date',
  'group',
  'last_call',
  'fname',
  'lname',
  'org',
  'prn',
  'routine',
  'sdv',
  'sex',
  'total_risk_score',
  'workflow'
]

export const statTypeOptionText = sort => {
  switch (sort.statType) {
    case 'dob':
      return 'age'
    case 'date':
      return 'last updated'
    case 'fname':
      return 'name (first)'
    case 'lname':
      return 'name (last)'
    case 'ci':
      return 'comp. inh.'
    default:
      return sort.statType.replace(/_/g, ' ')
  }
}

export const statTypeOrderText = sort => {
  if (sort.statType === 'dob') {
    return sort.order === 'asc' ? 'desc' : 'asc'
  }

  return sort.order
}

class SearchSort extends Component {
  getAvailableSortingStatTypes = () => {
    const { sort } = this.props
    var usedStatTypes = map(sort, currentSort => currentSort.statType)
    return difference(SORTING_STAT_TYPES, usedStatTypes)
  }

  shuffleSorts = index => {
    const sort = this.props.sort.slice(0)
    const previous = sort[index - 1]

    sort[index - 1] = sort[index]
    sort[index] = previous
    // we're technically altering props.. but updating the array in-place
    // and settingState in the parent is enough to let react re-render properly
    this.props.updateSorting(sort)
  }

  removeSort = sort => this.props.updateSorting(reject(this.props.sort, sort))

  handleMissingValues = statType => {
    return statType === 'last_call' ? '0' : '_last'
  }

  addSort = statType => {
    // make sure we have sort order and statType
    if (!statType) {
      // can't do anything
      return
    }

    // avoiding mutation of priority queue sort array stored in redux
    const sorts = this.props.sort.slice(0)
    const sort = {
      statType: statType,
      order: 'asc',
      missing: this.handleMissingValues(statType)
    }
    if (!find(sorts, sort)) {
      sorts.push(sort)
    }

    this.props.updateSorting(sorts)
  }

  toggleSortOrder = sort => {
    // avoiding mutation of priority queue sort array elements stored in redux
    let replacement = { ...sort }
    replacement.order = replacement.order === 'asc' ? 'desc' : 'asc'

    // avoiding mutation of priority queue sort array stored in redux
    const sorts = this.props.sort.slice(0)
    const index = findIndex(sorts, sort)

    sorts[index] = replacement
    this.props.updateSorting(sorts)
  }

  renderActiveSorting = () => {
    const { sort } = this.props
    return map(sort, (activeSort, index) => (
      <div key={activeSort.statType} className="flex flex-row mt2">
        {index > 0 && (
          <div className="mr3">
            <Button
              onClick={() => this.shuffleSorts(index)}
              variant="contained"
              size="small"
              style={{ minWidth: '25px' }}
              data-cy="shuffle-sort-btn"
            >
              <ArrowsIcon />
            </Button>
          </div>
        )}
        <div className="mr2" data-cy={`sort-${kebabCase(statTypeOptionText(activeSort))}`}>
          <Button
            onClick={() => this.removeSort(activeSort)}
            variant="contained"
            size="small"
            style={{ borderRadius: '4px 0 0 4px' }}
            data-cy="remove-sort-btn"
          >
            <TrashIcon className="pr2" color="red" />
            {statTypeOptionText(activeSort)}
          </Button>
        </div>
        <div className="mr3">
          <Button
            onClick={() => this.toggleSortOrder(activeSort)}
            variant="contained"
            size="small"
            style={{ minWidth: '45px', borderRadius: '0 4px 4px 0' }}
            data-cy="toggle-sort-btn"
          >
            {statTypeOrderText(activeSort).toUpperCase()}
          </Button>
        </div>
      </div>
    ))
  }

  renderAddSortButton = () => {
    const statTypes = this.getAvailableSortingStatTypes()
    return (
      <SimpleSelect
        cypressSuffix="add-sort"
        onChange={statType => this.addSort(statType.value)}
        options={statTypes.map(statType => ({
          label: statTypeOptionText({ statType: statType }),
          value: statType
        }))}
        placeholder="Add Sort Field"
        value={null}
      />
    )
  }

  render () {
    return (
      <div>
        <div className="">Sort Patients</div>
        <div className="flex flex-row flex-wrap items-center mt2" data-cy="search-sort">
          {this.renderActiveSorting()}
          <div className="w-20">{this.renderAddSortButton()}</div>
        </div>
      </div>
    )
  }
}

export default SearchSort
