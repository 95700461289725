import React, { Component } from 'react'
import { Form, Field, reduxForm } from 'redux-form'

import ReduxFormText from 'components/common/redux-form/text'
import ReduxFormSearchSelect from 'components/common/redux-form/search-select'
import ReduxFormDatePicker from 'components/common/redux-form/date-picker'
import { searchCondition, buildCondition } from 'services/clinical'
import { conditionValidation } from './validation'

const OBJECT_NAME = 'condition'

/**
 * Function to call the endpoint /api/medwise/condition/search to return conditions.  This is also the same endpoint for patient indications located in the add medication form.
 * @param  {string}  inputValue - String to search in conditions endpoint.
 * @return {object}   - returns
 */

export class ConditionsForm extends Component {
  conditionSearch = async inputValue => {
    // let icd10CodeCheck = new RegExp(/^[A-Za-z]\d(\d)/i)
    // // version: 'EireneRx' is a work around until Joey from Eirene fixes cst so medwise has their own version.
    // // the version is needed to return child level icd10s instead of just the parent.
    // const searchConditionOptions = icd10CodeCheck.test(inputValue)
    //   ? { _filter: 'byCode', code: inputValue, version: 'EireneRx' }
    //   : { _filter: 'byName', _content: inputValue }
    try {
      let conditions = await searchCondition(inputValue)
      return conditions.map(dx => buildCondition(dx))
    } catch (err) {
      if (inputValue.length < 3) {
        return
      }
      this.props.notifyActions.addNotification({
        message: 'An error occurred fetching conditions information',
        sticky: true
      })
    }
  }

  handleSelectionChange = condition => {
    const { change } = this.props
    change('code.coding', condition.substance.coding)
  }

  handleSelectionClear = () => {
    const { change } = this.props
    change('code.coding', '')
    change('code.text', '')
  }

  suggestionLabel = suggestion => {
    return (
      <div>
        <div className="truncate fw6">{suggestion.substance.text}</div>
        <div className="truncate black-40">ICD-10: {suggestion.substance.coding[0].code}</div>
      </div>
    )
  }
  render () {
    const { onSubmit } = this.props
    return (
      <Form name="conditionsForm" className="w-100 flex flex-column" onSubmit={onSubmit}>
        <div className="flex justify-between mb2">
          <div className="dib w-100">
            <Field
              required
              name="code.text"
              type="text"
              label="Name"
              helperText={'Start typing or click to select a condition name'}
              component={ReduxFormSearchSelect}
              debounce={300}
              getSuggestions={this.conditionSearch}
              getSuggestionLabel={this.suggestionLabel}
              getSelectionValue={condition =>
                `[${condition.substance.coding[0].code}] ${condition.substance.text}`
              }
              onSelectionChange={this.handleSelectionChange}
              onSelectionClear={this.handleSelectionClear}
            />
          </div>
        </div>
        <div className="flex justify-between mb2">
          <div className="dib w-40">
            <ReduxFormDatePicker name="onsetDate" label="Onset Date" disableFuture={true} />
          </div>
        </div>
        <div className="flex justify-between mb2">
          <div className="dib w-100">
            <Field name="note" type="text" label="Note" helperText="" component={ReduxFormText} />
          </div>
        </div>
      </Form>
    )
  }
}

const ReduxConditionsForm = reduxForm({
  form: OBJECT_NAME,
  validate: conditionValidation
})(ConditionsForm)

export default ReduxConditionsForm
