/*
 * Actions for MDS services
 */

import gateway from 'services/gateway'
import * as systemsConstants from 'constants/systems'
import logger from 'services/logger'
import dayjs from 'dayjs'
import { get } from 'lodash'

import { filterNoRXCUIs } from 'services/clinical'
import { actions as notifyActions } from 'store/modules/notify'

import * as C from './types'

export const extractRxcui = med => {
  return med.medication.code.coding.filter(code => {
    return code.system === systemsConstants.RXNORM_RXCUI
  })
}

export const buildRxcuiArray = medications => {
  return medications
    .map(med => {
      const rxcui = extractRxcui(med)
      return { rxcui: rxcui && rxcui.length ? rxcui[0].value : undefined }
    })
    .filter(med => {
      return med.rxcui && med.rxcui !== 'custom-medication'
    })
}

/**
 *  getScores will get the risk scores for a patient given their medications
 *  and patient information.
 *
 *  @param {object} input The {medications, patient} object to send to the API
 *  @returns {object} The risk scores
 */
export const getScores = input => {
  return async dispatch => {
    const { medications, patient } = input
    if (!medications || medications.length === 0) {
      dispatch(setScores({ risks: { TotalScore: 0 } }))
      dispatch({
        type: C.MDS_CLEAR_SCORES_LOADING
      })
      return logger('This patient has no allergies or medications')
    }

    if (!patient) {
      dispatch(setScores({ risks: { TotalScore: 0 } }))
      return logger('The patient is not defined')
    }

    dispatch({
      type: C.MDS_GET_SCORES
    })
    try {
      // TODO: I think this is deprecated
      let data = await gateway.post('/api/matrix/scores-normalized', {
        medications: filterNoRXCUIs(medications),
        patient
      })
      dispatch(setScores(data))
    } catch (err) {
      dispatch(setScores(null))
      dispatch(
        notifyActions.addNotification({
          message: 'An error occurred getting risk scores'
        })
      )
      logger('An error occurred getting the risk scores', err, true)
    }
  }
}

export const setScores = data => ({
  type: C.MDS_SET_SCORES,
  payload: data
})

/**
 *  DEPRECATED!
 *  getSummary will get the risk summary for a patient given their medications
 *  and allergy information.
 *
 *  @param {object} input The {medications, patient, demographics} object to send to the API
 *  @returns {object} The risk scores
 */
export const getSummary = input => {
  return async dispatch => {
    let notCustomMeds = get(input, 'medications', []).filter(
      med => !get(med, 'medication.isCustom')
    )
    if (!input.patient || !get(input, 'medications.length') || notCustomMeds.length === 0) {
      dispatch({
        type: C.MDS_CLEAR_SUMMARY_LOADING
      })
      return logger('No patient or medications included in input')
    }
    const realMedications = buildRxcuiArray(input.medications)
    if (!realMedications || !realMedications.length) {
      dispatch({
        type: C.MDS_CLEAR_SUMMARY_LOADING
      })
      return logger('No patient or medications included in input')
    }
    dispatch({
      type: C.MDS_GET_SUMMARY
    })

    try {
      const payload = {
        demographics: {
          sex: input.patient.sex,
          age: dayjs().diff(input.patient.dob, 'years')
        },
        medicationsRxcui: realMedications,
        patient: input.patient
      }
      let data = await gateway.post('/api/v2/matrix/summary', payload)
      dispatch(setSummary(data))
    } catch (err) {
      dispatch(setSummary(null, err))
      dispatch(
        notifyActions.addNotification({
          message: 'An error occurred getting risk summary'
        })
      )
      logger('An error occurred getting the risk summary', err, true)
    }
  }
}

export const setSummary = (data, error) => ({
  type: C.MDS_SET_SUMMARY,
  payload: data,
  error
})

export const getVisualization = payload => {
  if (!payload.type && !payload.mdsVisualizationType) {
    return logger('No type given for visualization')
  }
  if (!payload.mdsVisualizationType) {
    payload.mdsVisualizationType = payload.type
  }
  if (!payload.type) {
    payload.type = payload.mdsVisualizationType
  }
  payload.mdsVisualizationCard = true
  return async dispatch => {
    dispatch({
      type: C.MDS_GET_VISUALIZATION,
      vizType: payload.type
    })
    try {
      const data = await gateway.post(`/api/mds/matrix`, payload)
      dispatch(setVisualization(data, payload.type))
    } catch (err) {
      dispatch(setVisualization(null, payload.type))
      dispatch(
        notifyActions.addNotification({
          message: 'An error occurred getting MDS visualization'
        })
      )
      logger('An error occurred getting MDS visualization', err, true)
    }
  }
}

export const getMDSData = payload => {
  return async dispatch => {
    dispatch({
      type: C.MDS_GET_BATCH,
      types: payload.types
    })
    try {
      const data = await gateway.post(`/api/mds/batch`, payload)
      dispatch(setVisualizations(data))
    } catch (err) {
      // dispatch(setVisualizations(null))
      dispatch(
        notifyActions.addNotification({
          message: 'An error occurred getting MDS visualizations'
        })
      )
      logger('An error occurred getting MDS visualizations', err, true)
    }
  }
}

export const setVisualization = (data, type) => ({
  type: C.MDS_SET_VISUALIZATION,
  payload: data,
  vizType: type
})

export const setVisualizations = data => ({
  type: C.MDS_SET_BATCH,
  payload: data
  // vizType: type
})

export const getLaunchId = payload => {
  return async dispatch => {
    dispatch({
      type: C.MDS_GET_LAUNCH_ID,
      payload: payload
    })
    try {
      const data = await gateway.post(`/api/fhirCache`, payload)
      dispatch(setLaunchId(data))
    } catch (err) {
      dispatch(
        notifyActions.addNotification({
          message: 'An error occurred posting FHIR bundle to FHIR Cache'
        })
      )
      logger('An error occurred getting posting FHIR bundle to FHIR Cache', err, true)
    }
  }
}

export const setLaunchId = data => ({
  type: C.MDS_SET_LAUNCH_ID,
  launchUrl: data.launchUrl
})
