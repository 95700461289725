/*
 * Patient Search Actions
 */

export const SEARCH_PATIENTS_REQUEST = 'SEARCH_PATIENTS_REQUEST'
export const SEARCH_PATIENTS_SUCCESS = 'SEARCH_PATIENTS_SUCCESS'
export const SEARCH_PATIENTS_FAILURE = 'SEARCH_PATIENTS_FAILURE'

export const SET_PATIENTS = 'SET_PATIENTS'
export const REMOVE_PATIENTS = 'REMOVE_PATIENTS'
