/**
 *  Pagination component.
 *
 *  @param {number} total The total number of entries
 *  @param {number} pageSize The size of the pages
 *  @param {number} page The current page number, starting from 0
 *  @param {number} maxPages The max number of pages to show at one time
 *  @param {boolean} buttons Display the previous and next buttons, default false.
 *  @param {object} buttonText The text for buttons, defaults to {previous: 'Previous', next: 'Next'}. Could also put components in here.
 *  @param {function} onClick The method to handle a button press. The argument is the page number.
 *  @param {boolean} showSummary Show the summary of current page and total
 *  @param {string} itemName Defaults to `entries`, the text that goes after the total in the summary.
 *  @param {boolean} display Display the pagination.
 */

import React, { Component } from 'react'
import { get } from 'lodash'

import './styles/pagination.scss'

const numberOrDash = function (num) {
  return isNaN(num) ? '-' : num
}

class Pagination extends Component {
  constructor (props) {
    super(props)

    this.state = {
      currentPage: props.page || 0,
      numPages: Math.ceil(props.total / props.pageSize),
      maxPages: props.maxPages || 5,
      prevNextButtons: props.prevNextButtons || true,
      showSummary: props.showSummary || true
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.total === undefined) return
    // using componentDidUpdate to keep state in sync with props is an anti-pattern,
    // however we won't usually do this, this is for the rare case when page is controlled externally
    if (this.props.page && this.props.page !== this.state.currentPage) {
      this.setState({ currentPage: this.props.page })
    }
    if (prevProps.total !== this.props.total || prevProps.filterHash !== this.props.filterHash) {
      this.setState({
        // reset current page back to zero when the total changes
        currentPage: this.props.total === 0 ? 0 : this.state.currentPage,
        numPages: Math.ceil(this.props.total / this.props.pageSize),
        maxPages: this.props.maxPages || 5,
        prevNextButtons: this.props.prevNextButtons || true,
        showSummary: this.props.showSummary || true
      })
    }
  }

  handleClick (page) {
    this.setState({
      currentPage: page
    })
    this.props.onClick(page)
  }

  ellipsisButton = pageIndex => (
    <button
      key={`page-ellipsis-${pageIndex}`}
      className={`pagination-button`}
      onClick={() => this.handleClick(pageIndex)}
    >
      …
    </button>
  )

  buildPages () {
    let { currentPage, maxPages, numPages } = this.state
    let { buttons } = this.props
    let currentStart = this.props.total === 0 ? 0 : currentPage * this.props.pageSize + 1
    let currentEnd = currentStart + this.props.pageSize - 1
    let pages = []
    let startPage, endPage
    let firstPageButton, lastPageButton

    if (currentPage < Math.floor(maxPages / 2) + 1) {
      startPage = 0
      endPage = maxPages
    } else if (currentPage + Math.ceil(maxPages / 2) - 1 >= numPages) {
      startPage = numPages - maxPages
      endPage = numPages
    } else {
      startPage = currentPage - Math.floor(maxPages / 2)
      endPage = currentPage + Math.ceil(maxPages / 2)
    }

    firstPageButton = (
      <button
        key={`page-first`}
        className={`pagination-button`}
        onClick={() => this.handleClick(0)}
      >
        {1}
      </button>
    )

    lastPageButton = (
      <button
        key={`page-last`}
        className={`pagination-button`}
        onClick={() => this.handleClick(this.state.numPages - 1)}
      >
        {this.state.numPages}
      </button>
    )

    if (buttons) {
      pages.push(
        <button
          key="page-prev"
          data-cy="page-prev"
          className={`pagination-button`}
          onClick={() => this.handleClick(currentPage - 1)}
          disabled={currentPage === 0}
        >
          {get(this.props, 'buttonText.previous', 'Previous')}
        </button>
      )
    }

    // add button for first page
    if (startPage !== 0 && numPages > maxPages) {
      pages.push(firstPageButton)
      if (startPage !== 1) {
        pages.push(this.ellipsisButton(startPage - 1))
      }
    }

    for (let i = startPage; i < endPage; i++) {
      if (i >= 0 && i < numPages) {
        pages.push(
          <button
            key={`page-${i}`}
            className={`pagination-button ${i === currentPage ? 'active' : ''}`}
            onClick={() => this.handleClick(i)}
          >
            {i + 1}
          </button>
        )
      }
    }

    // add button for last page
    if (endPage !== this.state.numPages && numPages > maxPages) {
      if (this.state.numPages !== endPage + 1) {
        pages.push(this.ellipsisButton(endPage))
      }
      pages.push(lastPageButton)
    }

    if (buttons) {
      pages.push(
        <button
          key="page-next"
          data-cy="page-next"
          className={`pagination-button`}
          onClick={() => this.handleClick(currentPage + 1)}
          disabled={currentPage === numPages - 1}
        >
          {get(this.props, 'buttonText.next', 'Next')}
        </button>
      )
    }

    return { currentStart, currentEnd, pages }
  }

  render () {
    if (this.props.display === false) {
      return null
    }
    let { currentStart, currentEnd, pages } = this.buildPages()
    let paginator = (
      <span>
        <span className="pagination-summary">
          Showing
          <span className="pagination-number dib">{numberOrDash(currentStart)}</span>
          to
          <span className="pagination-number dib">
            {currentEnd > this.props.total
              ? numberOrDash(this.props.total)
              : numberOrDash(currentEnd)}
          </span>
          of
          <span className="pagination-number dib">{numberOrDash(this.props.total)}</span>
          {this.props.itemName || 'entries'}
        </span>
        <span className="pagination-pages">{this.props.total ? pages : null}</span>
      </span>
    )

    return <div className="pagination">{paginator}</div>
  }
}

export default Pagination
