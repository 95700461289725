import React from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { get } from 'lodash'
import CharacterCount from 'components/common/character-count'

/*
// Example Usage:
<div>
  <Field
    required
    name="isPrimary"
    type="checkbox"
    label="Primary"
    component={ReduxFormCheckbox}
  />               ↖️ THIS COMPONENT
</div
 */

function ReduxFormCheckbox(props) {
  const {
    children,
    helperText,
    input,
    externalOnChange,
    label,
    maxCharacters,
    meta: { touched, error, warning },
    required,
    showCharacterCount,
    disabled,
    ...custom
  } = props

  const showError = !!(touched && error)
  const showWarning = !!(touched && warning)
  return (
    <FormControl error={showError} warning={warning} required={required} fullWidth>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            disabled={disabled}
            onChange={event => {
              externalOnChange && externalOnChange(event.target.checked)
              input.onChange(event.target.checked)
            }}
            checked={!!input.value}
            {...custom}
          />
        }
      />

      <div className="flex justify-between">
        <div className="flex flex-column">
          {showError && <FormHelperText error>{error}</FormHelperText>}
          {!showError && showWarning && (
            <FormHelperText warning={warning}>{warning}</FormHelperText>
          )}
          {!showError && !showWarning && helperText && (
            <FormHelperText>{helperText}</FormHelperText>
          )}
        </div>
        {showCharacterCount && (
          <CharacterCount
            // className={this.props.classes.}
            maxCharacters={maxCharacters}
            characterCount={get(input, 'value.length', 0)}
          />
        )}
      </div>
    </FormControl>
  )
}

export default ReduxFormCheckbox
