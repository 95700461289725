/*
 * Dashboard component
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { get, isEqual } from 'lodash'
import { Card, Icon, Typography } from '@material-ui/core'

import { actions as patientActions } from 'store/modules/patient'
import { actions as patientDataActions } from 'store/modules/patient-data'
import { actions as hashActions } from 'store/modules/url-hash'
import { actions as mdsActions } from 'store/modules/mds'
import { actions as medRecActions } from 'store/modules/medication-reconciliation'
import { actions as projectActions } from 'store/modules/project'
import { actions as orgActions } from 'store/modules/organizations'
import { actions as reservationsActions } from 'store/modules/reservations'
import { actions as reviewActions } from 'store/modules/review'
import { actions as schemaActions } from 'store/modules/schema'
import { actions as workflowActions } from 'store/modules/workflow'

import { MED_REC_V2 } from 'constants/features'
import { canAccessFeature } from 'services/auth'

import WebSocketComponent from 'components/common/web-socket'
import { tabManager } from 'components/common/tab-manager'
// modules
import tabDefaults from './tabs'

import './dashboard.scss'

import { DashboardContext } from 'contexts/dashboard'

// Our DashboardContext data
let contextData = {
  patient: null,
  patientData: null,
  // change from medRecData to unreconciledMedRec or something?
  medRecData: null,
  boxes: null
}

class Dashboard extends Component {
  constructor (props) {
    super(props)

    this.state = {
      patientId: get(props, 'computedMatch.params.patientId', null),
      medListV2TabEnabled: false
    }
  }

  getPatientData () {
    const { medRecActions, patientActions, patientDataActions, reservationsActions } = this.props
    patientActions.getPatient(this.state.patientId)
    patientDataActions.getPatientData(this.state.patientId, {
      medications: true,
      allergies: true,
      condition: true,
      notes: true,
      observations: true
    })
    patientDataActions.getLatestReviewType(this.state.patientId)
    patientDataActions.getPatientPGxData(this.state.patientId)
    medRecActions.getListData(this.state.patientId)
    medRecActions.getReconciledData(this.state.patientId)
    reservationsActions.getReservations([this.state.patientId])
  }

  tearDownData () {
    this.setState({
      ...this.state,
      patientId: null
    })
    this.props.patientActions.removePatient()
    this.props.patientDataActions.removePatientData()
    this.props.mdsActions.setScores(null)
    this.props.medRecActions.clearData()
    this.props.workflowActions.clear()
  }

  componentDidMount () {
    const { auth, eula, organizations, orgActions, tabManager } = this.props
    this.props.projectActions.getProjects()
    this.props.schemaActions.getReferralOptions()
    if (!(organizations.userOrgs && organizations.userOrgs.length)) {
      orgActions.getUserOrgs(auth.profile.organizations)
    }
    if (this.state.patientId && eula && eula.acknowledgedAndAgreed) {
      this.getPatientData(this.state.patientId)
    }
    contextData.boxes = tabManager.boxes
  }

  componentDidUpdate (prevProps, prevState) {
    const { tabManager, review, patientData } = this.props
    let prevPatientId = get(prevProps, 'computedMatch.params.patientId')
    let newPatientId = get(this.props, 'computedMatch.params.patientId')
    let prevEulaAccept = get(prevProps, 'eula.acknowledgedAndAgreed')
    let newEulaAccept = get(this.props, 'eula.acknowledgedAndAgreed')

    /*
    * Added to enable new Medication List V2 tab
    * Workaround and acknowledged hacky way to handle the re-render of tabs
    * Temporary solution to solve need for re-render of tabs after auth handleLogin fetches app permissions
    * In current architecture, tabs get rendered before profile settings are returned and stored
    */
    if (!this.state.medListV2TabEnabled && canAccessFeature(MED_REC_V2)) {
      tabManager.emitter.emit('enableTab', {
        box: 'medications',
        tab: { id: 'medication-list-v2' }
      })

      this.setState({
        ...this.state,
        medListV2TabEnabled: true
      })
    }

    if (prevPatientId !== newPatientId) {
      if (newPatientId) {
        tabManager.emitter.emit('changeTab', {
          box: 'profile',
          tab: { id: 'profile' }
        })
        this.getPatientData(newPatientId)
      } else {
        this.tearDownData()
      }
      this.setState({
        ...this.state,
        patientId: newPatientId
      })
    } else if (prevEulaAccept !== newEulaAccept && newPatientId) {
      this.getPatientData(newPatientId)
    }

    if (get(prevProps, 'patient.data') !== get(this.props, 'patient.data')) {
      this.props.projectActions.getProjectsForPatient(this.props.patient)
    }

    let latestReviewIdForScores

    if (
      this.props.patientData &&
      !isEqual(
        get(prevProps, 'patientData.medications'),
        get(this.props, 'patientData.medications')
      )
    ) {
      //should only EVER be one of these
      const draftReviewId = get(review, 'draftReviews[0]._id')
      // couch view that loads this orders them correctly
      // [0] will always be latest
      const latestReviewId = get(patientData, 'latestReview[0].id')
      if ( draftReviewId) {
        latestReviewIdForScores = draftReviewId
      } else {
        latestReviewIdForScores = latestReviewId
      }

      if (canAccessFeature('NEW_RISK_VIS')) {
        this.props.mdsActions.getMDSData({
          patient: this.props.patient.data,
          medications: this.props.patientData.medications,
          types: ['normalizedRiskScores', 'riskScoreDial', 'riskLevel'],
          pgx: this.props.patientData.pgxData,
          latestReviewId: latestReviewIdForScores
        })
      } else {
        this.props.mdsActions.getMDSData({
          patient: this.props.patient.data,
          medications: this.props.patientData.medications,
          types: ['normalizedRiskScores'],
          pgx: this.props.patientData.pgxData,
          latestReviewId: latestReviewIdForScores
        })
      }
    }
  }

  componentWillUnmount () {
    this.tearDownData()
  }

  render () {
    const { children, patient } = this.props
    let contents = <div className="dashboard">{children}</div>
    if (!patient.data && patient.error) {
      let message = (
        <Typography variant="subtitle1">
          An error occurred getting patient data, and the patient could not be loaded. You may not
          have access to this patient. <NavLink to="/dashboard">Return to the Dashboard</NavLink>
        </Typography>
      )

      if (
        get(patient, 'error.message') !== '[object Object]' &&
        get(patient, 'error.error.message') !== '[object Object]'
      ) {
        message = (
          <Typography variant="subtitle1">
            {get(patient, 'error.message') || get(patient, 'error.error.message')}
          </Typography>
        )
      }

      contents = (
        <div className="dashboard">
          <Card className="pa2 mh4 mh6-l">
            <div className="mv1 flex items-center">
              <Icon>warning</Icon>
              <Typography variant="h6" className="pl2">
                <strong>An error occurred</strong>
              </Typography>
            </div>
            <div className="mv3">{message}</div>
          </Card>
        </div>
      )
    }
    return (
      <DashboardContext.Provider value={contextData}>
        <WebSocketComponent url={process.env.REACT_APP_WORKFLOW_WEB_SOCKET_ENDPOINT} />
        {contents}
      </DashboardContext.Provider>
    )
  }
}

const mapStateToProps = ({
  eula,
  form,
  mds,
  note,
  organizations,
  patient,
  patientData,
  project,
  reservations,
  review,
  medRecData,
  urlHash,
  schema,
  workflow
}) => ({
  reduxForm: form,
  eula,
  mds,
  note,
  organizations,
  patient,
  patientData,
  project,
  reservations,
  review,
  medRecData,
  urlHash,
  schema,
  workflow
})

const mapDispatchToProps = dispatch => ({
  patientActions: {
    getPatient: id => dispatch(patientActions.getPatient(id)),
    updatePatient: data => dispatch(patientActions.updatePatient(data)),
    setPatient: patient => dispatch(patientActions.setPatient(patient)),
    removePatient: () => dispatch(patientActions.removePatient()),
    dispatchInteractionEvent: (callLog, user) =>
      dispatch(patientActions.dispatchInteractionEvent(callLog, user)),
    manualWorkflowChange: (workflowState, reason, reasonOther, newIteration) =>
      dispatch(
        patientActions.manualWorkflowChange(workflowState, reason, reasonOther, newIteration)
      ),
    updateWorkflowState: (patientId, wfs, orgId) =>
      dispatch(patientActions.updateWorkflowState(patientId, wfs, orgId)),
    movePatientFromStaged: () => dispatch(patientActions.movePatientFromStaged())
  },
  patientDataActions: {
    getPatientData: (patientId, options) =>
      dispatch(patientDataActions.getPatientData(patientId, options)),
    removePatientData: () => dispatch(patientDataActions.removePatientData()),
    updatePatientData: (data, objectName, verb) =>
      dispatch(patientDataActions.updatePatientData(data, objectName, verb)),
    deletePatientData: data => dispatch(patientDataActions.deletePatientData(data)),
    getLatestReviewType: patientId => {
      dispatch(patientDataActions.getLatestReviewType(patientId))
    },
    getPatientPGxData: patientId => dispatch(patientDataActions.getPatientPGxData(patientId))
  },
  hashActions: {
    getHash: () => dispatch(hashActions.getHash()),
    setHash: payload => dispatch(hashActions.setHash(payload))
  },
  mdsActions: {
    setScores: value => dispatch(mdsActions.setScores(value)),
    getSummary: input => dispatch(mdsActions.getSummary(input)),
    getVisualization: postData => dispatch(mdsActions.getVisualization(postData)),
    getMDSData: postData => dispatch(mdsActions.getMDSData(postData)),
    getLaunchId: postData => dispatch(mdsActions.getLaunchId(postData))
  },
  medRecActions: {
    getReconciledData: patientId => dispatch(medRecActions.getReconciledData(patientId)),
    getListData: patientId => dispatch(medRecActions.getListData(patientId)),
    clearData: () => dispatch(medRecActions.clearData())
  },
  projectActions: {
    getProjectsForPatient: patient => dispatch(projectActions.getProjectsForPatient(patient)),
    getProjects: () => dispatch(projectActions.getProjects())
  },
  reviewActions: {
    getDraftReviews: patient => dispatch(reviewActions.getDraftReviews(patient)),
    getPublishedReviews: patient => dispatch(reviewActions.getPublishedReviews(patient)),
    saveDraftReview: (review, sendForReviewStatus, showLoadingIndicator = true) =>
      dispatch(reviewActions.saveDraftReview(review, sendForReviewStatus, showLoadingIndicator)),
    savePublishedReview: review => dispatch(reviewActions.savePublishedReview(review)),
    saveReview: review => dispatch(reviewActions.saveReview(review)),
    getPatientData: (patientId, getAutoRecs) =>
      dispatch(reviewActions.getPatientData(patientId, getAutoRecs)),
    unpublishReview: review => dispatch(reviewActions.unpublishReview(review)),
    clearPublish: () => dispatch(reviewActions.clearPublish()),
    clearError: () => dispatch(reviewActions.clearError()),
    rejectAndReturnToEcpn: () => dispatch(reviewActions.rejectAndReturnToEcpn()),
    deleteDraftReview: review => dispatch(reviewActions.deleteDraftReview(review))
  },
  reservationsActions: {
    getReservations: async ids => dispatch(reservationsActions.getReservations(ids)),
    createReservation: async params => dispatch(reservationsActions.createReservation(params)),
    deleteReservation: async params => dispatch(reservationsActions.deleteReservation(params))
  },
  orgActions: {
    getUserOrgs: organizations => dispatch(orgActions.getUserOrgs(organizations))
  },
  schemaActions: {
    getReferralOptions: () => dispatch(schemaActions.getReferralOptions()),
    getNonMedicationRelatedProblems: () => dispatch(schemaActions.getNonMedicationRelatedProblems())
  },
  workflowActions: {
    updatePatientWorkflowState: (patientId, workflowState) =>
      dispatch(workflowActions.updatePatientWorkflowState(patientId, workflowState)),
    clear: () => dispatch(workflowActions.clear())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(tabManager(tabDefaults, Dashboard))
