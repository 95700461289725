import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import { FaTrash as TrashIcon } from 'react-icons/fa'
import { MdSave as SaveIcon } from 'react-icons/md'
import { debounce, find, get, isEqual, pick, map, set } from 'lodash'

import IdAM from 'components/common/idam'
import Table from 'components/common/table'
import Pagination from 'components/common/pagination'
import ReduxFormRadioGroup from 'components/common/redux-form/radio-group'
import ReduxFormSelect from 'components/common/redux-form/select'
import ReduxFormTextField from 'components/common/redux-form/text-field'

import MemberFilter from './search-filter'
import MemberSort from './search-sort'
import SearchPropertyPicker from './search-property-picker'
import { buildOrgList } from './build-orgs'
import { removeOrgPrefix } from 'services/utils'

import {
  VIEWING_MODE,
  PRIORITY_QUEUE_KEYS,
  INDEXED_PROPERTIES,
  mapSourceToTableData
} from 'constants/search'

export class PriorityQueueConfigurationForm extends Component {
  constructor (props) {
    super(props)

    this.ALL_USER_ORG_FILTERS = []
    this.state = {
      searchString: '',
      filters: [],
      queries: [],
      from: 0,
      page: 0,
      size: 10,
      dob: undefined,
      displayColumns: []
    }

    this.debouncedSearchPatients = debounce(
      () => this.props.searchPatients(this.buildSearchParams()),
      250,
      {
        trailing: true,
        max_wait: 10000
      }
    )

    // build default for all user orgs
    this.props.auth.profile.organizations.forEach(orgName => {
      this.ALL_USER_ORG_FILTERS.push({
        term: orgName,
        statType: 'org',
        queryType: 'term'
      })
    })
  }

  componentDidMount = props => {
    const { change } = this.props
    this.ALL_USER_ORG_FILTERS = buildOrgList(get(this.props, 'organizations.userOrgs'))
    const priorityQueueData = get(this.props, 'priorityQueue.data')
    const pqOrg = get(priorityQueueData, 'Organization.externalId')
      ? removeOrgPrefix(priorityQueueData.Organization.externalId)
      : null
    this.setState(
      {
        id: get(priorityQueueData, 'id'),
        // name: get(priorityQueueData, 'name'),
        userId: get(priorityQueueData, 'userId'),
        organizationId: get(priorityQueueData, 'organizationId'),
        role: get(priorityQueueData, 'role'),
        filters: get(priorityQueueData, 'filters', []).slice(0),
        sorting: get(priorityQueueData, 'sorting', []).slice(0),
        displayColumns: { ...get(priorityQueueData, 'displayColumns', {}) },
        secondaryFilters: { ...get(priorityQueueData, 'secondaryFilters', {}) },
        createdAt: get(priorityQueueData, 'createdAt'),
        updatedAt: get(priorityQueueData, 'updatedAt'),
        Organization: { ...get(priorityQueueData, 'Organization', {}) },
        organization: pqOrg,
        public: String(!!get(priorityQueueData, 'Organization', null)) // this is weird, but radios want text.,
      },

      this.debouncedSearchPatients
    )

    change('public', String(!!get(priorityQueueData, 'Organization', null)))
    change('Organization', get(priorityQueueData, 'Organization', null))
    change('name', get(priorityQueueData, 'name', null))
  }

  componentDidUpdate = prevProps => {
    if (
      !isEqual(get(prevProps, 'organizations.userOrgs'), get(this.props, 'organizations.userOrgs'))
    ) {
      this.ALL_USER_ORG_FILTERS = buildOrgList(get(this.props, 'organizations.userOrgs'))
    }
  }

  ensureOrganizationFilters = searchParams => {
    var hasOrgFilter = !!find(searchParams.filters, ['statType', 'org'])
    if (!hasOrgFilter) {
      searchParams.filters = searchParams.filters.concat(this.ALL_USER_ORG_FILTERS)
    }
    return searchParams
  }

  buildSearchParams = () => {
    const { filters, from, sorting } = this.state

    let searchParams = {
      queries: [],
      filters,
      sort: sorting,
      from
    }

    searchParams = this.ensureOrganizationFilters(searchParams)

    return searchParams
  }

  updateFilters = filters => this.setState({ filters, page: 0 }, this.debouncedSearchPatients)

  updateSorting = sorting => this.setState({ sorting, page: 0 }, this.debouncedSearchPatients)

  toggleProperty = (propertyMapStateKey, propertyKey) => {
    const propertyMap = get(this.state, propertyMapStateKey, {})
    if (propertyMap[propertyKey]) {
      delete propertyMap[propertyKey]
    } else {
      propertyMap[propertyKey] = true
    }

    this.setState({ [propertyMapStateKey]: propertyMap })
  }

  toggleSecondaryFilter = propertyKey => {
    this.toggleProperty('secondaryFilters', propertyKey)
  }

  toggleDisplayColumn = propertyKey => {
    this.toggleProperty('displayColumns', propertyKey)
  }

  handleNameChange = event => {
    event.stopPropagation()
    this.setState({
      name: event.target.value
    })
  }

  changeOrgAccess = value => {
    this.setState({
      public: value
    })
  }

  handleOrgAccessChange = value => {
    this.setState({
      organization: removeOrgPrefix(value._id)
    })
    this.debouncedSearchPatients()
  }

  isSaveDisabled = () => {
    if (this.state.public === 'true' && !this.state.organization) return true
    if (!this.state.public || this.state.public === 'false') return false

    const matchedOrgs = this.state.filters.filter(item => item.statType === 'org')

    if (!matchedOrgs.length) return true
    if (matchedOrgs.length === 1) {
      return !(matchedOrgs[0].term === this.state.organization)
    }
    if (matchedOrgs.length > 1) return false
  }

  createPriorityQueue = async () => {
    const { auth } = this.props
    // build priority queue with current user's userID
    const queue = pick(this.state, PRIORITY_QUEUE_KEYS)
    queue.name = get(this.props, 'queueName')
    queue.userId = get(auth, 'profile._id')
    const success = await this.props.createPriorityQueue(queue)
    success && this.props.updateMode(VIEWING_MODE)
  }

  updatePriorityQueue = async () => {
    const queue = pick(this.state, PRIORITY_QUEUE_KEYS)
    queue.name = get(this.props, 'queueName')
    const success = await this.props.updatePriorityQueue(queue)
    success && this.props.updateMode(VIEWING_MODE)
  }

  removePriorityQueue = async () => {
    const id = get(this.state, 'id')
    const success = await this.props.removePriorityQueue(id)
    success && this.props.updateMode(VIEWING_MODE)
  }

  handlePaginate = page => {
    this.setState(
      {
        from: this.state.size * page,
        page
      },
      this.debouncedSearchPatients
    )
  }

  render () {
    const { filters, page, size, sorting, displayColumns } = this.state
    const { organizations, project, queueName } = this.props

    const organizationOptions = organizations.userOrgs.map((o, i) => {
      return (
        <MenuItem key={`org${i}`} value={o}>
          {o.name}
        </MenuItem>
      )
    })

    // map INDEXED_PROPERTIES to build headers
    const headers = []
    map(INDEXED_PROPERTIES, (indexedProperty, propertyKey) => {
      displayColumns[propertyKey] &&
        headers.push({
          name: indexedProperty.label,
          maps: propertyKey
        })
    })

    // for data loop through displayColumns
    // [indexed_property key]: display
    const data = []
    const hits = get(this.props, 'patientSearch.hits')

    hits &&
      hits.forEach(({ _source }) => {
        data.push(
          mapSourceToTableData(
            displayColumns,
            INDEXED_PROPERTIES,
            _source,
            organizations.userOrgs,
            project.allProjects
          )
        )
      })

    const saveDisabled = this.isSaveDisabled() || !queueName

    return (
      <div className="priority-queue-configuration">
        <form autoComplete="off" className="flex flex-row justify-between items-center">
          <div className="w-100">
            <Field
              name="name"
              type="text"
              component={ReduxFormTextField}
              placeholder="Enter a Queue Name"
              required
            />
          </div>
        </form>
        <div className="mt3">
          <MemberFilter
            buildSearchParams={this.buildSearchParams}
            filters={filters}
            updateFilters={this.updateFilters}
            patientSearchAggregates={this.props.patientSearchAggregates}
            fetchAggregates={this.props.fetchAggregates}
            {...this.props}
          />
        </div>
        <div className="mt3">
          <SearchPropertyPicker
            label="Edit Secondary Filters"
            excludedProperties={{ name: true }}
            propertyMap={this.state.secondaryFilters}
            toggleProperty={this.toggleSecondaryFilter}
          />
        </div>
        <div className="mt3">
          <SearchPropertyPicker
            label="Edit Display Columns"
            propertyMap={this.state.displayColumns}
            toggleProperty={this.toggleDisplayColumn}
          />
        </div>
        <div className="mt3">
          <MemberSort updateSorting={this.updateSorting} sort={sorting} />
        </div>
        <IdAM access={{ roles: { $eq: 'user_admin' } }}>
          <div className="mt3 flex">
            <div className="w-20">
              <Typography variant="subtitle1" noWrap>
                Organization Access
              </Typography>
              <Field
                name="public"
                component={ReduxFormRadioGroup}
                onChange={this.changeOrgAccess.bind(this)}
              />
            </div>
            <div className={`w-20 ml-2 ${this.state.public === 'true' ? '' : 'dn'}`}>
              <Field
                required
                name="Organization"
                component={ReduxFormSelect}
                label="Organization"
                externalOnChange={this.handleOrgAccessChange.bind(this)}
                renderValue={val => val.name}
              >
                {organizationOptions}
              </Field>
            </div>
          </div>
        </IdAM>
        <div className="mt4 flex justify-between">
          <div>
            {this.state.id && (
              <Button
                className="w-120"
                onClick={this.removePriorityQueue}
                variant="contained"
                size="small"
              >
                <TrashIcon className="pr2" color="red" />
                {'Delete Priority Queue'}
              </Button>
            )}
          </div>
          <div className="flex-end">
            <Button
              onClick={() => this.props.updateMode(VIEWING_MODE)}
              variant="contained"
              size="small"
            >
              {'Cancel'}
            </Button>
            <span className="ml2">
              <Button
                disabled={saveDisabled}
                onClick={this.state.id ? this.updatePriorityQueue : this.createPriorityQueue}
                variant="contained"
                size="small"
                color="primary"
              >
                <SaveIcon className="pr2 white" />
                <span className="white" data-cy="save-priority-queue">
                  {`${this.state.id ? 'Update' : 'Save'} Priority Queue`}
                </span>
              </Button>
            </span>
          </div>
        </div>
        <div className="mt3">
          <Table headers={headers} data={data} rowKeyProp="_id" />
          <Pagination
            total={this.props.patientSearch.total}
            pageSize={size}
            onClick={this.handlePaginate}
            maxPages={5}
            buttons={true}
            page={page}
          />
        </div>
      </div>
    )
  }
}

let ReduxPriorityQueueConfigurationForm = reduxForm({
  form: 'priorityQueue',
  validate: queue => {
    var errors = {}
    if (!queue.name) {
      set(errors, 'name', 'Queue Name is Required')
    }
    return errors
  }
})(PriorityQueueConfigurationForm)

const selector = formValueSelector('priorityQueue')

ReduxPriorityQueueConfigurationForm = connect(state => {
  let queueName = selector(state, 'name')
  return { queueName }
})(ReduxPriorityQueueConfigurationForm)

export default ReduxPriorityQueueConfigurationForm
