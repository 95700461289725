import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import BadgedIcon from 'components/common/badged-icon'
import { FileCopyOutlined, KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons'

/**
 * RowActions component that wraps/binds row actions to an item so they can trigger external
 * functions and pass the item.
 */
class RowActions extends Component {
  render () {
    const {
      data,
      parentData,
      onEdit,
      onCopy,
      item,
      iteree,
      onDelete,
      onHistory,
      onNotesBadge,
      disableEdit,
      disableDelete,
      disableCopy,
      sortable,
      onRowSort
    } = this.props

    let disableUp = false
    let disableDown = false
    if (sortable && iteree === 0) disableUp = true
    if (sortable && iteree === data.length - 1) disableDown = true
    let sortingDOM = sortable && (
      <Fragment>
        <button
          type="button"
          title="Move Up"
          disabled={disableUp || disableEdit || (item && item.editable === false)}
          onClick={() => onRowSort('up', item, parentData || data)}
        >
          <KeyboardArrowUp />
        </button>
        <button
          type="button"
          title="Move Down"
          disabled={disableDown || disableEdit || (item && item.editable === false)}
          onClick={() => onRowSort('down', item, parentData || data)}
        >
          <KeyboardArrowDown />
        </button>
      </Fragment>
    )

    return (
      <td key={`col-actions`} className="actions pv1 ph1">
        <div className="row-actions">
          {sortingDOM}
          {onEdit && (
            <button
              type="button"
              title="Edit"
              onClick={() => onEdit(item)}
              disabled={disableEdit || (item && item.editable === false)}
            >
              <i className="material-icons">edit</i>
            </button>
          )}
          {onCopy && (
            <button type="button" title="Copy" disabled={disableCopy} onClick={() => onCopy(item)}>
              <FileCopyOutlined />
            </button>
          )}
          {onDelete && (
            <button
              type="button"
              title="Delete"
              disabled={disableDelete}
              onClick={() => onDelete(item)}
            >
              <i className="material-icons">delete</i>
            </button>
          )}
          {onHistory && (
            <button type="button" onClick={() => onHistory(item)} title="Show History">
              <i className="material-icons">history</i>
            </button>
          )}
          {onNotesBadge && <BadgedIcon icon="assignment" backgroundColor="red" badge="1" />}
        </div>
      </td>
    )
  }
}

RowActions.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  onCopy: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onHistory: PropTypes.func,
  onNotesBadge: PropTypes.func,
  disableEdit: PropTypes.bool,
  disableDelete: PropTypes.bool,
  disableCopy: PropTypes.bool,
  sortable: PropTypes.bool,
  onRowSort: PropTypes.func
}

export default RowActions
