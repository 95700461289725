
import React from 'react'
import MarkdownView from 'react-showdown'

export const StepperDocumentation = () => {
  const markdown = `

# **Stepper**

### Description
Stepper takes a number of Step components and renders them.  

1. If no steps are marked active, then the steps will be numbered in sequential order.
2. If a step is marked active, all steps before it will be marked complete.
3. If multiple steps are marked active, the stepper will only honor the last step marked as active.  All prior steps will be marked complete.
4. The active step will be highlighted.
5. Previous completed steps will be marked with a checkmark.

# **Step**

### Description
Steps require a \`title\` and \`description\`.  The active step should be marked with \`isActive: true.\`

### Props
* title         (required) - title of the step.
* description   (required) - description of the step.
* isActive      (optional) - whether the current step is active.
* isComplete    (optional) - whether the step is marked complete.
* number        (optional) - the specific number for the step.

### Example

For example code used to generate the stepper below, please refer to [Stepper](https://git.carekinesis.net/clinical/map-front-end/-/blob/master/medwise-advisor/src/components/gallery/stepper/index.js).
`
  return (
    <MarkdownView
      markdown={markdown}
      options={{ tables: true, emoji: true, ghCodeBlocks: true }}
    />
  )
}