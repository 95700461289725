import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import { TRHC_GREEN, TRHC_GREEN_25 } from 'constants/colors'
import { FaSort } from 'react-icons/fa'
import { MdClear } from 'react-icons/md'
import { kebabCase } from 'lodash'

import './styles/filter.scss'

// the onChange callback function will receive the following arguments: (optionSelected , actionType)
// optionSelected is the option object passed in the object array and can have extraneous properties besides `label` & `value`
// actionType is one of : | 'clear' | 'create-option' | 'deselect-option' | 'pop-value' | 'remove-value' | 'select-option' | 'set-value'
const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
    })
  )
}

// Note: Theme description: themes are documented but not yet released to npm published version
//        Once themes are released, we could replace use of the style object with theme description
//        Some styles are overridden with style object with a few still needed in filter.scss file
// https://github.com/JedWatson/react-select/issues/3006#issuecomment-418408988
// {
//   "borderRadius": 4,
//   "colors":  {
//     "danger": "#DE350B",
//     "dangerLight": "#FFBDAD",
//     "neutral0": "hsl(218, 0%, 100%)",
//     "neutral10": "hsl(218, 10%, 90%)",
//     "neutral20": "hsl(218, 15%, 80%)",
//     "neutral30": "hsl(218, 20%, 70%)",
//     "neutral40": "hsl(218, 25%, 60%)",
//     "neutral5": "hsl(218, 5%, 95%)",
//     "neutral50": "hsl(218, 30%, 50%)",
//     "neutral60": "hsl(218, 35%, 40%)",
//     "neutral70": "hsl(218, 40%, 30%)",
//     "neutral80": "hsl(218, 45%, 20%)",
//     "neutral90": "hsl(218, 50%, 10%)",
//     "primary": "#2684FF",
//     "primary25": "#DEEBFF",
//     "primary50": "#B2D4FF",
//     "primary75": "#4C9AFF",
//   },
//   "spacing": {
//     "baseUnit": 4,
//     "controlHeight": 38,
//     "menuGutter": 8,
//   },
// }

/* I THINK THIS IS HANDLED BY Material Theme now, but commenting out just in case
const reactSelectColors = {
  danger: '#DE350B',
  dangerLight: '#FFBDAD',
  neutral0: 'pink',
  neutral10: 'pink',
  neutral20: 'pink',
  neutral30: 'pink',
  neutral40: 'pink',
  neutral5: 'pink',
  neutral50: 'pink',
  neutral60: 'pink',
  neutral70: 'pink',
  neutral80: 'pink',
  neutral90: 'pink',
  primary: TRHC_GREEN,
  primary25: TRHC_GREEN_25,
  primary50: TRHC_GREEN_50,
  primary75: TRHC_GREEN_75
}
const reactSelectSpacing = {
  controlHeight: 500 // 25
}
*/

const IndicatorWrapper = IconComponent => props => {
  const {
    children = <IconComponent />,
    innerProps: { ref, ...restInnerProps }
  } = props
  return (
    <div {...restInnerProps} ref={ref}>
      <div style={{ padding: '0px 5px' }}>{children}</div>
    </div>
  )
}

const Option = props => (
  <div data-cy={`option-${kebabCase(props.label)}`}>
    <components.Option {...props} />
  </div>
)
class SimpleSelect extends Component {
  styles = {
    option: (base, state) => {
      return {
        ...base,
        backgroundColor:
          state.isSelected || state.isActive ? TRHC_GREEN : state.isFocused ? TRHC_GREEN_25 : '#fff'
      }
    }
  }

  render () {
    return (
      <div
        className={this.props.className}
        data-cy={`select-${kebabCase(this.props.cypressSuffix)}`}
      >
        <Select
          styles={this.styles}
          // theme={theme => ({ ...theme, colors: reactSelectColors, spacing: reactSelectSpacing })}
          className="react-select__container"
          classNamePrefix="react-select"
          value={this.props.value}
          isClearable
          onChange={this.props.onChange}
          options={this.props.options}
          placeholder={this.props.placeholder}
          components={{
            DropdownIndicator: IndicatorWrapper(FaSort),
            ClearIndicator: IndicatorWrapper(MdClear),
            IndicatorSeparator: () => null,
            Option
          }}
        />
      </div>
    )
  }
}

SimpleSelect.propTypes = propTypes

export default SimpleSelect
