import React, { Component, Fragment } from 'react'
import Button from '@material-ui/core/Button'
import { MdEdit as EditIcon } from 'react-icons/md'
import { debounce, differenceWith, filter, find, get, isEmpty, isEqual, map, sortBy } from 'lodash'

import {
  CREATE_NEW,
  EDITING_MODE,
  REACT_SELECT_CLEAR_ACTION,
  INDEXED_PROPERTIES,
  mapSourceToTableData
} from 'constants/search'

import Loading from 'components/common/loading'
import ReservationButton from 'components/common/reservation-button'
import Table from 'components/common/table'
import Pagination from 'components/common/pagination'
import SimpleSelect from 'components/common/simple-select'

import MemberFilter from './search-filter'
import MemberSort from './search-sort'
import { buildOrgList } from './build-orgs'

export const isSecondaryFilter = priorityQueue => filter =>
  get(priorityQueue, `secondaryFilters.${filter.statType}`, false)

export const filtersAreEqual = (filter1, filter2) =>
  get(filter1, 'statType') === get(filter2, 'statType') &&
  get(filter1, 'queryType') === get(filter2, 'queryType') &&
  get(filter1, 'term') === get(filter2, 'term') &&
  get(filter1, 'gte') === get(filter2, 'gte') &&
  get(filter1, 'lte') === get(filter2, 'lte')

export const buildPriorityQueueOption = queue => ({ label: get(queue, 'name'), value: queue })
export const buildGroupedPriorityQueueOptions = priorityQueues => {
  const listByOrg = get(priorityQueues, 'data.listByOrg', {})
  const listByUser = get(priorityQueues, 'data.listByUser', {})
  const groupedOrgOptions = sortBy(
    map(listByOrg, (orgQueues, orgName) => ({
      label: orgName,
      options: map(orgQueues, buildPriorityQueueOption)
    })),
    'label'
  )
  const groupedUserOptions = map(listByUser, (userQueues, orgName) => ({
    label: orgName,
    options: map(userQueues, buildPriorityQueueOption)
  }))
  return [
    {
      label: 'Queue Management',
      options: [{ label: 'Create New Priority Queue...', value: CREATE_NEW }]
    },
    ...groupedUserOptions,
    ...groupedOrgOptions
  ]
}

class PriorityQueueExplorer extends Component {
  constructor (props) {
    super(props)

    this.ALL_USER_ORG_FILTERS = []
    this.state = {
      queries: [],
      sort: [],
      from: 0,
      page: 0,
      size: 10,
      mutableFilters: [],
      immutableFilters: [],
      filtersAdded: [],
      filtersRemoved: [],
      reservationConflict: {
        isConflicted: false,
        conflictedPatientId: undefined
      }
    }

    const fetchReservations = true
    this.debouncedSearchPatients = debounce(
      () =>
        this.ALL_USER_ORG_FILTERS.length &&
        this.props.searchPatients(
          this.buildSearchParams(get(this.props, 'organizations.userOrgs')),
          fetchReservations
        ),
      250,
      {
        trailing: true,
        max_wait: 250
      }
    )

    // build default for all user orgs

    this.ALL_USER_ORG_FILTERS = buildOrgList(get(this.props, 'organizations.userOrgs'))
  }

  componentDidMount = async () => {
    this.ALL_USER_ORG_FILTERS = buildOrgList(get(this.props, 'organizations.userOrgs'))
    // get any existing priorityQueueSettings (added and removed filter state)
    this.props.initializePriorityQueueSettings()
    // make sure last viewed priorityQueue is selected
    const priorityQueues = await this.props.getPriorityQueues()
    let priorityQueueId = parseInt(localStorage.getItem('priorityQueueId'), 10)
    priorityQueueId = isNaN(priorityQueueId) ? undefined : priorityQueueId
    // if we have a queue, set it as selected
    // else select first queue in list
    if (priorityQueueId) {
      const lastActiveQueue = find(priorityQueues, { id: priorityQueueId })
      await this.props.setPriorityQueue(lastActiveQueue)
    } else if (get(priorityQueues, '0')) {
      await this.props.setPriorityQueue(priorityQueues[0])
    }
    if (!this.ALL_USER_ORG_FILTERS.length) return
    this.initializeNewQueue()
  }

  componentDidUpdate = prevProps => {
    const prevPriorityQueueId = get(prevProps, 'priorityQueue.data.id')
    const priorityQueueId = get(this.props, 'priorityQueue.data.id')

    if (prevPriorityQueueId !== priorityQueueId && this.ALL_USER_ORG_FILTERS.length) {
      this.initializeNewQueue()
    }

    if (
      !isEqual(get(prevProps, 'organizations.userOrgs'), get(this.props, 'organizations.userOrgs'))
    ) {
      this.ALL_USER_ORG_FILTERS = buildOrgList(get(this.props, 'organizations.userOrgs'))
      this.initializeNewQueue()
    }
  }

  initializeNewQueue = () => {
    // reset filters
    this.initializeFilters()
    // reset everything else
    this.setState(
      {
        queries: [],
        sort: get(this.props, 'priorityQueue.data.sorting', []),
        from: 0,
        page: 0,
        size: 10
      },
      this.debouncedSearchPatients
    )
  }

  ensureOrganizationFilters = (searchParams, orgs) => {
    var hasOrgFilter = !!find(searchParams.filters, ['statType', 'org'])
    if (!hasOrgFilter) {
      searchParams.filters = searchParams.filters.concat(buildOrgList(orgs))
    }
    return searchParams
  }

  buildSearchParams = orgs => {
    const { immutableFilters, mutableFilters, from, sort } = this.state

    let searchParams = {
      queries: [],
      filters: [...immutableFilters, ...mutableFilters],
      sort: [...sort],
      from
    }

    searchParams = this.ensureOrganizationFilters(searchParams, orgs)
    return searchParams
  }

  updateFilters = mutatedFilters => {
    this.onFilterChange(mutatedFilters)
    this.setState({ filters: mutatedFilters, page: 0 }, this.debouncedSearchPatients)
  }

  updateSorting = sort => this.setState({ sort, page: 0 }, this.debouncedSearchPatients)

  handlePriorityQueueSelection = async (selection, meta) => {
    if (meta.action === REACT_SELECT_CLEAR_ACTION) {
      await this.props.clearPriorityQueue()
    } else if (selection.value === CREATE_NEW) {
      this.props.clearPriorityQueue()
      this.editPriorityQueue()
    } else {
      await this.props.setPriorityQueue(selection.value)
    }
    return this.debouncedSearchPatients()
  }

  handleChange = name => event => {
    this.setState(
      {
        [name]: event.target.value,
        page: 0
      },
      this.debouncedSearchPatients
    )
  }

  editPriorityQueue = () => {
    this.props.updateMode(EDITING_MODE)
  }

  handlePaginate = page => {
    this.setState(
      {
        from: this.state.size * page,
        page
      },
      this.debouncedSearchPatients
    )
  }

  onFilterChange = (mutatedFilters, filtersToRemove) => {
    this.prepareFilters(mutatedFilters, filtersToRemove)
  }

  resetFilters = () => {
    this.initializeFilters(true)
  }

  initializeFilters = resetFilters => {
    const { priorityQueue, priorityQueueSettings } = this.props

    const allPrimaryFilters = get(priorityQueue, 'data.filters', [])
    const isSecondaryFilterForQueue = isSecondaryFilter(priorityQueue.data)
    let initialSecondaries = filter(allPrimaryFilters, isSecondaryFilterForQueue)

    if (resetFilters) {
      return this.onFilterChange(initialSecondaries)
    }

    const savedPriorityQueueSettings = priorityQueueSettings[get(priorityQueue, 'data.id')]
    // reconcile priorityQueueSettings
    // add the added filters
    //   - add to initialSecondaries
    // remove removed filters
    //   - we reference the original in onFiltersChange, so pass as second argument (filtersToRemove)
    if (savedPriorityQueueSettings) {
      initialSecondaries = initialSecondaries.concat(
        get(savedPriorityQueueSettings, 'filtersAdded', [])
      )
    }

    const filtersToRemove = get(savedPriorityQueueSettings, 'filtersRemoved')
    return this.onFilterChange(initialSecondaries, filtersToRemove)
  }

  prepareFilters = (mutatedFilters, filtersToRemove) => {
    const { priorityQueue, priorityQueueSettings } = this.props
    const allPrimaryFilters = get(priorityQueue, 'data.filters', [])

    const isSecondaryFilterForQueue = isSecondaryFilter(priorityQueue.data)

    const allFilters = [...allPrimaryFilters, ...mutatedFilters]

    let currentSecondaries = filter(mutatedFilters, isSecondaryFilterForQueue)
    // remove filters if filterToRemove were passed
    // (filtersRemoved from localStorage after switching priorityQueues)
    currentSecondaries = filtersToRemove
      ? differenceWith(currentSecondaries, filtersToRemove, filtersAreEqual)
      : currentSecondaries
    const initialPrimaryDefaults = filter(allFilters, filter => !isSecondaryFilterForQueue(filter))

    // these are secondaryDefaults (what is saved configuration)
    const initialSecondaryDefaults = filter(allPrimaryFilters, isSecondaryFilterForQueue)

    const filtersAdded = differenceWith(
      currentSecondaries,
      initialSecondaryDefaults,
      filtersAreEqual
    )
    const filtersRemoved = differenceWith(
      initialSecondaryDefaults,
      currentSecondaries,
      filtersAreEqual
    )

    const remainingSecondaryDefaults = differenceWith(
      initialSecondaryDefaults,
      filtersRemoved,
      filtersAreEqual
    )

    // build mutable and immutable filters
    // mutable = (default queue filters whose type is also secondary) (MINUS any of them that were removed) (PLUS any filters added)
    const mutableFilters = [...remainingSecondaryDefaults, ...filtersAdded]
    const immutableFilters = initialPrimaryDefaults

    this.props.updatePriorityQueueSettings({
      ...priorityQueueSettings,
      [get(priorityQueue, 'data.id')]: {
        filtersAdded,
        filtersRemoved
      }
    })

    this.setState(
      {
        currentSecondaries,
        initialSecondaryDefaults,
        filtersAdded,
        filtersRemoved,
        mutableFilters,
        immutableFilters
      },
      this.debouncedSearchPatients
    )
  }

  refetchReservations = (isConflicted = false, conflictedPatientId = undefined) => {
    // this will refetch reservations for current search results
    this.setState(
      {
        reservationConflict: { isConflicted, conflictedPatientId }
      },
      () => {
        const { getReservationsForSearchResults, patientSearch } = this.props
        getReservationsForSearchResults(patientSearch)
      }
    )
  }

  render () {
    const { page, size, sort, filtersAdded, filtersRemoved } = this.state
    const { auth, priorityQueues, priorityQueue, reservations, ...props } = this.props

    const priorityQueueData = get(priorityQueue, 'data')
    const mutableFilterKeys = get(priorityQueueData, 'secondaryFilters')

    const displayColumns = get(priorityQueueData, 'displayColumns', {})

    // map INDEXED_PROPERTIES to build headers
    const headers = []
    map(INDEXED_PROPERTIES, (indexedProperty, propertyKey) => {
      displayColumns[propertyKey] &&
        headers.push({
          name: indexedProperty.label,
          maps: propertyKey
        })
    })

    // for data loop through displayColumns
    // [indexed_property key]: display
    const data = []
    const hits = get(this.props, 'patientSearch.hits')

    hits &&
      hits.forEach(({ _source }) => {
        data.push(
          mapSourceToTableData(
            displayColumns,
            INDEXED_PROPERTIES,
            _source,
            get(this.props, 'organizations.userOrgs'),
            get(this.props, 'project.allProjects')
          )
        )
      })

    const reservationButton = {
      name: 'Reservation',
      maps: [
        {
          propName: 'patient',
          rowItem: true
        }
      ],
      component: mappedProps => {
        const { patient } = mappedProps
        const { createReservation, deleteReservation } = this.props
        const {
          reservationConflict: { isConflicted, conflictedPatientId }
        } = this.state
        const { refetchReservations } = this
        const patientId = get(patient, 'id')
        return (
          <td key={`col-actions`} className="pv1 ph1">
            <ReservationButton
              auth={auth}
              reservationsStatus={get(reservations, `status`)}
              reservation={get(reservations, `data.${patientId}`)}
              patientId={patientId}
              createReservation={createReservation}
              deleteReservation={deleteReservation}
              onUpdate={refetchReservations}
              reservationConflict={isConflicted && conflictedPatientId === patientId}
            />
          </td>
        )
      }
    }

    headers.push(reservationButton)

    return (
      <div className="priority-queue">
        <form noValidate autoComplete="off" className="flex flex-row justify-between items-center">
          <div className="flex items-center w-100">
            {priorityQueues && (
              <div className="w-100">
                <SimpleSelect
                  cypressSuffix="priority-queue"
                  onChange={this.handlePriorityQueueSelection}
                  options={buildGroupedPriorityQueueOptions(priorityQueues)}
                  placeholder=""
                  value={
                    !isEmpty(priorityQueue)
                      ? { label: get(priorityQueueData, 'name'), value: priorityQueueData }
                      : undefined
                  }
                />
              </div>
            )}

            {priorityQueues && get(priorityQueueData, 'userId') === get(auth, 'profile._id') && (
              <div className="mr2">
                <Button onClick={this.editPriorityQueue} variant="contained" size="small">
                  <div className="flex items-center nowrap">
                    <EditIcon className="pr2" />
                    {'Edit Priority Queue'}
                  </div>
                </Button>
              </div>
            )}

            {(!isEmpty(filtersAdded) || !isEmpty(filtersRemoved)) && (
              <div>
                <Button
                  className="w-100"
                  onClick={this.resetFilters}
                  variant="contained"
                  size="small"
                >
                  <div className="nowrap">{'Reset Filters'}</div>
                </Button>
              </div>
            )}
          </div>
        </form>
        {isEmpty(get(priorityQueues, 'data.list')) ? (
          <div className="pa2">
            <Loading text="Loading Priority Queue Settings..." />
          </div>
        ) : (
          !isEmpty(priorityQueueData) && (
            <Fragment>
              <div className="mt3">
                <MemberFilter
                  auth={this.props.auth}
                  buildSearchParams={this.buildSearchParams}
                  filters={this.state.mutableFilters}
                  filtersToShow={mutableFilterKeys}
                  updateFilters={this.updateFilters}
                  patientSearchAggregates={this.props.patientSearchAggregates}
                  fetchAggregates={this.props.fetchAggregates}
                  {...props}
                />
              </div>

              <div className="mt3">
                <MemberSort updateSorting={this.updateSorting} sort={sort} />
              </div>

              <div className="mt3">
                <Table
                  headers={headers}
                  data={data}
                  loading={!this.ALL_USER_ORG_FILTERS.length || this.props.patientSearch.loading}
                  loadingMessage="Loading Priority Queue..."
                  rowKeyProp="id"
                />
                <Pagination
                  total={this.props.patientSearch.total}
                  pageSize={size}
                  onClick={this.handlePaginate}
                  maxPages={5}
                  buttons={true}
                  page={page}
                />
              </div>
            </Fragment>
          )
        )}
      </div>
    )
  }
}

export default PriorityQueueExplorer
