/*
 * Patient Data reducer
 */

import * as C from './types'

const GETTING = 'GETTING'
const UPDATING = 'UPDATING'
const SUCCESS = 'SUCCESS'
const ERROR = 'ERROR'

const initialState = {
  status: null,
  loading: false,
  error: null
}

const patientReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
  case C.REQUEST:
    return {
      ...state,
      loading: true,
      status: action.status,
      error: null
    }
  case C.FAILURE:
    return {
      ...state,
      loading: false,
      status: action.status,
      error: action.error
    }
  case C.GETTING_PATIENT_DATA:
    return {
      ...state,
      status: GETTING
    }
  case C.UPDATE_PATIENT_DATA_REQUEST:
    return {
      ...state,
      status: UPDATING
    }
  case C.LATEST_REVIEW_BY_TYPE:
    return {
      ...state,
      latestReview: action.payload
    }
  case C.GETTING_PGX_DATA:
    return {
      ...state,
      pgxData: action.payload
    }
  case C.UPDATE_PATIENT_DATA_SUCCESS:
    return { ...action.payload, status: SUCCESS }
  case C.SET_PATIENT_DATA:
    return { ...state, ...action.payload, status: SUCCESS }
  case C.REMOVE_PATIENT_DATA:
    return initialState
  case C.CLEAR_PATIENT_DATA:
    var currentState = { ...state }
    delete currentState[action.payload]
    return {
      ...currentState
    }
  case C.DELETE_PATIENT_DATA_REQUEST:
    return state
  case C.UPDATE_PATIENT_DATA_FAILURE:
    return {
      ...state,
      status: ERROR
    }
  default:
    return state
  }
}

export default patientReducer
