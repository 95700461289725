
import React from 'react'

import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded'
import PriorityHighRoundedIcon from '@material-ui/icons/PriorityHighRounded';
import ChangeHistoryRoundedIcon from '@material-ui/icons/ChangeHistoryRounded'
import AddRoundedIcon from '@material-ui/icons/AddRounded'

import { MEDREC_MEDICATION_STATUS } from 'constants/medications'

export const getIconForMedStatus = status => {
  const COLORS = {
    VERIFIED: {
      FILL: '#7EBA00' // green
    },
    ADDED: {
      FILL: '#47ADA4' // teal
    },
    MODIFIED: {
      FILL: '#2388FF' // blue
    },
    WARNING: {
      FILL: '#F9A36C' // orange
    },
    DISCONTINUED: {
      FILL: '#D85A65' // red
    }
  }

  const applyIconOutline = 'iconOutline'
  switch (status) {
  case MEDREC_MEDICATION_STATUS.VERIFIED:
    return <CheckRoundedIcon 
      style={{background: COLORS.VERIFIED.FILL}}
      className={applyIconOutline}
    />
  case MEDREC_MEDICATION_STATUS.WARNING:
    return <PriorityHighRoundedIcon 
      style={{background: COLORS.WARNING.FILL}}
      className={applyIconOutline}
    />
  case MEDREC_MEDICATION_STATUS.DISCONTINUED:
    return <RemoveRoundedIcon 
      style={{background: COLORS.DISCONTINUED.FILL}}
      className={applyIconOutline}
    /> 
  case MEDREC_MEDICATION_STATUS.MODIFIED:
    return <ChangeHistoryRoundedIcon
      style={{background: COLORS.MODIFIED.FILL}} 
      className={applyIconOutline}
    />
  case MEDREC_MEDICATION_STATUS.ADDED:
    return <AddRoundedIcon 
      style={{background: COLORS.ADDED.FILL}}
      className={applyIconOutline}
    />
  default:
    return null
  }
}
