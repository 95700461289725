import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import IdleTimer from 'react-idle-timer'

import * as AuthService from 'services/auth'
import log from 'services/logger'

import AlertDialog from 'components/common/alert-dialog'
const lastActiveKey = 'last-active'

/**
 * Wrapper for https://www.npmjs.com/package/react-idle-timer
 *
 * @param {Object} onLogout The method to handle logout
 * @param {Number} warningTime The number of milliseconds to wait before a warning is shown
 * @param {Number} logoutTime The number of milliseconds to wait AFTER the warning before being logged out
 */

export default class Idle extends Component {
  constructor (props) {
    super(props)

    this.idleTimer = null
    this.logoutTimer = null
    this.state = {
      alertOpen: false
    }
    this.handleStorageUpdate = this.handleStorageUpdate.bind(this)
    this.handleIdleLogout = this.handleIdleLogout.bind(this)
    this.setAlertOpen = this.setAlertOpen.bind(this)
  }

  static propTypes = {
    onLogout: PropTypes.func.isRequired,
    warningTime: PropTypes.number.isRequired,
    logoutTime: PropTypes.number.isRequired
  }

  handleIdle(e) {
    if (AuthService.loggedIn()) {
      const {logoutTime} = this.props
      this.setAlertOpen(true)
      this.logoutTimer = setTimeout(this.handleIdleLogout, logoutTime)
    }
  }

  handleActive() {
    clearTimeout(this.logoutTimer)
  }

  handleIdleLogout() {
      const { onLogout } = this.props
      this.setAlertOpen(false)
      log('idle logout', new Error('idle logout'), true)
      window.localStorage.removeItem(lastActiveKey)
      onLogout()
  }

  handleAction(lastActiveTime) {
    this.setAlertOpen(false)
    window.localStorage.setItem(lastActiveKey, this.idleTimer.getLastActiveTime())
  }

  setAlertOpen(isOpen) {
    this.setState({
      alertOpen: isOpen
    })
  }

  handleStorageUpdate(e) {
    if (e.key === lastActiveKey) {
      this.setAlertOpen(false)
      this.idleTimer.reset()
      clearTimeout(this.logoutTimer)
    }
  }

  componentDidMount() {
    window.addEventListener('storage', this.handleStorageUpdate)
    window.localStorage.setItem(lastActiveKey, this.idleTimer.getLastActiveTime())
  }

  render() {
    const {warningTime, logoutTime} = this.props
    return (
      <Fragment>
        <AlertDialog
          open={this.state.alertOpen}
          title={'Idle Warning Timeout'}
          text={`You have been inactive for a period of time. If you do not return to your computer in ${logoutTime /
          1000} seconds, you will be logged out of the application.`}
        />
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref
          }}
          element={document}
          onIdle={this.handleIdle.bind(this)}
          onAction={this.handleAction.bind(this)}
          onActive={this.handleActive.bind(this)}
          throttle={250}
          timeout={warningTime}
        />

      </Fragment>
    )
  }
}
