/*
 * App component
 * Set up the main stage for authentication
 * and accessing the dashboard
 */
import React, { Component } from 'react'
import { Switch, Route } from 'react-router'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { get as _get } from 'lodash'

import { Dashboard as DashboardIcon, ImportExport as ImportExportIcon } from '@material-ui/icons'

import { authActions } from 'store/modules/auth'
import { actions as notifyActions } from 'store/modules/notify'
import { actions as menuActions } from 'store/modules/main-menu'

import Auth from 'components/common/auth'
import Header from 'components/common/header'
import Footer from 'components/common/footer'
import Login from 'components/common/login'
import Notifications from 'components/common/notifications'
import DummyPage from 'components/common/dummy-page'
import PrivateRoute from 'components/common/private-route'
import Dashboard from 'components/dashboard'
import PatientImport from 'components/patient-import'
import SearchDashboard from 'components/search'
import MainMenu from 'components/common/main-menu'
import Privacy from 'components/common/privacy'
import ErrorBoundary from 'components/common/error-boundary'
import Idle from 'components/common/idle'
import ComponentGallery from 'components/gallery'

import * as AuthService from 'services/auth'
import { canAccess } from 'services/idam'

class App extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    authActions: PropTypes.shape({
      loginError: PropTypes.func.isRequired,
      loginSuccess: PropTypes.func.isRequired
    })
  }

  componentDidMount () {
    const { menuActions } = this.props

    menuActions.setMenuItems([
      {
        name: 'root',
        items: [
          {
            name: 'dashboard',
            text: 'Dashboard',
            icon: <DashboardIcon />,
            link: '/dashboard'
          },
          {
            name: 'patient-import',
            text: 'Add Patient',
            icon: <ImportExportIcon />,
            link: '/dashboard/patient-import',
            idam: {
              access: profile => {
                if (
                  _get(profile, 'organizations').indexOf('ECPN') > -1 &&
                  _get(profile, 'organizations').indexOf('EMTM') === -1
                ) {
                  return false
                }
                return true
              }
            }
          }
        ]
      }
    ])
  }

  handleLogout () {
    const { history, notifyActions } = this.props
    AuthService.setRedirect(history.location.pathname)
    this.props.authActions.logoutSuccess()
    AuthService.logout(true)
    history.push({ pathname: '/' })
    notifyActions.addNotification({
      message: `You were logged out due to inactivity`,
      sticky: true
    })
  }

  shouldComponentUpdate (nextProps, nextState) {
    // prevent the component from refreshing the login window on failure.
    if (!this.props.auth.error && nextProps.auth.error) {
      return false
    }

    return true
  }

  render () {
    /**
     * Let timeouts based on your .env
     */
    const timeouts = {
      warning: Number(process.env.REACT_APP_LOGOUT_TIMEOUT),
      logout: 60000
    }
    const { auth } = this.props
    return (
      <div className="mwa">
        <ErrorBoundary>
          <Auth {...this.props} />
          <Notifications notify={this.props.notify} notifyActions={this.props.notifyActions} />
          <MainMenu />
          <Header title={
            <img alt="MedWise" className="logo"
              src={process.env.REACT_APP_HEADER_LOGO || '/images/medwise_white.svg'}/>
          } {...this.props} />
          <div className="content-wrapper">
            <Switch>
              <PrivateRoute exact path="/dashboard" component={SearchDashboard} {...this.props} />
              <PrivateRoute
                path="/dashboard/patient/:patientId"
                component={Dashboard}
                {...this.props}
              />
              <PrivateRoute
                path="/dashboard/patient/:patientId/:hashPayload"
                component={Dashboard}
                {...this.props}
              />
              {
                canAccess(auth, true, {permissions: {$eq: ['medwise-advisor.edit-patient']}}) ?
                  <PrivateRoute
                    path="/dashboard/patient-import"
                    component={PatientImport}
                    {...this.props}
                  /> : null
              }
              <Route
                exact
                path="/"
                component={() => (
                  <Login authActions={this.props.authActions} />
                )}
              />
              <Route exact path="/contact" component={DummyPage} />
              <Route exact path="/terms" component={DummyPage} />
              <Route exact path="/legal" component={DummyPage} />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/components" component={ComponentGallery} />
            </Switch>
          </div>
          <Footer {...this.props} />
          <Idle
            warningTime={timeouts.warning}
            logoutTime={timeouts.logout}
            onLogout={this.handleLogout.bind(this)}
          />
        </ErrorBoundary>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, menu, notify, patient }) => ({
  auth,
  menu,
  notify,
  patient
})

const mapDispatchToProps = dispatch => ({
  authActions: {
    loginRequest: () => dispatch(authActions.loginRequest()),
    loginSuccess: profile => dispatch(authActions.loginSuccess(profile)),
    loginError: error => dispatch(authActions.loginError(error)),
    logoutSuccess: () => dispatch(authActions.logoutSuccess())
  },
  menuActions: {
    setMenuItems: items => dispatch(menuActions.setMenuItems(items))
  },
  notifyActions: {
    addNotification: (payload, errorToLog, sendToSentry) =>
      dispatch(notifyActions.addNotification(payload, errorToLog, sendToSentry)),
    removeNotification: id => dispatch(notifyActions.removeNotification(id)),
    clearNotifications: () => dispatch(notifyActions.clearNotifications())
  }
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
)
