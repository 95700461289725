import React from 'react'
import { Link } from 'react-router-dom'
import { push } from 'connected-react-router'
import { Form, Field, reduxForm } from 'redux-form'
import { get as _get, capitalize as _capitalize } from 'lodash'
import dayjs from 'dayjs'
import MenuItem from '@material-ui/core/MenuItem'
import { FaExclamationTriangle } from 'react-icons/fa'

import ContextButton from 'components/common/context-button'
import StandardTab from 'components/common/standard-tab'
import { DataItem, AddressItem, PhoneItem } from 'components/common/data-item'
import ReduxFormText from 'components/common/redux-form/text'
import ReduxFormSelect from 'components/common/redux-form/select'
import ReduxFormRadioGroup from 'components/common/redux-form/radio-group'

import { childOrganizations } from 'services/organizations'

import ProfileForm from 'components/dashboard/modules/profile/form'
import './patient-import.scss'
import { removeOrgPrefix } from 'services/utils'
import { DateFormat } from 'constants/date'

import {
  patientFormFields,
  initializeFormValuesFromModel
} from 'components/dashboard/modules/profile'

class ImportForm extends React.Component {
  render () {
    const { handleSubmit, handleImport, patient } = this.props

    return (
      <Form name="patientImport" onSubmit={handleSubmit}>
        <div>
          <p>Search by name or Patient ID</p>
          <div className="flex">
            <div className="w-50 pr1">
              <Field
                name="firstName"
                type="text"
                label="First Name"
                component={ReduxFormText}
                fullWidth={true}
              />
            </div>
            <div className=" w-50 pl1">
              <Field
                name="lastName"
                type="text"
                label="Last Name"
                component={ReduxFormText}
                fullWidth={true}
              />
            </div>
          </div>
          <div className="pt3 tc b">- OR -</div>
          <div>
            <Field
              name="externalId"
              type="text"
              label="Enter the Patient ID"
              component={ReduxFormText}
              fullWidth={false}
              className="w-100"
            />
          </div>
          <div className="pt3">
            <ContextButton
              type="submit"
              text="Search for Patients"
              className="primary db w-100"
              {...this.props}
            />
          </div>
        </div>
        <div className={_get(patient, 'patientPreview.length') ? 'pa 2' : null}>
          {_get(patient, 'patientPreview.length') ? (
            <Field
              name="selectMatch"
              component={ReduxFormRadioGroup}
              radioLabelsAndValues={(_get(patient, 'patientPreview', []) || []).map(preview => {
                return { value: preview.externalId, label: <MemberPreviewCard info={preview} /> }
              })}
            />
          ) : (
            patient.patientPreview && (
              <p className="pv3">
                No patients match the information entered. Reconfirm patient details or add the
                patient manually as a non-EMTM patient.
              </p>
            )
          )}
          {_get(this.props.reduxForm, 'patientImport.values.selectMatch') ? (
            <EligibilityDisclaimer {...this.props} />
          ) : null}
        </div>
        <div className="pt2">
          <ExportPharmacy {...this.props} />
          {_get(this.props, 'reduxForm.patientImport.values.selectMatch') &&
          _get(this.props, 'patient.patientPreview') != null &&
          _get(this.props, 'patient.patientPreview', []).length > 0 ? (
              <ContextButton
                text="Import"
                type="button"
                className="primary"
                disabled={patient.loading}
                onClick={handleImport}
                {...this.props}
              />
            ) : null}
        </div>
      </Form>
    )
  }
}

const ReduxImportForm = reduxForm({
  form: 'patientImport',
  initialValues: {
    externalId: null,
    firstName: null,
    lastName: null,
    exportPharmacy: null
  },
  validate (patient) {
    const errors = {}
    if (!patient.externalId) {
      if (!patient.firstName || !patient.lastName) {
        if (!patient.firstName) {
          errors.firstName = 'First name is required without Patient ID'
        }

        if (!patient.lastName) {
          errors.lastName = 'Last name is required without Patient ID'
        }
      }
    }
    return errors
  }
})(ImportForm)

class MemberPreviewCard extends React.Component {
  render () {
    let { info: member } = this.props
    let {
      name,
      externalId,
      address,
      dob,
      sex,
      telecom,
      managingOrganization,
      currentWorkflowState,
      workflowIteration
    } = member

    let age = dob ? dayjs().diff(dob, 'years') : null

    // Only show the MemberPreviewCard if at least this much data is present
    if (name && externalId) {
      return (
        <div>
          <main className="center">
            <div className="fl pt3 w-100">
              <DataItem
                className="f4"
                value={`${_get(name, 'first')} ${_get(name, 'middle')} ${_get(name, 'last')}`}
              />
            </div>
            <div className="fl w-100 w-third-ns pv3 pl2">
              <DataItem valueClass="pb1 gray" label="Patient ID:" value={`${externalId}`} />
              <DataItem
                valueClass="pb1 gray"
                label="Date of Birth:"
                value={dob ? `${dayjs(dob).format(DateFormat.DATE_DISPLAY)} (${age})` : null}
              />
              <DataItem label="Sex:" value={_capitalize(sex)} />
            </div>
            <div className="fl w-100 w-third-ns pv3 pl2">
              <DataItem
                valueClass="pb1 gray"
                label="Organization:"
                value={
                  _get(managingOrganization, 'primary')
                    ? removeOrgPrefix(managingOrganization.primary)
                    : removeOrgPrefix(managingOrganization.id)
                }
              />
              <DataItem
                valueClass="pb1 gray"
                label="Status:"
                value={_get(currentWorkflowState, 'name')}
              />
              <DataItem
                labelClass="dtc pb1 pr3 fw6"
                valueClass="dtc pb1 gray"
                label="Year:"
                value={`${currentWorkflowState.year ? currentWorkflowState.year : 1}`}
              />
              <DataItem
                labelClass="dtc pb1 pr3 fw6"
                valueClass="dtc pb1 gray"
                label="Engagement:"
                value={`${workflowIteration || 1}`}
              />
            </div>
            <div className="fl w-100 w-third-ns pv3 pl2">
              <AddressItem valueClass="pb1 gray" label="Address:" address={address} />
              <PhoneItem
                valueClass="pb1 gray"
                label="Phone Number:"
                value={_get(telecom, 'phone')}
              />
            </div>
          </main>
        </div>
      )
    } else {
      // This prevents the preview card from displaying with undefined/null values
      return null
    }
  }
}

class EligibilityDisclaimer extends React.Component {
  render () {
    const { reduxForm, patient } = this.props
    const selectedPatient =
      _get(patient, 'patientPreview') != null
        ? _get(patient, 'patientPreview').find(
          p => p.externalId === _get(reduxForm, 'patientImport.values.selectMatch')
        )
        : false
    if (!selectedPatient) return null

    let isEligible = selectedPatient.isEligible
    let hasBeenExported = selectedPatient.exported
    let exportedPatientId = _get(selectedPatient, 'export_subject')
    const exportMsg = () => {
      let msg
      if (hasBeenExported && exportedPatientId) {
        msg = (
          <p className="mt2">
            This patient exists within the system.{' '}
            <Link to={`/dashboard/patient/${selectedPatient.export_subject}`}>Click here</Link> to
            view their profile.
          </p>
        )
      } else if (hasBeenExported) {
        msg = (
          <p className="mt2">
            <FaExclamationTriangle className="mr1 yellow" />
            This patient may already exist in the MedWise platform. Please search for them via the
            Dashboard before proceeding with import.
          </p>
        )
      }
      return msg
    }

    const eligMsg = () => {
      let eligMsg
      if (isEligible === true) {
        eligMsg = (
          <div>
            <p>The patient is eligible for inclusion in the program.</p>
            <ul>
              <li>Click the button to import all patient data and medications.</li>
            </ul>
          </div>
        )
      } else if (isEligible === false) {
        eligMsg = (
          <div>
            <p>The patient is not currently eligible for inclusion in the program.</p>
            <ul>
              <li>You can still click the button to import all patient data and medications.</li>
              <li>
                Eligibility should be updated by ClearStone Solutions before proceeding with the
                Initiating Interaction.
              </li>
            </ul>
          </div>
        )
      } else {
        // essentially my null condition.
      }
      return eligMsg
    }

    return (
      <div className="pt2">
        {exportMsg()}
        {eligMsg()}
      </div>
    )
  }
}

class ExportPharmacy extends React.Component {
  render () {
    const { reduxForm, patient, organizations } = this.props
    const selectedPatient = (_get(patient, 'patientPreview', []) || []).find(
      p => _get(p, 'externalId', []) === _get(reduxForm, 'patientImport.values.selectMatch')
    )

    if (!selectedPatient || _get(selectedPatient, 'exported')) {
      return null
    }

    if (!_get(selectedPatient, 'isEligible')) return null

    const orgList = [organizations.allOrgs.find(o => o._id === 'org:EMTM')].concat(
      childOrganizations(organizations.allOrgs, 'org:EMTM')
    )

    return (
      <div className="pb2">
        <Field
          required
          name="exportPharmacy"
          component={ReduxFormSelect}
          placeholder="Select a pharmacy to export this patient to"
          label="Export to..."
          className="pb2"
        >
          {orgList.map(option => (
            <MenuItem key={option._id} value={option}>
              {option.name}
            </MenuItem>
          ))}
        </Field>
      </div>
    )
  }
}

export default class Display extends React.Component {
  constructor (props) {
    super(props)
    this.standardTab = React.createRef()
  }

  DEFAULT_STRUCTURE = initializeFormValuesFromModel(
    {
      languagePreference: 'English'
    },
    patientFormFields
  )

  async formHandler (data) {
    const { notifyActions, auth } = this.props
    const addOrgDetailsToSelectedOrg = data => {
      const allOrgs = _get(this.props, 'organizations.allOrgs')
      const selectedOrg = _get(data, 'managingOrganization.id')
      const orgWithDetails = allOrgs.filter(org => {
        return org._id === selectedOrg
      })
      return orgWithDetails[0]
    }
    
    const selectedOrgWithDetails = addOrgDetailsToSelectedOrg(data)
    this.props.patientActions.saveNew(data, selectedOrgWithDetails, auth)
    notifyActions.addNotification({
      message: 'Patient has been sent for import, you will be redirected shortly',
      type: 'info'
    })
  }

  componentDidMount () {
    this.props.schemaActions.getReferralOptions()
  }

  handleSearch = form => {
    const { externalId, firstName, lastName } = form
    if (!_get(externalId, 'length')) {
      var key = `${lastName.trim()}${firstName.trim()}`.toLowerCase()
      this.props.patientActions.eligibleSearch({ key: key })
    } else {
      this.props.patientActions.getRiskStratPatient(externalId)
    }
  }

  handleImport = (...args) => {
    const { patientActions, reduxForm, notifyActions } = this.props
    patientActions.importPatient(reduxForm.patientImport.values.selectMatch, reduxForm.patientImport.values.exportPharmacy)
    notifyActions.addNotification({
      message: 'Patient has been sent for import, you will be redirected shortly',
      type: 'info'
    })
  }

  componentDidUpdate (prevProps) {
    const { patientActions, patient, dispatch } = this.props
    if (
      !_get(prevProps, 'patient.importedPatient') &&
      _get(this.props, 'patient.importedPatient')
    ) {
      patientActions.removePatient()
      dispatch(push(`/dashboard/patient/${patient.importedPatient.mapPatientId}`))
    }
  }

  render () {
    const { notifyActions, organizations, project, schema, reduxForm, referrals, ...props } = this.props
    const formProps = {
      organizations,
      project,
      reduxForm,
      referrals,
      schema,
      notifyActions
    }

    const contextButtons = [
      {
        name: `Add Manually (for non-EMTM patients)`,
        component: () => {
          return (
            <ContextButton
              text="Add Manually (for non-EMTM patients)"
              className="primary"
              data-cy="add-manual-btn"
              onClick={() => {
                this.standardTab.current.handleAdd()
              }}
              {...this.props}
            />
          )
        }
      }
    ]

    return (
      <StandardTab
        ref={this.standardTab}
        name="Patient"
        object={'profile'}
        form={ProfileForm}
        initialValues={this.DEFAULT_STRUCTURE}
        formHandler={this.formHandler.bind(this)}
        contextButtons={contextButtons}
        modalFormProps={formProps}
        disableDefaultPrimaryAction={_get(this.props, 'patient.patientPreview.length', 0) > 0}
        {...props}
      >
        {({ handleAdd }) => (
          <div className="flex flex-column items-center">
            <div className="top-dawg w-50 items-center">
              {/*
                Note that this is confusing a bit. You must pass `onSubmit` to a ReduxForm component
                which is then propagated inside the component to `handleSubmit` (see ImportForm)
              */}
              <ReduxImportForm
                onSubmit={this.handleSearch.bind(this)}
                handleImport={this.handleImport.bind(this)}
                {...this.props}
              />
            </div>
          </div>
        )}
      </StandardTab>
    )
  }
}
