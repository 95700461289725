import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import Typography from '@material-ui/core/Typography/Typography'
import ReduxFormText from 'components/common/redux-form/text'

class EnrollmentFormFragment extends Component {
  render () {
    const { name, enrolled, showReasonField } = this.props

    return (
      <Fragment>
        <div className="flex justify-between mb2">
          <div className="dib mr4">
            <Typography variant="h5" noWrap>
              {enrolled ? 'Patient declined' : 'Enroll patient in'} project {name}
            </Typography>
          </div>
        </div>
        <div className="flex justify-between mb2">
          <div className="dib w-100">
            {showReasonField ? (
              <Field
                required
                name="reason"
                type="text"
                label="Reason"
                placeholder="Reason for Decision"
                component={ReduxFormText}
              />
            ) : (
              <div>
                This will enroll this patient in the project {name}, are you sure you wish to
                proceed?
              </div>
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}

EnrollmentFormFragment.propTypes = {
  name: PropTypes.string.isRequired,
  enrolled: PropTypes.bool.isRequired,
  showReasonField: PropTypes.bool.isRequired
}

EnrollmentFormFragment.defaultProps = {
  enrolled: false,
  showReasonField: false
}

export default EnrollmentFormFragment
