/*
 * Review Action Types
 */
const GET_DRAFT_REVIEWS_FOR_PATIENT = 'GET_DRAFT_REVIEWS_FOR_PATIENT'
const GET_PUBLISHED_REVIEWS_FOR_PATIENT = 'GET_PUBLISHED_REVIEWS_FOR_PATIENT'
const SAVE_DRAFT_REVIEW = 'SAVE_DRAFT_REVIEW'
const SAVE_REVIEW = 'SAVE_REVIEW'
const SAVE_PUBLISHED_REVIEW = 'SAVE_PUBLISHED_REVIEW'

const PREFIX = '@@review'

export const REQUEST = `${PREFIX}/REQUEST`
export const ERROR = `${PREFIX}/ERROR`

export const PATIENT_DATA = `${PREFIX}/PATIENT_DATA`

export const UNPUBLISH_MSR = `${PREFIX}/UNPUBLISH_MSR`

export const DELETE_DRAFT = `${PREFIX}/DELETE_DRAFT`

export const REJECT_AND_RETURN_TO_ECPN = `${PREFIX}/REJECT_AND_RETURN_TO_ECPN`

export const REJECT_AND_RETURN = `${PREFIX}/REJECT_AND_RETURN`
export const REVIEW_RESPONSES_COMPLETE = `${PREFIX}/REVIEW_RESPONSES_COMPLETE`
export const SEND_FOR_REVIEW = `${PREFIX}/SEND_FOR_REVIEW`

export const CLEAR_PUBLISH = `${PREFIX}/CLEAR_PUBLISH`
export const CLEAR_ERROR = `${PREFIX}/CLEAR_ERROR`

export const GET_DRAFT_REVIEWS_FOR_PATIENT_REQUEST = `${GET_DRAFT_REVIEWS_FOR_PATIENT}_REQUEST`
export const GET_DRAFT_REVIEWS_FOR_PATIENT_SUCCESS = `${GET_DRAFT_REVIEWS_FOR_PATIENT}_SUCCESS`
export const GET_DRAFT_REVIEWS_FOR_PATIENT_ERROR = `${GET_DRAFT_REVIEWS_FOR_PATIENT}_ERROR`

export const GET_PUBLISHED_REVIEWS_FOR_PATIENT_REQUEST = `${GET_PUBLISHED_REVIEWS_FOR_PATIENT}_REQUEST`
export const GET_PUBLISHED_REVIEWS_FOR_PATIENT_SUCCESS = `${GET_PUBLISHED_REVIEWS_FOR_PATIENT}_SUCCESS`
export const GET_PUBLISHED_REVIEWS_FOR_PATIENT_ERROR = `${GET_PUBLISHED_REVIEWS_FOR_PATIENT}_ERROR`

export const SAVE_DRAFT_REVIEW_REQUEST = `${SAVE_DRAFT_REVIEW}_REQUEST`
export const SAVE_DRAFT_REVIEW_SUCCESS = `${SAVE_DRAFT_REVIEW}_SUCCESS`
export const SAVE_DRAFT_REVIEW_ERROR = `${SAVE_DRAFT_REVIEW}_ERROR`

export const SAVE_REVIEW_REQUEST = `${SAVE_REVIEW}_REQUEST`
export const SAVE_REVIEW_SUCCESS = `${SAVE_REVIEW}_SUCCESS`
export const SAVE_REVIEW_ERROR = `${SAVE_REVIEW}_ERROR`

export const SAVE_PUBLISHED_REVIEW_REQUEST = `${SAVE_PUBLISHED_REVIEW}_REQUEST`
export const SAVE_PUBLISHED_REVIEW_SUCCESS = `${SAVE_PUBLISHED_REVIEW}_SUCCESS`
export const SAVE_PUBLISHED_REVIEW_ERROR = `${SAVE_PUBLISHED_REVIEW}_ERROR`
