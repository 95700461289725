/*
 * Dashboard conditions tab
 */

import React, { Component } from 'react'
import dayjs from 'dayjs'
import pluralize from 'pluralize'
import { get } from 'lodash'

import TitleCell from 'components/common/table-cells/title-cell'
import StandardTab from 'components/common/standard-tab'
import * as systemsConstants from 'constants/systems'
import { DateFormat } from 'constants/date'
import { formatUserName } from 'services/utils'
import ConditionsForm from './conditions-form'

const OBJECT_NAME = 'condition'

class Conditions extends Component {
  constructor (props) {
    super(props)

    this.formHandler = this.formHandler.bind(this)
    this.handleConditionDelete = this.handleConditionDelete.bind(this)
  }

  DEFAULT_STRUCTURE = {
    code: {
      coding: [
        {
          system: systemsConstants.FHIR_ICD10,
          value: undefined,
          display: undefined
        }
      ],
      text: undefined
    },
    type: `patient.${OBJECT_NAME}`,
    subject: this.props.patient.data._id,
    onsetDate: undefined,
    conditionNote: undefined
  }
  /**
   * Sends the condition to the store to be removed from the list.
   * @param  {object}  data Condition to be update to status: inactive
   */
  async handleConditionDelete (data) {
    const { patientDataActions } = this.props
    await patientDataActions.deletePatientData(data)
  }

  async formHandler (data, editing) {
    const { patientDataActions } = this.props
    await patientDataActions.updatePatientData(data, OBJECT_NAME, editing ? 'post' : 'post')
  }

  // if there is a weight and height we want to calculate and display BMI.
  // If there is only height we want to show that.
  render () {
    const { patientData } = this.props
    let headers = [
      {
        name: 'Name',
        classNames: 'w-20',
        maps: [
          { propName: 'boldText', value: 'code.text' },
          { propName: 'boldClass', default: 'truncate' }
        ],
        component: TitleCell
      },
      { name: 'Reported By', classNames: 'w-10', maps: cond => formatUserName(cond) },
      {
        name: 'Updated',
        classNames: 'w-10',
        maps: cond => dayjs(get(cond, 'updatedOn')).format(DateFormat.DATE_DISPLAY)
      }
    ]
    let data = get(patientData, pluralize(OBJECT_NAME), [])

    return (
      <StandardTab
        name="Condition"
        object={OBJECT_NAME}
        form={ConditionsForm}
        initialValues={this.DEFAULT_STRUCTURE}
        formHandler={this.formHandler}
        tableHeaders={headers}
        tableData={data}
        actionsColumnClasses={'w-30'}
        handleDelete={this.handleConditionDelete}
        deleteAlert
        pagination={{
          pageSize: 5,
          maxPages: 5
        }}
        {...this.props}
      />
    )
  }
}

export default Conditions
