/*
 * Patient Search reducer
 */

import * as C from './types'

const INITIAL_STATE = {
  total: 0,
  max_score: null,
  loading: false,
  hits: []
}

const patientSearchReducer = (state = { ...INITIAL_STATE }, action) => {
  switch (action.type) {
  case C.SEARCH_PATIENTS_REQUEST:
    // state = { ...INITIAL_STATE } // removing to prevent flicker.. will get cleared on error
    return { ...state, loading: true }
  case C.SEARCH_PATIENTS_SUCCESS:
  case C.SET_PATIENTS:
    return action.payload
  case C.SEARCH_PATIENTS_FAILURE:
    return { ...state, loading: false, error: action.error }
  case C.REMOVE_PATIENTS:
    return { ...INITIAL_STATE }
  default:
    return state
  }
}

export default patientSearchReducer
