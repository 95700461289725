import * as C from './types'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
  case C.REQUEST:
    return {
      ...state,
      patientId: action.patientId
    }
  case C.PATIENT_WORKFLOW:
  case C.UPDATE_WORKFLOW:
    return {
      ...state,
      workflowState: action.payload
    }
  default:
    return state
  }
}
