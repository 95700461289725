import React from 'react'
import MarkdownView from 'react-showdown'

export const SimpleListDocumentation = () => {
  const markdown = `
# **Simple Lists**

### Description
Simple Lists contain Simple List Items that are basic list items.   


## **Simple List Items**

### Description
Simple List Items are passed to Simple Lists and rendered as children.

### Props
* summary (optional) - summary will be displayed if given a value.
    * If the summary has a value, it is displayed by default. When clicked, the summary is hidden and the children are displayed.
* expandOnClick (optional) - whether or not the list item will expand to show content when clicked.  Defaults to \`true\`.
* expanded (optional) - whether or not the list item is currently expanded.  Defaults to \`false\`.
* classNames (optional) - additional classes to use for styling the simple list items.

### Example

For example code used to generate the simple list below, please refer to [Simple List](https://git.carekinesis.net/clinical/map-front-end/-/blob/master/medwise-advisor/src/components/gallery/simple-list/index.js).
`
  return (
    <MarkdownView
      markdown={markdown}
      options={{ tables: true, emoji: true, ghCodeBlocks: true }}
    />
  )
}