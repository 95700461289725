import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  get as _get,
  includes as _includes,
  isEqual as _isEqual,
  without as _without
} from 'lodash'
import classnames from 'classnames'
import hash from 'object-hash'
import { Checkbox } from '@material-ui/core'
import Loading from './loading'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import DraggableRow from './draggable-row'
import { DragIndicator } from '@material-ui/icons'

import './styles/tables.scss'

const buildEmptyRow = (headers, rowKey, rowActions, enableSelectCheckbox, draggable) => {
  const colSpan = headers.length + (rowActions ? 1 : 0) + (enableSelectCheckbox ? 1 : 0) + (draggable ? 1 : 0)
  return (
    <tr key={`empty-row${rowKey}`} className="striped--near-white">
      <td key={`empty-row-data${rowKey}`} colSpan={colSpan} className={classnames('pv1', 'ph2')} />
    </tr>
  )
}

/**
 * Table component
 *
 * @param {array} headers The table headers made of objects {name, maps,
 *                        component(optional)}. If there is not a `component`
 *                        key in the object, `maps` will be a string path of
 *                        the object in the data object or a function.
 *                        If there is a `component` key, `maps` will be an array of *                        objects with `propName` and then one of the following -
 *                        rowItem, func, or value.
 *                        `propName` - the prop name in the given component.
 *                        `func` - used if the value of the prop is a function.
 *                        `rowItem` - boolean set to true to pass the data from
 *                         the row into the prop.
 *                        `value` - string path of the object in the data object.
 *                        `default` - A default value or expression incase value
 *                        is empty.
 * @param {array} data Data passed to the table
 * @param {boolean} loading If the table is loading or not, will display the <Loading> component in a row.
 * @param {string} loadingMessage The message to associate with the loading component.
 * @param {boolean} enableRowSelection  When true, enables a checkbox on the header
 * @param {boolean} sortable Enable row sorting
 * @param {function} onRowSort The function to handle the row sorting. It will return the rows in sorted order.
 * @param {boolean} draggable Enable row dragging
 * @param {function} onRowDrag The function to handle drag and drop of a row.
 *
 * @example
 *   var data = [
 {id: 1, substance: {text: 'a bad Risk Factor'}, reaction: 'swelling', description: 'A string of words'},
 {id: 2, substance: {text: 'Another risk factor'}, reaction: 'rash', description: 'A string of words'}
 ]
 * var headers = [
 {
     name: 'Name',
     maps: [
       { propName: 'boldText', value: 'substance.text' },
       { propName: 'descriptionText', value: 'cellText', default: 'Reaction' },
       { propName: 'lightText', value: 'reaction' }
     ],
     component: TitleCell
   },
 { name: 'Summary', maps: 'description' },
 {
     name: 'Actions',
     maps: [
       {
         propName: 'item',
         rowItem: true
       },
       {
         propName: 'onEdit',
         func: this.onItemEdit
       }
      ]
   },
 {
      name: 'ComponentExample',
      maps: (item, key) => (
        <Component
          key={key}
          text={item.enrolled ? 'Enrolled' : 'Disenrolled'}
        />
      ),
      isComponent: true
    },
 ]
 The above object labeled "Actions" will make a component that looks like this:
 <Component onEdit={onItemEdit} item={<data currently being mapped over>} />
 The component labeled "ComponentExample" will render the provided component as the cell
 in the table; the <Component /> used should render a <td> object
 *
 */
class Table extends Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedRows: []
    }

    this.toggleRowSelection = this.toggleRowSelection.bind(this)
    this.toggleSelectAllRows = this.toggleSelectAllRows.bind(this)
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    const oldReduxForm = _get(this.props, 'reduxForm')
    const newReduxForm = _get(nextProps, 'reduxForm')

    return _isEqual(oldReduxForm, newReduxForm)
  }

  toggleRowSelection (event, rowData) {
    let selectedRows = Object.assign(this.state.selectedRows, [])
    let changed = true
    if (this.props.onSelectRow) {
      changed = this.props.onSelectRow(event.target.checked, rowData)
    }
    if (changed === false) return

    if (event.target.checked) {
      selectedRows.push(rowData)
    } else {
      selectedRows = _without(selectedRows, rowData)
    }

    this.setState({
      selectedRows
    })
  }

  toggleSelectAllRows (event) {
    const { data } = this.props
    if (event.target.checked) {
      this.setState({
        selectedRows: data
      })
    } else {
      this.setState({
        selectedRows: []
      })
    }

    this.props.onSelectAllRows(event.target.checked, data)
  }

  componentDidUpdate (nextProps, nextState) {
    if (!_isEqual(_get(nextProps, 'data'), _get(this.props, 'data'))) {
      this.setState({
        selectedRows: []
      })
    }
  }

  isRowChecked (row) {
    return _includes(this.state.selectedRows, row) || !!row.Selected
  }

  render () {
    // Set the table data
    const {
      data,
      parentData,
      headers,
      rowKeyProp,
      enableRowSelection,
      draggable,
      rowSelectionDisabled,
      rowActions,
      hideRowActionsHeader,
      minRowCount,
      emptyMessage,
      loading,
      loadingMessage,
      onRowDrag,
      onRowDragEnd,
      ...props
    } = this.props

    const moveRow = (dragIndex, hoverIndex) => {
      onRowDrag(dragIndex, hoverIndex, parentData)
    }

    const moveRowEnd = () => {
      onRowDragEnd(parentData)
    }

    let tableData = data.map((d, i) => {
      const cells = []

      enableRowSelection &&
        cells.push(
          <td key={`row${i}selectCheckbox`} className="w3 tc">
            <Checkbox
              disabled={rowSelectionDisabled}
              checked={this.isRowChecked(d)}
              onChange={e => this.toggleRowSelection(e, d)}
            />
          </td>
        )

      draggable &&
        cells.push(
          <td key={`row${i}selectCheckbox`} className="w2 tc">
            <DragIndicator className="drag-icon"/>
          </td>
        )

      headers.forEach((h, j) => {
        if (h.component) {
          const propValues = {}

          h.maps.forEach(prop => {
            if (prop.func) {
              // if passing a func for the component to call asynchronously
              propValues[prop.propName] = prop.func
            } else if (prop.rowItem) {
              // if passing the item itself (for passing back to a supplied func)
              propValues[prop.propName] = d
            } else {
              // for getting value from a path on the item
              // if prop.value is a function, derive the value by passing the item to the function,
              // else it should be a string path for deriving the value with lodash get,
              propValues[prop.propName] =
                typeof prop.value === 'function' ? prop.value(d) : _get(d, prop.value, prop.default)
            }
          })
          const Component = h.component
          cells.push(
            <Component
              key={`row${i}col${j}`}
              {...propValues}
              {...props}
              iteree={i}
              data={data}
              parentData={parentData}
              header={h}
            />
          )
        } else {
          if (typeof h.maps === 'function' && h.isComponent) {
            const key = `row${i}col${j}`
            const Component = h.maps(d, key)
            cells.push(Component)
          } else {
            cells.push(
              <td key={`row${i}col${j}`} className={classnames('pv2', 'ph2', h.classNames)}>
                {typeof h.maps === 'function' ? h.maps(d) : _get(d, h.maps)}
              </td>
            )
          }
        }
      })

      // If we have rowActions objects, iterate through the objects
      if (rowActions) {
        const actions = rowActions.map((act, j) => {
          if (act.onClick) {
            return (
              <button key={`col-actions${j}`} onClick={act.onClick.bind(this, d, data)}>
                {act.contents}
              </button>
            )
          } else {
            return <span key={`col-actions${j}`}>{act.contents}</span>
          }
        })
        cells.push(
          <td key={`col-actions`} className="actions pv1 ph1">
            {actions}
          </td>
        )
      }

      return (
        draggable 
          ?
          <DraggableRow 
            key={rowKeyProp ? _get(d, rowKeyProp) : hash(d)} 
            id={i}
            index={i}
            className="striped--near-white" 
            moveRow={moveRow}
            moveRowEnd={moveRowEnd}
          >
            {cells}
          </DraggableRow>
          :
          <tr key={rowKeyProp ? _get(d, rowKeyProp) : hash(d)} className="striped--near-white">
            {cells}
          </tr>
      )
    })

    // Set the table header
    const tableHeader = []

    enableRowSelection &&
      tableHeader.push(
        <th key="headerSelectAllChekbox" className="w3 tc">
          {this.props.onSelectAllRows && (
            <Checkbox
              disabled={rowSelectionDisabled}
              checked={!!data.length && this.state.selectedRows.length === data.length}
              onChange={this.toggleSelectAllRows}
            />
          )}
        </th>
      )

    draggable &&
      tableHeader.push(
        <th key="headerDraggableRow" className="w2 tc"></th>
      )

    headers.forEach((h, i) => {
      tableHeader.push(
        <th key={`th${i}`} className={classnames('pv1', 'ph1', 'tl', 'fw6', h.classNames)}>
          {h.name}
        </th>
      )
    })
    if (rowActions) {
      tableHeader.push(
        <th key={`th-actions`} className="pv1 ph1 tl fw6">
          {!hideRowActionsHeader ? 'Actions' : ''}
        </th>
      )
    }

    if (!tableData.length && emptyMessage) {
      tableData = [
        <tr key="empty" className="striped--near-white">
          <td colSpan={tableHeader.length} className="pv2 ph2">
            {emptyMessage}
          </td>
        </tr>
      ]
    }

    tableData.unshift(
      <tr key="header" className="header-row striped--near-white">
        {tableHeader}
      </tr>
    )

    if (minRowCount) {
      const tableLength = tableData.length - 1
      const pageSize = tableLength % minRowCount
      let rowsNeeded = 0

      if (tableLength === 0) {
        rowsNeeded = minRowCount
      } else if (pageSize) {
        rowsNeeded = minRowCount - pageSize
      }
      for (var i = 0; i < rowsNeeded; i++) {
        tableData.push(buildEmptyRow(headers, i, rowActions, enableRowSelection, draggable))
      }
    }

    if (loading) {
      tableData = (
        <tr>
          <td className="pa2">
            <Loading text={loadingMessage} color="primary" />
          </td>
        </tr>
      )
    }

    const tableDOM = (
      <div className="component table">
        <table className="collapse w-100 dt--fixed">
          <tbody>{tableData}</tbody>
        </table>
      </div>       
    )

    return (
      draggable
        ?
        <DndProvider backend={HTML5Backend}>
          {tableDOM}
        </DndProvider>
        :
        <Fragment>
          {tableDOM}
        </Fragment>
    )
  }
}

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideRowActionsHeader: PropTypes.bool,
  minRowCount: PropTypes.number,
  rowActions: PropTypes.arrayOf(PropTypes.object),
  enableRowSelection: PropTypes.bool,
  enableDraggableRow: PropTypes.bool,
  rowSelectionDisabled: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onSelectAllRows: PropTypes.func,
  emptyMessage: PropTypes.string
}

const mapStateToProps = ({ history }) => ({
  history
})

export default connect(mapStateToProps)(Table)
