// export const ALERT_TEXT_INFO = '#34708e'
// export const ALERT_TEXT_ERROR = '#aa0000'
// export const ALERT_TEXT_WARN = '#8a6d40'

// export const BORDER_ERROR = 'rgba(255, 0, 0, 0.56)'

export const BOX_SHADOW_ERROR = 'rgba(255,0,0, .5)'
export const BOX_SHADOW_PRIMARY = 'rgba(130,188,0, .5)'

export const TRHC_GREEN_LIGHT = '#b6ef49'
export const TRHC_GREEN = '#82bc00'
export const TRHC_GREEN_DARK = '#77b301'

export const TRHC_GREEN_25 = 'rgba(130,188,0, 0.25)'
export const TRHC_GREEN_50 = 'rgba(130,188,0, 0.5)'
export const TRHC_GREEN_75 = 'rgba(130,188,0, 0.75)'

// export const TRHC_ERROR_LIGHT = '#e4472c'
export const TRHC_ERROR = '#DD4B39'
export const TRHC_INFO = '#4591CF'
// export const TRHC_ERROR_DARK = '#'

export const TRHC_WARNING = '#FFA700'
export const TRHC_SUCCESS = '#86CC0C'

export const TRHC_EMPTY_INPUT_RGBA = 'rgba(0, 0, 0, 0.06)'
// export const TRHC_EMPTY_INPUT = 'red'
export const TRHC_EMPTY_INPUT_HEX = '#f0f0f0'

export const REQUIRED_ASTERISK_RED = '#ff0000'
