import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

/**
 * Table cell component with bold heading and light secondary field.
 */
class TitleCell extends Component {
  render () {
    let description = this.props.descriptionText ? `${this.props.descriptionText}:` : ''
    return (
      <td>
        <div className={classnames('fw6 truncate', this.props.boldClass)}>
          {this.props.boldText}
        </div>
        <div className={classnames('black-40', this.props.lightClass)}>
          {description} {this.props.lightText}
        </div>
        <div className={classnames('black-40', this.props.lightClass2)}>
          {this.props.lightText2}
        </div>
      </td>
    )
  }
}

TitleCell.propTypes = {
  boldText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  boldClass: PropTypes.string,
  lightText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  lightClass: PropTypes.string
}

export default TitleCell
