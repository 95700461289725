import { handleActions } from 'redux-actions'

import * as medrecActions from './actions'


const initialState = {
    medications: {
        unreconciled: [],
        reconciled: []
    }
}

const reducer = handleActions({
    [medrecActions.setMedications]: (state, action) => ({ ...state, medications: action.payload })
}, initialState)

export default reducer