import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core/'

/**
 * For use as heading on a form or tab.
 * @param {object} props The properties passed to the component.
 * @param {string} props.text The text that will be displayed as the heading.
 * @constructor
 */
const FormHeading = props => (
  <Typography variant="h5" noWrap>
    {props.text}
  </Typography>
)

FormHeading.propTypes = {
  text: PropTypes.string.isRequired
}

export default FormHeading
