/*
 * Actions for Patient services
 */
import { get } from 'lodash'
import gateway from 'services/gateway'
import uuid from 'uuid/v4'

import { actions as notifyActions } from 'store/modules/notify'
import { actions as patientDataActions } from 'store/modules/patient-data'

import * as C from './types'

export const errorCall = (dispatch, error, type) => {
  // send error to logger or notifier, or handle this in the UI modules?
  if (error.statusCode && error.statusCode >= 500) {
    dispatch(
      notifyActions.addNotification({
        message: error.message || 'An unknown error occurred'
      })
    )
  }

  return dispatch({
    type: type,
    error: error
  })
}

function applyGuids (listData) {
  get(listData, 'lists', []).forEach((x, i) => {
    // this is a little hack because i think we made some bad data.
    if (!x || typeof x !== 'object') {
      listData.lists.splice(i, 1)
    }
    return get(x, 'medications', []).forEach(med => {
      if (!med.uuid) {
        med.uuid = uuid()
      }
    })
  })
  return listData
}

/**
 * Handle get events for medication reconciliation api calls.
 * @param  {Function}  dispatch The dispatcher for redux-thunk
 * @param  {String}  uri      The uri to the API call
 * @param  {Constant}  dataType The event type for handling the data
 * @return {Promise}          [description]
 */
const getHandler = async (dispatch, uri, dataType) => {
  dispatch({
    type: C.RECONCILIATION_FETCHING
  })
  try {
    let medRecListData = await gateway.get(uri)
    dispatch({
      type: C.RECONCILIATION_SUCCESS
    })
    if (dataType === C.RECONCILED_DATA) {
      dispatch({
        type: dataType,
        payload: medRecListData.rows
      })
    } else {
      let formattedListData = applyGuids(medRecListData)
      dispatch({
        type: dataType,
        payload: formattedListData
      })
    }
  } catch (err) {
    errorCall(dispatch, err, C.RECONCILIATION_FAILURE)
  }
}

const postHandler = async (dispatch, uri, payload, dataType) => {
  dispatch({
    type: C.RECONCILIATION_FETCHING
  })
  try {
    let medRecListData = await gateway.post(uri, payload)
    dispatch({
      type: C.RECONCILIATION_SUCCESS
    })
    if (dataType === C.NEW_RECONCILIATION) {
      dispatch(
        notifyActions.addNotification({
          type: 'info',
          message: `Successfully created a new Medication Reconciliation`
        })
      )
      return dispatch(getListData(payload.subject))
    }
    dispatch({
      type: dataType,
      payload: medRecListData
    })
  } catch (err) {
    errorCall(dispatch, err, C.RECONCILIATION_FAILURE)
  }
}

const putHandler = async (dispatch, uri, payload, dataType) => {
  dispatch({
    type: C.RECONCILIATION_FETCHING
  })
  try {
    let medRecListData = await gateway.put(uri, payload)

    if (dataType === C.UPDATE_RECONCILIATION) {
      dispatch(
        notifyActions.addNotification({
          type: 'info',
          message: `Successfully updated Medication Reconciliation`
        })
      )
      return dispatch(getListData(payload.subject))
    } else {
      dispatch({ type: C.RECONCILIATION_SUCCESS })
    }

    if (dataType === C.COMPLETE_RECONCILIATION) {
      dispatch(
        patientDataActions.getPatientData(payload.subject, {
          medications: true
        })
      )

      dispatch({
        type: C.COMPLETE_RECONCILIATION,
        payload: payload
      })
      dispatch(getListData(payload.subject))
      return dispatch(getReconciledData(payload.subject))
    }
    dispatch({
      type: dataType,
      payload: medRecListData
    })
  } catch (err) {
    errorCall(dispatch, err, C.RECONCILIATION_FAILURE)
  }
}

/**
 * Get reconciliation list data
 * @param  {String} patientId The patient ID to get reconciliation lists for
 */
export const getListData = patientId => {
  return dispatch => {
    getHandler(
      dispatch,
      `/api/medwise/reconciliation/list?patientId=${patientId}`,
      C.RECONCILIATION_LIST_DATA
    )
  }
}

export const getReconciledData = patientId => {
  return dispatch => {
    getHandler(dispatch, `/api/reconciliation/reconciled?patientId=${patientId}`, C.RECONCILED_DATA)
  }
}

export const newReconciliation = body => {
  return dispatch => {
    postHandler(dispatch, `/api/reconciliation/new`, body, C.NEW_RECONCILIATION)
  }
}

export const saveReconciliationList = body => {
  return async dispatch => {
    postHandler(dispatch, `/api/reconciliation/newList`, body, C.NEW_RECONCILIATION_LIST)
  }
}

export const updateReconciliation = body => {
  return async dispatch => {
    putHandler(dispatch, `/api/reconciliation/update`, body, C.UPDATE_RECONCILIATION)
  }
}

export const completeReconciliation = body => {
  return async dispatch => {
    putHandler(dispatch, `/api/reconciliation/complete`, body, C.COMPLETE_RECONCILIATION)
  }
}

export const clearData = dataToClear => ({
  type: C.CLEAR_RECONCILIATION
})
