import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import { actions as searchActions } from 'store/modules/patient-search'
import { actions as aggregateActions } from 'store/modules/patient-search-aggregates'
import { actions as priorityQueueActions } from 'store/modules/priority-queues'
import { actions as reservationsActions } from 'store/modules/reservations'

import PriorityQueueExplorer from './priority-queue-explorer'
import ReduxPriorityQueueConfigurationForm from './priority-queue-configuration-form'
import { EDITING_MODE, VIEWING_MODE } from 'constants/search'

export class PriorityQueue extends Component {
  state = {
    mode: VIEWING_MODE
  }

  createPriorityQueue = async queue => {
    await this.props.createPriorityQueue(queue)
  }

  updatePriorityQueue = async queue => {
    await this.props.updatePriorityQueue(queue)
  }

  removePriorityQueue = async id => {
    await this.props.removePriorityQueue(id)
  }

  updateMode = mode => this.setState({ mode, dirty: true })

  componentWillUnmount () {
    this.props.removePatients()
  }

  render () {
    return (
      <Fragment>
        {this.state.mode === EDITING_MODE && (
          <ReduxPriorityQueueConfigurationForm
            updateMode={this.updateMode}
            createPriorityQueue={this.createPriorityQueue}
            updatePriorityQueue={this.updatePriorityQueue}
            removePriorityQueue={this.removePriorityQueue}
            {...this.props}
          />
        )}
        {this.state.mode === VIEWING_MODE && (
          <PriorityQueueExplorer updateMode={this.updateMode} {...this.props} />
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = ({
  patientSearch,
  patientSearchAggregates,
  priorityQueues,
  priorityQueue,
  priorityQueueSettings,
  reservations
}) => ({
  patientSearch,
  patientSearchAggregates,
  priorityQueues,
  priorityQueue,
  priorityQueueSettings,
  reservations
})

const mapDispatchToProps = dispatch => ({
  removePatients: () => dispatch(searchActions.removePatients()),
  searchPatients: (options, fetchReservations) =>
    dispatch(searchActions.searchPatients(options, fetchReservations)),
  fetchAggregates: options => dispatch(aggregateActions.fetchAggregates(options)),
  // CRUD for priority queues
  getPriorityQueues: id => dispatch(priorityQueueActions.getPriorityQueues(id)),
  createPriorityQueue: queue => dispatch(priorityQueueActions.createPriorityQueue(queue)),
  updatePriorityQueue: queue => dispatch(priorityQueueActions.updatePriorityQueue(queue)),
  removePriorityQueue: id => dispatch(priorityQueueActions.removePriorityQueue(id)),
  // set and clear the focused priority queue
  setPriorityQueue: queue => dispatch(priorityQueueActions.setPriorityQueue(queue)),
  clearPriorityQueue: () => dispatch(priorityQueueActions.clearPriorityQueue()),
  initializePriorityQueueSettings: () =>
    dispatch(priorityQueueActions.initializePriorityQueueSettings()),
  updatePriorityQueueSettings: priorityQueueSettings =>
    dispatch(priorityQueueActions.updatePriorityQueueSettings(priorityQueueSettings)),
  // reservations
  createReservation: async params => dispatch(reservationsActions.createReservation(params)),
  deleteReservation: async params => dispatch(reservationsActions.deleteReservation(params)),
  getReservationsForSearchResults: patientSearchResults =>
    dispatch(reservationsActions.getReservationsForSearchResults(patientSearchResults))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriorityQueue)
