import * as type from './types'

const initialState = {
  loading: false,
  saving: false,
  error: null,
  status: null,
  draftReviews: [],
  publishedReviews: [],
  patientData: null,
  published: null,
  review: null // the latest saved revied
}

const reviewReducer = (state = initialState, action) => {
  switch (action.type) {
  case type.ERROR:
    return {
      ...state,
      status: action.status,
      error: action.error,
      loading: false,
    }
  case type.REQUEST:
    return {
      ...state,
      status: action.status,
      payload: action.payload,
      loading: true
    }
  case type.GET_DRAFT_REVIEWS_FOR_PATIENT_REQUEST:
    return {
      ...state,
      patientId: action.patientId,
      loading: action.showLoadingIndicator
    }
  case type.GET_DRAFT_REVIEWS_FOR_PATIENT_SUCCESS:
    return {
      ...state,
      patientId: action.patientId,
      draftReviews: action.draftReviews,
      loading: false
    }
  case type.GET_DRAFT_REVIEWS_FOR_PATIENT_ERROR:
    return {
      ...state,
      patientId: action.patientId,
      error: action.error,
      loading: false
    }
  case type.GET_PUBLISHED_REVIEWS_FOR_PATIENT_REQUEST:
    return {
      ...state,
      patientId: action.patientId,
      loading: true
    }
  case type.GET_PUBLISHED_REVIEWS_FOR_PATIENT_SUCCESS:
    return {
      ...state,
      patientId: action.patientId,
      publishedReviews: action.publishedReviews,
      loading: false
    }
  case type.GET_PUBLISHED_REVIEWS_FOR_PATIENT_ERROR:
    return {
      ...state,
      patientId: action.patientId,
      error: action.error,
      loading: false
    }
  case type.SAVE_DRAFT_REVIEW_REQUEST:
    return {
      ...state,
      response: undefined,
      loading: false,
      saving: true,
      status: false
    }
  case type.SAVE_DRAFT_REVIEW_SUCCESS:
    return {
      ...state,
      review: action.review,
      response: action.response,
      loading: false,
      saving: false,
      status: false
    }
  case type.SAVE_DRAFT_REVIEW_ERROR:
    return {
      ...state,
      response: action.response,
      error: action.error,
      loading: false,
      saving: false,
      status: false
    }
  case type.SAVE_REVIEW_REQUEST:
    return {
      ...state,
      loading: true
    }
  case type.SAVE_REVIEW_SUCCESS:
    return {
      ...state,
      response: action.response,
      review: action.review,
      loading: false,
      status: false
    }
  case type.UNPUBLISH_MSR:
    return {
      ...state,
      response: action.response,
      review: action.review,
      loading: false,
      status: false
    }
  case type.DELETE_DRAFT:
    return {
      ...state,
      response: action.response,
      review: action.review,
      loading: false,
      status: false // wut?
    }
  case type.SAVE_REVIEW_ERROR:
    return {
      ...state,
      error: action.error,
      loading: false,
      status: false
    }
  case type.SAVE_PUBLISHED_REVIEW_REQUEST:
    return {
      ...state,
      response: undefined,
      published: null,
      loading: false,
      status: false
    }
  case type.SAVE_PUBLISHED_REVIEW_SUCCESS:
    return {
      ...state,
      response: action.response,
      review: null,
      published: action.published,
      loading: false,
      status: false
    }
  case type.SAVE_PUBLISHED_REVIEW_ERROR:
    return {
      ...state,
      response: action.response,
      error: action.error,
      published: null,
      loading: false,
      status: false
    }
  case type.CLEAR_PUBLISH:
    return {
      ...state,
      published: null
    }
  case type.CLEAR_ERROR:
    return {
      ...state,
      error: null
    }
  case type.PATIENT_DATA:
    return {
      ...state,
      patientData: action.payload,
      error: null,
      loading: false,
      status: false
    }
  case type.REJECT_AND_RETURN:
    return {
      ...state,
      loading: false
    }
  default:
    return state
  }
}

export default reviewReducer
