/*
 * Dummy Page for placeholder content
 */

import React, { Component } from 'react'

class DummyComponent extends Component {
  render () {
    return (
      <div className="component dummy">
        <h1>This is a dummy component</h1>
      </div>
    )
  }
}

export default DummyComponent
