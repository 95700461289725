/*
 * Dashboard conditions tab
 */

import React, { Component } from 'react'
import dayjs from 'dayjs'
import pluralize from 'pluralize'
import Tooltip from '@material-ui/core/Tooltip'
import { get as _get } from 'lodash'

import StandardTab from 'components/common/standard-tab'
import { dateByYear as dateByYearOptions, path as pathOptions } from 'services/options-builders'
import { formatUserName } from 'services/utils'
import { DateFormat } from 'constants/date'
import NoteForm from './form'

const OBJECT_NAME = 'note'

export class Notes extends Component {
  CURRENT_USER_NAMES = {
    userFirstName: _get(this.props, 'auth.profile.firstName'),
    userLastName: _get(this.props, 'auth.profile.lastName')
  }
  DEFAULT_STRUCTURE = {
    type: `patient.${OBJECT_NAME}`,
    subject: _get(this.props, 'patient.data._id'),
    dateAdded: dayjs(),
    engagement: _get(this.props, 'patient.data.workflowIteration', 1),
    year: _get(this.props, 'patient.data.currentWorkflowState.year', 1),
    performedBy: {
      ...this.CURRENT_USER_NAMES
    }
    // ...this.CURRENT_USER_NAMES
  }

  /**
   * Sends the note to the store to be removed from the list.
   * @param  {object}  data Note to be update to status: inactive
   */
  handleNoteDelete = async data => {
    const { patientDataActions } = this.props
    await patientDataActions.deletePatientData(data)
  }

  formHandler = async (data, editing) => {
    await this.props.patientDataActions.updatePatientData(
      { ...data },
      // { ...data, ...this.CURRENT_USER_NAMES }, // when updating a note, ensure current user's name is recorded
      OBJECT_NAME,
      editing ? 'post' : 'post'
    )
  }

  render () {
    const { patientData } = this.props
    let headers = [
      {
        name: 'Notes',
        maps: (note, key) => (
          <Tooltip
            key={key}
            title={note.description || 'Note'}
            interactive
            placement="right"
          >
            <td>{note.title}</td>
          </Tooltip>
        ),
        isComponent: true
      },
      // {
      //   name: 'Category',
      //   maps: 'category'
      // },
      {
        name: 'Year',
        maps: 'year'
      },
      {
        name: 'Engagement',
        maps: 'engagement'
      },
      {
        name: 'Author',
        maps: note => formatUserName(note)
      },
      {
        name: 'Updated',
        maps: note => dayjs(_get(note, ['updatedOn'])).format(DateFormat.DATE_DISPLAY)
      }
    ]
    let data = _get(patientData, pluralize(OBJECT_NAME), [])
    data.sort((a, b) => {
      if (new Date(a.createdOn) > new Date(b.createdOn)) return -1
      if (new Date(a.createdOn) < new Date(b.createdOn)) return 1
      return 0
    })
    let filters = [
      // {
      //   label: 'Category',
      //   name: 'category',
      //   options: [
      //     { label: 'Outreach', value: 'outreach' },
      //     { label: 'Profile', value: 'profile' },
      //     {
      //       label: 'Medication Safety Review',
      //       value: 'medication safety review'
      //     },
      //     { label: 'Urgent', value: 'urgent' },
      //     { label: 'Medication Adherence', value: 'medication adherence' }
      //   ],
      //   comparator: (filterValue, rowItem) => rowItem.category === filterValue
      // },
      {
        label: 'Updated',
        name: 'updatedOn',
        comparator: (filterValue, rowItem) => rowItem.updatedOn.startsWith(filterValue),
        options: dateByYearOptions(data, 'updatedOn')
      },
      {
        label: 'Year',
        name: 'year',
        options: pathOptions(data, 'year'),
        comparator: (filterValue, rowItem) => rowItem.year === filterValue
      },
      {
        label: 'Engagement',
        name: 'engagement',
        options: pathOptions(data, 'engagement'),
        comparator: (filterValue, rowItem) => rowItem.engagement === filterValue
      }
    ]

    return (
      <StandardTab
        name="Note"
        object={OBJECT_NAME}
        form={NoteForm}
        initialValues={this.DEFAULT_STRUCTURE}
        formHandler={this.formHandler}
        tableHeaders={headers}
        tableData={data}
        filters={filters}
        handleDelete={this.handleNoteDelete}
        deleteAlert
        pagination={{
          pageSize: 5,
          maxPages: 5
        }}
        {...this.props}
      />
    )
  }
}

export default Notes
