/* eslint-disable indent */

import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import './styles/simple-list.scss'

/**
 * The SimpleList component allows you to create a list of SimpleListItems.
 * You can pass a list of SimpleListItems as children to this component.
 */
export const SimpleList = ({children}) => {
  return (
    <div className="list-wrapper">
      {children}
    </div>
  )
}

/**
 * The SimpleListItem component displays a summary when not expanded.
 * When expanded, the summary will be hidden and the children will be displayed.
 *
 *  @param {object} summary           The information to be displayed when the simple list item is not expanded. (condensed)
 *  @param {string} className         Additional string to pass in order to append classnames.
 *  @param {function} onClickHandler  Optional function to call after onClick event.
 *  @param {boolean} expanded         True if the simple list item is expanded.
 */
export const SimpleListItem = ({ summary, className, onClickHandler, expanded, children }) => {

  const [isExpanded, setIsExpanded] = useState(summary === undefined)

  useEffect(
    () => {
      setIsExpanded(expanded)
    }, 
    [expanded]
  )

  const toggleExpanded = () => {
    setIsExpanded(isExpanded => !isExpanded)
  }

  return (
    <div className={classnames('list-item', className, { 'list-item-expanded': isExpanded })} onClick={ onClickHandler || toggleExpanded }>
      { summary && !isExpanded && <div className="summary">{summary}</div> }
      { isExpanded && children }
    </div>
  )
}

SimpleListItem.propTypes = {
  className: PropTypes.string,
  summary: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  expanded: PropTypes.bool,
  onClickHandler: PropTypes.func
}

SimpleListItem.defaultProps = {
  expanded: false,
}