import React, { Component } from 'react'

import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  nonEmptyInput: {
    input: {
      background: 'white'
    }
  },
  input: {
    height: 'auto'
  }
}

class ReduxFormTextField extends Component {
  render () {
    const {
      input,
      maxCharacters,
      placeholder,
      required,
      isFullWidth,
      helperText,
      label,
      meta: { error, touched, warning }
    } = this.props
    const showError = !!(touched && error)

    return (
      <FormControl
        error={showError}
        warning={warning}
        required={required}
        fullWidth={isFullWidth}
        className="w-100"
      >
        <TextField
          {...input}
          onChange={event => {
            const val = event.target.value
            maxCharacters ? input.onChange(val.substring(0, maxCharacters)) : input.onChange(val)
          }}
          margin="dense"
          variant="outlined"
          placeholder={placeholder}
          helperText={helperText}
          label={label}
          data-cy="a-cypress-lable"
        />
        <div>{showError && <FormHelperText error>{error}</FormHelperText>}</div>
      </FormControl>
    )
  }
}

export default withStyles(styles)(ReduxFormTextField)
