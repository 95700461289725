import React, { Component } from 'react'
import { connect } from 'react-redux'
import { change, Field, Form, isDirty, reduxForm, resetSection } from 'redux-form'
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Typography
} from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import { clone, get, includes } from 'lodash'
import dayjs from 'dayjs'

import IdAM from 'components/common/idam'
import { isPatientInECPNOrg } from 'services/patient'
import { childOrganizations, parentFromChild } from 'services/organizations'
import { doesPatientAlreadyExist } from 'store/modules/patient/actions'
import { actions as notifyActions } from 'store/modules/notify'
import * as C from 'store/modules/patient/types'
import { languages, locations } from 'constants/patient'
import { DateFormat } from 'constants/date'

import ReduxFormCheckbox from 'components/common/redux-form/checkbox'
import ReduxFormDatePicker from 'components/common/redux-form/date-picker'
import ReduxFormDateInput from 'components/common/redux-form/date-input'
import ReduxFormSelect from 'components/common/redux-form/select'
import ReduxFormText from 'components/common/redux-form/text'
import ReduxFormRadioGroup from 'components/common/redux-form/radio-group'

const OBJECT_NAME = 'profile'

class PatientFormProfileSection extends Component {
  state = {}

  componentDidMount () {
    const managingOrgId = get(this.props, 'patient.data.managingOrganization.id')
    const isTandigm = includes(['org:Tandigm', 'org:TandigmSaaS'], managingOrgId)
    this.setState({
      isTandigm
    })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { showHistoryChangeReasonField, dispatch, reduxForm, chosenOrg } = this.props
    // if the discharge date or home care flag history is changed, we have to provide a field for them to enter the reason
    if (prevProps.showHistoryChangeReasonField !== showHistoryChangeReasonField) {
      const historyArrayLength = get(reduxForm, `${OBJECT_NAME}.values.history.length`, 1) - 1
      if (showHistoryChangeReasonField) {
        // if the field is shown, clear the value from the previous entry
        dispatch(change(OBJECT_NAME, `history[${historyArrayLength}].manualChange.reason`, ''))
      } else {
        // if the field is not shown, restore the original value (to not mess up the validation on the fields)
        dispatch(resetSection(OBJECT_NAME, 'history'))
      }
    }
    // If the org changes we need to make sure the program field clears
    if (prevProps.chosenOrg !== chosenOrg) {
      dispatch(resetSection(OBJECT_NAME, 'programId'))
    }
  }

  handleAddReferral (value) {
    const { change, clearFields, blur } = this.props
    const referral = {
      ...value,
      date: new Date().toISOString(),
      isNew: true
    }
    const referralType = clone(get(this.props, 'reduxForm.profile.values.referralType', []))
    referralType.push(referral)
    change('referralType', referralType)
    clearFields(false, false, 'dummyReferral')
    blur('dummyReferral')
  }

  handleRemoveReferral (referralIndex) {
    const { change } = this.props
    const referralType = clone(get(this.props, 'reduxForm.profile.values.referralType', []))
    referralType.splice(referralIndex, 1)
    change('referralType', referralType)
  }

  checkAndWarnIfPatientExists (event) {
    const { dispatch } = this.props
    const patientId = event.target.value
    const cardholderIdExists = patientId.length > 0
    if (!cardholderIdExists) {
      return dispatch({ type: C.REMOVE })
    } else {
      doesPatientAlreadyExist(patientId)
        .then(res => {
          if (res.patientExists && res.patientInfo) {
            dispatch({
              type: C.PREVIEW,
              payload: [res.patientInfo]
            })
            dispatch(
              notifyActions.addNotification({
                type: 'warning',
                message: `This Patient ID (${patientId}) has already been imported. Close the "Add Patient" modal to preview the existing patient.`,
                sticky: true
              })
            )
          } else {
            // Patient did not exist
            // C.REMOVE ensures previous existing ids are cleared
            dispatch({
              type: C.REMOVE
            })
          }
        })
        .catch(err => {
          dispatch({
            type: C.REMOVE
          })
          dispatch(
            notifyActions.addNotification({
              message: `${err.message} More Info: An error occurred checking if this Patient ID (${patientId}) is already in use.`,
              sticky: true
            })
          )
        })
    }
  }

  render () {
    const {
      patient,
      reduxForm,
      organizations,
      project,
      referrals,
      handleSubmit,
      auth,
      showHistoryChangeReasonField,
      chosenOrg
    } = this.props
    const { isTandigm } = this.state
    const latestHistoryIndex = get(reduxForm, 'profile.initial.history.length', 1) - 1
    const organizationOptions = organizations.userOrgs.map((o, i) => {
      return (
        <MenuItem key={`org${i}`} value={o._id}>
          {o.name}
        </MenuItem>
      )
    })

    const projectOptions = get(project, "allProjects", [])
    const programOptions = projectOptions.filter( proj => {
      const orgId = get(proj, 'Organization.externalId')
      return orgId && orgId === chosenOrg
    }) || []

    const programDisplay = programOptions.map((o,i) => {
      return (
      // setting the couchId as value but this will potentially need to be updated with projects move to programs table in postgres.
        <MenuItem key={`proj${i}`} value={o.couchId}>
          {o.name}
        </MenuItem>
      )
    })

    
    let transferOptions = []
    const transferTo = get(reduxForm, 'profile.values.transferTo')
    const childOrgs = childOrganizations(
      organizations.userOrgs,
      get(patient, 'data.managingOrganization.id')
    )
    const parentOrg = parentFromChild(
      organizations.userOrgs,
      get(patient, 'data.managingOrganization.id')
    )
    if (childOrgs.length) {
      transferOptions = childOrgs.map((o, i) => {
        return (
          <MenuItem key={`transfer${i}`} value={o._id}>
            {o.name}
          </MenuItem>
        )
      })
    } else if (parentOrg) {
      transferOptions = [parentOrg].map((o, i) => {
        return (
          <MenuItem key={`transfer${i}`} value={o._id}>
            {o.name}
          </MenuItem>
        )
      })
    }

    const languageOptions = languages.map((l, i) => {
      return (
        <MenuItem key={`lang{${i}`} value={l}>
          {l}
        </MenuItem>
      )
    })

    const locationOptions = locations.map((l, i) => {
      return (
        <MenuItem key={`loc${i}`} value={l}>
          {l}
        </MenuItem>
      )
    })

    const referralOptions = referrals.allReferrals.map((referral, i) => {
      return (
        <MenuItem key={`ref${i}`} value={referral}>
          {referral.text}
        </MenuItem>
      )
    })

    /* Testing */
    /* const referralOptions = this.props.schema.allReferrals.map((referral, i) => {
    return (
      <MenuItem key={`ref${i}`} value={referral}>
        {referral.text}
      </MenuItem>
    )
    */
    // TODO: handle showing/hiding medicationManager field and related required
    // based on form values. Hide if the member manages their own meds
    const medicationManager =
      get(reduxForm, 'profile.values.medManager') === 'false' ? (
        <div className="mb2">
          <Field
            // required
            name="medManagerOther"
            type="text"
            label="Name of the person who manages the patient's medications"
            component={ReduxFormText}
          />
        </div>
      ) : null

    const isNew = !get(patient, 'data._id')
    const hasReferrals = !!get(reduxForm, 'profile.values.referralType', []).length

    return (
      <Form onSubmit={handleSubmit}>
        <div className="flex justify-between mb2">
          <div className="w-50 mr1">
            <Field
              required
              name="name.first"
              type="text"
              label="First Name"
              component={ReduxFormText}
              data-cy="first-name-field"
            />
            <Field
              name="name.middle"
              type="text"
              label="Middle Name"
              component={ReduxFormText}
              data-cy="middle-name-field"
            />
            <Field
              required
              name="name.last"
              type="text"
              label="Last Name"
              component={ReduxFormText}
              data-cy="last-name-field"
            />
          </div>
          <div className="w-50 ml1">
            <ReduxFormDateInput
              required
              name="dob"
              label="Date of Birth"
              data-cy="dob-field"
            />
            <Field
              required
              name="languagePreference"
              component={ReduxFormSelect}
              label="Preferred Language"
              data-cy="language-field"
            >
              {languageOptions}
            </Field>
            <Field required name="sex" component={ReduxFormSelect} label="Sex" data-cy="sex-field">
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="male">Male</MenuItem>
            </Field>
          </div>
        </div>
        <div className="flex justify-between mb2">
          <div className="w-third mr1" onBlur={this.checkAndWarnIfPatientExists.bind(this)}>
            <Field
              disabled={!!(get(patient, 'data.externalId.length') || !isNew)}
              name="externalId"
              type="text"
              label="Patient ID"
              component={ReduxFormText}
              data-cy="patient-id-field"
            />
          </div>
          <div className="w-two-thirds ml1">
            <Typography variant="subtitle1" noWrap>
              Patient manages their own medication?
            </Typography>
            <Field name="medManager" type="text" component={ReduxFormRadioGroup} />
          </div>
        </div>
        {/* TODO: This should be conditional based on whether or not the member manages their own meds */}
        {medicationManager}
        <div className="flex justify-between mb2">
          <div className="w-third mr1">
            <Field
              required
              disabled={!isNew}
              name="managingOrganization.id"
              component={ReduxFormSelect}
              label="Organization"
              data-cy="organization-field"
            >
              {organizationOptions}
            </Field>
          </div>
          <div className="w-third mh1">
            <Field
              name="facility"
              component={ReduxFormText}
              label="Facility"
              data-cy="facility-field"
            />
          </div>
          <div className="w-third ml1">
            <Field
              name="locationType"
              component={ReduxFormSelect}
              label="Patient Location"
              data-cy="location-field"
            >
              {locationOptions}
            </Field>
          </div>
        </div>
        {isNew && chosenOrg && programOptions.length > 0 ? <div className="w-50 mr1">
          <Field
            required
            name="programId"
            component={ReduxFormSelect}
            label="Program"
            data-cy="program-field"
          >
            {programDisplay}
          </Field>
        </div> : null}
        <div className="flex justify-between mb2">
          <Field
            required={!hasReferrals}
            name="dummyReferral"
            externalOnChange={this.handleAddReferral.bind(this)}
            component={ReduxFormSelect}
            label="Referrals"
            data-cy="referral-field"
          >
            {referralOptions}
          </Field>
        </div>
        <div className="flex justify-between mb2">
          <List>
            {get(reduxForm, 'profile.values.referralType', []).map((ref, i) => (
              <ListItem key={i} divider className="pl2">
                <ListItemText>
                  <div className="truncate w-100">
                    <strong>{ref.text}</strong> - {dayjs(ref.date).format('MM-DD-YYYY')}
                  </div>
                </ListItemText>
                <ListItemSecondaryAction>
                  <button
                    type="button"
                    className="borderless"
                    onClick={this.handleRemoveReferral.bind(this, i)}
                  >
                    <Close />
                  </button>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
        {!isNew && !!transferOptions.length && (
          <div className="flex mb2">
            <div className="w-50 mr1">
              <Field name="transferTo" component={ReduxFormSelect} label="Transfer to...">
                {transferOptions}
              </Field>
            </div>
            <div className={`w-50 ml1 ${!transferTo && 'dn'}`}>
              <Field
                required
                name="transferToReason"
                component={ReduxFormText}
                label="Reason for transfer"
              />
            </div>
          </div>
        )}
        {!isNew && isPatientInECPNOrg(patient.data) && (childOrgs.length || parentOrg) && (
          <div className="flex justify-between mb2">
            <div className="dib w-100">
              <Field
                name="removeFromECPNWorkflow"
                type="checkbox"
                label="Remove from eCPN Workflow"
                component={ReduxFormCheckbox}
              />
            </div>
          </div>
        )}
        {isTandigm && (
          <IdAM
            requireIdentity
            auth={auth}
            access={p =>
              includes(get(p, 'permissions.medwise-advisor', []), 'edit-discharge-date') ||
              includes(get(p, 'roles', []), 'support')
            }
          >
            <div className="flex justify-between mb2">
              <div className="w-50 pr1">
                <ReduxFormDatePicker
                  required={!!get(reduxForm, 'profile.initial.history.length')}
                  name={`history[${latestHistoryIndex}].dischargeDate`}
                  label="Date of Discharge"
                  parse={val => dayjs(val).format(DateFormat.SYSTEM_DATE)}
                  data-cy="dischargeDate-field"
                />
              </div>
              <div className="w-50 pl1">
                <Field
                  name={`history[${latestHistoryIndex}].homeCareFlag`}
                  component={ReduxFormSelect}
                  label="Home Care Flag"
                  data-cy="homeCareFlag-field"
                >
                  <MenuItem value="">Unknown</MenuItem>
                  <MenuItem value="1">Home</MenuItem>
                  <MenuItem value="0">Call</MenuItem>
                </Field>
              </div>
            </div>
            {showHistoryChangeReasonField && (
              <div className="flex justify-between mb2">
                <div className="w-100">
                  <Field
                    name={`history[${latestHistoryIndex}].manualChange.reason`}
                    required={showHistoryChangeReasonField}
                    component={ReduxFormText}
                    label="Reason for Change"
                    data-cy="dischargeDateChangeReason-field"
                  />
                </div>
              </div>
            )}
          </IdAM>
        )}
      </Form>
    )
  }
}

const mapStateToProps = state => {
  const lastHistoryIndex = get(state, 'form.profile.values.history.length', 1) - 1
  const chosenOrg = get(state, 'form.profile.values.managingOrganization.id')
  return {
    auth: get(state, 'auth'),
    showHistoryChangeReasonField: isDirty(OBJECT_NAME)(
      state,
      `history[${lastHistoryIndex}].dischargeDate`,
      `history[${lastHistoryIndex}].homeCareFlag`
    ),
    chosenOrg
  }
}

export default reduxForm({
  form: OBJECT_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(connect(mapStateToProps)(PatientFormProfileSection))
