/*
 * Dummy Page for placeholder content
 */

import React, { Component } from 'react'

class DummyPage extends Component {
  render () {
    return (
      <div className="page dummy">
        <h1>This is a dummy page</h1>
        <p className="h5">
          You could use it to test out building new components
        </p>
        <p className="h5">I'm making large blocks</p>
        <p className="h5">To test scrolling</p>
        <p className="h5">The page</p>
      </div>
    )
  }
}

export default DummyPage
