import React from 'react'
import { find, get, toString } from 'lodash'
import { connect } from 'react-redux'
import { Field, Form, formValueSelector, isDirty, reduxForm } from 'redux-form'
import { FormHelperText, Typography } from '@material-ui/core'

import { patientIsInProject } from 'services/project'

import { actions as patientActions } from 'store/modules/patient'

import AddressForm from 'components/common/form-address'
import PhoneForm from 'components/common/form-phone'
import FormHeading from 'components/common/form-heading'
import ReduxFormRadioGroup from 'components/common/redux-form/radio-group'
import ReduxFormText from 'components/common/redux-form/text'

/**
 * Contact information section of the patient information form.
 * @constructor
 */
class PatientFormContactSection extends React.Component {
  isCellphoneSupported () {
    const { reduxForm } = this.props
    const patient = get(reduxForm, 'profile.values')

    return ['android', 'ios'].includes(get(patient, 'telecom.cellphone.os'))
  }

  hasValidEmail () {
    const { reduxForm } = this.props
    const email = get(reduxForm, 'profile.values.telecom.email')
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  eligibleForMyMedWise () {
    return !get(this.props, 'patientOrg.usesNewWorkflow')
  }

  canInviteToMyMWA () {
    const { reduxForm } = this.props
    const patient = get(reduxForm, 'profile.values')

    return (
      get(patient, 'managingOrganization.id') === 'org:EMTM' &&
      get(patient, 'telecom.cellphone.isSmartPhone') &&
      this.isCellphoneSupported() &&
      this.hasValidEmail()
    )
  }

  justInvitedToMyMWA = false // used to keep the MyMedWise invite btn disabled
  handleInviteToMyMWA () {
    const { reduxForm } = this.props
    const patient = get(reduxForm, 'profile.values')
    const params = {
      patientId: patient._id,
      email: get(patient, 'telecom.email'),
      os: get(patient, 'telecom.cellphone.os')
    }

    this.props.patientActions.sendInvitationEmail(params)
    this.justInvitedToMyMWA = true
  }

  render () {
    const {
      address1IsRequired,
      address2IsRequired,
      badNumber,
      cellphoneIsPrimary,
      handleSubmit,
      phone1IsPrimary,
      phone2IsPrimary,
      patient,
      telecom
    } = this.props

    const myMWButtonDisabled = patient && (
      !this.canInviteToMyMWA() ||
      patient.loading ||
      this.justInvitedToMyMWA
    )

    return (
      <Form onSubmit={handleSubmit}>
        <AddressForm label="Address 1" formPrefix="address" isOptional={!address1IsRequired} />
        <AddressForm label="Address 2" formPrefix="address2" isOptional={!address2IsRequired} />

        <div className="flex justify-between">
          <div className="dib w-100 mt3">
            <FormHeading text="Phone and Email" />
          </div>
        </div>
        <div className="flex justify-between">
          <div className="dib w-50 mr1">
            <PhoneForm
              isOptional
              fieldLabel="Primary Phone"
              phoneFieldName="telecom.phone"
              preferredFieldName="telecom.phonePreferred"
              mobileFieldName="telecom.phoneMobile"
              badNumber={badNumber && phone1IsPrimary}
            />
          </div>
          <div className="dib w-50 ml1">
            <PhoneForm
              isOptional
              fieldLabel="Secondary Phone"
              phoneFieldName="address2.phone"
              preferredFieldName="address2.phonePreferred"
              mobileFieldName="address2.phoneMobile"
              badNumber={badNumber && phone2IsPrimary}
            />
          </div>
        </div>
        <div className="flex justify-between">
          <div className="dib w-50 mr1">
            <PhoneForm
              fieldLabel="Mobile Phone"
              phoneFieldName="telecom.cellphone.number"
              preferredFieldName="telecom.cellphone.preferred"
              smartphoneFieldName="telecom.cellphone.isSmartPhone"
              smartphoneTypeFieldName="telecom.cellphone.os"
              helperText="Required for Text Message Services"
              badNumber={badNumber && cellphoneIsPrimary}
              telecom={telecom}
            />
          </div>
          <div className="div w-50 ml1">
            <Field
              name="telecom.email"
              label="Email Address"
              helperText={this.eligibleForMyMedWise() ? "Required for MyMedWise Services" : null}
              component={ReduxFormText}
            />
            {patient && patientIsInProject(patient.data, 'proj:MyMWA') && (
              <div className="mt2">
                <button
                  type="button"
                  className={`primary ${myMWButtonDisabled ? 'disabled' : ''}`}
                  onClick={this.handleInviteToMyMWA.bind(this)}
                  disabled={myMWButtonDisabled}
                >
                  Invite to MyMedWise
                </button>
                {!this.hasValidEmail() && (
                  <FormHelperText error>The patient must have a valid email address</FormHelperText>
                )}
                {!this.isCellphoneSupported() && (
                  <FormHelperText error>
                    MyMedWise is only supported on Android and iOS devices
                  </FormHelperText>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-between">
          <div className="dib w-100 mt3">
            <FormHeading text="Service Options" />
            <div>Service options may require a mobile phone number and/or email address.</div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="dib w-40 mr1">
            <Typography variant="subtitle1" noWrap>
              <strong>Text Message Services</strong>
            </Typography>
          </div>
          <div className="dib w-60 ml1">
            <Field
              name="telecom.cellphone.receiveSMS"
              type="checkbox"
              component={ReduxFormRadioGroup}
              format={toString}
              normalize={JSON.parse}
            />
          </div>
        </div>
        {this.eligibleForMyMedWise() && (
          <div className="flex items-center justify-between">
            <div className="dib w-40 mr1">
              <Typography variant="subtitle1" noWrap>
                <strong>MyMedWise Services</strong>
              </Typography>
            </div>
            <div className="dib w-60 ml1">
              <Field
                name="serviceOptions.myMedWiseServices"
                type="text"
                component={ReduxFormRadioGroup}
              />
            </div>
          </div>
        )}
      </Form>
    )
  }
}

const selector = formValueSelector('profile')
const mapStateToProps = state => {
  const address1IsRequired = selector(state, 'address.primary')
  const address2IsRequired = selector(state, 'address2.primary')

  const badNumber = get(state, 'patient.data.badNumber')
  const memberIsPreferredContact = !selector(state, 'caregiver.preferredContact')

  const phone1IsPrimary = get(state, 'form.profile.initial.telecom.phonePreferred')
  const phone2IsPrimary = get(state, 'form.profile.initial.address2.phonePreferred')
  const cellphoneIsPrimary = get(state, 'form.profile.initial.telecom.cellphone.preferred')

  const primaryPhoneInfo = { phone: 'telecom.phone', preferred: 'telecom.phonePreferred' }

  if (!phone1IsPrimary && phone2IsPrimary) {
    primaryPhoneInfo.phone = 'address2.phone'
    primaryPhoneInfo.preferred = 'address2.phonePreferred'
  }

  if (!phone1IsPrimary && !phone2IsPrimary && cellphoneIsPrimary) {
    primaryPhoneInfo.phone = 'telecom.cellphone.number'
    primaryPhoneInfo.preferred = 'telecom.cellphone.preferred'
  }

  const telecom = selector(state, 'telecom')

  const hasBadNumber =
    badNumber &&
    memberIsPreferredContact &&
    !isDirty('profile')(state, primaryPhoneInfo.phone, primaryPhoneInfo.preferred)

  const allOrgs = get(state, 'organizations.allOrgs', [])
  const patientOrg = find(allOrgs, o => get(o, '_id') === selector(state, 'managingOrganization.id'))

  return {
    address1IsRequired,
    address2IsRequired,
    badNumber: hasBadNumber,
    phone1IsPrimary:
      phone1IsPrimary || (!phone1IsPrimary && !phone2IsPrimary && !cellphoneIsPrimary),
    phone2IsPrimary: !phone1IsPrimary && phone2IsPrimary && !cellphoneIsPrimary,
    cellphoneIsPrimary: !phone1IsPrimary && !phone2IsPrimary && cellphoneIsPrimary,
    telecom,
    patientOrg
  }
}

const mapDispatchToProps = dispatch => ({
  patientActions: {
    sendInvitationEmail: params => dispatch(patientActions.sendInvitationEmail(params))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'profile',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })(PatientFormContactSection)
)
