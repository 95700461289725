import React, { Component } from 'react'
import { get as _get } from 'lodash'
import dayjs from 'dayjs'
import { TabbedBox } from 'components/common/tabs'
import { DateFormat } from 'constants/date'
import PatientFormProfileSection from './form-sections/profile'
import PatientFormContactSection from './form-sections/contact-information'
import validate from './validation'
import { isValidDateObject } from 'validators/date'

class TabbedForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      box: {
        id: 'patient-form',
        enabled: true,
        tabs: [
          {
            id: 'patient-profile-form',
            name: 'Patient Form',
            component: props => (
              <PatientFormProfileSection
                initialValues={props.initialValues}
                onSubmit={props.onSubmit}
                organizations={props.organizations}
                project={props.project}
                referrals={props.referrals}
                patient={props.patient}
                reduxForm={props.reduxForm}
                validate={validate}
              />
            ),
            active: true,
            error: () => props.profileTabErrorsFunc()
          },
          {
            id: 'patient-contact-info-form',
            name: 'Contact Information',
            component: props => (
              <PatientFormContactSection
                initialValues={props.initialValues}
                project={props.project} // have to send these for validation purposes
                onSubmit={props.onSubmit}
                organizations={props.organizations}
                referrals={props.referrals}
                patient={props.patient}
                reduxForm={props.reduxForm}
                validate={validate}
              />
            ),
            error: () => props.contactTabErrorsFunc()
          }
        ],
        buttons: []
      }
    }
  }

  changeTab = ({ tab }) => {
    let { box } = this.state
    box.tabs.forEach(t => {
      if (t.id === tab.id) {
        t.active = true
      } else {
        t.active = false
      }
    })
    this.setState({ box })
  }

  render () {
    const { box } = this.state
    return (
      <TabbedBox
        key={box.id}
        box={box.id}
        tabs={box.tabs}
        buttons={box.buttons}
        changeTab={this.changeTab.bind(this)}
        {...this.props}
      />
    )
  }
}

class ProfileForm extends Component {
  profileTabRequiredFields = [
    'name.first',
    'name.last',
    'dob',
    'languagePreference',
    'sex',
    'medManagerOther',
    'managingOrganization.id',
    'dummyReferral'
  ]

  contactTabRequiredFields = ['address', 'address2', 'telecom', 'serviceOptions']

  checkTabErrors (requiredFieldsList) {
    const errors = _get(this.props, 'reduxForm.profile.syncErrors', {})
    // Because programId is conditionally required if managingOrganization has a program just check for it by itself.
    if (_get(errors, 'programId')) {
      return true
    } else {
      for (var i = 0; i < requiredFieldsList.length; i++) {
        const field = requiredFieldsList[i]
        if (_get(errors, field, false)) {
          return true
        }
      }
    }
  }

  render () {
    const { initialValues, onSubmit, patient, reduxForm, organizations, project, schema } = this.props
    initialValues.dob = isValidDateObject(initialValues.dob) ?
      dayjs(initialValues.dob).format(DateFormat.DATE_DISPLAY_SHORT) :
      undefined

    return (
      <TabbedForm
        organizations={organizations}
        project={project}
        referrals={schema}
        patient={patient}
        reduxForm={reduxForm}
        initialValues={initialValues}
        onSubmit={onSubmit}
        profileTabErrorsFunc={() => this.checkTabErrors(this.profileTabRequiredFields)}
        contactTabErrorsFunc={() => this.checkTabErrors(this.contactTabRequiredFields)}
      />
    )
  }
}

ProfileForm.propTypes = {}

export default ProfileForm
