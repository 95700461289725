import React, { Component } from 'react'
import { get, groupBy, includes, mapValues, forOwn } from 'lodash'
import dayjs from 'dayjs'
import { DateFormat } from 'constants/date'

const OK_STATUS = 'OK'
const MATRIX_ERR_STATUS = 'MATRIX_ERR'
// used for 'an' vs 'a' in plainEnglishSummary
const anStats = ['sdv', 'acb', 'dob', 'org', 'lqts']

//  AnyCharIn[ 0 to 9, '-', '.', '(', ')', '+', ',', WhiteSpaceCharacter]
const PHONE_REGEX = /^[0-9\-\.\(\)\+\,\s]*$/ // eslint-disable-line no-useless-escape

let dobFilterAgeExtractor = filterVal => {
  // grabs the year value form the elasticsearch date query string (i.e now-18y)
  return parseInt(filterVal.split('-')[1], 10)
}

let statTypeText = filterOrSort => {
  switch (filterOrSort.statType) {
    case 'dob':
      return 'age'
    case 'date':
      return 'last updated date'
    case 'fname':
      return 'first name'
    case 'lname':
      return 'last name'
    default:
      return filterOrSort.statType.replace(/_/g, ' ')
  }
}

let sortOrderText = sort => {
  if (sort.statType === 'dob') {
    return sort.order === 'asc' ? 'desc' : 'asc'
  }

  return sort.order
}

let termFilterText = (term, statType) => {
  if (statType === 'dob') {
    term = dobFilterAgeExtractor(term)
  } else if (statType === 'sex') {
    term = term === 'm' ? 'male' : 'female'
  } else if (statType === 'status') {
    switch (term) {
    case OK_STATUS:
      term = 'No Error'
      break
    case MATRIX_ERR_STATUS:
      term = 'Calculation Error'
      break
    default:
    }
  }

  return term
}

const rangeFilterText = (lteVal, gteVal, statType) => {
  var units = ''

  if (statType === 'dob') {
    // if they exist, parse to number
    var gteValTemp = gteVal && dobFilterAgeExtractor(gteVal)
    var lteValTemp = lteVal && dobFilterAgeExtractor(lteVal)
    // swap because user defining age.. which is inverse to date (greater age == lesser date)
    gteVal = lteValTemp
    lteVal = gteValTemp
    units = 'yrs'
  }

  if (gteVal === null) {
    return `less than ${lteVal} ${units}`
  } else if (lteVal === null) {
    return `greater than ${gteVal} ${units}`
  } else {
    return `${gteVal} - ${lteVal} ${units}`
  }
}

let nest = (seq, keys) => {
  if (!keys.length) return seq
  var first = keys[0]
  var rest = keys.slice(1)
  return mapValues(groupBy(seq, first), value => {
    return nest(value, rest)
  })
}

let fieldSort = (a, b, field) => {
  if (a[field] < b[field]) return -1
  if (a[field] > b[field]) return 1
  return 0
}

let statTypeSort = (a, b) => {
  return fieldSort(a, b, 'statType')
}

let termSort = (a, b) => {
  return fieldSort(a, b, 'term')
}

class PlainEnglishSummary extends Component {
  // render functions
  renderSearchString = () =>
    PHONE_REGEX.test(this.props.searchString) ? (
      <span>
        with a <strong>phone number</strong> starting with{' '}
        <strong>"{this.props.searchString}" </strong>
      </span>
    ) : (
      <span>
        with a <strong>name</strong> like <strong>"{this.props.searchString}" </strong>
      </span>
    )

  renderQuery = (statType, query, index, array) => {
    let gteVal = get(query, 'gte', null)
    let lteVal = get(query, 'lte', null)
    return (
      <span key={`${statType}-${index}`}>
        <strong>
          {query.queryType === 'term' && termFilterText(get(query, 'term', null), statType)}
          {query.queryType !== 'term' && rangeFilterText(lteVal, gteVal, statType)}
        </strong>
        {index >= 0 && index < array.length - 2 && ', '}
        {index === array.length - 2 && array.length && ' or '}
      </span>
    )
  }

  renderGroupedFiltersForStatType = (groupedFiltersForStatType, statTypeIndex, statTypeArray) => {
    let aOrAn = includes(anStats, groupedFiltersForStatType.statType) ? 'an' : 'a'
    let termThenRangeQueries = []
    termThenRangeQueries =
      get(groupedFiltersForStatType, 'queryTypes.term.length', 0) > 0
        ? termThenRangeQueries.concat(groupedFiltersForStatType.queryTypes.term.sort(termSort))
        : termThenRangeQueries
    termThenRangeQueries =
      get(groupedFiltersForStatType, 'queryTypes.range.length', 0) > 0
        ? termThenRangeQueries.concat(groupedFiltersForStatType.queryTypes.range)
        : termThenRangeQueries

    return (
      <span key={`${groupedFiltersForStatType.statType}-${statTypeIndex}`}>
        {` ${aOrAn} `}
        <strong>{statTypeText(groupedFiltersForStatType)}</strong>
        {' of '}
        {termThenRangeQueries.map((query, index, array) => {
          return this.renderQuery(groupedFiltersForStatType.statType, query, index, array)
        })}
        {statTypeIndex < statTypeArray.length - 1 && ' and '}
      </span>
    )
  }

  renderFilterString = () => {
    // filters grouped/nested by statType then queryType
    var groupedFilters = nest(this.props.filters, ['statType', 'queryType'])

    // turn this into array of {statType: 'sdv', queryTypes: {term: [], range: []} }
    var groupedFiltersCollection = []
    forOwn(groupedFilters, (queryTypes, statType) => {
      groupedFiltersCollection.push({
        statType: statType,
        queryTypes: queryTypes
      })
    })

    groupedFiltersCollection.sort(statTypeSort)

    return (
      <span>
        {'with '}
        {groupedFiltersCollection.map(this.renderGroupedFiltersForStatType)}
      </span>
    )
  }

  renderSortForStatType = (sort, index, array) => {
    const sortType = statTypeText(sort)

    const sortOrder = sortOrderText(sort)

    const sortOrderEnglish = sortOrder === 'asc' ? 'ascending' : 'descending'

    return (
      <span key={`sort-${sortType.replace(' ', '-')}-${sortOrder}`}>
        {`${index > 0 ? ' then' : ''} by `}
        <strong>{`${sortType} ${sortOrderEnglish}`}</strong>
        {`${index >= 0 && index < array.length - 1 ? ',' : ''}`}
      </span>
    )
  }

  renderDOBString = () => {
    return (
      <span>
        {`with a birth date of `}
        <strong>{dayjs(this.props.dob).format(DateFormat.DATE_DISPLAY)}</strong>
      </span>
    )
  }

  renderSortingString = () => (
    <span>
      {`sorted ${this.props.sort.length > 1 ? 'first' : ''} `}
      {this.props.sort.map((sortObject, sortingIndex, sortingArray) =>
        this.renderSortForStatType(sortObject, sortingIndex, sortingArray)
      )}
    </span>
  )

  render () {
    let hasValidSearchString = get(this.props, 'searchString.length', 0) >= 3
    let dob = get(this.props, 'dob')
    let hasFilters = get(this.props, 'filters.length', 0) > 0
    let hasSorting = get(this.props, 'sort.length', 0) > 0

    return (
      <div>
        {`showing ${
          !hasValidSearchString && !dob && !hasFilters && !hasSorting
            ? 'all patients '
            : 'patients '
        }`}
        {hasValidSearchString && this.renderSearchString()}
        {hasValidSearchString && dob && ' or '}
        {dob && this.renderDOBString(dob)}
        {(hasValidSearchString || dob) && hasFilters && ' and '}
        {hasFilters && this.renderFilterString()}
        {(hasValidSearchString || dob || hasFilters) && hasSorting && ' and '}
        {hasSorting && this.renderSortingString()}
      </div>
    )
  }
}

export default PlainEnglishSummary
