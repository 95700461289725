import { createAction } from 'redux-actions'

import gateway from 'services/gateway'

export const setMedications = createAction('MEDICATION_RECONCILIATION/SET_MEDICATIONS')

export const getPatientMedications = (patientID) => {
  return async dispatch => {

    try {
      const reconciledResults = await gateway.get(`/api/medwise/medications?includeFormularyData=true&patientId=${patientID}`)
      const patientListsMedications = await gateway.get(`/api/medwise/reconciliation/list?patientId=${patientID}`)

      let unreconciledMeds = []
      patientListsMedications.lists.forEach((list) => {
        unreconciledMeds = unreconciledMeds.concat(list.medications)
      })
      
      dispatch(setMedications({ reconciled: reconciledResults, unreconciled: unreconciledMeds }))
    } catch (err) {
      console.log('There was an error')
    }
  }
}

