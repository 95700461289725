import { get, set } from 'lodash'
import { DateFormat, Messages } from 'constants/date'
import { isValidDateObject, isFuture } from 'validators/date'
import dayjs from 'dayjs'

const validate = int => {
  let errors = {}
  const errorMessage = fieldName => `${fieldName} is required`
  const checkField = (fieldPath, errorMessage) => {
    if (!get(int, fieldPath)) {
      set(errors, fieldPath, errorMessage)
    }
  }

  const requiredFields = [
    { path: 'app', display: 'Interaction' },
    { path: 'currentYear', display: 'Year' },
    { path: 'workflowIteration', display: 'Engagement' },
    { path: 'record.dateRecorded', display: 'Date Added' }
  ]

  requiredFields.forEach(field => {
    checkField(field.path, errorMessage(field.display))
  })

  if (int.app === 'Initiating Call' && !get(int, 'deleteValues.attempts.initial')) {
    set(errors, 'deleteValues.attempts.initial', 'Attempt is required')
  }

  if (int.app === 'Medication Review' && !get(int, 'deleteValues.attempts.medrec')) {
    set(errors, 'deleteValues.attempts.medrec', 'Attempt is required')
  }

  if (int.app === 'Medication Safety Review' && !get(int, 'deleteValues.attempts.msr')) {
    set(errors, 'deleteValues.attempts.msr', 'Attempt is required')
  }
  const workflowInteractions = ['Initiating Call', 'Medication Review', 'Medication Safety Review']
  const successfulCalls = ['Member & Caregiver', 'Member', 'Caregiver']
  const inboundWorkflowCalls = ['member', 'caregiver']
  if (workflowInteractions.indexOf(int.app) === -1 && !get(int, 'record.description')) {
    set(errors, 'record.description', 'Note is required')
  }

  if (
    (workflowInteractions.indexOf(int.app) !== -1 &&
      (successfulCalls.indexOf(int.spokeTo) !== -1 ||
      inboundWorkflowCalls.indexOf(get(int, 'record.direction')) !== -1)) &&
    get(int, 'record.decision') === null
  ) {
    set(errors, 'record.decision', 'Member Decision is required')
  }

  if (int.spokeTo === 'Other' && !int.record.description) {
    set(errors, 'record.description', 'Note is required')
  }

  if (
    workflowInteractions.indexOf(int.app) !== -1 &&
    int.record.decision === false &&
    !int.record.decisionReason
  ) {
    set(errors, 'record.decisionReason', 'Reason is required')
  }

  if (get(int, 'record.direction') === 'user' && !int.spokeTo) {
    set(errors, 'spokeTo', 'Recipient is required')
  }

  const auditDate = get(int, 'record.dateRecorded')
  const validateDate = (date, errorKeyName) => {
    // Recorded dates are input as strings following a MM/DD/YYYY format. Once saved
    // the date persists in epoch format. For fns to handle this difference the fn's
    // date argument is converted to the MM/DD/YYYY format before checking its validity
    // explicitly against the expected input format of MM/DD/YYYY.
    if (!isValidDateObject(dayjs(date).format(DateFormat.DATE_DISPLAY_SHORT), DateFormat.DATE_DISPLAY_SHORT)) return set({}, errorKeyName, Messages.INVALID_DATE)
    if (isFuture(date)) return set({}, errorKeyName, Messages.TODAY_OR_PAST)
  }
  if (auditDate) {
    errors = Object.assign(errors, validateDate(auditDate, 'record.dateRecorded'))
  }

  return errors
}

export default validate
