/*
 * Notify reducer
 */

import * as C from './types'

const notifyReducer = (
  state = {
    timeout: 5000, // the default timeout
    notifications: []
  },
  action
) => {
  switch (action.type) {
  case C.ADD_NOTIFICATION:
    return {
      ...state,
      notifications: [...state.notifications, action.payload]
    }
  case C.REMOVE_NOTIFICATION:
    // action.payload should have the id of the notification.
    let i = state.notifications.findIndex(elm => elm.id === action.payload)
    if (i === -1) return state
    let notifications = [
      ...state.notifications.slice(0, i),
      ...state.notifications.slice(i + 1)
    ]
    return {
      ...state,
      notifications: notifications
    }
  case C.CLEAR_NOTIFICATIONS:
    return {
      ...state,
      notifications: []
    }
  default:
    return state
  }
}

export default notifyReducer
