import React  from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { get as _get } from 'lodash'
import { Typography } from '@material-ui/core'
import ReduxFormCheckbox from 'components/common/redux-form/checkbox'
import ReduxFormRadioGroup from 'components/common/redux-form/radio-group'
import ReduxPhoneField from 'components/common/redux-form/phone-input'

/**
 * Form field to input phone numbers.
 *
 * @param {object} props - Properties passed to the component
 * @param {string} props.fieldLabel - the label for this field
 * @param {boolean} isOptional=false - whether this field is optional
 * @param {string} props.phoneFieldName - the Redux field associated with the phone field
 * @param {string} [props.preferredFieldName] - the Redux field associated with marking this phone number as the Preferred phone number for this contact
 * @param {string} [props.mobileFieldName] - the Redux field associated with marking this phone number as a mobile phone number
 * @param {string} [props.smartphoneFieldName] - the Redux field associated with marking this phone number as a smartphone number
 * @param {string} [props.smartphoneTypeFieldName] - the Redux field associated with {android, ios, other} object. Generall should be `...os`
 * @param {string} [props.receiveSmsFieldName] - the Redux field associated with marking this phone number as recipient of SMS messages
 * @constructor
 */
const PhoneForm = props => {
  const {
    phoneFieldName,
    preferredFieldName,
    mobileFieldName,
    smartphoneFieldName,
    smartphoneTypeFieldName,
    receiveSmsFieldName,
    fieldLabel,
    isOptional,
    telecom,
    ...other
  } = props

  const osValues = [
    { label: 'iOS', value: 'ios' },
    { label: 'Android', value: 'android' },
    { label: 'Other', value: 'other' }
  ]

  return (
    <div className="flex flex-column">
      <ReduxPhoneField required={!isOptional} name={phoneFieldName} label={fieldLabel} {...other} />
      <div className="flex justify-between w-100">
        {preferredFieldName && (
          <div className="dib w-40">
            <Field
              name={preferredFieldName}
              label="Preferred"
              type="checkbox"
              component={ReduxFormCheckbox}
            />
          </div>
        )}
        {mobileFieldName && (
          <div className="dib w-40">
            <Field
              name={mobileFieldName}
              label="Mobile"
              type="checkbox"
              component={ReduxFormCheckbox}
            />
          </div>
        )}
        {smartphoneFieldName && (
          <div className="dib w-60">
            <Field
              name={smartphoneFieldName}
              label="Smart Phone"
              type="checkbox"
              component={ReduxFormCheckbox}
            />
          </div>
        )}
        {receiveSmsFieldName && (
          <div className="dib w-60">
            <Field
              name={receiveSmsFieldName}
              label="Receive SMS"
              type="checkbox"
              component={ReduxFormCheckbox}
            />
          </div>
        )}
      </div>
      {_get(telecom, 'cellphone.isSmartPhone') === true && smartphoneTypeFieldName && (
        <div className="w-100">
          <Typography variant="subtitle1" noWrap>
            Smartphone type?
          </Typography>
          <Field
            name={smartphoneTypeFieldName}
            type="text"
            component={ReduxFormRadioGroup}
            radioLabelsAndValues={osValues}
          />
        </div>
      )}
    </div>
  )
}

PhoneForm.propTypes = {
  fieldLabel: PropTypes.string.isRequired,
  phoneFieldName: PropTypes.string.isRequired,
  preferredFieldName: PropTypes.string,
  mobileFieldName: PropTypes.string,
  smartphoneFieldName: PropTypes.string,
  smartphoneTypeFieldName: PropTypes.string,
  receiveSmsFieldName: PropTypes.string,
  isOptional: PropTypes.bool
}

PhoneForm.defaultProps = {
  isOptional: true
}

export default PhoneForm
