/*
 * Dashboard allergies tab
 */

import React, { Component } from 'react'
import pluralize from 'pluralize'
import dayjs from 'dayjs'
import { get as _get } from 'lodash'

import TitleCell from 'components/common/table-cells/title-cell'
import StandardTab from 'components/common/standard-tab'

import { DateFormat } from 'constants/date'

import { formatUserName } from 'services/utils'

import { AllergiesForm } from './allergies-side-effects-form'

const OBJECT_NAME = 'allergy'

class Allergies extends Component {
  constructor (props) {
    super(props)

    this.formHandler = this.formHandler.bind(this)
    this.handleAllergyDelete = this.handleAllergyDelete.bind(this)
  }

  filterToAllergies = allergyArray => allergyArray.filter(a => !a.isSideEffect)

  // removed CLINDEV-3010 and CLINDEV-2976 from release/2.3.0
  // createNkda = nkda => {
  //   if (nkda.status) {
  //     return {
  //       cellText: '',
  //       substance: { text: _get(nkda, 'displayName'), coding: [{ type: 'Not Applicable' }] },
  //       performedBy: {
  //         userFirstName: _get(nkda, 'reportedBy.firstName'),
  //         userLastName: _get(nkda, 'reportedBy.lastName')
  //       },
  //       // onsetDate: 'Not Applicable',
  //       updatedOn: _get(nkda, 'updatedOn'),
  //       description: _get(nkda, 'description'),
  //       nkda: true,
  //       allergyNote: _get(nkda, 'note')
  //     }
  //   } else {
  //     return {}
  //   }
  // }

  DEFAULT_STRUCTURE = {
    substance: {
      text: undefined,
      coding: [
        {
          system: undefined,
          display: undefined,
          type: undefined
        },
        {
          system: undefined,
          display: undefined,
          type: undefined,
          value: undefined
        }
      ]
    },
    reaction: undefined,
    description: undefined,
    sensitivityType: 'allergy',
    type: 'patient.allergy',
    subject: this.props.patient.data._id,
    status: 'active',
    onsetDate: null,
    allergyNote: undefined
  }
  /**
   * Sends the allergy to the story to be removed from the list.
   * @param  {object}  data Allergy object to be sent to the gateway to have it's status updated to inactive.
   */
  async handleAllergyDelete (data) {
    const { patientDataActions } = this.props
    await patientDataActions.deletePatientData(data)
    /** removed CLINDEV-3010 and CLINDEV-2976 from release/2.3.0
    if (data.nkda) {
      let patientNkda = {
        status: false,
        reportedBy: getProfileForSave(),
        // onsetDate: 'Not Applicable',
        updatedOn: new Date(),
        description: _get(data, 'description'),
        note: _get(data, 'allergyNote')
      }
      _set(this.props, 'patient.data.medwise_meta.nkda', patientNkda)
      await patientActions.updatePatient(this.props.patient.data)
    } else {
      await patientDataActions.deletePatientData(data)
    }
    */
  }

  async formHandler (data, editing) {
    const { patientDataActions } = this.props
    await patientDataActions.updatePatientData(data, OBJECT_NAME, editing ? 'post' : 'post')
    /* removed CLINDEV-3010 and CLINDEV-2976 from release/2.3.0
    if (data.nkda) {
      // no known drug allergies is saved on the patient doc
      let patientNkda = {
        status: true,
        snowMedCode: '409137002',
        displayName: 'No known drug allergy (situation)',
        reportedBy: getProfileForSave(),
        updatedOn: new Date(),
        description: _get(data, 'description'),
        note: _get(data, 'allergyNote')
      }
      _set(this.props, 'patient.data.medwise_meta.nkda', patientNkda)
      await patientActions.updatePatient(this.props.patient.data)
    } else {
      await patientDataActions.updatePatientData(data, OBJECT_NAME, editing ? 'post' : 'post')
    }
    */
  }

  render () {
    const { patientData } = this.props
    let modalProps = {
      hideNkdaCheckbox: _get(this.props, 'patient.data.medwise_meta.nkda.status')
    }
    let headers = [
      {
        name: 'Name',
        classNames: 'w-30',
        maps: [
          { propName: 'boldText', value: 'substance.text' },
          {
            propName: 'descriptionText',
            default: 'Reaction',
            value: 'cellText'
          },
          { propName: 'lightText', value: 'reaction' },
          { propName: 'boldClass', default: 'truncate' }
        ],
        component: TitleCell
      },
      { name: 'Type', maps: 'substance.coding[0].type' },
      { name: 'Summary', classNames: 'w-20 truncate', maps: 'description' },
      {
        name: 'Onset Date',
        maps: allergy =>
          allergy.onsetDate
            ? dayjs(_get(allergy, 'onsetDate')).format(DateFormat.DATE_DISPLAY)
            : undefined
      },
      { name: 'Reported By', classNames: 'truncate', maps: allergy => formatUserName(allergy) },
      {
        name: 'Updated',
        maps: allergy => dayjs(_get(allergy, 'updatedOn')).format(DateFormat.DATE_DISPLAY)
      }
    ]

    let data = this.filterToAllergies(_get(patientData, pluralize(OBJECT_NAME), []))
    /** removed CLINDEV-3010 and CLINDEV-2976 from release/2.3.0

    let nkdaData = this.createNkda(_get(patient, 'data.medwise_meta.nkda', {}))

    if (Object.keys(nkdaData).length > 0) {
      data.unshift(nkdaData)
    }
    */
    return (
      <StandardTab
        name="Allergy"
        tableHeaders={headers}
        tableData={data}
        object={OBJECT_NAME}
        form={AllergiesForm}
        initialValues={this.DEFAULT_STRUCTURE}
        modalFormProps={modalProps}
        formHandler={this.formHandler}
        handleDelete={this.handleAllergyDelete}
        deleteAlert
        pagination={{
          pageSize: 5,
          maxPages: 5
        }}
        {...this.props}
      />
    )
  }
}

export default Allergies
