import { get, isEqual, omit, set } from 'lodash'
import dayjs from 'dayjs'

import * as patientTypes from './types'
import * as patientActions from './actions'
import { types as medRecTypes } from 'store/modules/medication-reconciliation'
import { types as reviewTypes } from 'store/modules/review'

import { INITIATING_CALL_FTE_MAX, MED_REC_REVIEW_FTE_MAX } from 'constants/interactions'
import { WORKFLOW_NAMES, reverseLookup } from 'constants/workflow'

import gateway from 'services/gateway'
import { getProjectIdAssumingATonOfThings } from 'services/project'
import { generateAutoReview, reviewIncludesResponses } from 'services/review'
import { isPatientInECPNOrg } from 'services/patient'
import ProjectService from 'services/project'
import { isUserGraduated } from 'services/auth'

export const alreadyInEndState = workflow => {
  return (
    workflow.includes('Disenroll') ||
    workflow.includes('Hospice') ||
    workflow.includes('Opt Out') ||
    workflow.includes('Failure to Engage') ||
    workflow.includes('Complete') ||
    workflow.includes('Waiting for Mailer')
  )
}

export const badPreviousState = workflow => {
  if (!workflow) return null
  return workflow.includes('Opt Out') || workflow.includes('Failure to Engage')
}

export const setOptOutInfo = (patient, call, user) => {
  const oldWorkflow = get(patient, 'currentWorkflowState.name')
  set(patient, 'currentWorkflowState.name', reverseLookup(`Member Opt Out at ${call.app}`))
  set(patient, 'currentWorkflowState.optOutInfo', {
    decisionReason: call.record.decisionReason,
    call,
    user: user.email,
    userId: user.id,
    date: dayjs().format()
  })

  if (!alreadyInEndState(oldWorkflow)) {
    set(patient, 'currentWorkflowState.previousActiveState', oldWorkflow)
  }
}

export const setFailureToEngageInfo = (patient, call, user) => {
  const oldWorkflow = get(patient, 'currentWorkflowState.name')
  set(patient, 'currentWorkflowState.name', reverseLookup(`Failure to Engage at ${call.app}`))
  set(patient, 'currentWorkflowState.failureToEngageInfo', {
    call,
    user: user.email,
    userId: user.id,
    date: dayjs().format()
  })

  if (!alreadyInEndState(oldWorkflow)) {
    set(patient, 'currentWorkflowState.previousActiveState', oldWorkflow)
  }
}

export const flipPatientOrgs = (patient, secondary = true) => {
  if (!get(patient, 'managingOrganization.secondary')) return patient
  if (secondary) {
    set(patient, 'managingOrganization.id', get(patient, 'managingOrganization.secondary'))
  } else {
    set(patient, 'managingOrganization.id', get(patient, 'managingOrganization.primary'))
  }

  return patient
}

export const getLatestCall = callLogs => {
  if (!callLogs || !callLogs.length) return null
  // call_logs get PUSHED, so the last one will always be the most recent
  callLogs.sort((a, b) => {
    if (new Date(get(a, 'record.dateRecorded')) > new Date(get(b, 'record.dateRecorded'))) {
      return 1
    }
    if (new Date(get(a, 'record.dateRecorded')) < new Date(get(b, 'record.dateRecorded'))) {
      return -1
    }
    return 0
  })
  return callLogs[callLogs.length - 1]
}

export const wfsTo = {
  OptIn: (patient, latestCall) => {
    if (!latestCall) return false

    const patientWorkflowState = get(patient, 'currentWorkflowState.name')
    const patientOptOutOrFTE =
      patientWorkflowState.includes('Opt Out') || patientWorkflowState.includes('Failure to Engage')

    if (latestCall.record.successful) {
      if (patientOptOutOrFTE && latestCall.record.decision === true) {
        // Member interaction to opt back in.
        if (
          !get(patient, 'currentWorkflowState.previousActiveState') ||
          badPreviousState(get(patient, 'currentWorkflowState.previousActiveState'))
        ) {
          set(patient, 'currentWorkflowState.name', WORKFLOW_NAMES.READY_INITIATING_CALL)
        } else {
          set(
            patient,
            'currentWorkflowState.name',
            get(patient, 'currentWorkflowState.previousActiveState')
          )
        }
        return true
      }
    }
  },
  OptOut: (patient, latestCall, primaryProject, user) => {
    let enrolling, reason, performedBy, couchId

    if (!latestCall) return false

    if (primaryProject) {
      enrolling = false
      reason = latestCall.record.decisionReason
      performedBy = {
        userEmail: latestCall.userMetadata.email,
        userFirstName: latestCall.userMetadata.firstName,
        userLastName: latestCall.userMetadata.lastName
      }
      couchId = get(primaryProject, 'couchId')
    }

    if (latestCall.record.successful) {
      if (latestCall.record.decision === false && !latestCall.record.forPrimaryProject) {
        // Opt out
        setOptOutInfo(patient, latestCall, user)
        // Also decline project
        if (primaryProject) {
          ProjectService.updatePatientProjectInfo(patient, couchId, enrolling, reason, performedBy)
        }

        return true
      } else if (latestCall.record.decision === false && latestCall.record.forPrimaryProject) {
        if (primaryProject) {
          ProjectService.updatePatientProjectInfo(patient, couchId, enrolling, reason, performedBy)
        }
        return true
      }
    }
  },
  FailureToEngage: (patient, latestCall, org, user) => {
    if (!latestCall) return false

    const patientWorkflowState = get(patient, 'currentWorkflowState.name')
    const attemptCheck =
      latestCall.app === 'Initiating Call' ? INITIATING_CALL_FTE_MAX : MED_REC_REVIEW_FTE_MAX

    if (!latestCall.record.successful) {
      if (latestCall.record.attempt >= attemptCheck && !latestCall.record.overrideWorkflow) {
        if (
          patientWorkflowState.includes('Ready for') &&
          !patientWorkflowState.includes('Ready for Printing') &&
          !patientWorkflowState.includes('Ready for Mailing')
        ) {
          setFailureToEngageInfo(patient, latestCall, user)
          return true
        }
      }
    }
  },
  MedicationReview: (patient, latestCall, org) => {
    if (!latestCall) return false
    const patientWorkflowState = get(patient, 'currentWorkflowState.name')
    let INITIATING_STATE =
      get(org, 'workflows.startingWorkflowState') || WORKFLOW_NAMES.READY_INITIATING_CALL
    if (latestCall.record.successful) {
      if (latestCall.record.decision === true && patientWorkflowState === INITIATING_STATE) {
        set(patient, 'currentWorkflowState.name', WORKFLOW_NAMES.READY_MEDICATION_REVIEW)
        return true
      }
    }
  },
  ReconciliationComplete: (patient, org) => {
    if (
      [WORKFLOW_NAMES.READY_INITIATING_CALL, WORKFLOW_NAMES.READY_MEDICATION_REVIEW].indexOf(
        get(patient, 'currentWorkflowState.name')
      ) > -1
    ) {
      let NEXT_STATE =
        get(org, 'workflows.completeReconcil') || WORKFLOW_NAMES.READY_MEDICATION_SAFETY_REVIEW
      set(patient, 'currentWorkflowState.name', NEXT_STATE)
      return true
    }
  },
  ReviewPublished: (patient, patientOrg, review) => {
    let NEXT_STATE
    let CURRENT_STATE =
      get(patientOrg, 'workflows.completeReconcil') || WORKFLOW_NAMES.READY_MEDICATION_SAFETY_REVIEW
    if (
      review.published === true &&
      (get(patient, 'currentWorkflowState.name') === CURRENT_STATE ||
        get(patient, 'currentWorkflowState.name') ===
          WORKFLOW_NAMES.FAILURE_ENGAGE_MEDICATION_SAFETY_REVIEW)
    ) {
      // This is standard workflow for reviews
      NEXT_STATE = get(patientOrg, 'workflows.reviewPublish') || WORKFLOW_NAMES.READY_PRINTING
      patient.currentWorkflowState.name = NEXT_STATE
      patient.currentWorkflowState.reviewType = review.reviewType
      return true
    } else if (
      review.published === true &&
      get(patient, 'currentWorkflowState.name') === get(patientOrg, 'workflows.saveReviewDraft') &&
      get(patientOrg, 'workflows.reviewPublish')
    ) {
      // This is where the org doc completely controls the workflow for reviews
      NEXT_STATE = get(patientOrg, 'workflows.reviewPublish') || WORKFLOW_NAMES.READY_PRINTING
      patient.currentWorkflowState.name = NEXT_STATE
      patient.currentWorkflowState.reviewType = review.reviewType
      return true
    }
  },
  ReviewPublishedByGradECPNUser: (patient, patientOrg, review, user) => {
    let NEXT_STATE
    if (
      isUserGraduated(user) &&
      review.published === true &&
      get(patient, 'currentWorkflowState.name') ===
        WORKFLOW_NAMES.READY_MEDICATION_SAFETY_REVIEW
    ) {
      NEXT_STATE = WORKFLOW_NAMES.READY_CONSULT
      if (!reviewIncludesResponses(review)) {
        patient.currentWorkflowState.name = NEXT_STATE
        patient.currentWorkflowState.ecpnReview = review._id
      } else {
        // the review is already complete.
        patient.currentWorkflowState.name = WORKFLOW_NAMES.COMPLETE
        patient.nextReviewDate = dayjs()
          .add(60, 'd')
          .format('YYYY-MM-DD')
        delete patient.currentWorkflowState.ecpnReview
      }
      return true
    }
    return false
  },
  ReturnToECPN: (patient, patientOrg, review) => {
    let NEXT_STATE

    if (
      review.published === true &&
      get(patient, 'currentWorkflowState.name') ===
        WORKFLOW_NAMES.READY_ECPN_MEDICATION_SAFETY_REVIEW
    ) {
      // This is part of eCPN workflow
      NEXT_STATE = WORKFLOW_NAMES.READY_CONSULT
      if (!reviewIncludesResponses(review)) {
        patient.currentWorkflowState.name = NEXT_STATE
        patient.currentWorkflowState.ecpnReview = review._id
      } else {
        // the review is already complete.
        patient.currentWorkflowState.name = WORKFLOW_NAMES.COMPLETE
        patient.nextReviewDate = dayjs()
          .add(60, 'd')
          .format('YYYY-MM-DD')
        delete patient.currentWorkflowState.ecpnReview
      }
      patient = flipPatientOrgs(patient, false)
      return true
    }
  },
  SendForReview: (patient, patientOrg, review) => {
    const CURRENT_STATE =
      get(patientOrg, 'workflows.completeReconcil') || WORKFLOW_NAMES.READY_MEDICATION_SAFETY_REVIEW
    if (review.dueDate && get(patient, 'currentWorkflowState.name') === CURRENT_STATE) {
      // This is part of the eCPN workflow
      const NEXT_STATE = WORKFLOW_NAMES.READY_ECPN_MEDICATION_SAFETY_REVIEW
      patient.currentWorkflowState.name = NEXT_STATE
      patient = flipPatientOrgs(patient, true)
      return true
    }
  },
  ReadyForConsult: (patient, patientOrg, review) => {
    const CURRENT_STATE = WORKFLOW_NAMES.READY_CONSULT
    // ecpnReview = Specific review sent by the ecpn user to be reviewed. 
    // It's the only review that should trigger a wfs change.
    if (
      get(patient, 'currentWorkflowState.name') === CURRENT_STATE &&
      review._id === get(patient, 'currentWorkflowState.ecpnReview') &&
      reviewIncludesResponses(review)
    ) {
      // this is part of eCPN workflow, where the review had its responses filled out.
      const NEXT_STATE = WORKFLOW_NAMES.COMPLETE
      patient.currentWorkflowState.name = NEXT_STATE
      patient.nextReviewDate = dayjs()
        .add(60, 'd')
        .format('YYYY-MM-DD')
      delete patient.currentWorkflowState.ecpnReview
      return true
    }
  },
  ReviewDraft: (patient, patientOrg, review) => {
    if (
      review.draft === true &&
      get(patient, 'currentWorkflowState.name') === get(patientOrg, 'workflows.completeReconcil') &&
      get(patientOrg, 'workflows.saveReviewDraft')
    ) {
      // This is where the org doc completely controls the workflow for reviews
      // we need to ensure that this org defined workflow gets updated
      patient.currentWorkflowState.name = get(patientOrg, 'workflows.saveReviewDraft')
      return true
    }
  },
  Unpublished: (patient, patientOrg, review, user) => {
    if (review.published === false && review.unpublishes.length > 0) {
      if (isPatientInECPNOrg(patient) && !isUserGraduated(user)) {
        patient.currentWorkflowState.name = WORKFLOW_NAMES.READY_ECPN_MEDICATION_SAFETY_REVIEW
        patient = flipPatientOrgs(patient, true)
        return true
      } else {
        patient.currentWorkflowState.name = WORKFLOW_NAMES.READY_MEDICATION_SAFETY_REVIEW
        return true
      }
    }
  },
  RejectAndReturnEcpnReview: patient => {
    if (
      get(patient, 'currentWorkflowState.name') ===
      WORKFLOW_NAMES.READY_ECPN_MEDICATION_SAFETY_REVIEW
    ) {
      if (isPatientInECPNOrg(patient)) {
        patient = flipPatientOrgs(patient, false)
      }
      set(patient, 'currentWorkflowState.name', WORKFLOW_NAMES.READY_MEDICATION_SAFETY_REVIEW)
      return true
    }
    return false
  }
}

export const backendWorkflowMiddleware = store => {
  const getUser = () => {
    return get(store.getState(), 'auth.profile')
  }

  let user

  return next => action => {
    user = getUser()
    // see if I can move this out to be used by both middlewares.

    const projectCouchId = getProjectIdAssumingATonOfThings(
      get(store.getState(), 'patient.data.projects', {})
    )
    // not all of these events currently trigger a workflow change but sending them now in case they are needed in the future.
    // List of workflow actions can be found here: https://confluence.carekinesis.com/display/MA/Workflow+Events
    const workflowActions = [
      patientTypes.INITIATING_CALL_SUCCESS,
      patientTypes.MEDICATION_REVIEW_SUCCESS,
      patientTypes.MEDICATION_SAFETY_REVIEW_SUCCESS,
      patientTypes.INITIATING_CALL_OPTOUT,
      patientTypes.MEDICATION_REVIEW_OPTOUT,
      patientTypes.MEDICATION_SAFETY_REVIEW_OPTOUT,
      patientTypes.INITIATING_CALL_DECLINE,
      patientTypes.MEDICATION_REVIEW_DECLINE,
      patientTypes.MEDICATION_SAFETY_REVIEW_DECLINE,
      patientTypes.INITIATING_INTERACTION_FAIL,
      patientTypes.MEDICATION_REVIEW_FAIL,
      patientTypes.MEDICATION_SAFETY_REVIEW_FAIL,
      patientTypes.MOVE_FROM_STAGED,
      medRecTypes.COMPLETE_RECONCILIATION,
      reviewTypes.SEND_FOR_REVIEW,
      reviewTypes.REJECT_AND_RETURN,
      reviewTypes.SAVE_PUBLISHED_REVIEW_SUCCESS,
      reviewTypes.REVIEW_RESPONSES_COMPLETE,
      reviewTypes.UNPUBLISH_MSR,
      patientTypes.MANUAL_WORKFLOW_CHANGE
    ]
    // Todo: run through work flow again.

    /**
     * Set up payload depending on action type.  Most actions will just pass the payload directly.
     * @param {string} actionType The type of the action.
     * @param {object} payload    The payload sent with the action.
     * @return {object|undefined} The adjusted payload, considering any special conditions of the
     *                            action type.
     */
    const createEventPayload = (actionType, payload = {}) => {
      switch (actionType) {
      case medRecTypes.COMPLETE_RECONCILIATION:
        return undefined
      case reviewTypes.SAVE_PUBLISHED_REVIEW_SUCCESS:
        set(payload, 'user', getUser())
        return payload
      default:
        return payload
      }
    }

    // Had to do this because med recs send the whole med rec doc as the payload.
    if (workflowActions.indexOf(action.type) !== -1) {
      const eventPayload = createEventPayload(action.type, action.payload)
      const actionForBackend = action.type.split('/').pop()
      gateway.post('/workflow', {
        eventType: actionForBackend,
        user: user._id,
        payload: eventPayload,
        patientId: get(store.getState(), 'patient.data._id'),
        programId: projectCouchId
      })

      // another kinda nasty workaround to move a published review that requires no responses
      // to "Complete" instead of "Ready for Consult" for SaaS preceptor orgs
      if (action.type === reviewTypes.SAVE_PUBLISHED_REVIEW_SUCCESS && reviewIncludesResponses(action.published)) {
        setTimeout(() => {  
          gateway.post('/workflow', {
            eventType: reviewTypes.REVIEW_RESPONSES_COMPLETE.split('/').pop(),
            user: user._id,
            patientId: get(store.getState(), 'patient.data._id'),
            payload: eventPayload,
            programId: projectCouchId
          })
        }, 2000)
      }
    }
      
    return next(action)
  }
}

export const workflowMiddlware = store => {
  /**
   * Helper function to get the logged in user
   * @return {Object} The logged in user
   */
  const getUser = () => {
    return get(store.getState(), 'auth.profile')
  }

  const getPatient = () => {
    return get(store.getState(), 'patient.data')
  }

  const getPatientOrg = () => {
    const state = store.getState()
    let patient = get(state, 'patient.data')
    let orgs = get(state, 'organizations.allOrgs', [])

    return orgs.find(o => o._id === patient.managingOrganization.id) || {}
  }

  const patientOrgUsesNewWorkflow = () => {
    const state = store.getState()
    const patient = get(state, 'patient.data')
    const allOrgs = get(state, 'organizations.allOrgs', [])

    const patientCurrentOrg = allOrgs.find(o => o._id === get(patient, 'managingOrganization.id'))
    const patientPrimaryOrg = allOrgs.find(o => o._id === get(patient, 'managingOrganization.primary'))

    return get(patientCurrentOrg, 'usesNewWorkflow') || get(patientPrimaryOrg, 'usesNewWorkflow')
  }

  const getPatientPrimaryProject = () => {
    let patientProjects = get(store.getState(), 'project.patientProjects', [])

    const thePrimary = patientProjects.length ? patientProjects.find(p => p.primary) : {}
    return thePrimary
  }

  return next => action => {
    /**
     * 1. Look for an action type of UPDATE_PATIENT_REQUEST
     * 2. Look for update to call_logs
     *   a. If there are no calls in the call log or the call log has not changed - return
     *   b. If the most recent call is a non workflow related call - return
     *   c. If patient.currentWorkflowState.name is opt out or failure to engage and latest call is successful - move to previousActiveState or INITIATING_STATE.
     *   d. If type is Initiating Call, and successful, and decision true - move to Ready for Medication Review
     *     i. If type is Initiating Call, and unsuccessful and attempt 3 - move to Failure to Engage
     *     ii. If type is Initiating Call, and successful, and decision is false - move to Member Opt Out
     * 3. Look for updates to call_logs for projects, these would be calls with `forPrimaryProject` and `project`
     *    on the record component
     *   a. If call has forPrimaryProject and successful, and decision is false - Decline Service from Project
     * 4. Look for COMPLETE_RECONCILIATION
     *   a. Update patient to the organization.completeReconcil state
     * 5. Look for SAVE_PUBLISHED_REVIEW_SUCCESS
     *   a. Update patient to the organization.reviewPublish State
     *   b. TODO: eCPN flow
     */

    let patient, patientOrg, review
    patient = getPatient()
    const user = getUser()
    // check patient's org and primary org for the new workflow flag
    if (!patient || patientOrgUsesNewWorkflow()) {
      return next(action)
    }

    // eslint-disable-next-line default-case
    switch (get(action, 'type')) {
    case patientTypes.REQUEST:
      // how to change the value before submitting
      // console.log(`I'm a middleware`, action)
      // action.payload.name.middle = 'CHANGED!'
      // check if patient is in 'Ready for Initiating Call'
      // if successful, and decision === true, move to 'Ready for Medication Review'
      // // if not, follow logic for FTE and Opt Outs
      if (get(action, 'status') !== patientTypes.UPDATE) return next(action)
      patientOrg = getPatientOrg()
      const callLogs = get(action, 'payload.call_log', [])
      const primaryProject = getPatientPrimaryProject()

      if (!callLogs.length) return next(action)

      const latestCall = getLatestCall(callLogs)
      // We want to ensure that calls are only considered here when they are actually for this year.
      // Could be possible to only check if the call was placed today/now as well, but doesn't seem as necessary.
      if (
        get(latestCall, 'currentYear', 1) !== get(patient, 'currentWorkflowState.year', 1) ||
          get(latestCall, 'workflowIteration', 1) !== get(patient, 'workflowIteration', 1)
      ) {
        return next(action)
      }

      if (isEqual(callLogs.length, get(patient, 'call_log.length'))) return next(action)

      // skip non-workflow calls
      if (latestCall.app === 'Profile') return next(action)

      if (wfsTo.OptIn(action.payload, latestCall)) {
        return next(action)
      }
      if (wfsTo.OptOut(action.payload, latestCall, primaryProject, user)) {
        return next(action)
      }
      if (wfsTo.FailureToEngage(action.payload, latestCall, patientOrg, user)) {
        if (
          [
            WORKFLOW_NAMES.FAILURE_ENGAGE_INITIATING_CALL,
            WORKFLOW_NAMES.FAILURE_ENGAGE_MEDICATION_REVIEW
          ].includes(get(action, 'payload.currentWorkflowState.name'))
        ) {
          // I hate that this is hardcoded, it should be an org option.
          if (patientOrg._id === 'org:EMTM') {
            ;(async function () {
              // TODO: should we be handling the failure case here?
              await gateway.put(`/api/medwise/patient`, {
                patient: action.payload
              })
              const result = await generateAutoReview(get(patient, '_id'))
              return store.dispatch(patientActions.setPatient(result[0]))
            })()
            return false
          }
        }
        return next(action)
      }
      if (wfsTo.MedicationReview(action.payload, latestCall, patientOrg)) {
        return next(action)
      }
      break
    case medRecTypes.COMPLETE_RECONCILIATION:
      // check if patient is in 'Ready for Medication Review'
      // get and update the patient from getState()
      patientOrg = getPatientOrg()
      if (wfsTo.ReconciliationComplete(patient, patientOrg)) {
        setTimeout(() => {
          store.dispatch(patientActions.updatePatient(patient))
        }, 3000)
      }
      break
    case reviewTypes.SAVE_PUBLISHED_REVIEW_SUCCESS:
      // check if patient is in 'reconcilComplete' or 'Ready for Medication Safety Review'
      // get and update the patient from getState()
      review = action.published
      patientOrg = getPatientOrg()
      if (
        wfsTo.ReviewPublishedByGradECPNUser(patient, patientOrg, review, user) ||
        wfsTo.ReviewPublished(patient, patientOrg, review) ||
        wfsTo.ReturnToECPN(patient, patientOrg, review)
      ) {
        setTimeout(() => {
          store.dispatch(patientActions.updatePatient(patient))
        }, 1000)
      }
      break
    case reviewTypes.SAVE_DRAFT_REVIEW_SUCCESS:
      review = action.review
      patientOrg = getPatientOrg()
      if (
        wfsTo.SendForReview(patient, patientOrg, review) ||
          wfsTo.ReviewDraft(patient, patientOrg, review)
      ) {
        setTimeout(() => {
          store.dispatch(patientActions.updatePatient(patient))
        }, 1000)
      }
      break
    case reviewTypes.SAVE_REVIEW_SUCCESS:
      // this is to move a patient to complete when ready for consult.
      review = action.review
      patientOrg = getPatientOrg()
      if (
        wfsTo.ReadyForConsult(patient, patientOrg, review) ||
          wfsTo.ReviewDraft(patient, patientOrg, review)
      ) {
        setTimeout(() => {
          store.dispatch(patientActions.updatePatient(patient))
        }, 1000)
      }
      break
    case reviewTypes.UNPUBLISH_MSR:
      review = action.review
      patientOrg = getPatientOrg()

      if (wfsTo.Unpublished(patient, patientOrg, review, user)) {
        setTimeout(() => {
          store.dispatch(patientActions.updatePatient(patient))
        }, 1000)
      }
      break
    case reviewTypes.REJECT_AND_RETURN:
      if (wfsTo.RejectAndReturnEcpnReview(patient)) {
        setTimeout(() => {
          store.dispatch(patientActions.updatePatient(patient))
        })
      }
      break
    case patientTypes.MANUAL_WORKFLOW_CHANGE:
      patientOrg = getPatientOrg()
      const workflowState = get(action, 'payload.workflowState')
      const reason = get(action, 'payload.reason')
      const reasonOther = get(action, 'payload.reasonOther')
      const newIteration = get(action, 'payload.newIteration', false)
      if (!patient.manualWorkflowStateChanges) {
        patient.manualWorkflowStateChanges = []
      }

      patient.manualWorkflowStateChanges.push({
        from: patient.currentWorkflowState.name,
        to: workflowState,
        date: dayjs().format('YYYY-MM-DD'),
        user: user.email,
        reason,
        reasonOther
      })
      patient.currentWorkflowState.name = workflowState
      if (newIteration) {
        patient.workflowIteration = get(patient, 'workflowIteration', 1) + 1
      }
      patient.currentWorkflowState = omit(patient.currentWorkflowState, [
        'reviewType',
        'MSASentToMember',
        'MSASentToPrescriber',
        'failureToEngageInfo',
        'optOutInfo',
        'previousActiveState'
      ])
      store.dispatch(patientActions.updatePatient(patient))
      break
    }
    return next(action)
  }
}
