import React, { Component } from 'react'
import { kebabCase, map, includes, compact, get, isString} from 'lodash'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'


/*
// Example Usage:
<div>                           THIS COMPONENT
  <Field name="bikeShedColor" component={ReduxFormSelect} label="Bike Shed Color">
    <MenuItem value="red">Red</MenuItem>
    <MenuItem value="blue">Blue</MenuItem>
    <MenuItem value="invisible">Invisible</MenuItem>
  </Field>
</div
 */

// something like below will be useful when the issue below is resolved
// showing the white background for a select when the field is filled
// https://github.com/mui-org/material-ui/issues/7633
const styles = {
  nonEmptyInput: {
    backgroundColor: 'white'
  }
}

class ReduxFormSelect extends Component {
  handleSelectAll (input, selection, children) {
    if (includes(selection, 'selectAll')) {
      this.displayDeselectAll ? input.onChange([]) : input.onChange(compact(children))
    } else {
      input.onChange(selection)
    }
  }

  render () {
    const {
      externalOnChange,
      label,
      input,
      meta: { touched, error, warning },
      children,
      helperText,
      required,
      className,
      classes,
      selectAll,
      onSelectionChange,
      ...custom
    } = this.props
    this.displayDeselectAll = input.value.length > 0
    const showError = !!(touched && error && isString(error))
    const showWarning = !!(touched && warning)
    const inputStyle =
      typeof input.value === 'boolean' || (input.value && input.value.length !== 0)
        ? classNames(classes.nonEmptyInput, className)
        : className

    return (
      <FormControl
        error={showError}
        // error
        warning={warning}
        required={required}
        fullWidth
      >
        {/* This is very much a hacky way of fixing the label overlap in the control; but this retains existing behavior without changing to a TextField component */}
        {label && <InputLabel style={{ backgroundColor: 'white', paddingLeft: '3px', paddingRight: '3px' }} className={className} {...custom}>{label}</InputLabel>}
        <Select
          data-cy={`select-${kebabCase(label)}`}
          className={classNames(inputStyle, className)}
          {...input}
          onChange={(event, selected) => {
            if (selectAll) {
              this.handleSelectAll(input, event.target.value, map(children, 'props.value'))
              if (onSelectionChange && children) children.map(child => onSelectionChange(get(child, 'props.value')))
            } else {
              input.onChange(event.target.value)
            }
            externalOnChange && externalOnChange(event.target.value)
            if (onSelectionChange) onSelectionChange(get(selected, 'props.value'))
          }}
          {...custom}
        >
          {selectAll && (
            <MenuItem value="selectAll">
              {this.displayDeselectAll ? 'Deselect All' : 'Select All'}
            </MenuItem>
          )}
          {children}
        </Select>
        {showError && <FormHelperText error>{error}</FormHelperText>}
        {!showError && showWarning && <FormHelperText warning={warning}>{warning}</FormHelperText>}
        {!showError && !showWarning && helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }
}

ReduxFormSelect.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    warning: PropTypes.string
  }),
  helperText: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  selectAll: PropTypes.bool,
  onSelectionChange: PropTypes.func
}

export default withStyles(styles)(ReduxFormSelect)
// export default ReduxFormSelect
