/*
 * Dashboard observations tab
 */

import React, { Component } from 'react'
import pluralize from 'pluralize'
import dayjs from 'dayjs'

import StandardTab from 'components/common/standard-tab'
import TitleCell from 'components/common/table-cells/title-cell'
import * as systemsConstants from 'constants/systems'
import { DateFormat } from 'constants/date'
import {
  convertInchesToFeetAndInches,
  calculateBMI,
  determineBmiClass
} from 'services/calculations'
import { get, set } from 'lodash'

import ObservationsForm from './observations-form'
import { formatUserName } from 'services/utils'

const OBJECT_NAME = 'observation'
/**
 * Observations are patient BMI, Creatinine Serum, or a custom value such as Blood Pressure or Blood sugar levels.
 * @extends Component
 */
class Observations extends Component {
  constructor (props) {
    super(props)

    this.formHandler = this.formHandler.bind(this)
    this.handleObservationDelete = this.handleObservationDelete.bind(this)
  }

  /**
   * Takes an array of multiple heights and weights and compares issued value to return the most recent.
   * @param  {array} obsArray [description]
   * @return {object}         Returns an object with two keys, mostRecentHeight and mostRecentWeight. Both of the keys are objects from the patients saved observations.
   */
  getMostRecentHeightWeight = obsArray => {
    let mostRecentHeight
    let mostRecentWeight
    let heights = obsArray.filter(o => o.text === 'Height')
    if (heights.length) {
      mostRecentHeight = heights.reduce((pre, cur) => {
        return dayjs(pre.issued).isBefore(dayjs(cur.issued)) ? cur : pre
      })
    }

    let weights = obsArray.filter(o => o.text === 'Weight')
    if (weights.length) {
      mostRecentWeight = weights.reduce((pre, cur) => {
        return dayjs(pre.issued).isBefore(dayjs(cur.issued)) ? cur : pre
      })
    }
    return { mostRecentHeight, mostRecentWeight }
  }

  /**
   * Intermediate values are needed in the observations form.  This function creates those values in an object that gets deleted when the observation info from the form is saved.
   * @param  {object} height - Height object from the patients saved observations.
   * @param  {object} height.valueQuantity - Contains the values for height.
   * @param  {string} height.valueQuantity.units - Units of measure for height. Will be 'cm' or 'in'.
   * @param  {string} height.valueQuantity.value - Value assigned with the units to the height.  Will be a number or string that is a number.
   * @param  {object} weight - Weight object from the patients saved observations.
   * @param  {object} weight.valueQuantity - Contains the values for weight.
   * @param  {string} weight.valueQuantity.units - Units of measure for weight. Will be 'kg' or 'lb'.
   * @param  {string} weight.valueQuantity.value - Value assigned with the units to the weight.  Will be a number or string that is a number.
   * @return {object}        - Returns an object with all the values that will be deleted when observation is saved.
   * @example <caption>Example of Returned Objects </caption>
   * {
   *  currentSavedHeight: {
   *    _id: "47319474c4a3ae7677a2def655066ee0",
   *    _rev: "1-714b9676ea1ce59af7c847716fc34fb2",
   *    …
   *  }, // used when value is edited.
   *  currentSavedWeight: {
   *  _id: "47319474c4a3ae7677a2def65506722b",
   *  _rev: "1-2bf17a8ed10c980134f4d2fc170f3fe6"
   *  …
   *  }, // used when value is edited.
   *  lengthLarge: 6,
   *  lengthSmall: 1,
   *  unitOfMeasure: "imperial",
   *  weight: "225"
   * }
   *
   */
  createDeleteValuesObject = (height, weight) => {
    let deleteValues = {}
    if (
      get(height, 'valueQuantity.units') === 'in' ||
      get(weight, 'valueQuantity.units') === 'lb'
    ) {
      deleteValues.unitOfMeasure = 'imperial'
      if (height) {
        let feetAndInches = convertInchesToFeetAndInches(get(height, 'valueQuantity.value'))
        deleteValues.lengthLarge = feetAndInches.feet
        deleteValues.lengthSmall = feetAndInches.inches
      }
      if (weight) {
        deleteValues.weight = get(weight, 'valueQuantity.value')
      }
    } else if (
      get(height, 'valueQuantity.units') === 'cm' ||
      get(weight, 'valueQuantity.units') === 'kg'
    ) {
      deleteValues.unitOfMeasure = 'metric'
      if (height) {
        deleteValues.lengthLarge = Math.floor(get(height, 'valueQuantity.value') / 100)
        deleteValues.lengthSmall = get(height, 'valueQuantity.value') % 100
      }
      if (weight) {
        deleteValues.weight = get(weight, 'valueQuantity.value')
      }
    } else {
      // default value when no height and weight have been added to profile
      deleteValues.unitOfMeasure = 'imperial'
    }
    deleteValues.currentSavedWeight = weight
    deleteValues.currentSavedHeight = height
    return deleteValues
  }

  /**
   * Gets the most recent of an observation if the same one is recorded twice.
   * @param  {array} obsArray [description]
   * @return {array}         - An array of most recent values when there are duplicates and adds additional information for display through the createDeleteValuesObject function.
   */
  getInitialValues = obsArray => {
    if (!obsArray) {
      obsArray = []
    }
    let heightAndWeight = this.getMostRecentHeightWeight(obsArray)

    return this.createDeleteValuesObject(
      heightAndWeight.mostRecentHeight,
      heightAndWeight.mostRecentWeight
    )
  }

  /**
   * Converts the observations array that come from the gateway into the format needed to display and edit. The need for this conversion is a bi-product of the implementation in 1.0 not being the same as the implementation in 2.0.
   * @param  {array} obsArray - All of the patient's observations in the database.
   * @return {array}          - Array of all the observations but height and weight are combined into BMI.
   */
  convertedObsArray = obsArray => {
    let arrayToReturn = []
    let BMI

    // If there is both height and weight for the patient, filter out height and weight from display data because height and weight will be in BMI display.
    let notHeightOrWeight = obsArray
      .filter(obs => obs.text !== 'Height')
      .filter(o => o.text !== 'Weight')
    notHeightOrWeight.map(obs =>
      obs.text === 'Creatinine Serum'
        ? set(obs, 'deleteValues.selectObs', 'Creatinine Serum')
        : set(obs, 'deleteValues.selectObs', 'Custom')
    )
    if (notHeightOrWeight.length) {
      arrayToReturn = [...arrayToReturn, ...notHeightOrWeight]
    }

    // calculate BMI with height and weight data
    let mostRecentHeightWeight = this.getMostRecentHeightWeight(obsArray)
    const { mostRecentHeight, mostRecentWeight } = mostRecentHeightWeight

    if (!mostRecentHeight && !mostRecentWeight) {
      return arrayToReturn
    } else if (mostRecentHeight && mostRecentWeight) {
      let deleteValuesObject = this.createDeleteValuesObject(mostRecentHeight, mostRecentWeight)
      let combinedWeightAndBMI
      BMI = calculateBMI(mostRecentHeight.valueQuantity, mostRecentWeight.valueQuantity)
      let bmiClass = determineBmiClass(BMI)
      combinedWeightAndBMI = {
        text: `BMI: ${bmiClass}`,
        performedBy: get(mostRecentWeight, 'performedBy', {}),
        issued: get(mostRecentWeight, 'issued', ''),
        deleteValues: {
          selectObs: 'BMI',
          ...deleteValuesObject,
          value: BMI,
          lightText: {
            text: 'Weight',
            value: mostRecentWeight.valueQuantity.value,
            units: mostRecentWeight.valueQuantity.units
          }
        }
      }
      arrayToReturn.push(combinedWeightAndBMI)
      // arrayToReturn = [...arrayToReturn, ...combinedWeightAndBMI]
    } else {
      let missingValue = mostRecentWeight ? 'Height' : 'Weight'
      let presentValue = mostRecentWeight || mostRecentHeight

      let deleteValuesObject = this.createDeleteValuesObject(mostRecentHeight, mostRecentWeight)
      let combinedWeightAndNoBMI = {
        text: `BMI: Add ${missingValue} to calculate`,
        performedBy: get(presentValue, 'performedBy', {}),
        issued: get(presentValue, 'issued', ''),
        deleteValues: {
          selectObs: 'BMI',
          ...deleteValuesObject,
          lightText: {
            text: get(presentValue, 'text'),
            value: get(presentValue, 'valueQuantity.value'),
            units: get(presentValue, 'valueQuantity.units')
          },
          value: 'unknown'
        }
      }
      arrayToReturn.push(combinedWeightAndNoBMI)
    }
    return arrayToReturn
  }

  DEFAULT_STRUCTURE = {
    text: undefined,
    name: {
      coding: [
        {
          system: systemsConstants.SNOWMED_BROWSING,
          code: undefined,
          display: undefined
        }
      ]
    },
    type: `patient.${OBJECT_NAME}`,
    subject: this.props.patient.data._id,
    issued: new Date(),
    deleteValues: undefined
    // deleteValues: this.getInitialValues(this.props.patientData.observations)
  }
  /**
   * Converts the values that come from the form into the object that gets saved to the database for the patient's height.
   * @param  {object} data -
   * @return {object}      - if there is already a current saved height this is updated with the new values and saved, or if there is not a new height object is created.
   * @example
   * // Height object that gets passed to save.
   * {
     text: 'Height',
     name: {
       coding: [
         {
           system: 'http://browser.ihtsdotools.org/',
           code: '50373000',
           display: 'Height'
         }
       ]
     },
     issued: '1/1/2017',
     valueQuantity: { value: '66', units: 'in' }
   }
   */
  extractHeightDataForSave = data => {
    const { unitOfMeasure, lengthSmall, lengthLarge, currentSavedHeight } = data.deleteValues
    let heightData = currentSavedHeight ? { ...currentSavedHeight } : { ...data }
    if (unitOfMeasure === 'imperial') {
      var inchesFromFeet = lengthLarge ? lengthLarge * 12 : 0
      heightData.valueQuantity = {
        // FIX the value saved here
        value: lengthSmall
          ? (Number(inchesFromFeet) + Number(lengthSmall)).toString()
          : inchesFromFeet,
        units: 'in'
      }
    }
    if (unitOfMeasure === 'metric') {
      var cmFromMeters = lengthLarge ? lengthLarge * 100 : 0
      heightData.valueQuantity = {
        value: lengthSmall ? (Number(cmFromMeters) + Number(lengthSmall)).toString() : cmFromMeters,
        units: 'cm'
      }
    }
    heightData.issued = data.issued
    heightData.text = 'Height'
    heightData.name = {
      coding: [
        {
          system: 'http://browser.ihtsdotools.org/',
          code: '50373000',
          display: 'Height'
        }
      ]
    }
    delete heightData.deleteValues
    return heightData
  }

  /**
   * Handles removing the observation from the list.
   * @param  {object}  data The observation to send to the store where status is set to inactive.
   * @param {object} data.deleteValues Values added to the observation for the purposes of display or edit.  They are deleted when obs is saved or deleted. Since BMI data is a combination of height and weight observation, the original height and weight objects are sent in this object as well. They can then be passed ot the store for removal.
   *
   */
  async handleObservationDelete (data) {
    const { patientDataActions } = this.props
    const { currentSavedHeight, currentSavedWeight } = data.deleteValues
    let removeDeleteValues = { ...data }
    delete removeDeleteValues.deleteValues
    if (currentSavedHeight) {
      await patientDataActions.deletePatientData(currentSavedHeight)
    }
    if (currentSavedWeight) {
      await patientDataActions.deletePatientData(currentSavedWeight)
    }
    if (!currentSavedHeight && !currentSavedWeight) {
      await patientDataActions.deletePatientData(removeDeleteValues)
    }
  }
  /**
   * Observations form handler determines what information has been taken by the form and converts the data to the correct format.
   * @param  {object}  data    - all the data collected by the form.
   * @param  {boolean}  editing - Flag to create the correct save route for an edit of information.
   * @return {Promise}         [description]
   *
   * @example
   * incoming BMI data from the form:
   * {
   * deleteValues: {
   *  currentSavedHeight: {
   *    _id: "47319474c4a3ae7677a2def655066ee0",
   *    _rev: "1-714b9676ea1ce59af7c847716fc34fb2",
   *    …
   *  }, // used when value is edited.
   *  currentSavedWeight: {
   *  _id: "47319474c4a3ae7677a2def65506722b",
   *  _rev: "1-2bf17a8ed10c980134f4d2fc170f3fe6"
   *  …
   *  },
   *  selectObs: 'BMI',
   *  unitOfMeasure: 'imperial',
   *  lengthLarge: '5',
   *  lengthSmall: '6',
   *  value: '22.5',
   *  weight: '200'
   * },
   * issued: Mon Nov 05 2018 11:39:49 GMT-0500 (Eastern Standard Time) {},
   * lbs: "200",
   * name: {coding: []},
   * subject: "08a19ced2293808a744d54b9ec1ae06a",
   * text: BMI,
   * type: "patient.observation"
   * }
   * // Converted BMI data for Weight to be saved:
   * issued: Mon Nov 12 2018 13:14:41 GMT-0500 (Eastern Standard Time) {},
   * name: {coding: [{ code: '27113001', display: 'Weight', system: 'http://browser.ihtsdotools.org/' }]},
   * performedBy: {userFirstName: "Kendra", userLastName: "Davis" userEmail: "kdavis@carekinesis.com"},
   * subject: "0ff57bae227ecfeca883e3f70cb19664",
   * text: "Weight",
   * type: "patient.observation",
   * valueQuantity: {value: "200", units: "lb"}
   *
   * // Converted BMI data for Height to be saved:
   * issued: Mon Nov 12 2018 13:14:41 GMT-0500 (Eastern Standard Time) {},
   * name: {coding: [{code: "50373000", display: "Height", system: "http://browser.ihtsdotools.org/"}]},
   * performedBy: {userFirstName: "Kendra", userLastName: "Davis" userEmail: "kdavis@carekinesis.com"},
   * subject: "0ff57bae227ecfeca883e3f70cb19664",
   * text: "Height",
   * type: "patient.observation",
   * valueQuantity: {value: "65", units: "in"}
   */
  formHandler (data, editing) {
    const {
      unitOfMeasure,
      lengthLarge,
      lengthSmall,
      weight,
      selectObs,
      currentSavedWeight
    } = data.deleteValues
    const { patientDataActions } = this.props
    let formattedHeightData
    // if saving BMI info -
    // height and weight are saved as separate docs but collected on the same form.
    if (selectObs === 'BMI') {
      if (lengthSmall || lengthLarge) {
        formattedHeightData = this.extractHeightDataForSave(data)
      }

      let formattedWeightData = currentSavedWeight ? { ...currentSavedWeight } : { ...data }

      if (unitOfMeasure === 'imperial') {
        formattedWeightData.valueQuantity = { value: weight, units: 'lb' }

        // get the issued date from the parent BMI object
        formattedWeightData.issued = data.issued
      }

      if (unitOfMeasure === 'metric') {
        formattedWeightData.valueQuantity = { value: weight, units: 'kg' }
      }
      formattedWeightData.text = 'Weight'
      formattedWeightData.name = {
        coding: [
          {
            system: 'http://browser.ihtsdotools.org/',
            code: '27113001',
            display: 'Weight'
          }
        ]
      }
      delete formattedWeightData.deleteValues

      if (formattedHeightData) {
        patientDataActions.updatePatientData(
          formattedHeightData,
          OBJECT_NAME,
          editing ? 'post' : 'post'
        )
      }
      if (formattedWeightData) {
        patientDataActions.updatePatientData(
          formattedWeightData,
          OBJECT_NAME,
          editing ? 'post' : 'post'
        )
      }
    }
    if (selectObs === 'Creatinine Serum') {
      data.text = selectObs
      data.valueQuantity.units = 'mg/dL'
      data.name.coding[0].code = '113075003'
      data.name.coding[0].display = 'Creatinine Serum'
      delete data.deleteValues
      patientDataActions.updatePatientData(data, OBJECT_NAME, editing ? 'post' : 'post')
    }

    if (selectObs === 'Custom') {
      data.isCustom = true
      data.name = { coding: [] }
      delete data.deleteValues // deleteValues are always part of the default form values incase user chooses BMI
      patientDataActions.updatePatientData(data, OBJECT_NAME, editing ? 'post' : 'post')
    }
  }

  render () {
    const { patientData } = this.props

    this.DEFAULT_STRUCTURE.deleteValues = this.getInitialValues(
      get(patientData, 'observations', [])
    )

    let headers = [
      {
        name: 'Observation',
        // need to put a filter here to only render TitleCell if the data is text= BMI
        maps: [
          { propName: 'boldText', value: 'text' },
          { propName: 'descriptionText', value: 'deleteValues.lightText.text' },
          {
            propName: 'lightText',
            value: obs =>
              get(obs, 'deleteValues.lightText')
                ? `${obs.deleteValues.lightText.value} (${obs.deleteValues.lightText.units})`
                : undefined
          }
        ],
        component: TitleCell
      },
      {
        name: 'Value',
        maps: obs =>
          obs.text.includes('BMI:') ? `${obs.deleteValues.value}` : `${obs.valueQuantity.value}`
      },
      { name: 'Reported by', maps: obs => formatUserName(obs) },
      {
        name: 'Updated',
        maps: obs =>
          obs.issued ? dayjs(get(obs, 'issued')).format(DateFormat.DATE_DISPLAY) : undefined
      }
    ]
    // let data = get(patientData, pluralize(OBJECT_NAME), [])
    let data = this.convertedObsArray(get(patientData, pluralize(OBJECT_NAME), []))
    return (
      <StandardTab
        name="Observation"
        tableHeaders={headers}
        tableData={data}
        object={OBJECT_NAME}
        form={ObservationsForm}
        initialValues={this.DEFAULT_STRUCTURE}
        formHandler={this.formHandler}
        handleDelete={this.handleObservationDelete}
        deleteAlert
        pagination={{
          pageSize: 5,
          maxPages: 5
        }}
        {...this.props}
      />
    )
  }
}

export default Observations
