import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Field, reduxForm, formValueSelector } from 'redux-form'
import MenuItem from '@material-ui/core/MenuItem'

import ReduxFormText from 'components/common/redux-form/text'
import ReduxFormSelect from 'components/common/redux-form/select'
import ReduxFormDatePicker from 'components/common/redux-form/date-picker'
import ReduxFormSearchSelect from 'components/common/redux-form/search-select'

import { allergyValidation, sideEffectValidation } from './validation'
import { searchAllergy, buildAllergy } from 'services/clinical'

/**
 * Side Effect and Allergies are the same form. To differentiate, SideEffects passes in isSideEffect: true as an initalValue.
 *
 */

export class AllergiesOrSideEffectForm extends Component {
  allergySearch = async inputValue => {
    try {
      let allergies = await searchAllergy({
        name: inputValue
      })
      return allergies.map(a => buildAllergy(a))
    } catch (err) {
      if (inputValue.length < 3) {
        return
      }
      this.props.notifyActions.addNotification({
        message: 'An error occurred fetching allergy information',
        sticky: true
      })
    }
  }
  handleSelectionChange = allergy => {
    const { change } = this.props
    change('substance.coding', allergy.substance.coding)
  }

  handleSelectionClear = () => {
    const { change } = this.props
    change('substance.text', '')
    change('substance.coding', '')
  }

  suggestionLabel = suggestion => {
    return (
      <div>
        <div className="truncate fw6">{suggestion.substance.text}</div>
        <div className="truncate black-40">Type: {suggestion.substance.coding[0].type}</div>
      </div>
    )
  }

  render () {
    const { onSubmit, nkda } = this.props
    // if form is allergy and the nkda checkbox is checked show a different view
    return (
      <Form className="w-100 flex flex-column" onSubmit={onSubmit}>
        {/* Comment out to remove CLINDEV-3010 and CLINDEV-2976 from release 2.3.0 */}
        {/* <div
          id="nkda"
          className={`w-100${
            hideNkdaCheckbox || text || this.props.form === 'sideeffect' ? ' dn' : ''
          }`}
        >
          <Field
            type="checkbox"
            name="nkda"
            component={ReduxFormCheckbox}
            label="No Known Drug Allergies"
          />
        </div> */}
        <div className={`w-100 ${nkda ? 'dn' : ''}`}>
          <Field
            required
            name="substance.text"
            type="text"
            label="Name"
            helperText={'Start typing a name or click to choose'}
            component={ReduxFormSearchSelect}
            debounce={300}
            getSuggestions={this.allergySearch}
            getSuggestionLabel={this.suggestionLabel}
            getSelectionValue={allergy => allergy.substance.text}
            onSelectionChange={this.handleSelectionChange}
            onSelectionClear={this.handleSelectionClear}
          />
        </div>
        <div className="flex justify-between mb2">
          <div className={`w-50 ${nkda ? 'dn' : ''}`}>
            <Field
              required
              name="reaction"
              type="text"
              label="Reaction"
              component={ReduxFormSelect}
            >
              <MenuItem value="rash">Rash</MenuItem>
              <MenuItem value="shock">Shock</MenuItem>
              <MenuItem value="asthma">Asthma</MenuItem>
              <MenuItem value="nausea">Nausea</MenuItem>
              <MenuItem value="anemia">Anemia</MenuItem>
              <MenuItem value="dizziness">Dizziness</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Field>
          </div>
          <div className={`w-40 ${nkda ? 'dn' : ''}`}>
            <ReduxFormDatePicker name="onsetDate" label="Onset Date" disableFuture={true} />
          </div>
        </div>
        <div className="flex justify-between mb2">
          <div className="w-100">
            <Field
              name="description"
              type="text"
              label="Summary"
              helperText={'This text appears in the Summary column of the known allergies list.'}
              maxCharacters={60}
              showCharacterCount
              component={ReduxFormText}
            />
          </div>
        </div>
        <div className="flex justify-between mb2">
          <div className="w-100">
            <Field
              name="allergyNote"
              type="text"
              label="Note"
              helperText=""
              component={ReduxFormText}
            />
          </div>
        </div>
      </Form>
    )
  }
}

const selector = formValueSelector('allergy')

const ConnectedAllergiesOrSideEffectForm = connect(state => {
  let nkda = selector(state, 'nkda')
  let text = selector(state, 'substance.text')
  return {
    nkda,
    text
  }
})(AllergiesOrSideEffectForm)

const ReduxSideEffectForm = reduxForm({
  form: 'sideeffect',
  validate: sideEffectValidation
})(AllergiesOrSideEffectForm)

const ReduxAllergiesForm = reduxForm({
  form: 'allergy',
  validate: allergyValidation
})(ConnectedAllergiesOrSideEffectForm)

export const AllergiesForm = ReduxAllergiesForm
export const SideEffectForm = ReduxSideEffectForm
