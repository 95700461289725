/*
 * Actions for Patient Data services
 */

import gateway from 'services/gateway'
import logger from 'services/logger'
import pluralize from 'pluralize'

import { actions as notifyActions } from 'store/modules/notify'
import { handleError, handleRequest } from 'store/actions'
import { getLatestReviewByType, getPGxData } from 'services/patient'

import * as C from './types'

const fixBadNames = name => {
  switch (name) {
  case 'outreach':
  case 'condition':
    return name

  default:
    return pluralize(name)
  }
}

const shouldNestDataUnderType = type => {
  switch (type) {
  case 'note':
  case 'medication':
    return false
  default:
    return true
  }
}

/**
 * TODO getPatientData is a function to get patient data such as
 *      allergies, notes, medications, conditions, risk factors, etc.
 *      Basically anything in map-patient, and can be configured through
 *      the options passed to the action.
 *      Approaching it this way will make it easy to transition to a SQL
 *      or relational DB approach.
 *
 * @param {string} patientId The patient ID
 * @param {object} options The options dictating which data to retrieve
 * @param {boolean} options.notes The notes
 * @param {boolean} options.allergies The allergies
 * @param {boolean} options.condition The conditions
 * @param {boolean} options.medications The medications
 * @param {boolean} options.outreach The outreach array
 * TODO These are items that need to be pulled out of the patient docs and
 * make some gateway calls for these.
 * @param {boolean} options.riskFactors The risk factors
 * @param {boolean} options.prescribers The prescribers
 * @param {boolean} options.pharmacies The pharmacies
 * @param {boolean} options.caregivers The caregivers
 * @param {boolean} options.call_logs The call logs
 * @param {boolean} options.workflow The workflow
 */
export const getPatientData = (patientId, options) => {
  return async dispatch => {
    if (!Object.keys(options).length) return
    dispatch({
      type: C.GETTING_PATIENT_DATA
    })
    let results = {}
    try {
      for (let k in options) {
        let apiRoot = '/medwise'
        let prefix = ''
        let postfix = ''
        let formulary = ''
        // We need to fix our RESTful api calls. This is terrible.
        if (k === 'outreach') {
          apiRoot = ''
        }
        if (k === 'allergies' || k === 'observations') {
          prefix = '/patient'
        }
        if (k === 'notes' || k === 'outreach') {
          postfix = '/list'
        }
        if (k === 'medications') {
          formulary = 'includeFormularyData=true&'
        }

        if (k === 'condition') {
          postfix = '/list'
          results['conditions'] = await gateway.post(`/api${apiRoot}${prefix}/${k}${postfix}`, {
            patientId
          })
        } else {
          results[k] = await gateway.get(
            `/api${apiRoot}${prefix}/${k}${postfix}?${formulary}patientId=${patientId}`
          )
        }
      }

      dispatch(setPatientData(results))
    } catch (err) {
      dispatch(removePatientData())
      handleError(dispatch, err, C.FAILURE, C.GETTING_PATIENT_DATA)
    }
  }
}

export const getLatestReviewType = patientId => {
  return async dispatch => {
    handleRequest(dispatch, C.REQUEST, C.LATEST_REVIEW_BY_TYPE)

    try {
      const result = await getLatestReviewByType(patientId, patientId)
      dispatch({
        type: C.LATEST_REVIEW_BY_TYPE,
        payload: result
      })
    } catch (err) {
      handleError(dispatch, err, C.FAILURE, C.LATEST_REVIEW_BY_TYPE)
    }
  }
}

/**
 *  TODO The idea here is that you can update individual docs/rows for an entry
 *       or multiple entries in the patient data. It just depends what data you submit
 *
 *       This will use the same options struction as getPatientData
 *
 */
// export const updatePatientData = data => ({
//   // check the data type to git the correct save endpoint.
//   type: C.SET_PATIENT_DATA,
//   payload: data
// })

export const setPatientData = data => ({
  type: C.SET_PATIENT_DATA,
  payload: data
})

export const removePatientData = data => ({
  type: C.REMOVE_PATIENT_DATA
})

export const clearPatientData = dataToClear => ({
  type: C.CLEAR_PATIENT_DATA,
  payload: dataToClear
})
export const updatePatientData = (data, type = data.type, verb = 'post') => {
  return async (dispatch, getState) => {
    if (!data) {
      dispatch({
        type: C.UPDATE_PATIENT_DATA_FAILURE
      })
      dispatch(
        notifyActions.addNotification({
          message: `Patient data [${type}] was missing for update`
        })
      )
      logger(`Patient data [${type}] was missing for update`)
      throw new Error('Missing data for update')
    }
    const { auth } = getState()

    let baseType = type.split('.').pop()
    let postData = {}
    postData[baseType] = data
    postData[baseType].performedBy = {
      userFirstName: auth.profile.firstName,
      userLastName: auth.profile.lastName,
      userEmail: auth.profile.email
    }
    // data.performedBy = {
    //   userId: auth.id
    // }
    postData = shouldNestDataUnderType(baseType)
      ? {
        [baseType]: data
      }
      : data

    let patientId = data.subject
    let apiRoot = '/medwise'

    if (baseType === 'outreach') {
      apiRoot = ''
    }

    let route = `/api${apiRoot}/${baseType}`

    dispatch({
      type: C.UPDATE_PATIENT_DATA_REQUEST
    })
    // make post verb variable
    try {
      await gateway[verb](route, postData)
      // This dispatch pattern is used in patient action creator but doesn't appear to work here????
      // dispatch({ type: C.UPDATE_PATIENT_DATA_SUCCESS, payload: updatedValue })
      dispatch(
        getPatientData(patientId, {
          [fixBadNames(baseType)]: true
        })
      )
    } catch (err) {
      dispatch({
        type: C.UPDATE_PATIENT_DATA_FAILURE
      })
      dispatch(
        notifyActions.addNotification({
          message: 'An error occurred updating the patient data'
        })
      )
      logger('An error occurred updating the patient data', err, true)
      throw err
    }
  }
}

export const deletePatientData = data => {
  return async (dispatch, getState) => {
    let baseType = data.type.split('.').pop()

    if (!data) {
      dispatch({
        type: C.UPDATE_PATIENT_DATA_FAILURE
      })
      dispatch(
        notifyActions.addNotification({
          message: `Patient data [${baseType}] was missing for update`
        })
      )
      logger(`Patient data [${baseType}] was missing for update`)
      throw new Error('Missing data for update')
    }

    let verb = baseType === 'medication' ? 'put' : 'post'
    let route =
      baseType === 'medication' ? `/api/medwise/${baseType}` : `/api/medwise/${baseType}/delete`
    let patientId = data.subject
    let postData = {}
    postData[baseType] = data
    postData = shouldNestDataUnderType(baseType)
      ? {
        [baseType]: data
      }
      : data

    dispatch({
      type: C.DELETE_PATIENT_DATA_REQUEST
    })
    try {
      await gateway[verb](route, postData)
      dispatch(
        getPatientData(patientId, {
          [fixBadNames(baseType)]: true
        })
      )
    } catch (err) {
      dispatch({
        type: C.UPDATE_PATIENT_DATA_FAILURE
      })
      dispatch(
        notifyActions.addNotification({
          message: 'An error occurred deleting the patient data'
        })
      )
      logger('An error occurred deleting the patient data', err, true)
      throw err
    }
  }
}

export const getPatientPGxData = patientId => {
  return async dispatch => {
    handleRequest(dispatch, C.REQUEST, C.GETTING_PGX_DATA)

    try {
      const result = await getPGxData(patientId)
      dispatch({
        type: C.GETTING_PGX_DATA,
        payload: result
      })
    } catch (err) {
      handleError(dispatch, err, C.FAILURE, C.GETTING_PGX_DATA)
    }
  }
}
