/*
 * Auth Actions
 */

export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST'
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR'
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS'
export const AUTH_FORCED_LOGOUT_SUCCESS = 'AUTH_FORCED_LOGOUT_SUCCESS'
export const CHECK_FOR_EULA_ACCEPTANCE = 'CHECK_FOR_EULA_ACCEPTANCE'
export const GET_EULA_INFO_FOR_DISPLAY = 'GET_EULA_INFO_FOR_DISPLAY'

