import React from 'react'
import { orderBy as _orderBy } from 'lodash'
import PropTypes from 'prop-types'
import ContextButton from 'components/common/context-button'

const ProjectsContextButton = props => {
  const { text, className, onClick, items, standardTabInstance, patient } = props

  const dropdown = _orderBy(items, item => item.name).map(p => ({
    name: p.name,
    onClick: () =>
      standardTabInstance.current.handleFocus({
        project: p,
        patient
      })
  }))

  return <ContextButton text={text} dropdown={dropdown} className={className} onClick={onClick} />
}

ProjectsContextButton.propTypes = {
  text: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  standardTabInstance: PropTypes.object.isRequired,
  patient: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string
}

ProjectsContextButton.defaultProps = {
  className: 'primary',
  onClick: () => {},
  items: [],
  standardTabInstance: {},
  patient: {
    data: {
      projects: []
    }
  }
}

export default ProjectsContextButton
