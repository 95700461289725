import React from 'react'

class StandardFailure extends React.Component {
  render () {
    return (
      <div className="component">
        <h2>An error has occurred, and this component can not be rendered</h2>
        <code>{this.props.error.message}</code>
      </div>
    )
  }
}

export default StandardFailure
