import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import dayjs from 'dayjs'

import { filter as _filter, find as _find, get as _get, orderBy as _orderBy } from 'lodash'
import { formatUserName } from 'services/utils'
import { DateFormat } from 'constants/date'
import ProjectService from 'services/project'
import StandardTab from 'components/common/standard-tab'
import RowActions from 'components/common/row-actions'
import TitleCell from 'components/common/table-cells/title-cell'
import ColoredCell from 'components/common/table-cells/colored-cell'
import ProjectsContextButton from './projects-context-button'
import ProjectsHistoryModal from './projects-history-modal'
import ReduxProjectsForm from './form'

const mappedItem = (name, maps, component, isComponent) => ({ name, maps, component, isComponent })

const TODAY = new Date()
const PAGINATION = { maxPages: 5, pageSize: 5 }
const OBJECT_NAME = 'project'
const HEADERS = [
  mappedItem('Project Name', [{ propName: 'boldText', value: 'name' }], TitleCell),
  mappedItem(
    'Status',
    (val, key) => {
      const text = val.enrolled ? 'Activated' : 'Declined'
      const type =
        val.projectEnd && dayjs(val.projectEnd).isBefore(TODAY)
          ? 'warn'
          : val.enrolled
            ? 'success'
            : 'error'

      return <ColoredCell key={key} text={text} type={type} />
    },
    undefined,
    true
  ),
  mappedItem('Reason', 'reason'),
  mappedItem('Performed By', 'userId'),
  // mappedItem('Date', val => dayjs(_get(val, 'date')).format(DateFormat.DATE_DISPLAY)),
  mappedItem('Date', val => dayjs(_get(val, 'date')).format(DateFormat.DATE_DISPLAY)),
  mappedItem('Project End', val => {
    const style = val.projectEnd &&
      dayjs(val.projectEnd).isBefore(TODAY) && {
      style: {
        color: '#fff',
        backgroundColor: '#f00',
        padding: '0 0.25em'
      }
    }
    return <span {...style}>{val.projectEnd}</span>
  })
]

/**
 * Lists projects for a given patient and allows user to enroll or disenroll patients
 * to and from projects.
 */
class Projects extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isHistoryModalOpen: false,
      historyModalItem: {}
    }

    this.standardTab = React.createRef()
    this.formHandler = this.formHandler.bind(this)
    this.getProjectsForEnrollment = this.getProjectsForEnrollment.bind(this)
    this.getProjectsForDisenrollment = this.getProjectsForDisenrollment.bind(this)
    this.onHistory = this.onHistory.bind(this)
    this.historyToggleHandler = this.historyToggleHandler.bind(this)
  }

  /**
   * Retrieves a list of projects to which the patient may be enrolled
   */
  getProjectsForEnrollment () {
    const patientDataProjects = _get(this.props, ['patient', 'data', 'projects'], {})
    const patientDataProjectsKeys = Object.keys(patientDataProjects)

    const patientProjects = _get(this.props, ['project', 'patientProjects'], [])

    const today = TODAY
    today.setHours(0, 0, 0)
    const filteredPatientProjects = _filter(patientProjects, p => {
      let projectInactive = false
      // make sure the project hasn't ended
      if (p.projectEnd) {
        const projectEndDate = dayjs(p.projectEnd).toDate()
        projectEndDate.setHours(0, 0, 0)
        projectInactive = projectEndDate < today
      }

      return (
        (patientDataProjectsKeys.indexOf(p.couchId) < 0 ||
          patientDataProjects[p.couchId].enrolled === false) &&
        !projectInactive
      )
    })

    return filteredPatientProjects
  }

  /**
   * Retrieves list of projects from which the patient may be disenrolled
   */
  getProjectsForDisenrollment () {
    const patientDataProjects = _get(this.props, ['patient', 'data', 'projects'], {})
    const patientProjects = _get(this.props, ['project', 'patientProjects'], [])

    const filteredPatientProjects = _filter(patientProjects, p =>
      _get(patientDataProjects, [p.couchId, 'enrolled'])
    )

    return filteredPatientProjects
  }

  formHandler (formData) {
    // get the props we need from the provided data
    // const userId = _get(this.props, ['auth', 'profile', 'email'])
    const performedBy = {
      userFirstName: _get(this.props, ['auth', 'profile', 'firstName']),
      userLastName: _get(this.props, ['auth', 'profile', 'lastName']),
      userEmail: _get(this.props, ['auth', 'profile', 'email'])
    }
    const { enrolling, reason, patient, project } = formData
    const { couchId } = project
    const justPatient = patient.data
    // update the patient via the service call
    ProjectService.updatePatientProjectInfo(justPatient, couchId, enrolling, reason, performedBy)

    // submit the update
    this.props.patientActions.updatePatient(patient.data)

    // get the new list of active projects here maybe?
    this.props.projectActions.getProjectsForPatient(patient)
  }

  onHistory (item) {
    this.setState({
      historyModalItem: item,
      isHistoryModalOpen: true
    })
  }

  historyToggleHandler () {
    this.setState({
      isHistoryModalOpen: !this.state.isHistoryModalOpen
    })
  }

  render () {
    // get the data - will require some manipulating to get it to display all desired info
    const data = _get(this.props.patient.data, pluralize(OBJECT_NAME.toLowerCase()), [])

    // new variable to hold the project data
    const tableData = []

    // get the information for each project associated to the patient
    Object.keys(data).forEach((key, index) => {
      // get all of the changes for this project
      const changes = _get(data[key], 'changes', [])

      // get the most recent of those (last one in the list)
      const latestChange = _get(changes, changes.length - 1)
      // get the project matching this key so we can find the name of it
      const project = _find(this.props.project.patientProjects, p => p.couchId === key)
      const projectEnd = _get(project, 'projectEnd') ? project.projectEnd.split('T')[0] : undefined
      tableData[index] = {
        couchId: key,
        name: _get(project, 'name', key),
        reason: _get(latestChange, 'reason'),
        date: _get(latestChange, 'date'),
        userId: _get(latestChange, 'performedBy')
          ? formatUserName(latestChange)
          : _get(latestChange, 'userId'),
        enrolled: _get(latestChange, 'enrolled'),
        editable: !projectEnd || dayjs(projectEnd).isAfter(TODAY),
        projectEnd: projectEnd,
        history: changes
      }
    })

    // the edit will essentially also be the deletion so no need for both actions
    const actions = {
      name: 'Actions',
      maps: [
        {
          propName: 'item',
          rowItem: true
        },
        {
          propName: 'onEdit',
          func: project =>
            this.standardTab.current.handleFocus({
              project,
              patient: this.props.patient
            })
        },
        {
          propName: 'editEnabled',
          func: project => !project.projectEnd || dayjs(project.projectEnd).isAfter(TODAY)
        },
        {
          propName: 'onHistory',
          func: project => this.onHistory(project)
        }
      ],
      component: RowActions
    }

    // create the project add and withdraw buttons for the box
    const contextButtons = [
      {
        name: 'Projects Add',
        component: () => (
          <ProjectsContextButton
            text="Add to Project"
            items={this.getProjectsForEnrollment()}
            standardTabInstance={this.standardTab}
            patient={this.props.patient}
          />
        )
      }
    ]

    return (
      <Fragment>
        <StandardTab
          ref={this.standardTab}
          name="Project"
          object={OBJECT_NAME}
          form={ReduxProjectsForm}
          pagination={PAGINATION}
          tableHeaders={HEADERS}
          tableData={_orderBy(tableData, 'name')}
          tableActionsCell={actions}
          contextButtons={contextButtons}
          formHandler={this.formHandler}
          {...this.props}
        />
        <ProjectsHistoryModal
          isOpen={this.state.isHistoryModalOpen}
          item={this.state.historyModalItem}
          toggleHandler={this.historyToggleHandler}
        />
      </Fragment>
    )
  }
}

Projects.propTypes = {
  project: PropTypes.shape({
    patientProjects: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    orgProjects: PropTypes.arrayOf(PropTypes.shape())
  }).isRequired,
  patient: PropTypes.shape({
    data: PropTypes.shape().isRequired
  }).isRequired,
  projectActions: PropTypes.shape({
    getProjectsForPatient: PropTypes.func.isRequired
  }).isRequired,
  patientActions: PropTypes.shape({
    updatePatient: PropTypes.func.isRequired
  }).isRequired
}

export default Projects
