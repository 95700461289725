/**
 * Handles loading an async component
 */

import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'

const Loading = props => {
  if (props.error) {
    return (
      <div className="loading loading-error">
        Error! <button onClick={props.retry}>Retry</button>
      </div>
    )
  } else {
    // return <div className="loading">Loading ...</div>
    return (
      <div className={`loading tc${props.inline ? ' dib' : ''}`}>
        <div className="flex justify-center items-center">
          <CircularProgress color={props.color} size={props.size} />
          <span className="pl3">{props.text}</span>
        </div>
      </div>
    )
  }
}

Loading.propTypes = {
  text: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
  size: PropTypes.number
}

Loading.defaultProps = {
  text: 'Loading',
  color: 'primary',
  size: 20
}

export default Loading
