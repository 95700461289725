import React from 'react'
import PropTypes from 'prop-types'

/**
 * ListItemAction component renders an action button for a list item and passes a callback function to trigger
 * any external functions that should get called during an onClick event.
 *
 *  @param {string} title             The name to be displayed on the list item action
 *  @param {function} onClickHandler  Callback function triggered by a click event on the list item action
 *  @param {string} className  Additional string to pass in order to append classnames.
 *  @param {boolean} disabled         Disables the list item action button when condition is met.
 */
export const ListItemAction = ({ title, onClickHandler, className, disabled, children }) => {

  const onListItemClick = (event) => {
    event.stopPropagation()
    onClickHandler && onClickHandler()
  }

  return (
    <button 
      type="button"
      className={className}
      title={title}
      onClick={onListItemClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

ListItemAction.propTypes = {
  title: PropTypes.string,
  onClickHandler: PropTypes.func,
  disabled: PropTypes.bool
}

/**
 * ListItemActions component renders/wraps actions for a list item.
*/
export const ListItemActions = ({ children }) => {
  return (
    <div className="actions" >
      {children}
    </div>
  )
}
