import dayjs from 'dayjs'
import { isNil, isEmpty } from 'lodash'
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat) // set related to validating interactions

///////////////////////////
// Foundational Date Checks
export const isValidDateObject = (date, format) => {
  if (isNil(date) || isEmpty(date)) return false
  if (format) return dayjs(date, format, true).isValid()
  return dayjs(date).isValid()
}
export const isPast = (date, granularity) => (
  isValidDateObject(date) && (
  granularity
    ? dayjs(date).isBefore(dayjs(), granularity)
    : dayjs(date).isBefore(dayjs())
  )
)
export const isPastInclusive = date => (
  isValidDateObject(date) &&
  !isFuture(date)
)
export const isAfter1899 = date => (
  isValidDateObject(date) && 
  !dayjs(date).isBefore('01/01/1900')
)
export const isToday = date => isValidDateObject(date) && dayjs(date).isToday()
export const isFuture = date => isValidDateObject(date) && dayjs(date).isAfter(dayjs())
export const isFutureInclusive = (date, granularity) => (
  isValidDateObject(date) && (
  granularity
    ? !isPast(date, granularity)
    : !isPast(date)
  )
)

//////////////////////////////////////
// Scenario and/or consolidated checks
export const isValidDateOfBirth = (date, inclusive) => (
  isValidDateObject(date) &&
  !isFuture(date) &&
  (!isToday(date) || inclusive)
)

export const isPastAfter1899 = (date, inclusive) => (
  isValidDateObject(date) &&
  isAfter1899(date) &&
  !isFuture(date) &&
  (!isToday(date) || inclusive)
)

export const isAfter1899OrFuture = date => (
  isValidDateObject(date) &&
  isAfter1899(date) &&
  !isToday(date)
)