import React, { Component } from 'react'
import { Field, Form, reduxForm } from 'redux-form'
import dayjs from 'dayjs'
import { get, set, kebabCase } from 'lodash'
import { isValidDateObject, isFutureInclusive } from 'validators/date'
import { DateFormat, Messages } from 'constants/date'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import ReduxFormDatePicker from 'components/common/redux-form/date-picker'
import ReduxFormTimePicker from 'components/common/redux-form/time-picker'
import ReduxFormSelect from 'components/common/redux-form/select'
import ReduxFormText from 'components/common/redux-form/text'

export class ScheduledInteractionForm extends Component {
  componentDidMount () {
    const { dispatch, initialize, initialValues } = this.props
    const { scheduledForDate, ...otherInitialValues } = initialValues
    const date = dayjs(scheduledForDate).toDate()
    const time = date

    dispatch(initialize({ date, time, ...otherInitialValues }))
  }

  render () {
    const { handleSubmit } = this.props

    return (
      <Form onSubmit={handleSubmit} className="w-100 flex flex-column">
        <div className="flex justify-between mb2">
          <Field
            required
            name="callType"
            component={ReduxFormSelect}
            placeholder="Select a category"
            label="Type of Interaction"
          >
            {[
              { label: 'Initiating Interaction', value: 'Initiating Call' },
              { label: 'Medication Review Interaction', value: 'Medication Review' },
              {
                label: 'Medication Safety Review Interaction',
                value: 'Medication Safety Review'
              }
            ].map(option => (
              <MenuItem
                key={option.value}
                value={option.value}
                data-cy={`option-${kebabCase(option.value)}`}
              >
                {option.label}
              </MenuItem>
            ))}
            <Divider component="li" />
            <li className="pa2">
              <Typography color="textSecondary" variant="caption">
                Non-Workflow Calls
              </Typography>
            </li>
            {/* These have a value of Profile to match with 1.0 structure */}
            {/* QUESTION: Should "Follow-up Call" simply be Follow-up to address Call vs Interaction? */}
            {[
              { label: 'Follow-up Call', value: 'Follow-up Call' },
              { label: 'Resend Documents', value: 'Resend Documents' },
              { label: 'Question for pharmacist', value: 'Question for pharmacist' },
              { label: 'Question for technician', value: 'Question for technician' },
              { label: 'Fax issues', value: 'Fax issues' },
              { label: 'Direct Communication', value: 'Direct Communication' },
              { label: 'Other', value: 'Other' }
            ].map(option => (
              <MenuItem
                key={option.value}
                value={option.value}
                data-cy={`option-${kebabCase(option.value)}`}
              >
                {option.label}
              </MenuItem>
            ))}
          </Field>
        </div>
        <div className="flex justify-between mb2">
          <div className="dib w-50 mr1">
            <ReduxFormDatePicker
              required
              name="date"
              label="Date"
              disablePast={true}
            />
          </div>
          <div className="dib w-50">
            <ReduxFormTimePicker required keyboard name="time" label="Time" />
          </div>
        </div>
        <div>
          <Field
            name="record.description"
            type="text"
            label="Note"
            component={ReduxFormText}
            multiline={true}
            rows={6}
          />
        </div>
      </Form>
    )
  }
}

export const validate = call => {
  let error = {}
  if (!call.callType) {
    set(error, 'callType', 'Type of interaction is required')
  }
  const callDate = get(call, 'date')
  // This additional check outside the validateDate() is necessary to show a 
  // diffence btwn "Required" when field is empty and "Invalid Date" for 
  // an incomplete date field.
  if (!callDate) {
    set(error, 'date', 'Required')
  }
  const validateDate = (date, errorKeyName) => {
    if (!isValidDateObject(dayjs(date).format(DateFormat.DATE_DISPLAY_SHORT), DateFormat.DATE_DISPLAY_SHORT)) return set({}, errorKeyName, 'Invalid Date')
    if (!isFutureInclusive(date, 'day')) return set({}, errorKeyName, Messages.TODAY_OR_FUTURE)
  }
  if (callDate) {
    const interactionErrors = validateDate(callDate, 'date')
    error = Object.assign(error, interactionErrors)
  }
  if (!call.time || !dayjs(call.time).isValid()) {
    set(error, 'time', 'Invalid Time')
  }

  return error
}

let ReduxScheduledInteractionForm = reduxForm({
  form: 'schedule_log',
  validate
})(ScheduledInteractionForm)

export default ReduxScheduledInteractionForm
