/*
 * Mock dashboard component
 * Tabbed wrapper dashboard to mimic the current Medwise dashboard design.  
 * Tabs are declared in the corresponding tabs.js file
 */

import React, { Fragment } from 'react'

import { tabManager } from 'components/common/tab-manager'

import {ActionableListDocumentation} from '../actionable-list-documentation'

// modules
import tabDefaults from './tabs'

import './mock-dashboard.scss'

const MockDashboard = (props) => {
  return (
    <Fragment>
      <div className="w-80 center pb4">
        <ActionableListDocumentation />
      </div>
      
      <div className="mock-dashboard">{props.children}</div>
    </Fragment>

  )
}

export default tabManager(tabDefaults, MockDashboard)
