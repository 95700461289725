import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { withStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import { get, gt, isEmpty } from 'lodash'
import CharacterCount from 'components/common/character-count'

const styles = {
  nonEmptyInput: {
    background: 'white'
  }
}

/**
 * @example
 * <div>
 *   <Field
 *     required
 *     name="medication"
 *     type="text"
 *     label="medication"
 *     component={ReduxFormAutoComplete}
 *     ↖️ THIS COMPONENT
 *     getSuggestions={someAsyncFunctionOrArray} // func that gets async suggestions (string inutText value passed to it)
 *     debounce={1000} // amount of time the component waits after last input before calling the getSuggestions function
 *
 *     //INPUT MANAGEMENT:
 *     //when the field first shows.. show field that is tied to local state
 *     //has search adornment that switches to spinner when searching
 *     //then `onSelect`, set the selected value to the field tied to redux state
 *     //show that field now with an x adornment to clear the redux form state
 *
 *     //this will automatically cause the localstate field to show
 *
 *     //SUGGESTIONS:
 *     // build a menu of items.. onClick set the
 *   />
 * </div>
 */
class ReduxFormSearchSelect extends Component {
  constructor (props) {
    super(props)

    this.searchTimeout = undefined
    this.menuPlacementRef = React.createRef()
    this.state = {
      inputText: '',
      suggestions: [],
      disableInput: props.disabled || false,
      focusedSuggestion: -1,
      focusField: false
    }

    this.scrollableAreaId = 'scrollable-select-area'
  }

  componentWillUnmount () {
    clearTimeout(this.searchTimeout)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { input, disableInputOnSelection } = this.props
    const { inputText } = this.state

    if(!isEmpty(input.value) && isEmpty(inputText)) {
      this.setState(
        { inputText: input.value, disableInput: disableInputOnSelection }
      )
    }

  }

  onInputChange = event => {
    const { debounce } = this.props
    const val = event.target.value

    clearTimeout(this.searchTimeout)
    this.setState({ inputText: val })
    this.searchTimeout = setTimeout(this.fetchSuggestions, debounce)
  }

  fetchSuggestions = async () => {
    const { getSuggestions, onInputTextChange } = this.props
    const { inputText } = this.state

    if(onInputTextChange) {
      onInputTextChange(inputText)
    }

    const suggestions = await getSuggestions(inputText)
    this.setState({
      suggestions,
      focusedSuggestion: -1
    })
  }

  setSelection = selection => {
    const { getSelectionValue, onSelectionChange, input, multiple, disableInputOnSelection, getSuggestionLabel, filterValue, onSelectionClear } = this.props

    if (multiple) {
      this.handleClear()
    } else if (getSelectionValue) {
      const value = getSelectionValue(selection) || selection
      this.setState(
        {
          selection: selection,
          disableInput: disableInputOnSelection,
          selectionText: value,
          inputText: value,
          focusedSuggestion: -1
        },
        () => input.onChange(value)
      )
    }

    onSelectionChange && onSelectionChange(selection)

    this.setState({
      inputText: onSelectionClear ? '' :
        (getSelectionValue && getSelectionValue(selection)) || (filterValue && filterValue(input.value)) || getSuggestionLabel(selection),
      focusField: true,
      disableInput: disableInputOnSelection,
      suggestions: []
    })

  }

  handleClickAway = event => {
    // if the event target's ID does not match our scrollable area, close the menu
    if (event.target.id !== this.scrollableAreaId) {
      this.handleClose()
    }
  }

  handleClose = () => {
    const { input } = this.props
    const { inputText } = this.state

    input.onChange(inputText)

    this.setState({
      suggestions: [],
      focusedSuggestion: -1
    })
  }

  handleKeyDown = event => {
    const { focusedSuggestion, suggestions } = this.state
    switch (event.keyCode) {
    case 9: // Tab - close the suggestion list, we are tabbing away from the field
    case 27: // escape - close the list
      this.handleClose()
      break
    case 38: // Arrow up
      event.preventDefault()
      if (focusedSuggestion > 0) {
        this.setState(
          {
            focusedSuggestion: focusedSuggestion - 1
          },
          () => this.handleScrollToDropdownItem()
        )
      }
      break
    case 40: // Arrow down
      event.preventDefault()
      if (focusedSuggestion < suggestions.length - 1) {
        this.setState(
          {
            focusedSuggestion: focusedSuggestion + 1
          },
          () => this.handleScrollToDropdownItem()
        )
      }
      break
    case 13: // enter key - we selected something
      event.preventDefault()
      if (focusedSuggestion > -1) {
        this.setState(
          {
            focusedElement: document.activeElement
          },
          () => this.setSelection(suggestions[focusedSuggestion])
        )
      }
      break
    default:
    }
  }

  handleScrollToDropdownItem () {
    const { focusedSuggestion } = this.state
    const scrollableArea = document.getElementById(this.scrollableAreaId)
    const focusedItem = scrollableArea.querySelector(`[data-key="suggestion-${focusedSuggestion}"`)

    focusedItem.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
  }

  handleClear = () => {
    const { onSelectionClear, input } = this.props

    input.onChange('')
    onSelectionClear && onSelectionClear()

    this.setState(
      {
        suggestions: [],
        inputText: '',
        selectionText: '',
        disableInput: false,
        focusedSuggestion: -1
      }
    )
  }

  renderSuggestions = () => {
    const { suggestions, focusedSuggestion } = this.state
    const { getSuggestionLabel } = this.props

    return (
      <Popper
        id={this.scrollableAreaId}
        open={true}
        anchorEl={this.menuPlacementRef.current}
        style={{
          position: 'absolute',
          maxHeight: '250px',
          width: this.menuPlacementRef.current.offsetWidth,
          overflow: 'scroll',
          zIndex: 1000000000
        }}
        transition
        placement="bottom-start"
      >
        {() => (
          <Paper>
            <ClickAwayListener onClickAway={this.handleClickAway}>
              <MenuList className="mw7">
                {suggestions &&
                  suggestions.map &&
                  suggestions.map((suggestion, index) => (
                    <MenuItem
                      className={cx('mw7', index === focusedSuggestion ? 'focused' : '')}
                      dense={true}
                      key={`suggestion-${index}`}
                      data-key={`suggestion-${index}`}
                      onClick={() => {
                        this.setSelection(suggestion)
                      }}
                    >
                      {getSuggestionLabel(suggestion)}
                    </MenuItem>
                  ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        )}
      </Popper>
    )
  }


  render () {
    const {
      children,
      classes,
      debounce,
      filterValue,
      helperText,
      input,
      label,
      maxCharacters,
      meta: { touched, error, warning },
      required,
      showCharacterCount,
      disabled,
      autoFocus,
      variant,
      margin
    } = this.props

    const { disableInput, focusField, inputText, selectionText, suggestions } = this.state

    let display
    let displayValue = (filterValue && filterValue(input.value)) || input.value
    let endAdornment = null

    if (disableInput) {
      endAdornment = <ClearIcon onClick={this.handleClear} className={cx('ph2')} />
    }
    
    if (disabled) {
      endAdornment = <ClearIcon className={cx('ph2')} />
    }

    if (!input.value && disableInput) {
      display = (
        <TextField
          autoFocus={autoFocus || focusField}
          disabled={disableInput}
          value={selectionText}
          label="Name"
          variant={variant}
          margin={margin}
          InputProps={{
            endAdornment
          }}
        />
      )
    } else if (input.value) {
      display = (
        <TextField
          required={required}
          autoFocus={autoFocus || focusField}
          disabled={disableInput}
          value={disableInput ? displayValue : inputText}
          label={label}
          onChange={ disableInput ? () => {} : this.onInputChange  }
          InputLabelProps={{ shrink: true }}
          onKeyDown={this.handleKeyDown.bind(this)}
          variant={variant}
          margin={margin}
          InputProps={{
            endAdornment,
            className: classes.nonEmptyInput
          }}
          onBlur={() => this.setState({ focusField: false })}
        />
      )
    } else if (!input.value && !disableInput) {
      display = (
        <TextField
          variant={variant}
          margin={margin}
          autoFocus={autoFocus || focusField}
          value={inputText}
          disabled={disableInput}
          label={label}
          InputProps={{
            endAdornment: <SearchIcon className={cx('ph2')}/>
          }}
          onChange={this.onInputChange}
          onBlur={() => {
            input.onBlur(undefined)
          }}
          onKeyDown={this.handleKeyDown.bind(this)}
          style={{
            ...(gt(get(input, 'value.length', 0), 0) ? styles.nonEmptyInput : {})
          }}
          inputProps={{ debounce: debounce }}
        >
          {children}
        </TextField>
      )
    }

    const showError = !!(touched && error)
    const showWarning = !!(touched && warning)
    return (
      <FormControl warning={warning} required={required} fullWidth>
        {display}

        <div ref={this.menuPlacementRef} />
        { inputText &&
          suggestions &&
          suggestions.length > 0 &&
          !disableInput &&
          this.renderSuggestions()}
        <div className="flex justify-between">
          <div className="flex flex-column">
            {showError && typeof showError !== 'object' && (
              <FormHelperText error>{error}</FormHelperText>
            )}
            {!showError && showWarning && (
              <FormHelperText warning={warning}>{warning}</FormHelperText>
            )}
            {!showError && !showWarning && helperText && (
              <FormHelperText>{helperText}</FormHelperText>
            )}
          </div>
          {showCharacterCount && (
            <CharacterCount
              maxCharacters={maxCharacters}
              characterCount={get(input, 'value.length', 0)}
            />
          )}
        </div>
      </FormControl>
    )
  }
}

ReduxFormSearchSelect.propTypes = {
  multiple: PropTypes.bool,
  disableInputOnSelection: PropTypes.bool,
  showCharacterCount: PropTypes.bool,
  getSuggestions: PropTypes.func,
  getSuggestionLabel: PropTypes.func,
  debounce: PropTypes.number,
  onInputChange: PropTypes.func,
}

ReduxFormSearchSelect.defaultProps = {
  disableInputOnSelection: true,
  showCharacterCount: false,
  debounce: 2000
}

export default withStyles(styles)(ReduxFormSearchSelect)
