import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Field, reduxForm, formValueSelector } from 'redux-form'
import { capitalize, get, find } from 'lodash'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import ReduxFormCheckbox from 'components/common/redux-form/checkbox'
import ReduxFormDatePicker from 'components/common/redux-form/date-picker'
import ReduxFormSelect from 'components/common/redux-form/select'
import ReduxFormText from 'components/common/redux-form/text'
import ReduxFormRadioGroup from 'components/common/redux-form/radio-group'
import AlertDialog from 'components/common/alert-dialog'
import StyledSnackbarContent from 'components/common/styled-snackbar'

import { formatWorkflowName } from 'services/workflow'
import { getPatientOrgDoc, isPatientInPreceptorModel } from 'services/patient'
import { canAccess } from 'services/idam'

import { INITIATING_CALL_FTE_MAX, MED_REC_REVIEW_FTE_MAX } from 'constants/interactions'

import interactionsFormValidation from './validation'

export const getInteractionConditionals = (interactionCategory, initiatedBy, spokeTo) => {
  const workflowCalls = ['Initiating Call', 'Medication Review', 'Medication Safety Review']
  const displayIfWorkflowCall =
    interactionCategory && workflowCalls.indexOf(interactionCategory) !== -1
  const notAWorkflowCall = workflowCalls.indexOf(interactionCategory) === -1
  const inboundWorkflowCall =
    displayIfWorkflowCall && ['member', 'caregiver'].indexOf(initiatedBy) > -1
  const successfulCall =
    inboundWorkflowCall || ['Member & Caregiver', 'Member', 'Caregiver'].indexOf(spokeTo) !== -1

  return {
    workflowCalls,
    displayIfWorkflowCall,
    notAWorkflowCall,
    inboundWorkflowCall,
    successfulCall
  }
}

const PROGRAM = 'PROGRAM'
const PROJECT = 'PROJECT'

export class InteractionForm extends Component {
  state = {
    optOutDialogOpen: false
  }

  componentDidMount () {
    const { dispatch, initialize, initialValues } = this.props
    const spokeToMember = get(initialValues, 'record.spokeToMember')
    const spokeToCaregiver = get(initialValues, 'record.spokeToCaregiver')
    const lvm = get(initialValues, 'record.lvm')

    const spokeTo =
      spokeToMember && spokeToCaregiver
        ? 'Member & Caregiver'
        : spokeToMember
          ? 'Member'
          : spokeToCaregiver
            ? 'Caregiver'
            : lvm
              ? 'Left Voicemail'
              : null

    dispatch(initialize({ spokeTo, ...initialValues }))
  }

  onDecisionChange = value => {
    if (value === false) {
      this.setState({ optOutDialogOpen: true })
    } else {
      this.setState({ withdrawFrom: undefined })
    }
  }

  getProgramName = () => {
    const { patient, organizations } = this.props
    const patientManagingOrg = get(patient, 'managingOrganization', {})
    const programOrgId = patientManagingOrg.secondary
      ? patientManagingOrg.secondary
      : patientManagingOrg.id
    const programOrg = find(get(organizations, 'allOrgs', []), { _id: programOrgId })

    return get(programOrg, 'name', '')
  }

  getPrimaryProjectName = () => {
    const { project } = this.props
    const patientProjects = get(project, 'patientProjects')
    const primaryProject = find(patientProjects, { primary: true })

    return get(primaryProject, 'name', '')
  }

  getOptOutText = () => {
    const { withdrawFrom } = this.state

    switch (withdrawFrom) {
    case PROGRAM:
      return `Withdraw from ${this.getProgramName()} program`
    case PROJECT:
      return `Withdraw from ${this.getPrimaryProjectName()} project`
    default:
      return 'No'
    }
  }

  handleCatChange = (e, value) => {
    const { initiatedBy } = this.props
    this.setAttempts(value, initiatedBy)
  }

  setAttempts (category, direction) {
    const { change, initialValues, spokeTo } = this.props
    const { inboundWorkflowCall } = getInteractionConditionals(category, direction, spokeTo)
    if (direction !== 'user') {
      change('record.attempt', `${capitalize(direction)} Initiated`)
      if (!inboundWorkflowCall) {
        change('record.decision', null)
        change('spokeTo', null)
      }
    } else if (direction === 'user') {
      if (category === 'Initiating Call') {
        change('record.attempt', get(initialValues, 'deleteValues.attempts.initial', 1))
      } else if (category === 'Medication Review') {
        change('record.attempt', get(initialValues, 'deleteValues.attempts.medrec', 1))
      } else if (category === 'Medication Safety Review') {
        change('record.attempt', get(initialValues, 'deleteValues.attempts.msr', 1))
      } else {
        change('record.attempt', null)
      }
    }
  }

  handleDirectionChange = (e, value) => {
    const { interactionCategory } = this.props
    this.setAttempts(interactionCategory, value)
  }

  cancelDeclination = () => {
    const { change } = this.props
    change('record.decision', null)
    this.setState({ optOutDialogOpen: false })
  }

  confirmProgramDeclination = () => {
    const { change } = this.props
    this.setState({ optOutDialogOpen: false, withdrawFrom: PROGRAM })
    change('record.forPrimaryProject', false)
  }

  confirmPrimaryProjectDeclination = () => {
    const { change } = this.props
    this.setState({ optOutDialogOpen: false, withdrawFrom: PROJECT })
    change('record.forPrimaryProject', true)
  }

  computeCallTypes () {
    const { auth } = this.props

    const categoryOptions = [
      { label: 'Initiating Interaction', value: 'Initiating Call' },
      { label: 'Medication Review Interaction', value: 'Medication Review' }
    ]

    if (canAccess(auth, true, { roles: { $or: ['pharmacist', 'support', 'clinical_admin'] } })) {
      categoryOptions.push({
        label: 'Medication Safety Review Interaction',
        value: 'Medication Safety Review'
      })
    }

    return categoryOptions
  }

  render () {
    const {
      handleSubmit,
      spokeTo,
      interactionCategory,
      initiatedBy,
      decisionTrue,
      record,
      patient,
      organizations
    } = this.props

    const maxCallsForFTE =
      interactionCategory === 'Initiating Call' ? INITIATING_CALL_FTE_MAX : MED_REC_REVIEW_FTE_MAX
    const fteWarningMessage = `After ${maxCallsForFTE} unsuccessful attempts, the member will be marked "Failure to Engage". If the member should remain in a state of "${formatWorkflowName(
      patient.currentWorkflowState.name
    )}", check the box below.`

    const fteWarningMessageNoCheckbox = `After ${maxCallsForFTE} unsuccessful attempts, the member will be marked "Failure to Engage".`

    const patientOrgDoc = getPatientOrgDoc(
      get(patient, 'managingOrganization'),
      organizations.allOrgs
    )

    const {
      displayIfWorkflowCall,
      notAWorkflowCall,
      inboundWorkflowCall,
      successfulCall
    } = getInteractionConditionals(interactionCategory, initiatedBy, spokeTo)

    const categoryOptions = this.computeCallTypes()

    let recipientListSuccessful = ['Member & Caregiver', 'Member', 'Caregiver']
    if (notAWorkflowCall) {
      recipientListSuccessful = recipientListSuccessful.concat([
        'Pharmacy',
        'Prescriber',
        'Facility'
      ])
    }

    let recipientListUnsuccessful = ['Left Voicemail', 'Bad Number', 'Other']

    let patientProjects = get(this.props, 'project.patientProjects', [])

    const primaryProject = patientProjects.length ? patientProjects.find(p => p.primary) : null

    let declineServiceButtons = [
      {
        buttonText: `Cancel`,
        color: 'default',
        onClick: this.cancelDeclination
      }
    ]
    //
    if (primaryProject && !isPatientInPreceptorModel(patientOrgDoc)) {
      declineServiceButtons = declineServiceButtons.concat([
        {
          buttonText: `Withdraw from ${this.getProgramName()}`,
          autoFocus: true,
          onClick: this.confirmProgramDeclination
        },
        {
          buttonText: `Decline ${this.getPrimaryProjectName()}`,
          autoFocus: true,
          onClick: this.confirmPrimaryProjectDeclination
        }
      ])
    } else if (primaryProject && isPatientInPreceptorModel(patientOrgDoc)) {
      declineServiceButtons = declineServiceButtons.concat([
        {
          buttonText: `Opt Out`,
          autoFocus: true,
          onClick: this.confirmProgramDeclination
        }
        // ,
        // {
        //   buttonText: `No Thanks`,
        //   autoFocus: true,
        //   // Don't love that this function doesn't really describe what is happening for preceptor model
        //   onClick: this.confirmPrimaryProjectDeclination
        // }
      ])
    } else {
      declineServiceButtons = declineServiceButtons.concat([
        {
          buttonText: `Opt Out`,
          autoFocus: true,
          onClick: this.confirmProgramDeclination
        }
      ])
    }

    return (
      <Form name="interactionForm" onSubmit={handleSubmit} className="w-100 flex flex-column">
        <AlertDialog
          open={this.state.optOutDialogOpen}
          title="Confirm Service Declination"
          text="Please indicate if the member wishes to..."
          actions={declineServiceButtons}
        />
        <div className="flex justify-between mb2">
          <div className="dib w-30">
            <Field
              required
              name="userMetadata.firstName"
              type="text"
              label="Initiator First Name"
              component={ReduxFormText}
              disabled
            />
          </div>
          <div className="dib w-30">
            <Field
              required
              name="userMetadata.lastName"
              type="text"
              label="Initiator Last Name"
              component={ReduxFormText}
              disabled
            />
          </div>
          <div className="dib w-30">
            <ReduxFormDatePicker
              name="record.dateRecorded"
              label="Date Added"
              disableFuture={true}
            />
          </div>
        </div>
        <div>
          <Field
            name="record.interaction"
            type="text"
            component={ReduxFormRadioGroup}
            radioLabelsAndValues={[
              { label: 'Call', value: 'call' },
              { label: 'Encounter', value: 'encounter' }
            ]}
          />
        </div>
        <div className="flex mb2">
          <div className="w-50 pr2">
            <Field
              required
              name="app"
              onChange={(e, val) => this.handleCatChange(e, val)}
              component={ReduxFormSelect}
              label="Type of Interaction"
            >
              {categoryOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
              <Divider component="li" />
              <li className="pa2">
                <Typography color="textSecondary" variant="caption">
                  Non-Workflow Calls
                </Typography>
              </li>
              {/* These have a value of Profile to match with 1.0 structure */}
              {[
                { label: 'Follow-up Call', value: 'Follow-up Call' },
                { label: 'Resend Documents', value: 'Resend Documents' },
                { label: 'Question for pharmacist', value: 'Question for pharmacist' },
                { label: 'Question for technician', value: 'Question for technician' },
                { label: 'Fax issues', value: 'Fax issues' },
                { label: 'Direct Communication', value: 'Direct Communication' },
                { label: 'Other', value: 'Other' }
              ].map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Field>
          </div>
          <div className="w-20 pr2">
            <Field
              required
              name="workflowIteration"
              type="number"
              label="Engagement"
              component={ReduxFormText}
              disabled
            />
          </div>
          <div className="w-20 pr2">
            {displayIfWorkflowCall && (
              <Field
                required
                name="record.attempt"
                parse={value => (!value ? null : Number(value))}
                disabled={initiatedBy !== 'user'}
                label="Attempt"
                component={ReduxFormText}
              />
            )}
          </div>
        </div>
        <div className="flex mb2">
          <div className="w-20 pr2">
            <Field
              required
              name="record.direction"
              type="text"
              label="Initiated By"
              component={ReduxFormSelect}
              onChange={(e, val) => this.handleDirectionChange(e, val)}
            >
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="member">Member</MenuItem>
              <MenuItem value="caregiver">Caregiver</MenuItem>
              <MenuItem value="pharmacy">Pharmacy</MenuItem>
              <MenuItem value="prescriber">Prescriber</MenuItem>
              <MenuItem value="facility">Facility</MenuItem>
            </Field>
          </div>

          <div className={`w-40 pr2 ${interactionCategory && initiatedBy === 'user' ? '' : 'dn'}`}>
            <Field
              required
              name="spokeTo"
              component={ReduxFormSelect}
              label="Recipient of Interaction"
            >
              <li className="pa2">
                <Typography color="textSecondary" variant="caption">
                  Successful
                </Typography>
              </li>
              {recipientListSuccessful.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
              <Divider component="li" />
              <li className="pa2">
                <Typography color="textSecondary" variant="caption">
                  Not Successful
                </Typography>
              </li>
              {recipientListUnsuccessful.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Field>
          </div>

          <div
            className={`w-40 pl4 ${
              (displayIfWorkflowCall && successfulCall) || inboundWorkflowCall ? '' : 'dn'
            }`}
          >
            <Field
              required
              name="record.decision"
              component={ReduxFormSelect}
              label="Member Decision"
              externalOnChange={this.onDecisionChange}
            >
              {[
                { label: 'Continue', value: true },
                { label: this.getOptOutText(), value: false }
              ].map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Field>
          </div>
        </div>
        <div
          className={`${
            (interactionCategory && notAWorkflowCall) || spokeTo === 'Other' ? 'mb2' : 'dn'
          }`}
        >
          <Field
            required
            name="record.description"
            type="text"
            label="Note"
            component={ReduxFormText}
            multiline={true}
            rows={6}
          />
        </div>
        <div className={`${displayIfWorkflowCall && decisionTrue === false ? 'mb2' : 'dn'}`}>
          <Field
            required
            name="record.decisionReason"
            type="text"
            label="Reason"
            component={ReduxFormText}
            multiline={true}
            rows={2}
          />
        </div>
        <div
          className={`${
            interactionCategory && displayIfWorkflowCall && spokeTo !== 'Other' ? 'mb2' : 'dn'
          }`}
        >
          <Field
            name="record.description"
            type="text"
            label="Note"
            component={ReduxFormText}
            multiline={true}
            rows={6}
          />
        </div>
        {get(record, 'attempt') >= maxCallsForFTE &&
          spokeTo &&
          !successfulCall &&
          !patientOrgDoc.usesNewWorkflow && (
          <div className="mb2">
            <StyledSnackbarContent variant="error" message={fteWarningMessage} />
            <div>
              <Field
                name="record.overrideWorkflow"
                type="checkbox"
                label="Override 'Failure to Engage'"
                component={ReduxFormCheckbox}
              />
            </div>
          </div>
        )}
        {get(record, 'attempt') >= maxCallsForFTE &&
          spokeTo &&
          !successfulCall &&
          patientOrgDoc.usesNewWorkflow && (
          <div className="mb2">
            <StyledSnackbarContent variant="error" message={fteWarningMessageNoCheckbox} />
          </div>
        )}
      </Form>
    )
  }
}

let ReduxInteractionForm = reduxForm({
  form: 'call_log',
  validate: interactionsFormValidation
})(InteractionForm)

const selector = formValueSelector('call_log')

ReduxInteractionForm = connect(state => {
  let spokeTo = selector(state, 'spokeTo')
  let interactionCategory = selector(state, 'app')
  let initiatedBy = selector(state, 'record.direction')
  let decisionTrue = selector(state, 'record.decision')
  let record = selector(state, 'record')
  return {
    spokeTo,
    interactionCategory,
    initiatedBy,
    decisionTrue,
    record,
    patient: state.patient.data,
    project: state.project
  }
})(ReduxInteractionForm)

export default ReduxInteractionForm
