// Note: JSDocs does not display these export const functions as functions in their documentation

/** @module Calculations */

import dayjs from 'dayjs'

/**
 * Converts inches to feet and inches string.
 * @param  {string} inches - numeric string or numeric value.
 * @returns {string} String display of value in feet and inches.
 */

export const convertInchesToFeetAndInches = (inches) => {
  const feetFromInches = Math.floor(inches / 12) // there are 12 inches in a foot
  const inchesRemainder = inches % 12

  // return `${feetFromInches} ft. ${inchesRemainder} in.`
  return {
    feet: feetFromInches,
    inches: inchesRemainder
  }
}
/**
 * Convert a value with feet and inches to just inches.
 * @param {number} feet - the number of feet to convert.
 * @param {number} inches - the number of inches to convert.
 * @returns {number} An inches value.
 */
export const convertFeetAndInchesToInches = (feet, inches) => {
  const inchesFromFeet = feet * 12
  return inchesFromFeet + inches
}

/**
 * Determines the BMI Class for a BMI score.
 * @param  {number} data - A BMI score.
 * @returns {string} A string that is the BMI class.
 */
export const determineBmiClass = (data) => {
  var bmiClass = ''
  switch (true) {
  case (data < 16.5):
    bmiClass = 'Severely Underweight'
    break
  case (data < 18.5):
    bmiClass = 'Underweight'
    break
  case (data < 25):
    bmiClass = 'Normal'
    break
  case (data < 30):
    bmiClass = 'Overweight'
    break
  case (data < 35):
    bmiClass = 'Obese Class I'
    break
  case (data < 40):
    bmiClass = 'Obese Class II'
    break
  case (data > 39.9):
    bmiClass = 'Obese Class III'
    break
  default:
    bmiClass = 'Not Specified'
  }
  return bmiClass
}
/**
 * Converts kilograms to pounds.
 * @param  {number} data - Kilogram value to be converted.
 * @returns {number} A pounds value.
 */
export const kgToLb = (data) => data * 2.2
/**
 * Converts centimeters to inches.
 * @param  {number} data - Centimeter value to be converted.
 * @returns {number} An inches value.
 */
export const cmToIn = (data) => data * 0.39
/**
 * Converts pounds to kilograms.
 * @param  {number} data - Pounds value to be converted to centimeters.
 * @returns {number} A Kilogram value.
 */
export const lbToKg = (data) => data * 0.45

/**
 * Calculate BMI
 * @param  {object} heightData - The height information of the patient.
 * @param  {string} heightData.units - The units height is measured in. Can be either in (inches) or cm (centimeters).
 * @param  {string} heightData.value - The number of inches or centimeters. Function can accept a string or number.
 * @param  {object} weightData - The weight information of the patient.
 * @param  {string} weightData.units - The units the weight is measured in. Can be lb(pounds) or kg(kilos).
 * @param  {string} weightData.value - The number of lbs or kilos. Function can accept a string or number.
 * @returns {number}  - Returns the computed BMI rounded to two decimal places.
 */
export const calculateBMI = (heightData, weightData) => {
  const weight = (weightData.units === 'lb') ? parseFloat(weightData.value) : kgToLb(parseFloat(weightData.value))

  const height = (heightData.units === 'in') ? parseFloat(heightData.value) : cmToIn(parseFloat(heightData.value))
  return ((weight * 703) / (height * height)).toFixed(2)
}

/**
 * Calculates the adjusted body weight for the calculateCreatinineClearance function.
 * @param  {number} actualWeight - Patients recorded body weight in kilograms.
 * @param  {number} ibw          - Ideal Body Weight as calculated by the calculateIdealBodyWeightMale or calculateIdealBodyWeightFemale functions.
 * @return {number}              - The adjusted body weight.
 */
function calculateAdjustedBodyWeight (actualWeight, ibw) {
  // ABW = IBW + 0.4(actual weight - IBW)
  return ibw + (0.4 * (actualWeight - ibw))
}

/**
 * Function to determine if Actual Body Weight is higher than Ideal Body Weight by less than 25%.
 * @param  {number} actualWeight - Patients recorded body weight in kilograms.
 * @param  {[type]} ibw          - Ideal Body Weight as calculated by the calculateIdealBodyWeightMale or calculateIdealBodyWeightFemale functions.
 * @return {boolean}              - Returns true if ideal body weight should be used.
 */
function useIdealBodyWeight (actualWeight, ibw) {
  return (actualWeight - ibw) <= (ibw * 0.25)
}

/**
 * Function to determine if Actual Body Weight is less than Ideal Body Weight.
 * @param  {number} actualWeight - Patients recorded body weight in kilograms.
 * @param  {number} ibw          - Ideal Body Weight as calculated by the calculateIdealBodyWeightMale or calculateIdealBodyWeightFemale functions.
 * @return {boolean}             - Returns true if actual body weight should be used.
 */
function useActualWeight (actualWeight, ibw) {
  // Use Actual Body Weight if Actual Body Weight is less than Ideal Body Weight
  return actualWeight < ibw
}

/**
 * Function to determine if Actual Body Weight is higher than Ideal Body Weight by more than 25%.
 * @param  {number} actualWeight - Patients recorded body weight in kilograms.
 * @param  {number} ibw          - Ideal Body Weight as calculated by the calculateIdealBodyWeightMale or calculateIdealBodyWeightFemale functions.
 * @return {boolean}             - Returns true if adjusted body weight should be used.
 */
function useAdjustedBodyWeight (actualWeight, ibw) {
  return (actualWeight - ibw) > (ibw * 0.25)
}
/**
 * Funtion that determines the weight to use in the calculateCreatinineClearance function.
 * @param  {number} actualWeight - Patients recorded body weight in kilograms.
 * @param  {number} height       - Patient height in inches.
 * @param  {string} gender       - 'male' or 'female'.
 * @return {number}              - Number representing the calculated weight.
 */
function calculatedWeight (actualWeight, height, gender) {
  gender = gender.toLowerCase()
  const ibw = (gender === 'male') ? calculateIdealBodyWeightMale(height) : calculateIdealBodyWeightFemale(height)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (useActualWeight(actualWeight, ibw)) {
    return actualWeight
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (useIdealBodyWeight(actualWeight, ibw)) {
    return ibw
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (useAdjustedBodyWeight(actualWeight, ibw)) {
    return calculateAdjustedBodyWeight(actualWeight, ibw)
  }
}
/**
 * Calculates the ideal male body weight by adding 2.3KG for every inch over 5 feet + 50Kg.
 * @param  {number} data - Patient height data in inches.
 * @return {number}      - calulation of ideal male body weight based on height.
 */
function calculateIdealBodyWeightMale (data) {
  // IBW (Male) = 2.3Kg for every inch over 5 feet + 50Kg
  return (data > 60) ? ((data - 60) * 2.3) + 50 : 50
}
/**
 * Calculates the ideal female body weight by adding 2.3KG for every inch over 5 feet + 45.5Kg.
 * @param  {number} data - Patient height data in inches.
 * @return {number}      - calulation of ideal female body weight based on height.
 */
function calculateIdealBodyWeightFemale (data) {
  // IBW (Female) = 2.3Kg for every inch over 5 feet + 45.5Kg
  return (data > 60) ? ((data - 60) * 2.3) + 45.5 : 45.5
}
/**
 * Calculates the Creatinine Clearance - Sex * ((140 - Age) / (SerumCreat)) * (Weight / 72)
 * @param  {object} heightData       - The height information of the patient.
 * @param  {string} heightData.units - The units height is measured in. Can be either in (inches) or cm (centimeters).
 * @param  {string} heightData.value - The number of inches or centimeters. Function can accept a string or number.
 * @param  {object} weightData - The weight information of the patient.
 * @param  {string} weightData.units - The units the weight is measured in. Can be lb(pounds) or kg(kilos).
 * @param  {string} weightData.value - The number of lbs or kilos. Function can accept a string or number.
 * @param  {object} creatinineSerumData - Kendr
 * @param  {string} creatinineSerumData.units - As of Oct 2018 the only option for this is 'mg/dL'
 * @param  {string} creatinineSerumData.value - Numberical value of the creatinine serum measurement
 * @param  {string} genderData          - String equal to Male or Female that can be upper or lower case.
 * @param  {string} dobData             - patients day of birth to calculate age.
 * @return {number}                     - Creatinine Clearance value to two decimal places.
 */
export const calculateCreatinineClearance = (heightData, weightData, creatinineSerumData, genderData, dobData) => {
  const heightIn = (heightData.units === 'cm') ? cmToIn(heightData.value) : parseFloat(heightData.value)
  const weightKg = (weightData.units === 'lb') ? lbToKg(weightData.value) : parseFloat(weightData.value)
  const genderWeight = (genderData.toLowerCase() === 'male') ? 1 : 0.85
  const age = dayjs().diff(dobData, 'years')
  // CreatClear = Sex * ((140 - Age) / (SerumCreat)) * (Weight / 72)
  return (genderWeight * ((140 - age) / parseFloat(creatinineSerumData.value)) * (calculatedWeight(weightKg, heightIn, genderData) / 72)).toFixed(2)
}
