/*
 * Dashboard member search component
 */

import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { debounce, find, get, isEqual } from 'lodash'
import { KeyboardDatePicker as MuiDatePicker } from '@material-ui/pickers'
import TextField from '@material-ui/core/TextField'

import { actions as searchActions } from 'store/modules/patient-search'
import { actions as aggregateActions } from 'store/modules/patient-search-aggregates'

import { formatWorkflowName } from 'services/workflow'
import { getOrganizationById } from 'services/organizations'
import Table from 'components/common/table'
import Pagination from 'components/common/pagination'

import MemberFilter from './search-filter'
import MemberSort from './search-sort'
import PlainEnglishSummary from './search-plain-english-summary'
import { MEMBER_SEARCH_FILTER_PROPERTIES } from 'constants/search'
import { buildOrgList } from './build-orgs'
import { DateFormat, Date, Messages } from 'constants/date'

export class MemberSearch extends Component {
  constructor (props) {
    super(props)

    this.ALL_USER_ORG_FILTERS = []
    this.state = {
      searchString: '',
      filters: [],
      queries: [],
      sort: [],
      from: 0,
      page: 0,
      size: 10,
      dob: undefined
    }

    this.debouncedSearchPatients = debounce(
      () => this.ALL_USER_ORG_FILTERS.length && this.props.searchPatients(this.buildSearchParams()),
      250,
      {
        trailing: true,
        max_wait: 250
      }
    )

    // build default for all user orgs
    this.ALL_USER_ORG_FILTERS = buildOrgList(get(this.props, 'organizations.userOrgs'))
  }

  ensureOrganizationFilters = searchParams => {
    const hasOrgFilter = !!find(searchParams.filters, ['statType', 'org'])
    if (!hasOrgFilter) {
      searchParams.filters = searchParams.filters.concat(this.ALL_USER_ORG_FILTERS)
    }
    return searchParams
  }

  buildSearchParams = () => {
    const { dob, filters, from, searchString, sort } = this.state

    let searchParams = {
      queries: [],
      filters,
      sort,
      from
    }

    if (searchString.length >= 3 /* minimum ngram indexing in elasticsearch */) {
      searchParams.queries.push(searchString)
    }

    searchParams = this.ensureOrganizationFilters(searchParams)

    // handle search by birth date
    if (dob) {
      let formatted = dayjs(dob).format(DateFormat.SYSTEM_DATE)
      const dobQuery = {
        statType: 'dob',
        queryType: 'range',
        lte: formatted + 'T23:59:59Z',
        gte: formatted + 'T00:00:00Z'
      }

      searchParams.queries.push(dobQuery)
    }

    return searchParams
  }

  updateFilters = filters => this.setState({ filters, page: 0 }, this.debouncedSearchPatients)

  updateSorting = sort => this.setState({ sort, page: 0 }, this.debouncedSearchPatients)

  handleChange = name => event => {
    this.setState(
      {
        [name]: event.target.value,
        page: 0
      },
      this.debouncedSearchPatients
    )
  }

  handleDOBChange = newDate => {
    this.setState(
      {
        dob: newDate,
        page: 0
      }
    )
  }

  componentDidMount () {
    this.ALL_USER_ORG_FILTERS = buildOrgList(get(this.props, 'organizations.userOrgs'))
    if (!this.ALL_USER_ORG_FILTERS.length) return
    this.debouncedSearchPatients()
  }

  componentWillUnmount () {
    this.props.removePatients()
  }

  componentDidUpdate = prevProps => {
    if (
      !isEqual(get(prevProps, 'organizations.userOrgs'), get(this.props, 'organizations.userOrgs'))
    ) {
      this.ALL_USER_ORG_FILTERS = buildOrgList(get(this.props, 'organizations.userOrgs'))
      if (!this.ALL_USER_ORG_FILTERS.length) return
      this.debouncedSearchPatients()
    }
  }

  handlePaginate = page => {
    this.setState(
      {
        from: this.state.size * page,
        page
      },
      this.debouncedSearchPatients
    )
  }

  render () {
    const { auth, organizations } = this.props
    const { dob, filters, page, searchString, size, sort } = this.state
    const headers = [
      { name: 'Patient', maps: 'name', classNames: ['w-50'] },
      { name: 'Age', maps: 'age' },
      { name: 'Sex', maps: 'sex' },
      { name: 'Total Risk Score', maps: 'total_risk_score' },
      { name: 'Routine Meds', maps: 'routine' },
      { name: 'Workflow', maps: 'workflow' }
    ]
    if (get(auth, 'profile.organizations', []).indexOf('Tandigm') > -1) {
      headers.push({
        name: 'Call or Home',
        maps: patient => {
          return get(patient, 'home_care_flag') === '1'
            ? 'Home'
            : get(patient, 'home_care_flag') === '0'
              ? 'Call'
              : ''
        }
      })
    }
    const data = []
    if (get(this.props, 'patientSearch.hits.length')) {
      this.props.patientSearch.hits.forEach(({ _source }) => {
        data.push({
          ..._source,
          _id: _source.id,
          name: (
            <Link key={_source.id} to={`/dashboard/patient/${_source.id}`}>
              {_source.name}
            </Link>
          ),
          age: dayjs().diff(_source.dob, 'years'),
          sex: _source.sex,
          routine: _source.routine,
          workflow: formatWorkflowName(_source.workflow),
          org: getOrganizationById(organizations.allOrgs, ['org', _source.org].join(':')).name
        })
      })
    }
    const rowActions = [
      {
        contents: <i className="material-icons">edit</i>,
        onClick: function (data) {
          this.props.dispatch(push(`/dashboard/patient/${data._id}`))
        }
      }
    ]

    for (let i = 0; i < rowActions.length; i++) {
      if (rowActions[i].onClick) {
        rowActions[i].onClick.bind(this)
      }
    }

    return (
      <div className="member-search">
        <form noValidate autoComplete="off" className="flex flex-row justify-between items-center">
          <TextField
            id="searchString"
            className="w-70"
            placeholder="Type patient's name or phone number"
            value={searchString}
            onChange={this.handleChange('searchString')}
            margin="dense"
            variant="outlined"
          />
          <MuiDatePicker
            autoOk
            animateYearScrolling={false}
            className="w-25 date-picker-dob"
            clearable
            disableFuture={true}
            format={DateFormat.DATE_DISPLAY_SHORT}
            invalidDateMessage={Messages.INVALID_DATE}
            maxDateMessage={Messages.FUTURE_DATE}
            minDate={Date.MIN_DATE}
            minDateMessage={null}
            onAccept={this.debouncedSearchPatients}
            onBlur={this.debouncedSearchPatients}
            onChange={this.handleDOBChange}
            onClick={this.debouncedSearchPatients}
            placeholder="Select a Birthdate"
            style={{ background: 'white !important' }}
            value={dob}
          />
        </form>

        <div className="mt3">
          <MemberFilter
            buildSearchParams={this.buildSearchParams}
            filters={filters}
            filtersToShow={MEMBER_SEARCH_FILTER_PROPERTIES}
            updateFilters={this.updateFilters}
            patientSearchAggregates={this.props.patientSearchAggregates}
            fetchAggregates={this.props.fetchAggregates}
            {...this.props}
          />
        </div>

        <div className="mt3">
          <MemberSort updateSorting={this.updateSorting} sort={sort} />
        </div>
        <div className="mt3">
          <PlainEnglishSummary
            searchString={searchString}
            filters={filters}
            sort={sort}
            dob={dob}
          />
        </div>
        <div className="mt3">
          <Table
            headers={headers}
            data={data}
            loading={!this.ALL_USER_ORG_FILTERS.length || this.props.patientSearch.loading}
            loadingMessage="Loading Member Search..."
            rowKeyProp="_id"
            rowActions={rowActions}
          />
          <Pagination
            total={this.props.patientSearch.total}
            pageSize={size}
            onClick={this.handlePaginate}
            maxPages={5}
            buttons={true}
            page={page}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ patientSearch, patientSearchAggregates }) => ({
  patientSearch,
  patientSearchAggregates
})

const mapDispatchToProps = dispatch => ({
  removePatients: () => dispatch(searchActions.removePatients()),
  searchPatients: options => dispatch(searchActions.searchPatients(options)),
  fetchAggregates: options => dispatch(aggregateActions.fetchAggregates(options))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberSearch)
