import React, { Component } from 'react'
import ReactToPrint from 'react-to-print'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'
import dayjs from 'dayjs'
import { get } from 'lodash'
import Icon from '@material-ui/core/Icon'
import ContextButton from './context-button'
import Modal from 'components/common/modal'
import { Field, Form, reduxForm } from 'redux-form'
import ReduxFormCheckbox from 'components/common/redux-form/checkbox'
import LoadingSpinner from 'components/common/loadingSpinner'

import { eulaActions } from 'store/modules/eula'
import { authActions } from 'store/modules/auth' // TODO: refactor as prop so that the Eula /> remains more flexible.
import * as AuthService from 'services/auth'

import './styles/eula.scss'

// Eula is displayed within a Modal and the Modal's actions / buttons
// are a the EulaForm component.
class EulaForm extends Component {
  render () {
    return (
      <Form
        name={`Form`}
        className="flex flex-column"
        onSubmit={e => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <div className="center">
          <Field
            required={true}
            name="readAndUnderstood"
            type="checkbox"
            label="I have read and understood these terms of use"
            component={ReduxFormCheckbox}
          />
        </div>
        <div className="flex context-buttons pb2 mb2">
          <ContextButton
            text="Acknowledged and agreed"
            className="primary"
            disabled={
              get(this.props, 'eula.isFetchingContent', true) ||
              !get(this.props, 'reduxForm.eula.values.readAndUnderstood', false) ||
              !get(this.props, 'eula.details.content', false)
            }
            onClick={e => {
              this.props.eulaActions.acknowledgedAndAgreed(true)
              this.props.eulaActions.postEulaAcceptance(get(this.props, 'eula.details.id'))
            }}
          />
          <ContextButton
            text="I do not agree"
            type="button"
            className="secondary"
            onClick={() => {
              this.props.eulaActions.acknowledgedAndAgreed(false)
              this.props.authActions.logoutSuccess()
              AuthService.logout()
              this.props.dispatch(push({ pathname: '/' }))
              this.props.eulaActions.toggleModal()
            }}
          />
        </div>
      </Form>
    )
  }
}

const ReduxEulaForm = reduxForm({
  form: 'eula'
})(EulaForm)

class EulaContent extends Component {
  render () {
    let eulaContent = get(this.props, 'content', undefined)
    let isFetchingContent = get(this.props, 'isFetchingContent')
    if (isFetchingContent || eulaContent === undefined) {
      return (
        <main className="tc">
          <LoadingSpinner />
        </main>
      )
    } else {
      return (
        <main
          className="ba b--moon-gray pa2 eula-content"
          dangerouslySetInnerHTML={{ __html: eulaContent }}
        />
      )
    }
  }
}

class Eula extends Component {
  checkEula = () => {
    const { auth, eula, eulaActions } = this.props
    if (auth.isAuthenticated && !eula.acknowledgedAndAgreed) {
      eulaActions.checkIfEulaIsNeeded()
    }
  }

  componentDidMount () {
    this.checkEula()
  }

  componentDidUpdate (previousProps) {
    if (!previousProps.auth.isAuthenticated && this.props.auth.isAuthenticated) {
      this.checkEula()
    }
  }

  render () {
    let createdAt = get(this.props, 'eula.details.createdAt', null)
    let formattedCreatedAt = createdAt ? dayjs(createdAt).format('M/D/YY') : createdAt
    let eulaContent = get(this.props, 'eula.details.content')

    const defaultActions = (
      <div>
        <ReduxEulaForm {...this.props} />
      </div>
    )

    return (
      <div>
        <Modal
          title=""
          isOpen={this.props.eula.isOpen}
          actions={this.props.modalActions || defaultActions}
          modalBackgroundClasses={['blackout']}
          toggle={() => {
            // authActions.logoutSuccess()
            // AuthService.logout()
          }}
        >
          <small>{createdAt ? `Version: ${formattedCreatedAt}` : null}</small>
          <div className="flex flex-row">
            <div className="f2 w-90">End User License Agreement</div>
            <ReactToPrint
              trigger={() => (
                // TODO: Should this be a button instead?
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a href="#" className="f2 tr w-10 ">
                  <Icon className="near-black">print</Icon>
                </a>
              )}
              content={() => this.componentRef}
              pageStyle=""
            />
          </div>
          <EulaContent
            ref={el => (this.componentRef = el)}
            content={eulaContent}
            isFetchingContent={get(this.props, 'eula.isFetchingContent', true)}
          />
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, menu, eula, form, history }) => ({
  auth,
  menu,
  eula,
  reduxForm: form
})

const mapDispatchToProps = dispatch => ({
  authActions: {
    logoutSuccess: () => dispatch(authActions.logoutSuccess())
  },
  eulaActions: {
    toggleModal: () => dispatch(eulaActions.toggleModal()),
    readAndUnderstood: acknowledgement => dispatch(eulaActions.readAndUnderstood(acknowledgement)),
    acknowledgedAndAgreed: agreement => dispatch(eulaActions.acknowledgedAndAgreed(agreement)),
    postEulaAcceptance: eulaVersionId => dispatch(eulaActions.postEulaAcceptance(eulaVersionId)),
    checkIfEulaIsNeeded: () => dispatch(eulaActions.checkIfEulaIsNeeded())
  }
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Eula)
)
