// Remove this when backend workflow is rolled out for all orgs.
export const WORKFLOW_NAMES = {
  IMPORTED: 'Imported',
  WAITING_MAILER: 'Waiting for Mailer',
  READY_CONSULT: 'Ready for Consult',
  READY_ECPN_MEDICATION_SAFETY_REVIEW: 'Ready for eCPN Medication Safety Review',
  READY_FOR_MSR_PRECEPTOR_REVIEW: 'Ready for MSR Preceptor Review',
  READY_INITIATING_CALL: 'Ready for Initiating Call',
  READY_MEDICATION_REVIEW: 'Ready for Medication Review',
  READY_MEDICATION_SAFETY_REVIEW: 'Ready for Medication Safety Review',
  READY_PRINTING: 'Ready for Printing',
  READY_MAILING: 'Ready for Mailing',
  OPT_OUT_INITIATING_CALL: 'Member Opt Out at Initiating Call',
  OPT_OUT_MEDICATION_REVIEW: 'Member Opt Out at Medication Review',
  OPT_OUT_MEDICATION_SAFETY_REVIEW: 'Member Opt Out at Medication Safety Review',
  SERVICE_DECLINED_INITIATING_INTERACTION: 'Decline of Service at Initiating Interaction',
  SERVICE_DECLINED_MEDICATION_REVIEW: 'Decline of Service at Medication Review',
  SERVICE_DECLINED_MSR: 'Decline of Service at Medication Safety Review',
  FAILURE_ENGAGE_INITIATING_CALL: 'Failure to Engage at Initiating Call',
  FAILURE_ENGAGE_MEDICATION_REVIEW: 'Failure to Engage at Medication Review',
  FAILURE_ENGAGE_MEDICATION_SAFETY_REVIEW: 'Failure to Engage at Medication Safety Review',
  NOT_ELIGIBLE: 'Not Eligible',
  HOSPICE: 'Hospice Do Not Call',
  COMPLETE: 'Complete',
  UNKNOWN: 'Unknown'
}

export const REVERSE_NAMES = {
  'Waiting for Mailer': WORKFLOW_NAMES.WAITING_MAILER,
  'Ready for Consult': WORKFLOW_NAMES.READY_CONSULT,
  'Ready for eCPN Medication Safety Review': WORKFLOW_NAMES.READY_ECPN_MEDICATION_SAFETY_REVIEW,
  'Ready for MSR Preceptor Review': WORKFLOW_NAMES.READY_FOR_MSR_PRECEPTOR_REVIEW,
  'Ready for Initiating Call': WORKFLOW_NAMES.READY_INITIATING_CALL,
  'Ready for Medication Review': WORKFLOW_NAMES.READY_MEDICATION_REVIEW,
  'Ready for Medication Safety Review': WORKFLOW_NAMES.READY_MEDICATION_SAFETY_REVIEW,
  'Ready for Printing': WORKFLOW_NAMES.READY_PRINTING,
  'Ready for Mailing': WORKFLOW_NAMES.READY_MAILING,
  'Scheduled for Consult': WORKFLOW_NAMES.READY_CONSULT,
  'Scheduled for eCPN Medication Safety Review': WORKFLOW_NAMES.READY_ECPN_MEDICATION_SAFETY_REVIEW,
  'Scheduled for Initiating Interaction': WORKFLOW_NAMES.READY_INITIATING_CALL,
  'Scheduled for Medication Review': WORKFLOW_NAMES.READY_MEDICATION_REVIEW,
  'Scheduled for Medication Safety Review': WORKFLOW_NAMES.READY_MEDICATION_SAFETY_REVIEW,
  'Scheduled for Printing': WORKFLOW_NAMES.READY_PRINTING,
  'Scheduled for Mailing': WORKFLOW_NAMES.READY_MAILING,
  'Member Opt Out at Initiating Call': WORKFLOW_NAMES.OPT_OUT_INITIATING_CALL,
  'Member Opt Out at Initiating Interaction': WORKFLOW_NAMES.OPT_OUT_INITIATING_CALL,
  'Member Opt Out at Medication Review': WORKFLOW_NAMES.OPT_OUT_MEDICATION_REVIEW,
  'Member Opt Out at Medication Safety Review': WORKFLOW_NAMES.OPT_OUT_MEDICATION_SAFETY_REVIEW,
  'Failure to Engage at Initiating Call': WORKFLOW_NAMES.FAILURE_ENGAGE_INITIATING_CALL,
  'Failure to Engage at Initiating Interaction': WORKFLOW_NAMES.FAILURE_ENGAGE_INITIATING_CALL,
  'Failure to Engage at Medication Review': WORKFLOW_NAMES.FAILURE_ENGAGE_MEDICATION_REVIEW,
  'Failure to Engage at Medication Safety Review':
    WORKFLOW_NAMES.FAILURE_ENGAGE_MEDICATION_SAFETY_REVIEW,
  'Not Eligible': WORKFLOW_NAMES.NOT_ELIGIBLE,
  'Hospice Do Not Call': WORKFLOW_NAMES.HOSPICE,
  Complete: WORKFLOW_NAMES.COMPLETE
}

/**
 *  Look up a term and return the currently active value.
 *
 *  @param {String} str The term to look up
 *  @returns {String} The currently active value of this term
 */
export const reverseLookup = str => {
  if (REVERSE_NAMES[str]) return REVERSE_NAMES[str]

  return WORKFLOW_NAMES.UNKNOWN
}
