import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import hash from 'object-hash'
import { filter as _filter, get as _get, reject } from 'lodash'
import FilterSet from 'components/common/filter-set'
import Pagination from 'components/common/pagination'
import RowActions from 'components/common/row-actions'
import Table from 'components/common/table'

/**
 * Standard Table component for use within the Standard Tab.  This table can handle pagination and
 * filtering.
 *
 * @param {(string|string[])} [actionsColumnClasses] - Classes to apply to the actions column.
 * @param {object[]} [filters] - Filters to apply to the table data.
 * @param {function} [handleDelete] - Method to handle deletion of a record. If not provided, the
 *                                    delete option will not display in the table rows.
 * @param {function} [handleFocus] - Method to handle focus of a record for editing. If not
 *                                    provided, the edit option will not display in the table rows.
 * @param {object} [pagination] - Pagination data to apply to the table.
 * @param {object[]} tableData - Data to be displayed in the table.
 * @param {object[]} tableHeaders - Headers to show on the table.
 * @param {object} [tableActionsCell] - Custom actions cell to display for records in the table.
 *
 */
class StandardTable extends Component {
  constructor (props) {
    super(props)
    const { pagination } = props
    this.state = {
      selectedFilters: [],
      filterHash: hash([]),
      showPaging: !!pagination,
      pageSize: _get(pagination, 'pageSize', 10),
      maxPages: _get(pagination, 'maxPages', 10),
      minRowCount: _get(pagination, 'minRowCount', null),
      from: 0
    }
  }

  /**
   * Handles the paginagion of the table data.  This is passed to the `Pagination` component.
   *
   * @param {number} page The current page number.
   */
  handlePaginate = page => {
    this.setState({
      from: this.state.pageSize * page
    })
  }

  /**
   * Retrieves the page items for the current page.
   *
   * @param {object[]} filteredItems The current list of items, filtered by any applicable filter
   *                                 data.
   * @returns {object[]} The filtered list of items sliced to display on the current page.
   */
  getPageItems = filteredItems => {
    const { pageSize, from } = this.state
    return filteredItems.slice(from, from + pageSize)
  }

  /**
   * Filters the items provided against any selected filters in the table.
   *
   * @param {object[]} items The available items to be filtered.
   * @returns {object[]} A list of filtered items after selected filters are applied.
   */
  getFilterdItems = items => {
    const { selectedFilters } = this.state

    if (!selectedFilters.length) {
      return items
    }

    const filteredItems = _filter(items, item => {
      let filtersSatisfied = true
      for (let i = 0; i < selectedFilters.length; i++) {
        if (!filtersSatisfied) {
          return
        }
        filtersSatisfied = selectedFilters[i].comparator(selectedFilters[i].value, item)
      }
      return filtersSatisfied
    })
    return filteredItems
  }

  /**
   * Performs the actual filtering for the table data.
   *
   * @param {string} name The name of the filter.
   * @param {any} value The value selected from the filter.
   * @param {function} comparator The comparator by which to filter the data.
   */
  onFilterUpdate = (name, value, comparator) => {
    const { selectedFilters } = this.state
    let updatedFilters = reject(selectedFilters, { name })
    value && updatedFilters.push({ name, value, comparator })
    this.setState({
      selectedFilters: updatedFilters,
      filterHash: hash(updatedFilters),
      from: 0
    })
  }

  render () {
    const {
      filters,
      tableData,
      tableHeaders,
      actionsColumnClasses,
      handleCopy,
      handleDelete,
      handleFocus,
      enableRowSelection,
      onSelectRow,
      onSelectAllRows,
      ...props
    } = this.props

    let { tableActionsCell } = props

    if (!tableActionsCell) {
      tableActionsCell = {
        name: 'Actions',
        classNames: actionsColumnClasses,
        maps: [
          {
            propName: 'item',
            rowItem: true
          },
          {
            propName: 'onEdit',
            func: handleFocus
          },
          {
            propName: 'onDelete',
            func: handleDelete
          },
          {
            propName: 'onCopy',
            func: handleCopy
          }
        ],
        component: RowActions
      }
    }

    // Do not add Action row if there are no actions.
    const tableHeadersWithActions =
      handleCopy || handleDelete || handleFocus
        ? tableHeaders.concat(tableActionsCell)
        : tableHeaders
    const filteredData = this.getFilterdItems(tableData || [])
    const dataCount = _get(filteredData, 'length', 0)
    const pageData = this.state.showPaging && this.getPageItems(filteredData)

    return (
      <Fragment>
        {filters && <FilterSet onUpdate={this.onFilterUpdate} filters={filters} />}
        <Table
          headers={tableHeadersWithActions}
          data={this.state.showPaging ? pageData : filteredData}
          minRowCount={this.state.minRowCount || this.state.pageSize}
          enableRowSelection={enableRowSelection}
          onSelectRow={onSelectRow}
          onSelectAllRows={onSelectAllRows}
          {...props}
        />
        {this.state.showPaging && (
          <Pagination
            display={this.state.pageSize < dataCount}
            key={this.state.filterHash}
            filterHash={this.state.filterHash}
            total={dataCount}
            currentStart={this.state.from}
            pageSize={this.state.pageSize}
            onClick={this.handlePaginate}
            maxPages={this.state.maxPages}
            buttons={true}
          />
        )}
      </Fragment>
    )
  }
}

StandardTable.propTypes = {
  actionsColumnClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  filters: PropTypes.array,
  handleCopy: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  handleDelete: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  handleFocus: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  pagination: PropTypes.object,
  tableData: PropTypes.array,
  tableHeaders: PropTypes.array,
  tableActionsCell: PropTypes.object,
  enableRowSelection: PropTypes.bool,
  rowSelectionDisabled: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onSelectAllRows: PropTypes.func
}

StandardTable.defaultProps = {
  tableHeaders: [],
  tableData: []
}

export default StandardTable
