/*
 * Eula Actions
 */

export const TOGGLE_MODAL = 'TOGGLE_MODAL'

export const GET_EULA_ACCEPTANCE_REQUEST = 'GET_EULA_ACCEPTANCE_REQUEST'
export const GET_EULA_ACCEPTANCE_SUCCESS = 'GET_EULA_ACCEPTANCE_SUCCESS'

export const GET_EULA_CONTENT_REQUEST = 'GET_EULA_CONTENT_REQUEST'
export const GET_EULA_CONTENT_SUCCESS = 'GET_EULA_CONTENT_SUCCESS'
export const GET_EULA_ERROR = 'GET_EULA_ERROR'

export const READ_AND_UNDERSTOOD = 'READ_AND_UNDERSTOOD'
export const ACKNOWLEDGED_AND_AGREED = 'ACKNOWLEDGED_AND_AGREED'

export const POST_EULA_ACCEPTANCE_REQUEST = 'POST_EULA_ACCEPTANCE_REQUEST'
export const POST_EULA_ACCEPTANCE_SUCCESS = 'POST_EULA_ACCEPTANCE_SUCCESS'
export const POST_EULA_ERROR = 'POST_EULA_ERROR'

export const CHECK_IF_EULA_IS_NEEDED = 'CHECK_IF_EULA_IS_NEEDED'