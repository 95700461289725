/*
 * Main Menu reducer
 */

import * as C from './types'

const mainMenuReducer = (
  state = {
    open: false,
    items: []
  },
  action
) => {
  switch (action.type) {
  case C.OPEN_MENU:
    return { ...state, open: true }
  case C.CLOSE_MENU:
    return { ...state, open: false }
  case C.TOGGLE_MENU:
    return { ...state, open: !state.open }
  case C.SET_MENU_ITEMS:
    return { ...state, items: action.payload }
  default:
    return state
  }
}

export default mainMenuReducer
