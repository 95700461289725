/*
 * note reducer
 */

import * as C from './types'

const FETCHING = 'FETCHING'
const SUCCESS = 'SUCCESS'
const ERROR = 'ERROR'

const reservationsInitialState = {
  data: {},
  status: undefined
}

const reservationsReducer = (state = { ...reservationsInitialState }, action) => {
  switch (action.type) {
  case C.FETCH_RESERVATIONS_REQUEST:
    return {
      ...state,
      status: FETCHING
    }
  case C.FETCH_RESERVATIONS_SUCCESS:
    return {
      data: action.payload,
      status: SUCCESS
    }
  case C.FETCH_RESERVATIONS_FAILURE:
    return {
      data: {},
      status: ERROR
    }
  default:
    return state
  }
}

export { reservationsReducer }
