import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'

import { get } from 'lodash'

import ReduxFormSelect from 'components/common/redux-form/select'
import ReduxFormText from 'components/common/redux-form/text'
import ReduxFormDatePicker from 'components/common/redux-form/date-picker'
import ReduxFormRadioGroup from 'components/common/redux-form/radio-group'
import { calculateBMI, convertFeetAndInchesToInches } from 'services/calculations'
import { observationValidation } from './validation'

import MenuItem from '@material-ui/core/MenuItem'

const convertValuesForBMI = data => {
  let unitOfMeasure = get(data, 'deleteValues.unitOfMeasure')
  if (unitOfMeasure === 'metric') {
    let meters = Number(get(data, 'deleteValues.lengthLarge', 0))
    let cm = Number(get(data, 'deleteValues.lengthSmall', 0))
    let convertedCentimeters = meters * 100 + cm

    let heightData = { units: 'cm', value: convertedCentimeters }
    let weight = get(data, 'deleteValues.weight', 0)
    let weightData = { units: 'kg', value: weight }
    if (weightData.value && heightData.value) {
      return calculateBMI(heightData, weightData)
    }
  }

  if (unitOfMeasure === 'imperial') {
    let feet = Number(get(data, 'deleteValues.lengthLarge', 0))
    let inches = Number(get(data, 'deleteValues.lengthSmall', 0))
    let convertedInches = convertFeetAndInchesToInches(feet, inches)
    let heightData = { units: 'in', value: convertedInches }
    let weight = get(data, 'deleteValues.weight', 0)
    let weightData = { units: 'lb', value: weight }
    if (weightData.value && heightData.value) {
      return calculateBMI(heightData, weightData)
    }
  }
}

const MEASUREMENTS = {
  imperial: {
    lengthLarge: 'Feet',
    lengthSmall: 'Inches',
    lengthDescription: 'Enter feet and inches, or just total inches.',
    weight: 'Weight (lbs.)'
  },
  metric: {
    lengthLarge: 'Meters',
    lengthSmall: 'Centimeters',
    lengthDescription: 'Enter meteres & centimeters, or just total centimeters.',
    weight: 'Weight (kg.)'
  }
}
/**
 * Redux Form to collect and display observation data.
 * @param {object} props [description]
 */
let ObservationsForm = props => {
  const { onSubmit, selectObs, selectedUnitOfMeasure, BMIData, initialValues } = props
  let disableObsChoice = !!get(initialValues, 'deleteValues.selectObs')

  let BMIDisplay = convertValuesForBMI(BMIData)
  return (
    <form className="w-100 flex flex-column" onSubmit={onSubmit}>
      <div className="flex justify-between mb2">
        <div className="dib w-50 pr3">
          <Field
            required
            name="deleteValues.selectObs"
            type="text"
            disabled={disableObsChoice}
            label="Choose an Observation"
            component={ReduxFormSelect}
          >
            <MenuItem value="BMI">Body Mass Index (BMI)</MenuItem>
            <MenuItem value="Creatinine Serum">Creatinine Serum</MenuItem>
            <MenuItem value="Custom">Custom observation</MenuItem>
          </Field>
        </div>
        <div className="dib w-50">
          <ReduxFormDatePicker name="issued" label="Date Recorded" disableFuture={true} />
        </div>
      </div>
      {selectObs === 'BMI' && (
        <div className="flex justify-between mb2">
          <div className="dib w-50">
            <div className="dib w-100">
              <Field
                name="deleteValues.unitOfMeasure"
                type="text"
                component={ReduxFormRadioGroup}
                radioLabelsAndValues={[
                  { value: 'imperial', label: 'Imperial' },
                  { value: 'metric', label: 'Metric' }
                ]}
              />
            </div>
            <div>
              <div className="dib w-40 mr2">
                <Field
                  name="deleteValues.lengthLarge"
                  label={
                    MEASUREMENTS[selectedUnitOfMeasure || initialValues.deleteValues.unitOfMeasure]
                      .lengthLarge
                  }
                  placeholder={
                    MEASUREMENTS[selectedUnitOfMeasure || initialValues.deleteValues.unitOfMeasure]
                      .lengthLarge
                  }
                  component={ReduxFormText}
                  type="number"
                />
              </div>
              <div className="dib w-40">
                <Field
                  name="deleteValues.lengthSmall"
                  label={
                    MEASUREMENTS[selectedUnitOfMeasure || initialValues.deleteValues.unitOfMeasure]
                      .lengthSmall
                  }
                  placeholder={
                    MEASUREMENTS[selectedUnitOfMeasure || initialValues.deleteValues.unitOfMeasure]
                      .lengthSmall
                  }
                  component={ReduxFormText}
                  type="number"
                />
              </div>
              <small id="name-desc" className="f6 black-60 db mt2">
                {
                  MEASUREMENTS[selectedUnitOfMeasure || initialValues.deleteValues.unitOfMeasure]
                    .lengthDescription
                }
              </small>
            </div>
            <div className="dib w-50">
              <Field
                name="deleteValues.weight"
                label={
                  MEASUREMENTS[selectedUnitOfMeasure || initialValues.deleteValues.unitOfMeasure]
                    .weight
                }
                component={ReduxFormText}
                type="number"
                maxCharacters={3}
                placeholder={
                  MEASUREMENTS[selectedUnitOfMeasure || initialValues.deleteValues.unitOfMeasure]
                    .weight
                }
                required
              />
            </div>
          </div>
          <div className="dib w-50">
            <div className="center mw5 mw6-ns br3 hidden ba b--black-10 mv4">
              <div className="pa3 bt b--black-10 tc">
                <h1 className="f1 mb2">{BMIDisplay}</h1>
                <div className="ba mr5 ml5" />
                <h2 className="f4 fw4 mt2">Body Mass Index</h2>
              </div>
            </div>
          </div>
        </div>
      )}
      {selectObs === 'Creatinine Serum' && (
        <div className="flex justify-between mb2">
          <div className="dib w-70">
            <Field
              name="valueQuantity.value"
              label="Creatinine Serum"
              component={ReduxFormText}
              type="number"
              placeholder="Creatinine Serum (mg/dL)"
              required
            />
          </div>
        </div>
      )}
      {selectObs === 'Custom' && (
        <div className="flex justify-between mb2">
          <div className="dib w-50">
            <Field
              name="text"
              label="Name"
              component={ReduxFormText}
              type="text"
              placeholder="Name"
              required
            />
          </div>
          <div className="dib w-20">
            <Field
              name="valueQuantity.value"
              component={ReduxFormText}
              label="Value"
              type="text"
              placeholder="Value"
              required
            />
          </div>
          <div className="dib w-20">
            <Field
              name="valueQuantity.units"
              label="Units"
              component={ReduxFormText}
              type="text"
              placeholder="Units"
            />
          </div>
        </div>
      )}
    </form>
  )
}

ObservationsForm = reduxForm({
  form: 'observation',
  validate: observationValidation
})(ObservationsForm)

// Decorate with connect to read form values
const selector = formValueSelector('observation') // <-- same as form name
ObservationsForm = connect(state => {
  // can select values individually
  const selectObs = selector(state, 'deleteValues.selectObs')
  const selectedUnitOfMeasure = selector(state, 'deleteValues.unitOfMeasure')
  const kiloSelected = selector(state, 'weightKg')
  const BMIData = selector(
    state,
    'deleteValues.unitOfMeasure',
    'deleteValues.lengthLarge',
    'deleteValues.lengthSmall',
    'deleteValues.weight'
  )
  // or together as a group
  // const { firstName, lastName } = selector(state, 'firstName', 'lastName')
  return {
    selectObs,
    selectedUnitOfMeasure,
    kiloSelected,
    BMIData
  }
})(ObservationsForm)

export default ObservationsForm