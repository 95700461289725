/*
 * med rec reducer
 */

import * as C from './types'

const initialState = {
  data: null,
  reconciled: null,
  completed: null,
  error: null,
  fetching: false,
  type: null
}

const reducer = (
  state = {
    ...initialState
  },
  action
) => {
  switch (action.type) {
  case C.RECONCILIATION_FETCHING:
    return {
      ...state,
      fetching: true,
      type: action.type
    }
  case C.RECONCILIATION_SUCCESS:
    return {
      ...state,
      error: null,
      fetching: false,
      type: action.type
    }
  case C.RECONCILIATION_FAILURE:
    return {
      ...state,
      error: action.error,
      fetching: false,
      type: action.type
    }
  case C.RECONCILED_DATA:
    return {
      ...state,
      error: null,
      fetching: false,
      reconciled: action.payload,
      type: action.type
    }
  case C.COMPLETE_RECONCILIATION:
    return {
      ...state,
      error: null,
      fetching: false,
      completed: action.payload,
      type: action.type
    }
  case C.RECONCILIATION_LIST_DATA:
  case C.NEW_RECONCILIATION:
  case C.NEW_RECONCILIATION_LIST:
    return {
      ...state,
      error: null,
      data: action.payload,
      type: action.type
    }
  case C.CLEAR_RECONCILIATION:
    return initialState
  default:
    return state
  }
}

export default reducer
