import { get, forIn, set } from 'lodash'

const requiredFields = {
  name: 'Pharmacy Name',
  pharmacyType: 'Pharmacy Type',
  addressLine1: 'Address Line 1',
  city: 'City',
  state: 'State',
  zip: 'Zip',
  telephoneNumber: 'Phone number'
}

const validateRequiredFields = (data, errors) => {
  forIn(requiredFields, (label, fieldKey) => {
    if (!get(data, fieldKey)) {
      set(errors, fieldKey, `${label} is required`)
    }
  })
}

const validate = data => {
  const errors = {}

  validateRequiredFields(data, errors)

  return errors
}

export default validate
