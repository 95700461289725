import React, { Component, Fragment } from 'react'
import Button from '@material-ui/core/Button'
import { FaTrash as TrashIcon } from 'react-icons/fa'
import { kebabCase, map, reject, get, find } from 'lodash'
import SimpleSelect from 'components/common/simple-select'

import { INDEXED_PROPERTIES } from 'constants/search'

class SearchFilter extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  componentDidMount () {
    const userOrgs = get(this.props, 'organizations.userOrgs', [])
    this.initializeFilterOptions(userOrgs)
  }

  componentDidUpdate (prevProps) {
    if (
      get(prevProps, 'organizations.userOrgs', []) !== get(this.props, 'organizations.userOrgs')
    ) {
      const userOrgs = get(this.props, 'organizations.userOrgs', [])
      this.initializeFilterOptions(userOrgs)
    }
  }

  initializeFilterOptions = orgs => {
    let currentSearchParams = this.props.buildSearchParams(orgs)
    const hasOrgFilter = !!find(currentSearchParams.filters, ['statType', 'org'])
    if (!hasOrgFilter) return
    this.props.fetchAggregates(currentSearchParams)
  }

  removeFilter = filter => {
    let filters = reject(this.props.filters, filter)
    this.props.updateFilters(filters)
  }

  addFilter = filter => {
    if (!find(this.props.filters, filter)) {
      this.props.filters.push(filter)
      this.props.updateFilters(this.props.filters)
    }
  }

  filterText (filter) {
    return get(INDEXED_PROPERTIES, `${filter.statType}.summary`, () => '')(filter)
  }

  renderActiveFilters = () => {
    return map(this.props.filters, (filter, index) => (
      <div key={`${filter.queryType}-${filter.statType}-${index}`} className="mr2 mt2">
        <Button
          onClick={() => this.removeFilter(filter)}
          variant="contained"
          size="small"
          data-cy={`filter-${kebabCase(this.filterText(filter))}`}
        >
          <TrashIcon className="pr2" color="red" />
          {this.filterText(filter)}
        </Button>
      </div>
    ))
  }

  shouldShowFilter = property => {
    const { filtersToShow } = this.props
    return filtersToShow ? get(filtersToShow, property) : true
  }

  getAggregationKeys = property => {
    const { patientSearchAggregates } = this.props
    return get(patientSearchAggregates, property, [])
  }

  // Returns true if the option is not in the currently selected filters
  isUnselected = option => {
    return !find(this.props.filters, option.filter)
  }

  filterOptions = (property, propertyKey) => {
    const { auth, organizations, project } = this.props
    return property.filter
      .options(
        get(auth, 'profile'),
        this.getAggregationKeys(propertyKey),
        get(organizations, 'userOrgs'),
        get(project, 'allProjects', [])
      )
      .filter(this.isUnselected)
  }

  render () {
    return (
      <Fragment>
        <div className="">Filter Patients</div>
        <div className="flex flex-row flex-wrap mt2" data-cy="active-filters">
          {this.renderActiveFilters()}
        </div>
        <div className="flex flex-row flex-wrap mt2" data-cy="available-filters">
          {map(INDEXED_PROPERTIES, (property, propertyKey) => {
            return (
              this.shouldShowFilter(propertyKey) &&
              get(property, 'filter.options') && (
                <div key={propertyKey} className="w-20 mb1">
                  <SimpleSelect
                    cypressSuffix={property.label}
                    onChange={selection => this.addFilter(selection.filter)}
                    options={this.filterOptions(property, propertyKey)}
                    placeholder={property.label}
                    value={null}
                  />
                </div>
              )
            )
          })}
        </div>
      </Fragment>
    )
  }
}

export default SearchFilter
