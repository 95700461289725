/**
 * The dashboard is basically a shell for the whole inner app.
 * Below is a proposed structure for dashboard content "boxes":
 *
 * {
     id: 'name', // the name of the box
     enabled: true | false | function(props) {}, // whether a box is enabled,
                                                    the function allows you to
                                                    return true/false to control
     tabs: [], // the tabs
     buttons: [] // the buttons
   }
 *
 * tab: {
     name: 'Name' || function(props) {}, // Can be a string or a function
                                         // with props passed, should return a
                                         // string
     component: Component, // the component object to display in the tab content
     active: true | false, // whether this is active or not, controlled via
                              DashboardEmitter
     enabled: true | false | function(props) {}, // use this to control
                                                    permissions, defaults true
     children: [] // child tabs, these have the same structure
 * }
 *
 * button: {
     name: 'Name',
     component: Component, // its easier to just pass a component as the button.
     enabled: true | false | function(props) {}, // same as above
 * }
 */

import { get } from 'lodash'
import Loadable from 'react-loadable'

import DummyComponent from 'components/common/dummy-component'
import Loading from 'components/common/loading'

import Profile from './modules/profile'
import Notes from './modules/notes'
import Interactions from './modules/interactions'
import ScheduledInteractions from './modules/interactions-scheduled'

import Allergies from './modules/past-medical-history/allergies'
import SideEffects from './modules/past-medical-history/side-effects'
import Conditions from './modules/past-medical-history/conditions'
import RiskFactors from './modules/past-medical-history/risk-factors'
// import Lifestyles from './modules/past-medical-history/lifestyles'
import Observations from './modules/past-medical-history/observations'

import Prescribers from './modules/prescribers'
import Pharmacies from './modules/pharmacies'
import Caregivers from './modules/caregivers'
import Projects from './modules/projects'
import { canAccessFeature } from 'services/auth'

import { MED_REC_V2 } from 'constants/features'

const requiresPatient = props => {
  return !!props.patient && !!props.patient.data
}

// const requiresEula = props => {
//   return props.eula && props.eula.acknowledgedAndAgreed
// }

const showNumber = (props, name, path, filter) => {
  let output = name
  let obj = get(props, path, [])
  if (obj) {
    if (filter) {
      output += ` (${obj.filter(filter).length})`
    } else {
      output += ` (${obj.length})`
    }
  }
  return output
}

const showObservationNumber = obsArray => {
  let output = 'Observation'
  let num = obsArray.length

  let height = obsArray.filter(o => o.text === 'Height')
  let weight = obsArray.filter(o => o.text === 'Weight')
  // Don't count height and weight as separate observations. They are combined into BMI observation in component. Because of previous implementation, there can be more than one height and weight per patient.
  if (height.length) {
    num = num - height.length
  }
  if (weight.length) {
    num = num - weight.length
  }
  if (height.length || weight.length) {
    num = num + 1
  }
  return (output += ` (${num})`)
}

const MDSLoadable = Loadable({
  loader: () => import('./modules/mds'),
  loading: Loading
})
MDSLoadable.preload()

const MSRLoadable = Loadable({
  loader: () => import('./modules/msr'),
  loading: Loading
})
MSRLoadable.preload()

const MedRecLoadable = Loadable({
  loader: () => import('./modules/medication-reconciliation'),
  loading: Loading
})
MedRecLoadable.preload()

const MedListLoadable = Loadable({
  loader: () => import('./modules/medication-list'),
  loading: Loading
})
MedListLoadable.preload()

const MedListV2Loadable = Loadable({
  loader: () => import('./modules/medication-list-v2'),
  loading: Loading
})
MedListV2Loadable.preload()

export default [
  {
    id: 'profile',
    enabled: true,
    tabs: [
      {
        id: 'profile',
        name: 'Profile',
        component: Profile,
        active: true,
        /*
        children: [
          {
            id: 'information',
            name: 'Patient Information',
            component: Profile
          },
          {
            id: 'history',
            name: 'Patient History',
            component: DummyComponent
          }
        ],
        */
        enabled: requiresPatient
      },
      {
        id: 'past-medical-history',
        name: 'Past Medical History',
        component: Allergies,
        children: [
          {
            id: 'allergies',
            name: props =>
              showNumber(props, 'Allergies', 'patientData.allergies', a => !a.isSideEffect),
            component: Allergies
          },
          {
            id: 'side-effect',
            name: props =>
              showNumber(props, 'Side Effects', 'patientData.allergies', a => a.isSideEffect),
            component: SideEffects
          },
          {
            id: 'conditions',
            name: props => showNumber(props, 'Conditions', 'patientData.conditions'),
            component: Conditions
          },
          {
            id: 'observations',
            name: props => showObservationNumber(get(props, 'patientData.observations', [])),
            // showNumber(
            //   props,
            //   'Observations',
            //   'patientData.observations',
            //   obs => obs.text === 'Height' || obs.text === 'Weight'
            // ),
            component: Observations
          },
          {
            id: 'risk-factors',
            name: props => showNumber(props, 'Risk Factors', 'patient.data.riskFactors'),
            component: RiskFactors
          }
          // {
          //   id: 'lifestyles',
          //   name: 'Lifestyles',
          //   component: Lifestyles
          // }
        ],
        enabled: requiresPatient,
        buttons: []
      },
      {
        id: 'prescribers-pharmacies',
        name: 'Prescribers and Pharmacies',
        component: DummyComponent,
        children: [
          {
            id: 'prescribers',
            name: props => {
              let prescribers = get(props, 'patient.data.prescribers', [])
              return `Prescribers(${prescribers.length})`
            },
            component: Prescribers
          },
          {
            id: 'pharmacies',
            name: props => {
              let pharmacies = get(props, 'patient.data.pharmacies', [])
              return `Pharmacies(${pharmacies.length})`
            },
            component: Pharmacies
          }
        ],
        enabled: requiresPatient
      },
      {
        id: 'caregivers',
        name: 'Caregivers',
        component: Caregivers,
        enabled: requiresPatient
      }
    ],
    buttons: []
  },
  {
    id: 'notes-outreach',
    enabled: requiresPatient,
    tabs: [
      {
        id: 'notes',
        name: props => {
          let notes = get(props, 'patientData.notes', [])
          return `Notes (${notes.length})`
        },
        component: Notes,
        active: true
      },
      {
        id: 'interactions',
        name: props => {
          let interactions = get(props, 'patient.data.call_log', [])
          return `Interactions (${interactions.length})`
        },
        component: Interactions
      },
      {
        id: 'interactions-scheduled',
        name: props => {
          let scheduledInteractions = get(props, 'patient.data.schedule_log', [])
          return `Scheduled Interactions (${scheduledInteractions.length})`
        },
        component: ScheduledInteractions
      },
      {
        id: 'projects',
        name: props => {
          let projects = Object.keys(get(props, 'patient.data.projects', {}))
          return `Projects (${projects.length})`
        },
        component: Projects
      }
    ],
    buttons: []
  },
  {
    id: 'medications',
    enabled: requiresPatient,
    tabs: [
      {
        id: 'medication-list',
        name: 'Medication List',
        component: MedListLoadable,
        children: [
          {
            id: 'current-medications',
            name: 'Current Medications',
            component: MedListLoadable
          }
          // ,
          // {
          //   id: 'medication-history',
          //   name: 'Medication History',
          //   component: DummyComponent
          // }
        ],
        active: true
      },
      {
        id: 'medication-list-v2',
        name: 'Medication List V2',
        component: MedListV2Loadable,
        children: [
          {
            id: 'current-medications',
            name: 'Current Medications',
            component: MedListV2Loadable
          }
        ],
        enabled: canAccessFeature(MED_REC_V2),
        active: false
      },
      {
        id: 'medication-reconciliation',
        name: 'Medication Reconciliation',
        component: MedRecLoadable
      },
      {
        id: 'medication-decision-support',
        name: 'Medication Decision Support',
        component: MDSLoadable
      },
      {
        id: 'medication-safety-review',
        name: 'Medication Safety Review',
        component: MSRLoadable
        // 2018-11-07 SLW - Don't think we need child components anymore with the new design....
        //                  commenting out just in case
        // children: [
        //   {
        //     id: 'unpublished-recommendations',
        //     name: 'Unpublished Recommendations',
        //     component: DummyComponent
        //   },
        //   {
        //     id: 'published-recommendations',
        //     name: 'Published Recommendations',
        //     component: DummyComponent
        //   }
        // ]
      }
      /** Commenting this out for now **/
      // { id: 'outcomes', name: 'Outcomes', component: DummyComponent }
    ],
    buttons: []
  }
]
