import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import { map } from 'lodash'

const styles = {
  warning: {
    backgroundColor: '#FFA700',
    color: 'white'
  }
}

class AlertDialog extends React.Component {
  render () {
    const { title, text, actions, classes } = this.props
    return this.props.open ? (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
          </DialogContent>
          <DialogActions>
            {map(actions, ({ buttonText, variant, color, autoFocus, onClick, size }, index) => (
              <Button
                className={classNames(classes[color])}
                key={`alert-button-${index}`}
                onClick={onClick}
                color={color || 'primary'}
                autoFocus={autoFocus}
                variant={variant || 'contained'}
                size={size || 'small'}
              >
                {buttonText}
              </Button>
            ))}
          </DialogActions>
        </Dialog>
      </div>
    ) : null
  }
}

// actions [
//   {
//     buttonText,
//     variant,
//     color,
//     autoFocus, (bool)
//     onClick,
//     size
//   }
//   ...
// ]

export default withStyles(styles)(AlertDialog)
