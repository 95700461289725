import { createMuiTheme } from '@material-ui/core'
import {
  BOX_SHADOW_PRIMARY,
  TRHC_GREEN,
  TRHC_GREEN_DARK,
  TRHC_EMPTY_INPUT_RGBA,
  REQUIRED_ASTERISK_RED
} from 'constants/colors'

const materialTheme = () =>
  createMuiTheme({
    typography: {
      useNextVariants: true
    },
    palette: {
      primary: {
        main: TRHC_GREEN,
        dark: TRHC_GREEN_DARK
      },
      secondary: {
        main: TRHC_GREEN_DARK
      }
    },
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: REQUIRED_ASTERISK_RED
        }
      },
      MuiInput: {
        root: {
          borderRadius: '2px 2px 0 0',
          backgroundColor: TRHC_EMPTY_INPUT_RGBA,
          '&$disabled': {
            '&:before': {
              borderBottom: 'none!important'
            },
            '&:after': {
              borderBottom: 'none!important'
            }
          }
        },
        input: {
          height: 'auto'
        },

        underline: {
          '&:before': {
            backgroundColor: 'rgba(0, 0, 0, 0.42)',
            height: 1,
            borderBottom: 'none'
          },
          '&:after': {
            backgroundColor: TRHC_GREEN,
            boxShadow: `0px 1px 1px ${BOX_SHADOW_PRIMARY}`,
            height: 1,
            borderBottom: 'none'
          },
          '&:hover:not($disabled):before': {
            backgroundColor: TRHC_GREEN,
            height: 1,
            borderBottom: 'none!important'
          }
        }
      },
      MuiInputBase: {
        input: {
          height: 'auto'
        }
      },
      MuiInputLabel: {
        formControl: {
          transform: `translate(0.7rem, 24px) scale(1)`
        }
      },
      MuiSelect: {
        root: {
          '&:focus': {
            borderRadius: '2px 2px 0 0',
            background: TRHC_EMPTY_INPUT_RGBA
          }
        },
        select: {
          '&:focus': {
            borderRadius: '2px 2px 0 0',
            background: TRHC_EMPTY_INPUT_RGBA
          }
        }
      },
      MuiSnackbar: {
        root: {
          zIndex: 1400000000
        }
      },
      MuiSnackbarContent: {
        root: {
          flexWrap: 'nowrap',
          alignItems: 'flex-start'
        }
      },
      MuiDialog: {
        root: {
          zIndex: 1300000000
        }
      },
      MuiModal: {
        root: {
          zIndex: 1300000000
        }
      },
      MuiTooltip: {
        tooltip: {
          maxWidth: '425px',
          fontSize: '14px',
          lineHeight: '1.5em',
          padding: '1.25em',
          backgroundColor: 'rgba(0,0,0,.87)',
          whiteSpace: 'pre-wrap',
          maxHeight: '600px',
          overflowY: 'auto'
        },
        popper: {
          opacity: 1
        }
      },
      MuiAutocomplete: {
        popup: {
          zIndex: 1500000000
        },
        inputRoot: {
          background: 'none'
        }
      }
    },
    MuiDrawer: {
      root: {
        zIndex: 1500000000
      },
      modal: {
        zIndex: 1500000000
      }
    }
  })

export default materialTheme
