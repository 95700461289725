/*
 * Auth reducer
 */

import * as C from './types'
import * as AuthService from 'services/auth'

const authReducer = (
  state = {
    isAuthenticated: !AuthService.isTokenExpired(),
    isFetching: false,
    profile: AuthService.getProfile(),
    error: null
  },
  action
) => {
  switch (action.type) {
  case C.AUTH_LOGIN_REQUEST:
    return {
      ...state,
      isFetching: true,
      error: null
    }
  case C.AUTH_LOGIN_SUCCESS:
    return {
      ...state,
      error: null,
      isFetching: false,
      isAuthenticated: true,
      profile: action.payload.profile
    }
  case C.AUTH_LOGIN_ERROR:
    return {
      ...state,
      isFetching: false,
      isAuthenticated: false,
      profile: {},
      error: action.error
    }
  case C.AUTH_LOGOUT_SUCCESS:
    return {
      ...state,
      isAuthenticated: false,
      profile: {}
    }
  case C.AUTH_FORCED_LOGOUT_SUCCESS:
    return {
      ...state,
      isAuthenticated: false,
      profile: {},
      error: {
        ...state.error,
        message: action.message
      }
    }
  default:
    return state
  }
}

export default authReducer
