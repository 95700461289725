/*
 * Schema Actions - This should be deprecated in favor of SNOMED service
 */

import gateway from 'services/gateway'
import { setReferralOptions, setNMRP } from 'services/schema'

import { handleRequest, handleError } from 'store/actions'

import * as C from './types'

export const getReferralOptions = () => {
  return async dispatch => {
    try {
      handleRequest(dispatch, C.REQUEST, C.REFERRAL_OPTIONS)
      const id = 'patientReferralOptions'
      const response = await gateway.get('/api/medwise/schema', { id })
      dispatch({
        type: C.REFERRAL_OPTIONS,
        payload: response.body
      })
      setReferralOptions(response.body)
    } catch (err) {
      handleError(dispatch, err, C.ERROR)
    }
  }
}

export const getNonMedicationRelatedProblems = () => {
  return async dispatch => {
    try {
      handleRequest(dispatch, C.REQUEST, C.REFERRAL_OPTIONS)
      const id = 'nonMedicationRelatedProblems'
      const response = await gateway.get('/api/medwise/schema', { id })
      dispatch({
        type: C.NON_MEDICATION_RELATED_PROBLEMS,
        payload: response.body
      })
      setNMRP(response.body)
    } catch (err) {
      handleError(dispatch, err, C.ERROR)
    }
  }
}
