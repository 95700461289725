import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, formValueSelector } from 'redux-form'
import ReduxFormCheckbox from 'components/common/redux-form/checkbox'
import ReduxFormText from 'components/common/redux-form/text'
import ReduxFormSelect from 'components/common/redux-form/select'
import FormHeading from 'components/common/form-heading'
import { Format } from 'constants/formatting'

const STATES = [
  'AK',
  'AL',
  'AR',
  'AS',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'FM',
  'GA',
  'GU',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MH',
  'MI',
  'MN',
  'MO',
  'MP',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'PW',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VI',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY'
]

/**
 * Renders address segment of a redux form.
 *
 * @param {object} props - Properties passed to the component
 * @param {boolean} [props.isOptional=false] - Whether or not the address is optional; defaults to false
 * @param {string} props.formPrefix - The prefix used by the data object to store address fields
 * @param {string} props.label - The label for the address component
 * @returns {*} Redux form segment for inputting address
 * @constructor
 */
const AddressForm = props => {
  const {
    isOptional, // sometimes a second address isn't required
    formPrefix,
    label,
    hasLine2, // dynamically show/hide address line 3 based on whether lines 2/3 are present
    hasLine3
  } = props

  return (
    <Fragment>
      <div className="flex items-center justify-start mb2">
        <div className="dib mr4">
          <FormHeading text={label} />
        </div>
        <div>
          <Field
            name={`${formPrefix}.primary`}
            type="checkbox"
            label="Primary"
            component={ReduxFormCheckbox}
            data-cy={`${formPrefix}-primary`}
          />
        </div>
      </div>
      <div className="flex justify-between mb2">
        <Field
          required={!isOptional}
          name={`${formPrefix}.line1`}
          label="Address Line 1"
          type="text"
          component={ReduxFormText}
          data-cy={`${formPrefix}-line1`}
        />
      </div>
      <div className="flex justify-between mb2">
        <Field
          name={`${formPrefix}.line2`}
          label="Address Line 2"
          type="text"
          component={ReduxFormText}
          data-cy={`${formPrefix}-line2`}
        />
      </div>
      {(hasLine2 || hasLine3) && (
        <div className="flex justify-between mb2">
          <Field
            name={`${formPrefix}.line3`}
            label="Address Line 3"
            type="text"
            component={ReduxFormText}
            data-cy={`${formPrefix}-line3`}
          />
        </div>
      )}
      <div className="flex justify-between mb2">
        <div className="dib w-50 mr1">
          <Field
            required={!isOptional}
            name={`${formPrefix}.city`}
            label="City"
            type="text"
            component={ReduxFormText}
            data-cy={`${formPrefix}-city`}
          />
        </div>
        <div className="dib w-20 mh1">
          <Field
            native
            required={!isOptional}
            name={`${formPrefix}.state`}
            label="State"
            type="text"
            component={ReduxFormSelect}
            data-cy={`${formPrefix}-state`}
          >
            <option disabled />
            {STATES.map((state, key) => (
              <option key={key} value={state}>
                {state}
              </option>
            ))}
          </Field>
        </div>
        <div className="dib w-30 ml1">
          <Field
            required={!isOptional}
            name={`${formPrefix}.zip`}
            label="Zip Code"
            type="text"
            validate={ zip => {
              if (zip) {
                return Format.VALID_FORMAT(Format.ZIP_5_OR_9_DASHED, zip)
                  ? undefined
                  : Format.ZIP_MSG
              }
            }}
            component={ReduxFormText}
            data-cy={`${formPrefix}-zip`}
          />
        </div>
      </div>
    </Fragment>
  )
}

AddressForm.propTypes = {
  isOptional: PropTypes.bool,
  formPrefix: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}

AddressForm.defaultProps = {
  isOptional: false
}

/**
 * Connects to the Redux state in order to properly render the form based on existing field values.
 */
const ConnectedAddressForm = connect((state, props) => {
  // get the form from the redux state
  const { form } = state

  // get the form prefix from props
  const { formPrefix } = props

  // get the name of the form from the form object
  const formName = Object.keys(form)[0]

  // if there is no form name, return here so the selector doesn't blow up.
  // also, we should have a form name, so if this happens we're in bad shape.
  if (!formName) {
    return {}
  }

  // create the value selector
  const selector = formValueSelector(formName)

  // find the address lines 2 and 3 fields from the named form
  const hasLine2 = selector(state, `${formPrefix}.line2`)
  const hasLine3 = selector(state, `${formPrefix}.line3`)

  // return these things to component props to use with rendering line 3
  return {
    hasLine2,
    hasLine3
  }
})(AddressForm)

export default ConnectedAddressForm
