import React from 'react'
import PropTypes from 'prop-types'
import { formatUserName } from 'services/utils'
import Modal from 'components/common/modal'
import Table from 'components/common/table'
import ColoredCell from 'components/common/table-cells/colored-cell'
import ContextButton from 'components/common/context-button'

const ProjectsHistoryModal = props => {
  const { isOpen, item, toggleHandler } = props

  const headers = [
    { name: 'Date', maps: 'date' },
    {
      name: 'User',
      maps: event => {
        return event.performedBy ? formatUserName(event) : event.userId
      }
    },
    {
      name: 'Action',
      maps: (item, key) => (
        <ColoredCell
          key={key}
          text={item.enrolled ? 'Enrolled' : 'Disenrolled'}
          type={item.enrolled ? 'success' : 'error'}
        />
      ),
      isComponent: true
    },
    { name: 'Reason', maps: 'reason' }
  ]

  const actions = (
    <div className="context-buttons">
      <ContextButton text={<span>Close</span>} className="secondary" onClick={toggleHandler} />
    </div>
  )

  return (
    <Modal
      title={`Project ${item.name} History`}
      isOpen={isOpen}
      toggle={toggleHandler}
      actions={actions}
    >
      <Table data={item.history} headers={headers} />
    </Modal>
  )
}

ProjectsHistoryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  toggleHandler: PropTypes.func
}

ProjectsHistoryModal.defaultProps = {
  isOpen: false,
  item: {}
}

export default ProjectsHistoryModal
