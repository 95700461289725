/*
 * StyledNotification component gallery
 */

import React from 'react'
import StyledNotification from 'components/common/styled-notification'
import {NotificationDocumentation} from './notifications-documentation'


 // Create Different Data Scenarios for your component (different props etc)

const msgTextSmall = "Lorem ipsum dolar"
const msgTextMed = "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do"
const msgTextLong = "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor sunt in culpa qui officia deserunt mollit anim id est laborum."

const StyledNotificationGallery = () => {
  return (
    <div className="w-80 center">
    <NotificationDocumentation />
      <br />
      <StyledNotification
        variant='help'
        message={msgTextSmall}
        closeNotification={() => {alert("Nice click.")}}
      />
      <br />
      <StyledNotification
        variant='success'
        message={msgTextMed}
        closeNotification={() => {alert("Nice click.")}}

      />
      <br />
      <StyledNotification
        variant='warning'
        message={msgTextLong}
        closeNotification={() => {alert("Nice click.")}}

      />
      <br />
      <StyledNotification
        variant='error'
        message={msgTextLong}
        closeNotification={() => {alert("Nice click.")}}
      />
      <br />
      <StyledNotification
        variant='info'
        message={msgTextLong}
        closeNotification={() => {alert("Nice click.")}}
      />
      <br />
    </div>
  )
}

 export default StyledNotificationGallery
