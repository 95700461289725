/*
 * Patient Data Actions
 */

const PREFIX = '@@patient'

// global
export const REQUEST = `${PREFIX}/REQUEST`
export const FAILURE = `${PREFIX}/FAILURE`
export const LATEST_REVIEW_BY_TYPE = `${PREFIX}/LATEST_REVIEW_BY_TYPE`
export const GETTING_PATIENT_DATA = 'GETTING_PATIENT_DATA'
export const UPDATE_PATIENT_DATA_SUCCESS = 'UPDATE_PATIENT_DATA_SUCCESS'
export const UPDATE_PATIENT_DATA_REQUEST = 'UPDATE_PATIENT_DATA_REQUEST'
export const SET_PATIENT_DATA = 'SET_PATIENT_DATA'
export const REMOVE_PATIENT_DATA = 'REMOVE_PATIENT_DATA'
export const CLEAR_PATIENT_DATA = 'CLEAR_PATIENT_DATA'
export const UPDATE_PATIENT_DATA_FAILURE = 'UPDATE_PATIENT_DATA_FAILURE'
export const DELETE_PATIENT_DATA_REQUEST = 'DELETE_PATIENT_DATA_REQUEST'
export const GETTING_PGX_DATA = `${PREFIX}/GETTING_PGX_DATA`
