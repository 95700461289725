import React, { Component } from 'react'
import { change, Form, Field, reduxForm } from 'redux-form'
import ReduxFormText from 'components/common/redux-form/text'
import ReduxFormCheckbox from 'components/common/redux-form/checkbox'
import ReduxPhoneField from 'components/common/redux-form/phone-input'
import prescriberValidation from './validation'
import { Format } from 'constants/formatting'

const OBJECT_NAME = 'prescriber'

class TabForm extends Component {
  onPrimaryChange = checked => checked && this.props.dispatch(change(OBJECT_NAME, 'isSecondary', false))

  onSecondaryChange = checked => checked && this.props.dispatch(change(OBJECT_NAME, 'isPrimary', false))

  validZipFormat = zip => Format.VALID_FORMAT_MSG(Format.ZIP_5_OR_9_DASHED, Format.ZIP_MSG, zip)

  validEmail = email => (email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) ? 'Invalid email format' : undefined)

  render() {
    const { handleSubmit } = this.props
    return (
      <Form name="conditionsForm" className="w-100 flex flex-column" onSubmit={handleSubmit}>
        <div className="flex justify-between mb2">
          <div className="dib w-50 mr2">
            <Field required name="firstName" type="text" label="First Name" component={ReduxFormText} />
          </div>
          <div className="dib w-50">
            <Field required name="lastName" type="text" label="Last Name" component={ReduxFormText} />
          </div>
        </div>
        <div className="flex justify-between mb2">
          <div className="dib w-100">
            <Field required name="addressLine1" type="text" label="Address Line 1" component={ReduxFormText} />
          </div>
        </div>
        <div className="flex justify-between mb2">
          <div className="dib w-100">
            <Field name="addressLine2" type="text" label="Address Line 2" component={ReduxFormText} />
          </div>
        </div>
        <div className="flex justify-between mb2">
          <div className="dib w-33 mr2">
            <Field required name="city" type="text" label="City" component={ReduxFormText} />
          </div>
          <div className="dib w-33 mr2">
            <Field required name="state" type="text" label="State" component={ReduxFormText} />
          </div>
          <div className="dib w-33">
            <Field
              required
              name="zip"
              type="text"
              label="Zip Code"
              validate={this.validZipFormat}
              component={ReduxFormText}
            />
          </div>
        </div>
        <div className="flex justify-between mb2">
          <div className="dib w-50 mr2">
            <ReduxPhoneField required name="phone" label="Phone" />
          </div>
          <div className="dib w-50">
            <ReduxPhoneField name="faxNumber" label="Fax" />
          </div>
        </div>
        <div className="flex justify-between mb2">
          <div className="dib w-100">
            <Field
              name="email"
              type="email"
              label="Email Address"
              component={ReduxFormText}
              validate={this.validEmail}
            />
          </div>
        </div>
        <div className="flex flex-start mb2">
          <div className="mr4">
            <Field
              name="isPrimary"
              type="checkbox"
              label="Is Primary"
              component={ReduxFormCheckbox}
              externalOnChange={this.onPrimaryChange}
            />
          </div>
          <div className="mr4">
            <Field
              name="isSecondary"
              type="checkbox"
              label="Is Secondary"
              component={ReduxFormCheckbox}
              externalOnChange={this.onSecondaryChange}
            />
          </div>
          <div className="mr4">
            <Field name="verified" type="checkbox" label="Verified" component={ReduxFormCheckbox} />
          </div>
        </div>
        {/* <div className="flex justify-between mb2">
          <div className="dib w-100">
            <Field
              // required
              name="note"
              type="text"
              label="Note"
              helperText=""
              component={ReduxFormText}
            />
          </div>
        </div> */}
      </Form>
    )
  }
}

const ReduxTabForm = reduxForm({
  form: OBJECT_NAME,
  validate: prescriberValidation
})(TabForm)

export default ReduxTabForm
