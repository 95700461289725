import { get as _get, set as _set } from 'lodash'

export default caregiver => {
  const errors = {}

  const errorMessage = fieldName => `${fieldName} is required`
  const checkField = (fieldPath, errorMessage) => {
    if (!_get(caregiver, fieldPath)) {
      _set(errors, fieldPath, errorMessage)
    }
  }

  const requiredFields = [
    { path: 'name.first', display: 'First name' },
    { path: 'name.last', display: 'Last name' },
    { path: 'relationship', display: 'Relationship' },
    { path: 'address.line1', display: 'Address' },
    { path: 'address.city', display: 'City' },
    { path: 'address.state', display: 'State' },
    { path: 'address.zip', display: 'Zip code' }
  ]

  requiredFields.forEach(field => {
    checkField(field.path, errorMessage(field.display))
  })

  const phone1PreferredWithoutPhone1 =
    _get(caregiver, 'phonePreferred') && !_get(caregiver, 'phone')
  if (phone1PreferredWithoutPhone1) {
    _set(errors, 'phonePreferred', 'Phone number required to set this phone preference')
  }
  const phone2PreferredWithoutPhone2 =
    _get(caregiver, 'address2.phonePreferred') && !_get(caregiver, 'address2.phone')
  if (phone2PreferredWithoutPhone2) {
    _set(errors, 'address2.phonePreferred', 'Phone number required to set this phone preference')
  }

  const multiplePhonesPreferred =
    _get(caregiver, 'phonePreferred') && _get(caregiver, 'address2.phonePreferred')
  if (multiplePhonesPreferred) {
    _set(errors, 'phonePreferred', 'Only one preferred phone number allowed')
    _set(errors, 'address2.phonePreferred', 'Only one preferred phone number allowed')
  }

  const incompleteAddress2 =
    !_get(caregiver, 'address2') ||
    !_get(caregiver, 'address2.line1') ||
    !_get(caregiver, 'address2.city') ||
    !_get(caregiver, 'address2.state') ||
    !_get(caregiver, 'address2.zip')

  const address2PreferredWithoutAddress2 =
    _get(caregiver, 'address2.primary') && incompleteAddress2
  if (address2PreferredWithoutAddress2) {
    _set(errors, 'address2.primary', 'Primary address is missing required fields')
  }

  const multipleAddressesPreferred =
    _get(caregiver, 'address.primary') && _get(caregiver, 'address2.primary')
  if (multipleAddressesPreferred) {
    _set(errors, 'address.primary', 'Only one preferred address allowed')
    _set(errors, 'address2.primary', 'Only one preferred address allowed')
  }
  return errors
}
