const
  DATE_DISPLAY = 'MMM D, YYYY', // Feb 4, 2019
  DATE_DISPLAY_SHORT = 'MM/DD/YYYY', // 02/04/2019
  TIME_DISPLAY = 'h:mmA', // 7:55PM
  SYSTEM_DATE = 'YYYY-MM-DD', // 2019-02-04
  MIN_YEAR = '1900', // defined related to date of birth
  MIN_DATE = `01/01/${MIN_YEAR}`

export const DateFormat = {
  DATE_DISPLAY,
  DATE_DISPLAY_SHORT,
  TIME_DISPLAY,
  DATETIME_DISPLAY: `${DATE_DISPLAY} ${TIME_DISPLAY}`, // Feb 4, 2019 7:55PM
  SYSTEM_DATE
}

export const Date = {
  MIN_DATE
}

export const Messages = {
  MIN_DATE: `Date cannot be before year ${MIN_YEAR}`,
  FUTURE_DATE: 'Date cannot be in the future',
  INVALID_DATE: 'Invalid Date',
  TODAY_OR_FUTURE: 'Date must be today or in the future',
  TODAY_OR_PAST: 'Date must be today or in the past'
}