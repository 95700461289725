/*
 * Simple List component gallery
 */

import React from 'react'

import {SimpleList, SimpleListItem} from 'components/common/simple-list'
import {SimpleListDocumentation} from './simple-list-documentation'

/// mock data items to use in list generation
const mockItems = [
  {
    "status": "success",
    "summary": "Summary medication details goes here in this example.",
    "content": "Expanded medication content/form goes here in this example."
  },
  {
    "status": "new",
    "summary": "Summary medication details goes here in this example.",
    "content": "Expanded medication content/form goes here in this example."
  },
  {
    "status": "modify",
    "summary": "Summary medication details goes here in this example.",
    "content": "Expanded medication content/form goes here in this example."
  },
  {
    "status": "warning",
    "summary": "Summary medication details goes here in this example.",
    "content": "Expanded medication content/form goes here in this example."
  },
  {
    "status": "error",
    "summary": "Summary medication details goes here in this example.",
    "content": "Expanded medication content/form goes here in this example."
  }
]

const SimpleListGallery = (props) => {

  // simple list
  const simpleListItems = (
    <SimpleList>
      { mockItems.map((item, i) => 
        <SimpleListItem 
          key={`item${i}`} 
          className={item.status}
          {...item}
        >
          {item.content}
        </SimpleListItem>
      )}
    </SimpleList>
  )

  return (
    <div className="simple-list w-80 center">
      <SimpleListDocumentation />
      <div className="pt3">
        {simpleListItems}
      </div>
    </div>
  )
}

export default SimpleListGallery