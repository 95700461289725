/**
 * Simple service to save and clear the cached org list
 */

export const CACHE_EXPIRES = 60000 * 30 // 30 minutes
export const defaults = { allOrgs: [], userOrgs: [] }

export const getOrganizations = () => {
  const list = JSON.parse(window.sessionStorage.getItem('organization_list'))
  if (!list || list.timestamp < Date.now() - CACHE_EXPIRES) {
    clearOrganizations()
    return defaults
  }
  return list
}

export const childOrganizations = (allOrgs, orgId) => {
  return allOrgs.filter(o => o.parent && o.parent === orgId)
}

export const parentFromChild = (allOrgs, childId) => {
  const child = allOrgs.find(o => o._id === childId)
  if (!child) return
  return allOrgs.find(o => o._id === child.parent)
}

export const getOrganizationById = (allOrgs, orgId) => {
  return allOrgs.find(o => o._id === orgId)
}

export const setOrganizations = (allOrgs, userOrgs) => {
  const output = {
    timestamp: Date.now(),
    allOrgs,
    userOrgs
  }
  window.sessionStorage.setItem('organization_list', JSON.stringify(output))
}

export const clearOrganizations = () => {
  window.sessionStorage.removeItem('organization_list')
}
