import React, { useState } from 'react'
import { kebabCase } from 'lodash'

import './styles/proper-button.scss'

const ProperButton = ({ type, text, icon, children, className, disabled, onClick, ...props}) => {

  const PENDING = {
    name: 'pending',
    classButton: 'promise-pending',
    classIconCustom: '',
    classIconDone: 'hidden',
    buttonText: 'Saving...'
  }
  const RESOLVED = {
    name: 'resolved',
    classButton: 'promise-resolved',
    classIconCustom: 'hidden',
    classIconDone: '',
    buttonText: 'Saved'
  }
  const NONE = {
    name: 'none',
    classButton: null,
    classIconCustom: '',
    classIconDone: 'hidden',
    buttonText: null
  }

  const [promiseState, setPromiseState] = useState(NONE)

  /**
   * We want to provide "Saving...", and "Saved" placaters automatically
   */
  const placaterDecorator = () => {
    // If we're disabled or already processing, do nothing.
    if (disabled || promiseState.name === PENDING.name) return

    return async (event) => {
      setPromiseState(PENDING)
      try {
        await onClick(event)
        setPromiseState(RESOLVED)
      } catch (error) {
        console.error(error)
        setPromiseState(NONE)
      }
    }
  }

  const iconElement = icon ?
    <i className={`material-icons
      ${promiseState.classIconCustom}`}>
      {icon}
    </i> : null

  return (
    <button
      data-cy={`context-btn${
        props.cypressSuffix ? `-${kebabCase(props.cypressSuffix)}` : ''
      }`}
      type={type}
      className={`medwise-ns ${className}
        ${promiseState.classButton}
        ${disabled ? 'disabled' : ''}`}
      onClick={placaterDecorator(onClick)}>
      {iconElement}
      <i className={`material-icons
        ${promiseState.classIconDone}`}>
      done</i>
      { promiseState.buttonText || children || text}
    </button>
  )
}

export default ProperButton
