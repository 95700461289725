import React, { Component } from 'react'
import { get as _get } from 'lodash'

import { getPDF } from 'services/print'
import { getPatientOrgDoc, isPatientInECPNOrg, isPatientInPreceptorModel } from 'services/patient'

import ContextButton from 'components/common/context-button'

const urlLocation = (response) => {
  return _get(response, 's3Location') ?
    _get(response, 's3Location') :
    _get(response, 'artifact[0].ephemeralUrl')
}

export default class PrintButton extends Component {
  usePDFService = async (context, route, patientOrg, options = {}) => {
    const { notifyActions, patient, patientData } = context
    const patientReviewId = _get(patientData, 'latestReview[0].id')
    if (!options.reviewId && !!patientReviewId) {
      options.reviewId = patientReviewId
    }
    try {
      const response = await getPDF(_get(patient, 'data._id'), route, patientOrg, options)
      // window.open(response.s3Location, '_blank')
      const a = document.createElement('a')
      // TODO: Confirm this works for all print versions
      a.href = urlLocation(response)
      a.target = '_blank'
      a.download = decodeURIComponent(
        urlLocation(response)
          .split('/')
          .pop()
          .split('?')[0]
      )
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    } catch (err) {
      notifyActions.addNotification({
        message: err.message || 'There was an error trying to download the Fridge Report'
      })
    }
  }

  canPrintMSRCoverLetter = (patient, patientReview, orgDoc, shouldShowCoverLetters) =>
    patientReview.length &&
    patientReview[0].value.reviewType === 'Medication Safety Review (MSR)' &&
    shouldShowCoverLetters

  render () {
    const { patient, patientData, organizations, notifyActions } = this.props
    const dropdown = []
    const patientOrg = _get(patient, 'data.managingOrganization.id', '')

    const orgDoc = getPatientOrgDoc(
      _get(patient, 'data.managingOrganization'),
      _get(organizations, 'allOrgs')
    )
    
    const coverLetterType = _get(orgDoc, 'printData.coverLetters')
    const shouldShowCoverLetters = coverLetterType && coverLetterType !== 'none'
    const inECPNOrg = isPatientInECPNOrg(_get(patient, 'data'))

    const patientReview = _get(patientData, 'latestReview', [])
    const patientPrescribers = _get(patient, 'data.prescribers', [])
    /* MSR Letters */
    if (this.canPrintMSRCoverLetter(patient, patientReview, orgDoc, shouldShowCoverLetters)) {
      dropdown.push({
        name: 'Member MSR Cover Letter',
        onClick: context => {
          this.usePDFService(this.props, '/api/print/patientMSRCoverLetter', patientOrg)
        }
      })
    }
    if (
      patientReview.length &&
      patientReview[0].value.reviewType === 'Medication Safety Review (MSR)'
    ) {
      dropdown.push({
        name: 'Member MSR Medication Action Plan',
        onClick: context => {
          this.usePDFService(this.props, '/api/print/patientMSRMap', patientOrg)
        }
      })
    }

    /* MSR Prescriber Cover Letters (emtm or community pharms [ex: ecpn]) */

    if (
      patientReview.length &&
      patientReview[0].value.reviewType === 'Medication Safety Review (MSR)' &&
      shouldShowCoverLetters &&
      patientPrescribers.length
    ) {
      dropdown.push({
        name: 'Prescriber MSR Cover Letter',
        onClick: context => {
          !inECPNOrg // still need ecpn specific
            ? this.usePDFService(this.props, '/api/print/prescriberMSRCoverLetter', patientOrg)
            : this.usePDFService(
              this.props,
              '/api/print/prescriberMSRCoverLetterForECPNs',
              patientOrg,
              { attachments: true }
            )
        }
      })
    }

    /* <!-- MSRL Letters --> */
    if (
      patientReview.length &&
      patientReview[0].value.reviewType === 'Medication Safety Review Lite (MSR Lite)' &&
      shouldShowCoverLetters &&
      !isPatientInPreceptorModel(orgDoc) && !inECPNOrg
    ) {
      dropdown.push({
        name: 'Member MSRL Cover Letter',
        onClick: context => {
          this.usePDFService(this.props, '/api/print/msrlMemberCoverLetter', patientOrg)
        }
      })
    }

    if (
      patientReview.length &&
      patientReview[0].value.reviewType === 'Medication Safety Review Lite (MSR Lite)'
    ) {
      dropdown.push({
        name: 'Member MSRL Medication Action Plan',
        onClick: context => {
          this.usePDFService(this.props, '/api/print/msrlPatientMap', patientOrg)
        }
      })
    }
    if (
      patientReview.length &&
      patientReview[0].value.reviewType === 'Medication Safety Review Lite (MSR Lite)' &&
      shouldShowCoverLetters &&
      patientPrescribers.length
    ) {
      dropdown.push({
        name: 'Prescriber MSRL Cover Letter',
        onClick: context => {
          this.usePDFService(this.props, '/api/print/msrlPrescriberCoverLetter', patientOrg)
        }
      })
    }

    /* <!-- MSA Letters --> */
    if (
      patientReview.length &&
      patientReview[0].value.reviewType === 'Medication Safety Advisory (MSA)'
    ) {
      dropdown.push({
        name: 'Member MSA Letter',
        onClick: context => {
          this.usePDFService(this.props, '/api/print/msaMemberCoverLetter', patientOrg)
        }
      })
    }
    if (
      patientReview.length &&
      patientReview[0].value.reviewType === 'Medication Safety Advisory (MSA)' &&
      patientPrescribers.length
    ) {
      dropdown.push({
        name: 'Prescriber MSA Letter',
        onClick: context => {
          this.usePDFService(this.props, '/api/print/msaPrescriberCoverLetter', patientOrg)
        }
      })
    }

    if (
      patientReview.length &&
      patientReview[0].value.reviewType !== 'Medication Safety Advisory (MSA)' &&
      patientPrescribers.length
    ) {
      dropdown.push({
        name: 'Prescriber Action Plan w/o Rationale',
        onClick: context => {
          this.usePDFService(this.props, '/api/print/prescriberMap', patientOrg)
        }
      })
      dropdown.push({
        name: 'Prescriber Action Plan with Rationale',
        onClick: context => {
          this.usePDFService(this.props, '/api/print/prescriberMAPWithRationale', patientOrg)
        }
      })
    }

    dropdown.push({
      name: 'Fridge Report',
      onClick: async context => {
        const medicationsLength = _get(patientData, 'medications.length', 0)

        if (!medicationsLength) {
          return notifyActions.addNotification({
            message:
              'Refrigerator Report cannot be generated because this patient does not have any medications.',
            type: 'warning'
          })
        }
        if (patientData.medications) {
          const medsWithoutTime = patientData.medications.filter(med => {
            return Object.keys(_get(med, 'dosageInstructions.timingSchedule', [])).length === 0
          })
          if (medsWithoutTime.length === medicationsLength) {
            return notifyActions.addNotification({
              message: `Refrigerator Report cannot be generated because this patient's medication(s) do not have times of administration.`,
              type: 'warning'
            })
          }
        }

        try {
          const response = await getPDF(
            _get(patient, 'data._id'),
            '/api/print/fridgeReport',
            patientOrg
          )
          // window.open(response.s3Location, '_blank')
          const a = document.createElement('a')
          a.href = urlLocation(response)
          a.target = '_blank'
          a.download = decodeURIComponent(
            urlLocation(response)
              .split('/')
              .pop()
              .split('?')[0]
          )
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        } catch (err) {
          notifyActions.addNotification({
            message: err.message || 'There was an error trying to download the Fridge Report'
          })
        }
      }
    })

    return (
      <ContextButton
        text={
          <span>
            Print <i className="material-icons">arrow_drop_down</i>
          </span>
        }
        dropdown={dropdown}
        className="secondary"
        {...this.props}
      />
    )
  }
}
