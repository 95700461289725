/*
 * Component gallery
 * This is a temporary place to drop a new/WIP component for visibility
 */
import React from 'react'

// Step 1/2: Import the component you wish to display:
// Ex: import StepperGallery from 'components/gallery/stepper'
import ButtonGallery from 'components/gallery/button'
import StepperGallery from 'components/gallery/stepper'
import StyledNotificationGallery from './styled-notifications'
import MockDashboard from 'components/gallery/actionable-list/mockDashboard'
import SimpleListGallery from 'components/gallery/simple-list'

import './gallery.scss'

// your quick and uniform spacer
const Spacer = () => {
  return (
    <div className="pb4"></div>
  )
}

const ComponentGallery = () => {
  // Step 2/2: Drop your component's "gallery" below for display. It's called a gallery b/c the component will likely have different data/views that should each be mocked out and displayed.
  return (
    <div className="gallery pa4 center">
      <div className="header pt4 pb2 w-80 center">
        MedWise Components
      </div>
      <div className="sub-header pb4 w-80 center">
        Based on <a href="https://trhc.invisionapp.com/d/main#/projects/prototypes/19914455?scrollOffset=0">TRHC Design Guidelines</a>.  Made with ❤️
      </div>
    
      {/* Your Component Gallery here. */}

      <ButtonGallery />
      <Spacer />
      <StepperGallery />
      <Spacer />
      <StyledNotificationGallery />
      <Spacer />
      <SimpleListGallery />
      <Spacer />
      <MockDashboard />

    </div>
  )
}

export default ComponentGallery
