/**
 *  DashboardContext creator so we can use it in common components.
 *
 *  To use the DashboardContext wrap a DOM element like so:
 *
 *  ```js
 *  import { DashboardContext } from 'contexts/dashboard'
 *
 *  ... component setup ...
 *
 *  render(
      <DashboardContext.Consumer>
        {({ patient, patientData }) => (
          <your component DOM>
        )}
      </DashboardContext.Consumer>
    )
 *
 *  ```
 *
 *  If you need to use that context in lifecycle methods you can wrap the component
 *  in a parent and pass the contact as a prop.
 *
 *
 */
import React from 'react'

export const DashboardContext = React.createContext({
  patient: null,
  patientData: null,
  boxes: null
})
