/*
 * Dashboard conditions tab
 */

import React, { Component } from 'react'
import dayjs from 'dayjs'
import { capitalize, cloneDeep, find, get as _get, pick, set, parseInt as _parseInt } from 'lodash'

import StandardTab from 'components/common/standard-tab'
import ColoredCell from 'components/common/table-cells/colored-cell'
import { validateCallType } from 'services/interactions'
import { DateFormat } from 'constants/date'
import logger from 'services/logger'
import { dateByYear as dateByYearOptions, path as pathOptions } from 'services/options-builders'
import InteractionForm from './form'

const OBJECT_NAME = 'call_log'
// call record: successful, decision = false, no project === Member Opt Out
// call record: successful, decision = false , project === Declined Service
const memberDecisionForRecord = record => {
  if (record.direction && record.direction !== 'user') {
    if (record.successful && record.decision === false && record.forPrimaryProject) {
      return {
        type: 'error',
        text: 'Declined Service'
      }
    } else if (record.successful && record.decision === false && !record.forPrimaryProject) {
      return {
        type: 'error',
        text: 'Member Opt Out'
      }
    } else {
      if (['member', 'caregiver'].indexOf(record.direction) > -1) {
        return {
          type: 'success',
          text: 'Successful Opt-In'
        }
      } else {
        return {
          type: 'success',
          text: 'Successful'
        }
      }
    }
  }
  if (!record.successful) {
    return {
      type: 'warn',
      text: 'Not Successful'
    }
  } else if (record.successful && !record.decision && record.forPrimaryProject) {
    return {
      type: 'error',
      text: 'Declined Service'
    }
  } else if (record.successful && !record.decision && !record.forPrimaryProject) {
    return {
      type: 'error',
      text: 'Member Opt Out'
    }
  } else {
    // TODO: will need to handle eFax when that's implemented
    return {
      type: 'success',
      text: 'Successful Opt-In'
    }
  }
}

const deriveFormData = data => {
  const { spokeTo, ...formData } = data
  const workflowCalls = ['Initiating Call', 'Medication Review', 'Medication Safety Review']
  let reason
  let spokeToMember = false
  let spokeToCaregiver = false
  let lvm = false
  let decision = _get(formData, 'record.decision')
  let decisionReason = _get(formData, 'record.decisionReason')
  let direction = _get(formData, 'record.direction')
  let description = _get(formData, 'record.description')
  let successful = _get(formData, 'record.successful')
  let interaction = _get(formData, 'record.interaction')
  let dateRecorded = _get(formData, 'record.dateRecorded')
  let attempt = _get(formData, 'record.attempt')
  dateRecorded = dayjs(dateRecorded)
  delete formData.deleteValues
  switch (spokeTo) {
  case 'Member & Caregiver':
    spokeToMember = true
    spokeToCaregiver = true
    break
  case 'Member':
    spokeToMember = true
    break
  case 'Caregiver':
    spokeToCaregiver = true
    break
  case 'Left Voicemail':
    lvm = true
    decision = null
    decisionReason = null
    break
  default:
    // case 'none':
  }

  successful = ['Left Voicemail', 'Bad Number', 'Other'].indexOf(spokeTo) === -1
  // if not a workflow call
  if (workflowCalls.indexOf(_get(formData, 'app')) === -1) {
    description = _get(formData, 'record.description')
    // decisionReason = null
    reason = formData.app
    formData.app = 'Profile'
    interaction = 'nonworkflow'
  }

  formData.record = {
    ...formData.record,
    ...{
      successful,
      attempt,
      direction,
      spokeToMember,
      spokeToCaregiver,
      spokeTo,
      lvm,
      decision,
      decisionReason,
      description,
      dateRecorded: dateRecorded.valueOf(),
      interaction,
      reason
    }
  }

  return formData
}

const createNoteTitle = callType => {
  return callType === 'Initiating Call' ? 'Initiating Call' : `${callType} Call`
}

class Interactions extends Component {
  // could not get this to work to disable Edit.
  // constructor (props) {
  //   super(props)
  //   this.standardTab = React.createRef()
  // }

  componentDidMount () {
    const { patient } = this.props
    this.props.projectActions.getProjectsForPatient(patient)
  }

  formHandler = async (data, editing, originalItem) => {
    // manually merge the call_log entry into the array
    const { patient, patientActions, patientDataActions } = this.props
    let realPatient = cloneDeep(_get(patient, 'data'))
    const callLog = _get(realPatient, 'call_log', [])

    const formData = deriveFormData(data)

    if (editing) {
      // DISCLAIMER: Change this once the back end supports granular CRUD operations for this resource.
      // We are currently finding the item that is being edited by using _.find with _.matches shorthand (which checks all object attributes for equality).
      // We do this because the item doesn't have a unique identifier, and manually hashing the objects to have a temporary ID
      // for front-end use only comes with it's own tradeoffs, but adds complexity, with the IDs then needing to be manually stripped before persisting.
      //
      // This method (using _.find with _.matches shorthand) is slow but reliable and a simple fill-gap.
      //
      // Once the backend supports granular CRUD operations for this resource, we should be able to make a call to update the item,
      // while optimistically updating it on the front-end, and then refetch the item list to get back in sync with the true backend state.
      //
      // This means this front-end find/update will only serve to optimistically update the item and may still use _.find,
      // but can search more efficiently, by searching for a specific item id

      let foundOriginalItem = find(callLog, originalItem)
      let foundItemIndex = callLog.indexOf(foundOriginalItem)
      if (foundItemIndex !== -1) {
        callLog[foundItemIndex] = formData
      } else {
        // This is bad UX. With unique IDs in the future we could merge their changes over the existing changes
        // or otherwise indicate what was concurrently updated by another user while they were editing
        // so they can confirm their changes and try again or cancel.
        this.props.notifyActions.addNotification({
          message:
            'Unable to save interaction. The item was updated by another user before saving. Please try editing the item again'
        })
        logger('Original callLog item not found when saving.')
      }
    } else {
      // If there is a "Note" saved from the form, create a note in the note section.
      if (_get(formData, 'record.description')) {
        let record = formData.record
        let nonWorkflow = formData.app === 'Profile'
        // creating a note to be saved as a separate note doc.
        const dateNoteIsAdded = dayjs(_get(formData, 'record.dateRecorded')).toISOString()
        var note = {
          type: 'patient.note',
          subject: realPatient._id,
          engagement: realPatient.workflowIteration || 1,
          year: realPatient.currentWorkflowState.year || 1,
          authorLastName: _get(this.props, 'auth.profile.lastName'),
          authorFirstName: _get(this.props, 'auth.profile.firstName'),
          dateAdded: dateNoteIsAdded
        }

        note.title = nonWorkflow ? 'Non-Workflow Call' : createNoteTitle(formData.app)

        if (record.successful) {
          var subject = record.subject ? 'to ' + record.subject : ''
          var description = _get(record, 'description') ? ': ' + record.description : ''
          note.description = [
            record.direction,
            record.spokeTo,
            'Call',
            subject,
            '-',
            record.reason,
            description
          ].join(' ')
        } else {
          note.description = [
            record.direction,
            record.spokeTo,
            'Call',
            '- Not Successful. ',
            record.description
          ].join(' ')
        }
        
        patientDataActions.updatePatientData(
          { ...note }, // when updating a note, ensure current user's name is recorded
          'note',
          editing ? 'post' : 'post'
        )
      }
      callLog.push(formData)
    }

    if (formData.record.spokeTo === 'Bad Number') {
      realPatient.badNumber = true
    } else {
      // check for an existing bad number flag
      // if the member is the preferred contact and we spoke to member, OR the caregiver is
      // primary and we spoke to the caregiver, remove the flag
      if (
        (realPatient.badNumber &&
          _get(formData, 'record.successful', false) &&
          (_get(formData, 'record.spokeToMember', false) &&
            !_get(realPatient, 'caregiver.preferredContact', false))) ||
        (_get(formData, 'record.spokeToCaregiver', false) &&
          _get(realPatient, 'caregiver.preferredContact', false))
      ) {
        set(realPatient, 'badNumber', false)
      }
    }
    realPatient.call_log = callLog
    const workflowCalls = ['Initiating Call', 'Medication Review', 'Medication Safety Review']
    // fire this event if there is a new workflow call
    if (workflowCalls.indexOf(formData.app) !== -1) {
      console.log("_get(this.props, 'auth.profile')", _get(this.props, 'auth.profile'))
      const user = _get(this.props, 'auth.profile')
      patientActions.dispatchInteractionEvent(callLog, user)
    }
    patientActions.updatePatient(realPatient)
  }

  getAttemptValue = (category, callLog, patient) => {
    // let patientCallLog = _get(this.props, 'patient.data.call_log', [])
    const currentYear = _get(patient, 'currentWorkflowState.year', 1)
    const currentIteration = _get(patient, 'workflowIteration', 1)
    if (callLog && callLog.length > 0) {
      let categorizedCalls =
        callLog.filter(
          c =>
            c.app === category &&
            c.workflowIteration === currentIteration &&
            c.currentYear === currentYear &&
            !Number.isNaN(_parseInt(c.record.attempt, 10)) // Sometimes attempt is saved as a string, eg "1". Parse these.
        ) || []

      if (!categorizedCalls.length) {
        return 1
      } else {
        let mostRecent = categorizedCalls.reduce((a, b) =>
          a.record.attempt > b.record.attempt ? a : b
        )
        return _parseInt(mostRecent.record.attempt, 10) + 1
      }
    } else {
      return 1
    }
  }

  convertCallLogArray = callLog => {
    callLog.sort((a, b) => {
      if (new Date(_get(a, 'record.dateRecorded')) > new Date(_get(b, 'record.dateRecorded'))) {
        return -1
      }
      if (new Date(_get(a, 'record.dateRecorded')) < new Date(_get(b, 'record.dateRecorded'))) {
        return 1
      }
      return 0
    })
    let convertedCallLog = callLog.map(call => {
      set(call, 'deleteValues.attempts', { initial: 1, medrec: 1, msr: 1 })
      if (call.app === 'Initiating Call') {
        set(call, 'deleteValues.attempts.initial', call.record.attempt)
        return call
      } else if (call.app === 'Medication Review') {
        set(call, 'deleteValues.attempts.medrec', call.record.attempt)
        return call
      } else if (call.app === 'Medication Safety Review') {
        set(call, 'deleteValues.attempts.msr', call.record.attempt)
        return call
      } else {
        return call
      }
    })
    let callLogWithInitiated = convertedCallLog.map(call => {
      const callToChange = { ...call }
      let inOrOutBound = call.record.direction
      if (inOrOutBound === 'outbound') {
        set(callToChange, 'record.direction', 'user')
        return callToChange
      } else if (inOrOutBound === 'inbound') {
        set(callToChange, 'record.direction', call.record.spokeTo)
        return callToChange
      } else {
        return callToChange
      }
    })

    return callLogWithInitiated
  }

  render () {
    const { patient, project, organizations } = this.props
    // need to pass all the calls from
    const workflowCalls = [
      'Initiating Call',
      'Initiating Interaction',
      'Medication Review',
      'Medication Safety Review'
    ]
    const DEFAULT_STRUCTURE = {
      subject: patient.data._id,
      workflowIteration: _get(patient, 'data.workflowIteration', 1),
      currentYear: _get(patient, 'data.currentWorkflowState.year', 1),
      user: _get(this.props, 'auth.profile.email'), // currently not populated?
      userMetadata: pick(_get(this.props, 'auth.profile'), [
        '_id',
        'auth0Id',
        'firstName',
        'lastName',
        'email'
      ]),
      auditDate: dayjs().format(),
      deleteValues: {
        attempts: {
          initial: this.getAttemptValue(
            'Initiating Call',
            _get(patient, 'data.call_log'),
            _get(patient, 'data')
          ),
          medrec: this.getAttemptValue(
            'Medication Review',
            _get(patient, 'data.call_log'),
            _get(patient, 'data')
          ),
          msr: this.getAttemptValue(
            'Medication Safety Review',
            _get(patient, 'data.call_log'),
            _get(patient, 'data')
          )
        }
      },
      record: {
        attempt: null,
        dateRecorded: dayjs().valueOf(),
        direction: 'user',
        successful: false,
        decision: null,
        decisionReason: null,
        interaction: 'call', // 'encounter', 'call'
        lvm: false,
        spokeToMember: false,
        spokeToCaregiver: false
      }
    }

    let headers = [
      {
        name: 'Type of Interaction',
        maps: interaction => {
          let int = interaction
          let type = _get(interaction, 'app')
          if (type) {
            return workflowCalls.indexOf(validateCallType(type)) === -1
              ? `${_get(int, 'record.reason', 'Non-Workflow Call')}`
              : `${int.app}`
          } else {
            return ''
          }
        }
      },
      {
        name: 'Engagement',
        maps: 'workflowIteration'
      },
      {
        name: 'Attempt',
        maps: 'record.attempt'
      },
      {
        name: 'Initiated By',
        maps: interaction => {
          let firstInitial = _get(interaction, 'userMetadata.firstName[0]', '')
          let lastName = _get(interaction, 'userMetadata.lastName', '')
          if (Number.isNaN(_parseInt(_get(interaction, 'record.attempt'), 10))) {
            if (_get(interaction, 'record.direction')) {
              if (interaction.record.direction === 'user' && _get(firstInitial, 'length')) {
                return `${firstInitial ? `${firstInitial}. ` : ''}${lastName}`
              }
              return capitalize(interaction.record.direction)
            } else {
              if (
                _get(interaction, 'record.spokeToMember') &&
                !_get(interaction, 'record.spokeToCaregiver')
              ) {
                return 'Member'
              } else if (
                !_get(interaction, 'record.spokeToMember') &&
                _get(interaction, 'record.spokeToCaregiver')
              ) {
                return 'Caregiver'
              } else if (
                _get(interaction, 'record.spokeToMember') &&
                _get(interaction, 'record.spokeToCaregiver')
              ) {
                return 'Member & Caregiver'
              }
            }
          } else {
            if (_get(interaction, 'record.direction') && interaction.record.direction !== 'user') {
              return capitalize(_get(interaction, 'record.direction', 'Unknown'))
            }
          }
          return `${firstInitial ? `${firstInitial}. ` : ''}${lastName}`
        }
      },
      {
        name: 'Recipient',
        maps: interaction => {
          if (!_get(interaction, 'record.successful')) {
            return null
          }

          if (_get(interaction, 'record.direction') && interaction.record.direction !== 'user') {
            let firstInitial = _get(interaction, 'userMetadata.firstName[0]', '')
            let lastName = _get(interaction, 'userMetadata.lastName', '')
            return `${firstInitial ? `${firstInitial}. ` : ''}${lastName}`
          }
          const spokeToMemberOnly =
            _get(interaction, 'record.spokeToMember', false) &&
            !_get(interaction, 'record.spokeToCaregiver', false)
          const spokeToCaregiverOnly =
            !_get(interaction, 'record.spokeToMember', false) &&
            _get(interaction, 'record.spokeToCaregiver', false)
          const spokeToBoth =
            _get(interaction, 'record.spokeToMember', false) &&
            _get(interaction, 'record.spokeToCaregiver', false)

          if (spokeToMemberOnly) return 'Member'
          if (spokeToCaregiverOnly) return 'Caregiver'
          if (spokeToBoth) return 'Member & Caregiver'

          return _get(interaction, 'record.spokeTo', 'Unknown')
        }
      },
      {
        name: 'Date Recorded',
        maps: interaction =>
          dayjs(_get(interaction, 'record.dateRecorded')).format(DateFormat.DATE_DISPLAY)
      },
      {
        name: 'Member Decision',
        maps: (interaction, key) => {
          return _get(interaction, 'app') === 'Profile' ? (
            <ColoredCell
              key={key}
              {...{
                type: _get(interaction, 'record.successful') ? 'success' : 'warn',
                text: _get(interaction, 'record.successful') ? 'Successful' : 'Not Successful'
              }}
            />
          ) : (
            <ColoredCell key={key} {...memberDecisionForRecord(_get(interaction, 'record', {}))} />
          )
        },
        isComponent: true
      }
    ]

    let data = this.convertCallLogArray(_get(patient, `data.${OBJECT_NAME}`, []))
    // Tried to get this to work to disable the edit button for a non workflow call.  Was not successful.
    // const actions = {
    //   name: 'Actions',
    //   maps: [
    //     {
    //       propName: 'item',
    //       rowItem: true
    //     },
    //     {
    //       propName: 'onEdit',
    //       func: call => this.standardTab.current.handleFocus(call)
    //     },
    //     {
    //       propName: 'editEnabled',
    //       func: call =>
    //         call.app === 'Profile'
    //       }
    //     }
    //   ],
    //   component: RowActions
    // }

    let filters = [
      {
        label: 'Type of Interaction',
        name: 'type',
        options: pathOptions(data, 'app', {
          Profile: { label: 'Non workflow calls', value: 'Profile' }
        }),
        comparator: (filterValue, rowItem) => rowItem.app === filterValue
      },
      {
        label: 'Engagement',
        name: 'workflowIteration',
        options: pathOptions(data, 'workflowIteration'),
        comparator: (filterValue, rowItem) => rowItem.workflowIteration === filterValue
      },
      {
        label: 'Attempt',
        name: 'attempt',
        options: pathOptions(data, 'record.attempt'),
        comparator: (filterValue, rowItem) => _get(rowItem, 'record.attempt') === filterValue
      },

      {
        label: 'Year',
        name: 'year',
        options: dateByYearOptions(data, 'record.dateRecorded'),
        comparator: (filterValue, rowItem) => {
          // sometimes this is a UTC string and other times its a unix timestamp
          let dateRecorded = _get(rowItem, 'record.dateRecorded', '')
          if (typeof dateRecorded !== 'string') {
            dateRecorded = dayjs(dateRecorded).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
          }
          return dateRecorded.startsWith(filterValue)
        }
      }
    ]

    return (
      <StandardTab
        name="Interaction"
        object={OBJECT_NAME}
        form={InteractionForm}
        modalFormProps={{ patient, project, organizations }}
        initialValues={DEFAULT_STRUCTURE}
        formHandler={this.formHandler}
        hideEdit
        tableHeaders={headers}
        tableData={data}
        filters={filters}
        pagination={{
          pageSize: 5,
          maxPages: 5
        }}
        {...this.props}
      />
    )
  }
}

export default Interactions
