import React from 'react'
import MarkdownView from 'react-showdown'

export const NotificationDocumentation = () => {
  const markdown = `
# **Styled Notifications**

### Props
* message            (required) - text to appear in the notfication.
* variant            (required) - styling for UI/UX.
* classes            (optional) - adds additional styling through \`className\` on element.
* closeNotification  (optional) - callback function to be triggered during \`onClick\` event.

The current state of this component is simply a displayable component that styles according to the UI/UX specification.

### Example

For example code used to generate the notifications below, please refer to [Styled Notifications](https://git.carekinesis.net/clinical/map-front-end/-/blob/master/medwise-advisor/src/components/gallery/styled-notifications/index.js).
`
  return (
    <MarkdownView
      markdown={markdown}
      options={{ tables: true, emoji: true, ghCodeBlocks: true }}
    />
  )
}