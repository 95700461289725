/*
 * Schema Actions
 */

const PREFIX = '@@schema'
export const REQUEST = `${PREFIX}/SCHEMA_REQUEST`
export const ERROR = `${PREFIX}/SCHEMA_ERROR`

export const REFERRAL_OPTIONS = `${PREFIX}/REFERRAL_OPTIONS`

export const NON_MEDICATION_RELATED_PROBLEMS = `${PREFIX}/NON_MEDICATION_RELATED_PROBLEMS`
