/**
 * Component wrapper to manage Tabs
 */

import React from 'react'
import { connect } from 'react-redux'
import { EventEmitter } from 'fbemitter'
import { get, isEqual } from 'lodash'

import { actions as hashActions } from 'store/modules/url-hash'

import { TabbedBox } from 'components/common/tabs'

import { unhash } from 'services/url-hash'

/**
 * An HOC to wrap tab managing functionality
 * @param  {Array.<Object>} tabs        The tab definitions
 * @param  {Component} WrappedComponent The component that is being wrapped
 * @return {Component}                  The "connected" component with the tab manager, giving you access to a `tabManager` prop.
 */
export const tabManager = (tabs, WrappedComponent) => {
  class TabManager extends React.Component {
    constructor (props) {
      super(props)

      const tabsEmitter = new EventEmitter()

      /*
      * Added to enable new Medication List V2 tab
      * Workaround and acknowledged hacky way to handle the re-render of tabs
      * Temporary solution to solve need for re-render of tabs after auth handleLogin fetches app permissions
      * In current architecture, tabs get rendered before profile settings are returned and stored 
      */
      tabsEmitter.addListener('enableTab', ({ box, tab }) => {
        if (tab && tab.id === 'medication-list-v2') {
          const boxes = this.state.boxes
          const tabs = boxes.find(b => b.id === box).tabs

          tabs.forEach(t => {
            if (t.id === tab.id) {
              t.enabled = true
            } 
          })
        } 
      })

      /**
       * This listens in child components for tab changes.
       * Useful for things like needing to change the tab when a patient is
       * selected from a queue.
       */
      tabsEmitter.addListener('changeTab', ({ box, tab, child }) => {
        let boxes = this.state.boxes
        let changed = false
        let tabs = boxes.find(b => b.id === box).tabs
        tabs.forEach(t => {
          if (t.id === tab.id) {
            t.active = true
          } else {
            if (t.active) {
              changed = true
            }
            t.active = false
          }

          if (t.children && !child) {
            t.children.forEach((c, i) => {
              if (i === 0) {
                c.active = true
              } else {
                c.active = false
              }
            })
          }

          if (child && t.children) {
            t.children.forEach(c => {
              if (c.id === child.id) {
                c.active = true
              } else {
                if (t.id === tab.id && c.active) {
                  changed = true
                }
                c.active = false
              }
            })
          }
        })

        if (changed === true) {
          boxes.find(b => b.id === box).buttons = []
        }

        this.props.hashActions.setHash({ patientId: this.state.patientId, boxes })
        this.setState({ boxes })
      })

      /**
       * Use this listener to trigger context button changes.
       * In other components you can do this.state.emitter.emit('changeButtons', {box, buttons})
       * NOTE: It might be useful to add this same logic for child tabs
       */
      tabsEmitter.addListener('changeButtons', ({ box, buttons, context }) => {
        let boxes = this.state.boxes
        /**
        buttons.forEach(b => {
          b.context = context
        })
        **/
        boxes.find(b => b.id === box).buttons = buttons
        this.props.hashActions.setHash({
          boxes
        })
        this.setState({ patientId: this.state.patientId, boxes })
      })

      /**
       * Use this listener to append new buttons to the already-existing buttons for a tab.
       */
      tabsEmitter.addListener('appendButtons', ({ box, buttons, context }) => {
        const boxes = this.state.boxes
        buttons.forEach(b => {
          b.context = context
        })

        buttons.forEach(btn => {
          boxes.find(b => b.id === box).buttons.push(btn)
        })

        this.props.hashActions.setHash({
          patientId: this.state.patientId,
          boxes
        })
        this.setState({ boxes })
      })

      this.state = {
        patientId: get(props, 'computedMatch.params.patientId', null),
        boxes: tabs,
        emitter: tabsEmitter
      }
    }

    getBoxActiveTab (boxId) {
      return this.state.boxes.find(b => b.id === boxId).tabs.find(t => t.active)
    }

    getBoxActiveChildTab (boxId) {
      return this.state.boxes
        .find(b => b.id === boxId)
        .tabs.find(t => t.active)
        .children.find(c => c.active)
    }

    async componentDidMount () {
      // We store a JSON.stringify string of certain params such as current tabs,
      // pagination, etc
      this.props.hashActions.getHash()
    }

    componentWillUnmount () {
      this.props.hashActions.setHash(null)
      this.state.emitter.removeAllListeners()
    }

    componentDidUpdate (prevProps, prevState) {
      if (!isEqual(this.prevState, this.state)) {
        this.handleHashBoxUpdates()
      }
    }

    handleHashBoxUpdates () {
      // we need this for redirects since urlHash gets clobbered
      let boxState = unhash(this.props.history.location.pathname)
      if (!this.props.urlHash && !boxState) {
        this.props.hashActions.setHash(this.state)
      } else {
        let urlHash
        if (boxState && !this.props.urlHash) {
          urlHash = boxState
        } else {
          urlHash = this.props.urlHash
        }
        for (let id in urlHash) {
          let box = urlHash[id]
          this.state.boxes.forEach(b => {
            if (b.id === id) {
              b.tabs.forEach(tab => {
                if (box.tab && tab.id === box.tab.id) {
                  if (!tab.active) {
                    if (box.tab.child) {
                      this.state.emitter.emit('changeTab', {
                        box: id,
                        tab,
                        child: box.tab.child
                      })
                    } else {
                      this.state.emitter.emit('changeTab', { box: id, tab })
                    }
                  }
                }
              })
            }
          })
        }
      }
    }

    render () {
      let boxesDOM = this.state.boxes.map(box => {
        if (typeof box.enabled !== 'undefined' && box.enabled === false) {
          return null
        }
        if (typeof box.enabled === 'function' && !box.enabled(this.props)) {
          return null
        }

        return (
          <TabbedBox
            eventEmitter={this.state.emitter}
            key={box.id}
            box={box.id}
            tabs={box.tabs}
            buttons={box.buttons}
            tabManager={this.state}
            {...this.props}
          />
        )
      })
      return (
        <WrappedComponent {...this.props} tabManager={this.state}>
          {boxesDOM}
        </WrappedComponent>
      )
    }
  }

  const mapStateToProps = ({ urlHash }) => ({
    urlHash
  })

  const mapDispatchToProps = dispatch => ({
    hashActions: {
      getHash: () => dispatch(hashActions.getHash()),
      setHash: payload => dispatch(hashActions.setHash(payload))
    }
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(TabManager)
}
