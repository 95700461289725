import React from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import { get as _get } from 'lodash'
import { change, Form, reduxForm } from 'redux-form'
import EnrollmentFormFragment from './enrollment-form-fragment'
import projectFormValidation from './validation'

const OBJECT_NAME = 'project'

/**
 * Project form as stateless component
 *
 * @param {object} props The properties passed into this component
 * @returns {*} Form for enrolling or disenrolling patient
 * @constructor
 */
const ProjectsForm = props => {
  // get the initial values and keys necessary to populate the fragment
  const { project, patient } = props.initialValues
  const patientProjectKeys = Object.keys(_get(patient, 'data.projects', []))
  const couchId = _get(project, 'couchId', undefined)
  const name = _get(project, 'name', undefined)
  const enrolled = _get(patient, ['data', 'projects', couchId, 'enrolled'], false)

  // tell the form whether we are enrolling or disenrolling
  props.dispatch(change(OBJECT_NAME, 'enrolling', !enrolled))

  return (
    <Form
      name={`${pluralize(OBJECT_NAME)}Form`}
      className="w-100 flex flex-column"
      onSubmit={props.handleSubmit}
    >
      <EnrollmentFormFragment
        name={name}
        enrolled={enrolled}
        showReasonField={patientProjectKeys.indexOf(couchId) > -1}
      />
    </Form>
  )
}

ProjectsForm.propTypes = {
  project: PropTypes.shape().isRequired,
  patient: PropTypes.shape().isRequired,
  handleSubmit: PropTypes.func.isRequired
}

ProjectsForm.defaultProps = {
  project: {},
  patient: {},
  handleSubmit: () => {}
}

const ReduxProjectsForm = reduxForm({
  form: OBJECT_NAME,
  validate (data) {
    return projectFormValidation(data)
  }
})(ProjectsForm)

export default ReduxProjectsForm
