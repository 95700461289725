/*
 * Dashboard prescribers tab
 */

import React, { Component } from 'react'
import { MdCheck as CheckIcon } from 'react-icons/md'
import { PhoneItem } from 'components/common/data-item'
import TitleCell from 'components/common/table-cells/title-cell'
import StandardTab from 'components/common/standard-tab'
import logger from 'services/logger'
import { get as _get, find as _find, orderBy as _orderBy } from 'lodash'
import Form from './form'

const OBJECT_NAME = 'prescriber'

class Prescribers extends Component {
  DEFAULT_STRUCTURE = {
    id: undefined,
    prescriberType: undefined,
    firstName: undefined,
    lastName: undefined,
    addressLine1: undefined,
    addressLine2: undefined,
    city: undefined,
    state: undefined,
    zip: undefined,
    phone: undefined,
    faxNumber: undefined,
    email: undefined,
    isPrimary: false,
    isSecondary: false,
    verified: false
  }

  formHandler = async (data, editing, originalItem) => {
    // manually merge the schedule_log entry into the array
    const { patient, patientActions } = this.props
    const prescribers = _get(patient, 'data.prescribers', [])

    if (editing) {
      // DISCLAIMER: Change this once the back end supports granular CRUD operations for this resource.
      // We are currently finding the item that is being edited by using _.find with _.matches shorthand (which checks all object attributes for equality).
      // We do this because the item doesn't have a unique identifier, and manually hashing the objects to have a temporary ID
      // for front-end use only comes with it's own tradeoffs, but adds complexity, with the IDs then needing to be manually stripped before persisting.
      //
      // This method (using _.find with _.matches shorthand) is slow but reliable and a simple fill-gap.
      //
      // Once the backend supports granular CRUD operations for this resource, we should be able to make a call to update the item,
      // while optimistically updating it on the front-end, and then refetch the item list to get back in sync with the true backend state.
      //
      // This means this front-end find/update will only serve to optimistically update the item and may still use _.find,
      // but can search more efficiently, by searching for a specific item id

      const foundOriginalItem = _find(prescribers, originalItem)
      const foundItemIndex = prescribers.indexOf(foundOriginalItem)
      if (foundItemIndex !== -1) {
        prescribers[foundItemIndex] = data
      } else {
        // This is bad UX. With unique IDs in the future we could merge their changes over the existing changes
        // or otherwise indicate what was concurrently updated by another user while they were editing
        // so they can confirm their changes and try again or cancel.
        this.props.notifyActions.addNotification({
          message:
            'Unable to save prescriber. The item was updated by another user before saving. Please try editing the item again'
        })
        logger('Original prescriber item not found when saving.')
      }
    } else {
      prescribers.push(data)
    }

    patient.data.prescribers = prescribers

    patientActions.updatePatient(patient.data)
  }

  // QA-4255 - sort by primary prescriber status
  getOrderedData = () => _orderBy(
    _get(this.props, 'patient.data.prescribers', []),
    [
      // show primary prescribers first, then secondary prescribers
      // normal string orderBy doesn't work because they come in as true/false
      // which creates unwanted sorting situations
      item => item.isPrimary ? 'Primary' : undefined,
      item => item.isSecondary ? 'Secondary' : undefined
    ]
  )

  render () {
    const headers = [
      {
        name: 'Name',
        maps: [
          {
            propName: 'boldText',
            value: prescriber => `${prescriber.firstName} ${prescriber.lastName}`
          },
          { propName: 'lightText', value: 'type' }
        ],
        component: TitleCell
      },
      {
        name: 'Relation',
        maps: prescriber =>
          prescriber.isPrimary ? 'Primary' : prescriber.isSecondary ? 'Secondary' : '',
        classNames: 'w4 '
      },
      {
        name: 'Phone',
        maps: prescriber => <PhoneItem value={_get(prescriber, 'phone')} />,
        classNames: 'w-10'
      },

      { name: 'Fax',
        maps: prescriber => <PhoneItem value={_get(prescriber, 'faxNumber')} />,
        classNames: 'w-10'
      },
      {
        name: 'Address',
        maps: prescriber =>
          `${prescriber.addressLine1}${prescriber.addressLine2 ? prescriber.addressLine2 : ''}, ${
            prescriber.city
          }, ${prescriber.state} ${prescriber.zip}`
      },
      {
        name: 'Email',
        maps: prescriber => `${prescriber.email ? prescriber.email : ''}`,
        classNames: 'truncate'
      },
      {
        name: 'Verified',
        maps: prescriber =>
          prescriber.verified ? <CheckIcon className="center" size="25" /> : null,
        classNames: 'w4'
      }
    ]
    const data = this.getOrderedData()

    return (
      <StandardTab
        name="Prescriber"
        object={OBJECT_NAME}
        form={Form}
        initialValues={this.DEFAULT_STRUCTURE}
        formHandler={this.formHandler}
        tableHeaders={headers}
        tableData={data}
        pagination={{
          pageSize: 5,
          maxPages: 5
        }}
        {...this.props}
      />
    )
  }
}

export default Prescribers
