import { get as _get, isObject as _isObject } from 'lodash'
import dayjs from 'dayjs'

/**
 * [getDeepObjectKeys description]
 * @param  {object} obj         A nested object.
 * @param  {String} [parent=''] String that accumulates the dot separated keys.
 * @param  {Array}  [acc=[]]    Accumulated keys.
 * @return {array}             Array of dot separated keys.
 * @module
 *
 * @example:
 * crazyNestedObject = {
  dosageInstructions: {
      label: "error",
      timingSchedule: "error
   },
   med: {
       product: {
           code: [{display: error}]
       }
   }
}
    returnedValueExample = ['dosageInstructions.label', 'dosageInstructions.timingSchedule', 'med.product.code[0].display']
 */
export const getDeepObjectKeys = (obj, parent = '', acc = []) => {
  if (Array.isArray(obj)) {
    return obj.forEach((o, i) => {
      if (typeof o === 'object') {
        return getDeepObjectKeys(o, `${parent.length ? `${parent}` : ''}[${i}]`, acc)
      }

      return acc.push(`${parent}[${i}]`)
    }, acc)
  }
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object') {
      return getDeepObjectKeys(obj[key], `${parent.length ? `${parent}.` : ''}${key}`, acc)
    } else if (Array.isArray(obj[key])) {
      return getDeepObjectKeys(obj[key], `${parent.length ? `${parent}.` : ''}${key}`, acc)
    } else {
      return acc.push(`${parent.length ? `${parent}.` : ''}${key}`)
    }
  })

  return acc
}

/**
 * Sort medications
 * @param  {Array} meds The medications to sort
 * @return {Array}      The sorted medications in ascending order
 */
export const sortMedications = meds => {
  return meds.sort((a, b) => {
    var nameA = _get(a, 'medication.name.full', '').toUpperCase()
    var nameB = _get(b, 'medication.name.full', '').toUpperCase()

    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
  })
}

/**
 * A method to try to normalize the error messages we get from
 * @param  {Error} error     The error in question. Will look for the `error` prop first, then the `message` prop.
 *                           If it finds neither, it will default to the `backup` message
 * @param  {String} [backup] The backup message if an error can't be extracted from the error object.
 * @return {String}          The string error message
 */
export const getErrorMessage = (error, backup = 'An error occurred (default message)') => {
  return error.error || error.message || backup
}

/**
 * Simple helper function to get the business days between dates.
 *
 * @param  {Date}   startDate         The starting date
 * @param  {number} numBusinessDays   The number of business days to move forward
 * @return {Date}                     Date after it has been adjusted to reflect the number of business days
 */
export const nextBusinessDate = (startDate, numBusinessDays) => {
  var day = dayjs(startDate)
  var totalDays = 0

  while (numBusinessDays > 0) {
    totalDays++
    const newDay = dayjs(day).add(totalDays, 'd')
    if (!isWeekend(newDay.toDate()) && !isHoliday(newDay.toDate())) numBusinessDays--
  }
  return day.add(totalDays, 'd').toDate()
}

/**
 * Simple helper function to format raw organization names.
 * @param  {String} org The raw string for a managingOrganiation
 * @return {String} organization name without an 'org:' prefix
 */
export const removeOrgPrefix = org => {
  if (org === undefined || org === null) {
    return ''
  } else {
    // Examples:
    // 'org:EMTM' returns 'EMTM'
    // 'org:Community Pharmacy 212' returns 'Community Pharmacy 212'
    // 'Comm. Pharm. without org prefix' returns 'Comm. Pharm. without org prefix'
    return org.split('org:').pop()
  }
}

export const formatUserName = med => {
  if (!_isObject(med.performedBy)) {
  } else {
    let firstName = _get(med, 'performedBy.userFirstName')
    let lastName = _get(med, 'performedBy.userLastName')
    return firstName && lastName ? `${firstName[0]}. ${lastName}` : ''
  }
}
/**
 * Checks to see whether a date in question is a weekend.
 *
 * @param   {Date}    date The date to check.
 * @returns {boolean} True if it is a weekend, false if it is not.
 */
const isWeekend = date => {
  const day = dayjs(date)
  return day.day() === 0 || day.day() === 6
}

/**
 * Checks whether the date in question is a holiday.  Copied from 1.x.
 *
 * @param   {Date}    dt_date The date to check.
 * @returns {boolean} True if the date is a holiday, false if it is not.
 */
const isHoliday = dt_date => {
  // check simple dates (month/date - no leading zeroes)
  var n_date = dt_date.getDate()

  var n_month = dt_date.getMonth() + 1
  var s_date1 = n_month + '/' + n_date
  if (s_date1 === '1/1' || s_date1 === '7/4' || s_date1 === '12/25') {
    return true
  }

  // weekday from beginning of the month (month/num/day)
  var n_wday = dt_date.getDay()

  var n_wnum = Math.floor((n_date - 1) / 7) + 1
  var s_date2 = n_month + '/' + n_wnum + '/' + n_wday

  if (s_date2 === '9/1/1' || s_date2 === '11/4/4') {
    // 9/1/1 Labor Day, first Monday in September
    // 11/4/4 Thanksgiving Day, fourth Thursday in November
    return true
  }
  // weekday number from end of the month (month/num/day)
  var dt_temp = new Date(dt_date)
  dt_temp.setDate(1)
  dt_temp.setMonth(dt_temp.getMonth() + 1)
  dt_temp.setDate(dt_temp.getDate() - 1)
  n_wnum = Math.floor((dt_temp.getDate() - n_date - 1) / 7) + 1

  var s_date3 = n_month + '/' + n_wnum + '/' + n_wday
  // 5/1/1 Memorial Day, last Monday in May return true
  if (s_date3 === '5/1/1') {
    return true
  }
  return false
}
