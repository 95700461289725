import log from 'services/logger'
/**
 * Some commone actions useful in many module actions
 */

/**
 * Handle a standard request
 * @param  {Function} dispatch The redux dispatch function
 * @param  {String} type       Generally a string constant passed here for the module's REQUEST
 * @param  {String} [status]   Additional debugging status like the type of request this was intended for,
 *                             eg: patientTypes.SET
 * @param  {Object} [payload]  Optional payload to attach to the request, useful when you want to manipulate
 *                             data via a middleware (see patient/middleware.js)
 * @return {Object}            Dispatched action
 */
export const handleRequest = (dispatch, type, status, payload) => {
  return dispatch({
    type,
    status,
    payload
  })
}

/**
 * Handle an standard error
 * @param  {Function} dispatch  The redux dispatch function
 * @param  {Error} error        The error object
 * @param  {String} type        Generally a string constant passed here
 * @param  {String} [status]    Additional debugging status like the type of request this was intended for,
 *                              eg: patientTypes.SET
 * @return {Object}             Dispatched action
 */
export const handleError = (dispatch, error, type, status) => {
  log(`Reporting redux module error from ${type} ${status}`, error, true)
  return dispatch({
    type,
    error,
    status
  })
}
