import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { get } from 'lodash'
import Input from '@material-ui/core/Input'
import { withStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import CharacterCount from 'components/common/character-count'

/*
// Example Usage:
<div>
  <Field
    required
    name="firstName"
    type="text"
    label="First Name"
    component={ReduxFormText}
  />               ↖️ THIS COMPONENT
</div
 */

const styles = {
  nonEmptyInput: {
    height: 'auto',
    background: 'white'
  },
  input: {
    height: 'auto'
  }
}

class ReduxFormText extends Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  render () {
    const {
      children,
      helperText,
      input,
      label,
      maxCharacters,
      meta: { initial, touched, error, warning },
      required,
      showCharacterCount,
      className,
      classes,
      fullWidth,
      ...custom
    } = this.props

    const showError = !!((initial || touched) && error)
    const showWarning = !!(touched && warning)
    const isFullWidth = typeof fullWidth === 'undefined' ? true : fullWidth

    const inputStyle =
      get(input, 'value') || get(this.props, 'value.length')
        ? classNames(classes.nonEmptyInput)
        : classNames(classes.input)
    return (
      <FormControl
        error={showError}
        warning={warning}
        required={required}
        fullWidth={isFullWidth}
        className={className}
      >
        {label && <InputLabel>{label}</InputLabel>}
        <Input
          {...input}
          onChange={event => {
            const val = event.target.value
            maxCharacters ? input.onChange(val.substring(0, maxCharacters)) : input.onChange(val)
          }}
          {...custom}
          className={inputStyle}
        >
          {children}
        </Input>
        <div className="flex justify-between">
          <div className="flex flex-column">
            {showError && <FormHelperText error>{error}</FormHelperText>}
            {!showError && showWarning && (
              <FormHelperText warning={warning}>{warning}</FormHelperText>
            )}
            {!showError && !showWarning && helperText && (
              <FormHelperText>{helperText}</FormHelperText>
            )}
          </div>
          {showCharacterCount && (
            <CharacterCount
              // className={this.props.classes.}
              maxCharacters={maxCharacters}
              characterCount={get(input, 'value.length', 0)}
            />
          )}
        </div>
      </FormControl>
    )
  }
}

ReduxFormText.propTypes = {
  maxCharacters: PropTypes.number,
  showCharacterCount: PropTypes.bool
}

ReduxFormText.defaultProps = {
  maxCharacters: 0,
  showCharacterCount: false
}

export default withStyles(styles)(ReduxFormText)
