/*
 * Url Hash reducer
 */

import * as C from './types'

const reducer = (state = null, action) => {
  switch (action.type) {
  case C.GET_URL_HASH:
    state = action.payload
    return state
  case C.SET_URL_HASH:
    state = action.payload
    return state
  default:
    return state
  }
}

export default reducer
