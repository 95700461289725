import React from 'react'
import classNames from 'classnames'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { withStyles } from '@material-ui/core/styles'
import { TRHC_ERROR, TRHC_INFO, TRHC_WARNING, TRHC_SUCCESS } from 'constants/colors'

const styles1 = theme => ({
  snackbar: {
    margin: 0
  },
  success: {
    backgroundColor: TRHC_SUCCESS,
    boxShadow: 'none',
    color: 'black'
  },
  error: {
    backgroundColor: TRHC_ERROR,
    boxShadow: 'none',
    color: 'white'
  },
  info: {
    backgroundColor: TRHC_INFO,
    boxShadow: 'none',
    color: 'white'
  },
  warning: {
    backgroundColor: TRHC_WARNING,
    boxShadow: 'none',
    color: 'black'
  },
  mediumFont: {
    fontSize: 16
  },
  smallFont: {
    fontSize: 12
  },
  icon: {
    fontSize: 24
  },
  iconVariant: {
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'flex-start',
    wordBreak: 'break-word'
  },
  container: {
    maxWidth: '100%'
  }
})

function MySnackbarContent (props) {
  const { message, variant, classes, fontSize, elevation, closeSnackbar, key } = props
  return (
    <SnackbarContent
      className={classNames(
        classes.snackbar,
        classes[fontSize],
        classes.container,
        classes[variant]
      )}
      message={
        <span className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)}>warning</Icon>
          {message}
        </span>
      }
      elevation={elevation}
      action={
        closeSnackbar
          ? [
            <IconButton
              color="inherit"
              onClick={() => {
                closeSnackbar(key)
              }}
              key={`close-snackbar-${key}`}
            >
              <Icon style={{ fontSize: '18px' }}>cancel</Icon>
            </IconButton>
          ]
          : null
      }
    />
  )
}

const StyledSnackbarContent = withStyles(styles1)(MySnackbarContent)

export default StyledSnackbarContent
