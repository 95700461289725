import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
  progress: {
    margin: theme.spacing(2)
  }
})

// Loading Spinner as component -
// https://material-ui.com/demos/progress/
function CircularIndeterminate (props) {
  const { classes } = props
  return (
    <div>
      <CircularProgress className={classes.progress} />
    </div>
  )
}

CircularIndeterminate.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CircularIndeterminate)
