import { get, reverse, sortBy, uniqBy } from 'lodash'
import dayjs from 'dayjs'

/**
 * Returns an array of unique objects with label and value fields. Data must be an array of objects each of which has
 * a value at the provided path.
 * @param data
 * @param path
 * @param override
 * @example
 * path([{name:'foo'}, {name:'bar'}, {name:'foo'}, {name:'baz'}], 'name', {'baz':{label:'baz label', value:'baz value'}})
 * will return
 * [
 * {label:'foo', value:'foo'},
 * {label:'bar', value:'bar'},
 * {label:'baz label', value:'baz value'}
 * ]
 * @returns {(*[keyof TObject] | object[keyof TObject] | {label: any[keyof TObject] | object[keyof TObject] | any | undefined, value: any[keyof TObject] | object[keyof TObject] | any | undefined} | undefined | any)[]}
 */
export const path = (data, path, override) =>
  sortBy(
    uniqBy(data, object => get(object, path)).map(object => {
      let option = get(object, path)
      // if an override is found for the option, return that instead of the default value
      return get(override, option, { label: option, value: option })
    }),
    'label'
  )

export const dateByYear = (data, pathToDate) =>
  reverse(
    sortBy(
      uniqBy(data, object => dayjs(get(object, pathToDate)).year()).map(object => {
        let year = dayjs(get(object, pathToDate)).year()
        return { label: year, value: year }
      }),
      'label'
    )
  )
