/*
 * Stepper component gallery
 */

import React from 'react'

import {Stepper, Step} from 'components/common/stepper'
import {StepperDocumentation} from './stepper-documentation'

/*
 * 1. No active steps.  All steps should be numbered in sequential order.
 */
const steps = [
  {number: 1, title: "Reconcile", description: "Yr. 1, Eng. 2"},
  {number: 2, title: "Review", description: "Yr. 1, Eng. 2"},
  {number: 3, title: "Complete", description: "Yr. 1, Eng. 2"}
]

/*
 * 2. Second step is active. It should mark all prior steps with checkmark
 */
const stepsWithActive = [
  {number: 1, title: "Reconcile", description: "Yr. 1, Eng. 2"},
  {number: 2, title: "Review", description: "Yr. 1, Eng. 2", isActive: true},
  {number: 3, title: "Complete", description: "Yr. 1, Eng. 2"}
]

/*
 * 3. Second and third step is active. Only final active step should be honored.  All previous steps should be marked complete.
 */
const stepsWithActives = [
  {number: 1, title: "Reconcile", description: "Yr. 1, Eng. 2", isActive: true},
  {number: 2, title: "Review", description: "Yr. 1, Eng. 2"},
  {number: 3, title: "Complete", description: "Yr. 1, Eng. 2", isActive: true}
]

const StepperGallery = () => {
  return (
    <div className="stepper-gallery w-80 center">
      <StepperDocumentation />
      <div className="pb4"></div>
      <Stepper>
        { steps.map((step, i) => <Step key={`step${i}`} title={step.title} description={step.description} isActive={step.isActive} /> )}
      </Stepper>

      <Stepper>
        { stepsWithActive.map((step, i) => <Step key={`step${i}`} title={step.title} description={step.description} isActive={step.isActive} /> )}
      </Stepper>

      <Stepper>
        { stepsWithActives.map((step, i) => <Step key={`step${i}`} title={step.title} description={step.description} isActive={step.isActive} /> )}
      </Stepper>
    </div>
  )
}

 export default StepperGallery