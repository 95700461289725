/*
 * Notifications component
 * Note that we're inspecting some styles from this page:
 * http://demo.geekslabs.com/materialize-v1.0/ui-alerts.html
 */

import React, { Component } from 'react'
import { withSnackbar } from 'notistack'
import StyledSnackbarContent from './styled-snackbar'
import { isEqual } from 'lodash'

import './styles/notifications.scss'

class Notifications extends Component {
  componentDidUpdate (prevProps) {
    const { notifyActions, enqueueSnackbar, notify, closeSnackbar } = this.props
    if (isEqual(notify, prevProps.notify)) return
    notify.notifications.forEach(n => {
      const message = n.message
      const type = n.type
      const notificationOptions = {
        variant: n.type,
        autoHideDuration: n.sticky ? undefined : n.timeout || notify.timeout,
        content: key => (
          <div style={{ maxWidth: '620px' }}>
            <StyledSnackbarContent
              message={message}
              variant={type}
              fontSize="mediumFont"
              closeSnackbar={closeSnackbar}
              key={key}
            />
          </div>
        )
      }
      enqueueSnackbar(n.message, notificationOptions)
      notifyActions.removeNotification(n.id)
    })
  }

  render () {
    return null
  }
}

export default withSnackbar(Notifications)
