import React, { Component } from 'react'
import { Form, Field, reduxForm } from 'redux-form'

import ReduxFormDatePicker from 'components/common/redux-form/date-picker'
import ReduxFormText from 'components/common/redux-form/text'
import validation from './validation'

export class NoteForm extends Component {
  render () {
    const { handleSubmit } = this.props

    return (
      <Form name="noteForm" onSubmit={handleSubmit} className="w-100 flex flex-column">
        <div className="flex justify-between mb2">
          <div className="dib w-30">
            <Field
              required
              disabled
              name="performedBy.userFirstName"
              type="text"
              label="Author First Name"
              component={ReduxFormText}
            />
          </div>
          <div className="dib w-30">
            <Field
              required
              disabled
              name="performedBy.userLastName"
              type="text"
              label="Author Last Name"
              component={ReduxFormText}
            />
          </div>
          <div className="dib w-30">
            <ReduxFormDatePicker 
              name="dateAdded" 
              label="Date Added" 
              disableFuture={true} 
            />
          </div>
        </div>
        <div className="flex justify-between mb2">
          <div className="dib w-50">
            {/* <Field
              required
              name="noteCategory"
              component={ReduxFormSelect}
              placeholder="Select a category"
              label="Category"
              helperText="Just pick one already!"
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value={1}>Category 1</MenuItem>
              <MenuItem value={2}>Category 2</MenuItem>
              <MenuItem value={3}>Category 3</MenuItem>
            </Field> */}
          </div>
          <div className="dib w-20">
            <Field
              required
              disabled
              name="year"
              type="number"
              label="Year"
              component={ReduxFormText}
            />
          </div>
          <div className="dib w-20">
            <Field
              required
              disabled
              name="engagement"
              type="number"
              label="Engagement"
              component={ReduxFormText}
            />
          </div>
        </div>

        <Field
          required
          name="title"
          type="text"
          label="Title"
          component={ReduxFormText}
          helperText={'Summarize this note in 60 characters or less'}
          maxCharacters={60}
          showCharacterCount
        />

        <Field
          name="description"
          type="text"
          label="Note"
          component={ReduxFormText}
          multiline={true}
          rows={6}
        />
      </Form>
    )
  }
}

const ReduxNoteForm = reduxForm({
  form: 'note',
  validate: validation
})(NoteForm)

export default ReduxNoteForm
