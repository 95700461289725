/*
 * Patient Search reducer
 */

import * as C from './types'

const INITIAL_STATE = {}

const patientSearchReducer = (state = { ...INITIAL_STATE }, action) => {
  switch (action.type) {
  case C.FETCH_SEARCH_AGGREGATES_REQUEST:
    state = { ...INITIAL_STATE }
    return state
  case C.FETCH_SEARCH_AGGREGATES_SUCCESS:
    return action.payload
  case C.FETCH_SEARCH_AGGREGATES_FAILURE:
  case C.CLEAR_AGGREGATES:
    return { ...INITIAL_STATE }
  default:
    return state
  }
}

export default patientSearchReducer
