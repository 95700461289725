import * as reviewService from 'services/review'
import * as C from './types'

import { actions as notifyActions } from 'store/modules/notify'

import { handleRequest, handleError } from 'store/actions'

const getDraftReviewsRequest = (patientId, showLoadingIndicator = true) => ({
  type: C.GET_DRAFT_REVIEWS_FOR_PATIENT_REQUEST,
  patientId,
  showLoadingIndicator
})

const getDraftReviewsSuccess = (patientId, draftReviews) => ({
  type: C.GET_DRAFT_REVIEWS_FOR_PATIENT_SUCCESS,
  patientId,
  draftReviews
})


const getPublishedReviewsRequest = patientId => ({
  type: C.GET_PUBLISHED_REVIEWS_FOR_PATIENT_REQUEST,
  patientId
})

const getPublishedReviewsSuccess = (patientId, publishedReviews) => ({
  type: C.GET_PUBLISHED_REVIEWS_FOR_PATIENT_SUCCESS,
  patientId,
  publishedReviews
})

const saveDraftReviewRequest = draft => ({
  type: C.SAVE_DRAFT_REVIEW_REQUEST,
  draft
})

const saveDraftReviewSuccess = (response, review) => ({
  type: C.SAVE_DRAFT_REVIEW_SUCCESS,
  response,
  review
})

const saveReviewRequest = review => ({
  type: C.SAVE_REVIEW_REQUEST,
  review,
  patientId: review.subject
})

const saveReviewSuccess = (response, review) => ({
  type: C.SAVE_REVIEW_SUCCESS,
  response,
  review
})


const savePublishedReviewRequest = review => ({
  type: C.SAVE_PUBLISHED_REVIEW_REQUEST,
  review,
  patientId: review.subject
})

const savePublishedReviewSuccess = (response, published) => ({
  type: C.SAVE_PUBLISHED_REVIEW_SUCCESS,
  payload: {workflowData: {reviewType: published.reviewType}}, // have to set the reviewType for batch printing to work
  response,
  published
})

export const clearPublish = () => ({
  type: C.CLEAR_PUBLISH
})

export const clearError = () => ({
  type: C.CLEAR_ERROR
})

export const getDraftReviews = (patient, showLoadingIndicator = true) => {
  return async dispatch => {
    dispatch(getDraftReviewsRequest(patient._id, showLoadingIndicator))
    try {
      const reviews = await reviewService.getDraftReviews(patient)
      dispatch(getDraftReviewsSuccess(patient._id, reviews))
    } catch (err) {
      handleError(dispatch, err, C.ERROR, C.GET_DRAFT_REVIEWS_FOR_PATIENT_REQUEST)
    }
  }
}

export const getPublishedReviews = patient => {
  return async dispatch => {
    dispatch(getPublishedReviewsRequest(patient._id))
    try {
      const reviews = await reviewService.getPublishedReviews(patient)
      dispatch(getPublishedReviewsSuccess(patient._id, reviews))
    } catch (err) {
      handleError(dispatch, err, C.ERROR, C.GET_PUBLISHED_REVIEWS_FOR_PATIENT_REQUEST)
    }
  }
}

export const saveDraftReview = (draft, sendForReview, showLoadingIndicator = true) => {
  return async dispatch => {
    dispatch(saveDraftReviewRequest(draft))
    try {
      const response = await reviewService.saveDraftReview(draft)
      dispatch(saveDraftReviewSuccess(response, draft))
      if (sendForReview) dispatch({ type: C.SEND_FOR_REVIEW })
      dispatch(getDraftReviews(draft.patient, showLoadingIndicator))
    } catch (err) {
      handleError(dispatch, err, C.ERROR, C.SAVE_DRAFT_REVIEW_REQUEST)
      dispatch(
        notifyActions.addNotification(notification(`Unable to save draft review. ${err.message}`))
      )
    }
  }
}

export const savePublishedReview = review => {
  return async dispatch => {
    dispatch(savePublishedReviewRequest(review))
    try {
      const response = await reviewService.savePublishedReview(review)
      dispatch(savePublishedReviewSuccess(response, review))
      // get reviews again for patient... if newly published we need the drafts refreshed too
      dispatch(getDraftReviews(review.patient))
      dispatch(getPublishedReviews(review.patient))
    } catch (err) {
      handleError(dispatch, err, C.ERROR, C.SAVE_PUBLISHED_REVIEW_REQUEST)
      dispatch(
        notifyActions.addNotification(
          notification(`An error occured while attempting to publish this review: ${err.message}`)
        )
      )
    }
  }
}

export const saveReview = review => {
  return async dispatch => {
    dispatch(saveReviewRequest(review))
    try {
      const response = await reviewService.saveReview(review)
      dispatch(saveReviewSuccess(response, review))
      // get reviews again for patient... if newly published we need the drafts refreshed too
      if (review.draft === true) {
        dispatch(getDraftReviews(review.patient))
      }
      if (review.published === true) {
        if (reviewService.reviewIncludesResponses(review)) {
          dispatch({ type: C.REVIEW_RESPONSES_COMPLETE })
        }
        dispatch(getPublishedReviews(review.patient))
      }
    } catch (err) {
      handleError(dispatch, err, C.ERROR, C.SAVE_DRAFT_REVIEW_REQUEST)
      // if err.message contains document update conflict, display a phrase to allert user that one or more patients might be working on this.
      dispatch(notifyActions.addNotification(notification(`Unable to save review. ${err.message}`)))
    }
  }
}

export const unpublishReview = review => {
  return async dispatch => {
    handleRequest(dispatch, C.REQUEST, C.UNPUBLISH_MSR)
    try {
      const response = await reviewService.saveReview(review)
      dispatch({
        type: C.UNPUBLISH_MSR,
        response,
        review
      })
      dispatch(getDraftReviews(review.patient))
      dispatch(getPublishedReviews(review.patient))
    } catch (err) {
      handleError(dispatch, err, C.ERROR, C.UNPUBLISH_MSR)
    }
  }
}

export const deleteDraftReview = review => {
  return async dispatch => {
    handleRequest(dispatch, C.REQUEST, C.DELETE_DRAFT)
    try {
      const response = await reviewService.deleteDraftReview(review)
      dispatch({
        type: C.DELETE_DRAFT,
        response,
        review
      })
      dispatch(getDraftReviews(review.patient))
      dispatch(getPublishedReviews(review.patient))
    } catch (err) {
      handleError(dispatch, err, C.ERROR, C.DELETE_DRAFT)
    }
  }
}

export const getPatientData = patientId => {
  return async dispatch => {
    handleRequest(dispatch, C.REQUEST, C.PATIENT_DATA)
    try {
      const response = await reviewService.getPatientData(patientId)
      dispatch({
        type: C.PATIENT_DATA,
        payload: response
      })
    } catch (err) {
      handleError(dispatch, err, C.ERROR, C.PATIENT_DATA)
    }
  }
}

export const rejectAndReturnToEcpn = () => {
  return async dispatch => {
    handleRequest(dispatch, C.REQUEST, C.REJECT_AND_RETURN)
    try {
      dispatch({ type: C.REJECT_AND_RETURN })
    } catch (err) {
      handleError(dispatch, err, C.ERROR, C.PATIENT_DATA)
    }
  }
}

const notification = (message, type, sticky = true) => ({
  message,
  type: type || 'error',
  sticky
})
