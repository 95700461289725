/*
 * Schema reducer
 */

import { getReferralOptions, getNMRP } from 'services/schema'

import * as C from './types'

const reducer = (
  state = {
    loading: false,
    error: null,
    allReferrals: getReferralOptions().allReferrals,
    nonMedicationRelatedProblems: getNMRP().nmrp
  },
  action
) => {
  switch (action.type) {
  case C.REQUEST:
    return {
      ...state,
      loading: true,
      error: null
    }
  case C.ERROR:
    return {
      ...state,
      loading: false,
      error: action.error
    }
  case C.REFERRAL_OPTIONS:
    return {
      ...state,
      loading: false,
      allReferrals: action.payload
    }
  case C.NON_MEDICATION_RELATED_PROBLEMS:
    return {
      ...state,
      loading: false,
      nonMedicationRelatedProblems: action.payload
    }
  default:
    return state
  }
}

export default reducer
