import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { canAccess } from 'services/idam'

/**
 * This is a component, which will likely need to be altered in the future to accommodate a better
 * IdAM system in place for MedWise and related TRHC apps
 * @extends Component
 * @param {Boolean} requireIdentity Whether an identity is required (may not be necessary since almost everything in the app requires you to be authed before access)
 * @param {Object} access The permissions object definitions
 * @param {Object} access.permissions The permissions object which specifies the EAON requirements for permissions
 * @param {Object} access.roles The roles object which specifies the EAON strategy
 * @param {Object} access.organizations The organizations object witch specifies the EAON strategy
 * @param {Component} rejectComponent A component to display if the access test fails, other wise nothing is rendered.
 */
class IdAM extends Component {
  static propTypes = {
    identity: PropTypes.bool,
    access: PropTypes.oneOfType([
      PropTypes.shape({
        permissions: PropTypes.object,
        roles: PropTypes.object,
        organizations: PropTypes.object
      }),
      PropTypes.func
    ])
  }

  render () {
    const { auth, requireIdentity, access, rejectComponent, children, ...props } = this.props

    const hasAccess = canAccess(auth, requireIdentity, access)

    return (
      <Fragment>
        {!hasAccess ? rejectComponent ? <rejectComponent {...props} /> : null : children}
      </Fragment>
    )
  }
}

const mapStateToProps = ({ auth }) => ({ auth })

export default connect(mapStateToProps)(IdAM)
