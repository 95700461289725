/*
 * Dashboard side effects tab
 */

import React, { Component } from 'react'
import dayjs from 'dayjs'

import TitleCell from 'components/common/table-cells/title-cell'
import StandardTab from 'components/common/standard-tab'
import { get } from 'lodash'

// SideEffect and Allergies are the same form.
// To differentiate, SideEffects passes in isSideEffect: true as an initalValue.
import { SideEffectForm } from './allergies-side-effects-form'
import { formatUserName } from 'services/utils'
import { DateFormat } from 'constants/date'

const OBJECT_NAME = 'sideeffect'

class SideEffects extends Component {
  constructor (props) {
    super(props)

    this.formHandler = this.formHandler.bind(this)
    this.handleSideEffectDelete = this.handleSideEffectDelete.bind(this)
  }
  filterToSideEffects = allergyArray => allergyArray.filter(a => a.isSideEffect)

  DEFAULT_STRUCTURE = {
    substance: {
      text: undefined,
      coding: [
        {
          system: undefined,
          display: undefined,
          type: undefined
        },
        {
          system: undefined,
          display: undefined,
          type: undefined,
          value: undefined
        }
      ]
    },
    reaction: undefined,
    description: undefined,
    sensitivityType: 'allergy',
    type: 'patient.allergy',
    subject: this.props.patient.data._id,
    status: 'active',
    isSideEffect: true,
    onsetDate: undefined,
    sideEffectNote: undefined
  }

  /**
   * Sends the side effect to the store to be removed from the list.
   * @param  {object}  data The side effect to be set to inactive.
   */
  async handleSideEffectDelete (data) {
    const { patientDataActions } = this.props
    await patientDataActions.deletePatientData(data)
  }

  async formHandler (data, editing) {
    const { patientDataActions } = this.props
    // side effects are currently saved as allergies with isSideEffect: true
    await patientDataActions.updatePatientData(data, 'allergy', editing ? 'post' : 'post')
  }
  render () {
    const { patientData } = this.props
    let headers = [
      {
        name: 'Name',
        classNames: 'w-30',
        maps: [
          { propName: 'boldText', value: 'substance.text' },
          { propName: 'descriptionText', value: 'cellText' },
          { propName: 'lightText', value: 'reaction' },
          { propName: 'boldClass', default: 'truncate' }
        ],
        component: TitleCell
      },
      { name: 'Summary', classNames: 'w-20', maps: 'description' },
      {
        name: 'Onset Date',
        maps: allergy =>
          allergy.onsetDate
            ? dayjs(get(allergy, 'onsetDate')).format(DateFormat.DATE_DISPLAY)
            : undefined
      },
      { name: 'Reported By', classNames: 'truncate', maps: se => formatUserName(se) },
      {
        name: 'Updated',
        maps: allergy => dayjs(get(allergy, 'updatedOn')).format(DateFormat.DATE_DISPLAY)
      }
    ]

    let data = this.filterToSideEffects(get(patientData, 'allergies', []))

    return (
      <StandardTab
        name="Side Effect"
        tableHeaders={headers}
        tableData={data}
        object={OBJECT_NAME}
        form={SideEffectForm}
        initialValues={this.DEFAULT_STRUCTURE}
        formHandler={this.formHandler}
        handleDelete={this.handleSideEffectDelete}
        deleteAlert
        pagination={{
          pageSize: 5,
          maxPages: 5
        }}
        {...this.props}
      />
    )
  }
}

// const mapStateToProps = ({ patientData }) => ({ patientData })

export default SideEffects
