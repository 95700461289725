import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { MuiThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DayjsUtils from '@date-io/dayjs'
import * as Sentry from '@sentry/browser'
import materialTheme from 'theme/MaterialTheme'

import rootReducer from 'store'

import App from 'components/app'
import StyleSnackbarProvider from 'components/common/styled-snackbar-provider'
import { unregister } from './registerServiceWorker'
import { workflowMiddlware, backendWorkflowMiddleware } from 'store/modules/patient/middleware'

import { MixpanelProvider } from 'react-mixpanel-browser'

import './styles/index.scss'
import '@jrs/trhc-tachyons'

Sentry.init({
  dsn: process.env.REACT_APP_RAVEN_DSN,
  logger: 'map-front-end',
  release: process.env.REACT_APP_VERSION,
  environment: process.env.NODE_ENV,
  defaultIntegrations: false
})

// Now you can dispatch navigation actions from anywhere!
// store.dispatch(push('/foo'))
export const history = createBrowserHistory()

const initialState = {}
const enhancers = []
const middleware = [workflowMiddlware, backendWorkflowMiddleware, thunk, routerMiddleware(history)]

const isNOTproduction =
  process.env.NODE_ENV === 'development' || ['local', 'develop', 'staging'].includes(process.env.REACT_APP_ENVIRONMENT)

if (isNOTproduction) {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

const store = createStore(rootReducer(history), initialState, composedEnhancers)

ReactDOM.render(
  <MuiPickersUtilsProvider utils={DayjsUtils}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <MuiThemeProvider theme={materialTheme()}>
          <StyleSnackbarProvider>
            <MixpanelProvider>
              <App />
            </MixpanelProvider>
          </StyleSnackbarProvider>
        </MuiThemeProvider>
      </ConnectedRouter>
    </Provider>
  </MuiPickersUtilsProvider>,
  document.getElementById('root')
)

// we shouldn't need this in the future. removing because it seems to be buggy
unregister()

// expose redux store when run in Cypress
if (isNOTproduction && window.Cypress) {
  window.store = store
}
