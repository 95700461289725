/*
* BadgedIcon component that wraps an icon with a badge prop.
*
*
*
*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class BadgedIcon extends Component {
  render () {
    const { icon, badge, backgroundColor } = this.props
    return (
      <span className="wrapper relative">
        <span className="material-icons">{icon}</span>
        <span className={`absolute f6 ph1 bg-${backgroundColor} top--1 left-1 br1 white courier`}>
          {badge}
        </span>
      </span>
    )
  }
}

BadgedIcon.propTypes = {
  icon: PropTypes.string,
  backgroundColor: PropTypes.string,
  badge: PropTypes.string
}

export default BadgedIcon
