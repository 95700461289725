/**
 * The dashboard is basically a shell for the whole inner app.
 * Below is a proposed structure for dashboard content "boxes":
 *
 * {
     id: 'name', // the name of the box
     enabled: true | false | function(props) {}, // whether a box is enabled,
                                                    the function allows you to
                                                    return true/false to control
     tabs: [], // the tabs
     buttons: [] // the buttons
   }
 *
 * tab: {
     name: 'Name' || function(props) {}, // Can be a string or a function
                                         // with props passed, should return a
                                         // string
     component: Component, // the component object to display in the tab content
     active: true | false, // whether this is active or not, controlled via
                              DashboardEmitter
     enabled: true | false | function(props) {}, // use this to control
                                                    permissions, defaults true
     children: [] // child tabs, these have the same structure
 * }
 *
 * button: {
     name: 'Name',
     component: Component, // its easier to just pass a component as the button.
     enabled: true | false | function(props) {}, // same as above
 * }
 */

import {ActionableListContainer} from '../medRecContainer'

export default [
  {
    id: 'actionable-list',
    enabled: true,
    tabs: [
      {
        id: 'actionable-list-example',
        name: 'Actionable List',
        component: ActionableListContainer,
        active: true
      },
    ],
    buttons: []
  },
]
