import { get, forIn } from 'lodash'
/**
 * EAON Strategy - Equal, And, Or, Not
 * This strategy is based off MedWise's current IdAM (which will change soon)
 * permissions, roles, and organizations are all arrays on a user profile. they contain simple,
 * string-based information which is used to determine if a user has access to "something"
 *
 * @type $eq Specifies that access must have this value (shortcut for `$or: ['singleValue']`)
 * @type $and Specifies that access must be equal to all values in this array
 * @type $or Specifies that access must be equal to some values in this array
 * @type $not Specifies that access must not contain this value or values
 */
export const eaon = (type, checkType, condition, access) => {
  switch (type) {
  case 'permissions':
    let permissionsMap = {}
    forIn(access, (permission, key) => {
      if (!permissionsMap[key]) {
        permissionsMap[key] = true
      }
      permission.forEach(subPermission => {
        permissionsMap[`${key}.${subPermission}`] = true
      })
    })

    if (checkType === '$eq') {
      return get(permissionsMap, condition, false)
    } else if (checkType === '$and') {
      return condition.every(val => get(permissionsMap, val, false))
    } else if (checkType === '$or') {
      return condition.some(val => get(permissionsMap, val, false))
    } else if (checkType === '$not') {
      return condition.every(val => !get(permissionsMap, val, false))
    }
    return false
  case 'roles':
  case 'organizations':
    if (checkType === '$eq') {
      return access.includes(condition)
    } else if (checkType === '$and') {
      return condition.every(val => access.includes(val))
    } else if (checkType === '$or') {
      return condition.some(val => access.includes(val))
    } else if (checkType === '$not') {
      return condition.every(val => !access.includes(val))
    }
    return false
  default:
    return false
  }
}

export const canAccess = (auth, requireIdentity = false, access = {}) => {
  if (!auth.isAuthenticated && requireIdentity) {
    return false
  }

  try {
    if (access && typeof access === 'function') {
      return access(auth.profile)
    }
    const conditions = Object.entries(access)
    if (!conditions.length) return true
    return conditions.every(([type, condition]) => {
      const restrictions = Object.entries(condition)
      if (!restrictions.length) return true
      return restrictions.every(([checkType, value]) => {
        return eaon(type, checkType, value, auth.profile[type])
      })
    })
  } catch (err) {
    return false
  }
}
