/**
 * Context button component
 *
 * @param {string|object} text the text to appear on the button,
 *                             or it could be another component
 * @param {string} type The button type
 * @param {function} onClick the method to call on click, do we need other events?
 * @param {string|function} className the class name or method to build it (passed props)
 * @param {array} dropdown an array of {name, onClick} options for a drop down
 * @param {object} context the optional context to pass to this component
 */

import React, { Component } from 'react'
import Tooltip from '@material-ui/core/Tooltip'

import { findDOMNode } from 'react-dom'
import classNames from 'classnames'
import { get as _get, kebabCase } from 'lodash'
import ConditionalWrap from 'conditional-wrap'

import './styles/context-buttons.scss'

class ContextButton extends Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false
    }

    this._onWindowClick = this._onWindowClick.bind(this)
    this.toggleDropDown = this.toggleDropDown.bind(this)
  }

  componentDidMount () {
    window.addEventListener('click', this._onWindowClick)
    window.addEventListener('touchstart', this._onWindowClick)
  }

  componentWillUnmount () {
    window.removeEventListener('click', this._onWindowClick)
    window.removeEventListener('touchstart', this._onWindowClick)
  }

  // private function to handle global clicks
  _onWindowClick (event) {
    const dropdownElement = findDOMNode(this)
    if (
      event.target !== dropdownElement &&
      !dropdownElement.contains(event.target) &&
      this.state.open
    ) {
      this.toggleDropDown()
    }
  }

  toggleDropDown = () => {
    if (this.props.disabled) return
    this.setState({ open: !this.state.open })
  }

  handleMainClick () {
    const { disabled, dropdown, onClick } = this.props
    if (disabled) return
    dropdown ? this.toggleDropDown() : onClick && onClick(this.props.context || this)
  }

  handleDropdownClick (onClick, event) {
    if (this.props.disabled) return
    this.toggleDropDown()
    onClick(this.props.context || this)
  }

  render () {
    let { type, text, children, className, wrapperClass, dropdown, disabled, hint } = this.props
    let that = this
    let dropDownDOM
    if (dropdown && Array.isArray(dropdown)) {
      dropDownDOM = dropdown.map((d, i) => {
        return (
          <button
            key={`dropdown-${d.name}-${i}`}
            onClick={that.handleDropdownClick.bind(that, d.onClick)}
          >
            {d.name}
          </button>
        )
      })
    }

    let buttonWrapper = (
      <div
        className={classNames('button-wrapper', wrapperClass, 'dib')}
        data-cy={_get(this.props, 'data-cy')}
      >
        <ConditionalWrap
          condition={!!hint}
          wrap={children => (
            <Tooltip title={hint} interactive>
              {children}
            </Tooltip>
          )}
        >
          <button
            data-cy={`context-btn${
              this.props.cypressSuffix ? `-${kebabCase(this.props.cypressSuffix)}` : ''
            }`}
            type={type}
            className={`${className} ${this.state.open ? 'open' : 'closed'} ${
              disabled ? 'disabled' : ''
            }`}
            onClick={this.handleMainClick.bind(this)}
            disabled={disabled}
          >
            {children || text}
          </button>
        </ConditionalWrap>

        <span className={`dropdown ${this.state.open ? 'open' : 'closed'}`}>{dropDownDOM}</span>
      </div>
    )

    return buttonWrapper
  }
}

export default ContextButton
