/*
 * MDS Actions
 */

export const MDS_GET_SCORES = 'MDS_GET_SCORES'
export const MDS_SET_SCORES = 'MDS_SET_SCORES'
export const MDS_GET_SUMMARY = 'MDS_GET_SUMMARY'
export const MDS_SET_SUMMARY = 'MDS_SET_SUMMARY'
export const MDS_CLEAR = 'MDS_CLEAR'
export const MDS_CLEAR_SCORES_LOADING = 'MDS_CLEAR_SCORES_LOADING'
export const MDS_CLEAR_SUMMARY_LOADING = 'MDS_CLEAR_SUMMARY_LOADING'
export const MDS_GET_VISUALIZATION = 'MDS_GET_VISUALIZATION'
export const MDS_SET_VISUALIZATION = 'MDS_SET_VISUALIZATION'
export const MDS_GET_LAUNCH_ID = 'MDS_GET_LAUNCH_ID'
export const MDS_SET_LAUNCH_ID = 'MDS_SET_LAUNCH_ID'
export const MDS_GET_BATCH = 'MDS_GET_BATCH'
export const MDS_SET_BATCH = 'MDS_SET_BATCH'
