import React, { useImperativeHandle, useRef } from 'react'
import { DragSource, DropTarget, } from 'react-dnd'

export const ItemTypes = {
  DRAGGABLE_ROW: 'draggable_row',
}

const style = {
  cursor: 'grab',
}

const DraggableRow = React.forwardRef(({ key, className, children, isDragging, connectDragSource, connectDropTarget }, ref) => {
  const elementRef = useRef(null)
  connectDragSource(elementRef)
  connectDropTarget(elementRef)
  const opacity = isDragging ? 0 : 1
  useImperativeHandle(ref, () => ({
    getNode: () => elementRef.current,
  }))

  return (
    <tr key={key} className={className} ref={elementRef} style={{ ...style, opacity }}>
      {children}
    </tr>
  )
})

export default DropTarget(ItemTypes.DRAGGABLE_ROW, {
  hover(props, monitor, component) {

    if (!component) {
      return null
    }

    // node = HTML Div element from imperative API
    const node = component.getNode()
    if (!node) {
      return null
    }
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index
    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }
    // Determine rectangle on screen
    const hoverBoundingRect = node.getBoundingClientRect()
    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
    // Determine mouse position
    const clientOffset = monitor.getClientOffset()
    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top
    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%
    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return
    }
    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return
    }
    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex)
    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex
  },
}, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
}))(DragSource(ItemTypes.DRAGGABLE_ROW, {
  beginDrag: (props) => ({
    id: props.id,
    index: props.index,
  }),
  endDrag(props, monitor){
    props.moveRowEnd()
  }
}, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))(DraggableRow))
