import React from 'react'
import FailureComponent from './standard-failure'

import log from 'services/logger'

/**
 * Use `ErrorBoundary` to wrap components. There is a default `StandardFailure` message, but can be changed by passing in the `fallbackComponent` prop, which will have `error` passed to it.
 * @extends React
 *
 * @example
 * <ErrorBoundary>
 *  <Child />
 *  </ErrorBoundary>
 */
class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError (error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error }
  }

  componentDidCatch (error, info) {
    // You can also log the error to an error reporting service
    log(info, error, true)
  }

  render () {
    const { fallBackComponent } = this.props
    if (this.state.hasError) {
      return fallBackComponent ? (
        <fallBackComponent error={this.state.error} />
      ) : (
        <FailureComponent error={this.state.error} />
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
