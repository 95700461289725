import dayjs from 'dayjs'
import { get, set } from 'lodash'
import { DateFormat, Messages } from 'constants/date'
import { isValidDateObject, isFuture } from 'validators/date'

export default (note) => {
  var errors = {}
  if (!note.year) {
    set(errors, 'year', 'Year is required')
  }
  if (!note.engagement) {
    set(errors, 'engagement', 'Engagement is required')
  }
  if (!note.title) {
    set(errors, 'title', 'Title is required')
  }
  const dateAdded = get(note, 'dateAdded')
  const validateDate = (date, errorKeyName) => {
    if (!isValidDateObject(dayjs(date).format(DateFormat.DATE_DISPLAY_SHORT), DateFormat.DATE_DISPLAY_SHORT)) return set({}, errorKeyName, Messages.INVALID_DATE)
    if (isFuture(date)) return set({}, errorKeyName, Messages.TODAY_OR_PAST)
  }  
  if (!dateAdded) {
    set(errors, 'dateAdded', 'Date Added is required')
  }
  if (dateAdded) {
    const dateErrors = validateDate(dateAdded, 'dateAdded')
    errors = Object.assign(errors, dateErrors)
  }
  return errors
}

